import cx from "classnames";
import { AlertType } from "../../interfaces/enums";
import { AlertContent } from "../../interfaces/interfaces";

interface Props {
  content?: AlertContent;
  children?: any;
}

//TODO - Refactor this so that content.message becomes children and type is a separate prop
//Also - type should be an enum
const Alert = (props: Props) => {
  const { content, children } = props;
  let success, warning, error, info;
  let mainContent;

  if (!content) {
    mainContent = children;
    info = true;
  } else {
    const { type, message } = content;
    success = type === AlertType.Success;
    warning = type === AlertType.Warning;
    error = type === AlertType.Error;
    info = type === AlertType.Info || (!success && !warning && !error);

    mainContent = message;
  }

  return (
    <div
      className={cx({
        "custom-alert": true,
        "custom-alert-info": info,
        "custom-alert-success": success,
        "custom-alert-warning": warning,
        "custom-alert-error": error
      })}
      role="alert"
    >
      {mainContent}
    </div>
  );
};

export default Alert;
