import { useDispatch, useSelector } from "react-redux";
import IconWrapper from "../../../../components/IconWrapper/IconWrapper";
import { Container } from "../../../../components/styled/styled-components";
import { FreightOption } from "../../../../interfaces/types";
import { updateFreightOption } from "../../../../redux/actions/orders";
import { RootState } from "../../../../redux/store";
import Icon from "../../../../components/Icon/icon";

const FreightOptions = () => {
  const freightOption: FreightOption = useSelector(
    (state: RootState) => state.orders.freightOption
  );

  const dispatch = useDispatch();

  const setFreightOption = (option: FreightOption) => {
    dispatch(updateFreightOption(option));
  };

  return (
    <Container row margin="1em 1em 1em 0">
      <IconWrapper
        text="Road"
        isSelected={freightOption === "by_ground"}
        isMultiChoice
        onClick={() => setFreightOption("by_ground")}
      >
        <Icon name="ShippingFast" />
      </IconWrapper>
      <div style={{ marginLeft: "1rem", marginRight: "1rem" }}>
        <IconWrapper
          text="Air"
          isSelected={freightOption === "by_air"}
          isMultiChoice
          onClick={() => setFreightOption("by_air")}
        >
          <Icon name="PlaneArrival" />
        </IconWrapper>
      </div>
      <IconWrapper
        text="Pick up"
        isSelected={freightOption === "pickup"}
        isMultiChoice
        onClick={() => setFreightOption("pickup")}
      >
        <Icon name="PeopleCarry" />
      </IconWrapper>
    </Container>
  );
};

export default FreightOptions;
