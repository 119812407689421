import { IconNames, icons, SvgIcons } from './types';

interface IProps {
  name: IconNames;
  className?: string;
  iconWidth?: string;
}

export const isIconNameExist = (iconName: string) => icons.includes(iconName as IconNames);

export default function Icon(props: IProps) {
  const { name, className, iconWidth = '1rem' } = props;
  const SvgIcon = SvgIcons[name];

  if (!isIconNameExist(name)) {
    return <svg width={iconWidth} height={iconWidth} className="empty_block" />;
  }

  return <SvgIcon width={iconWidth} className={(className)} role="icon" />;
}
