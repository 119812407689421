import { SVGProps } from "react"
const ArrowUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 138.6 139.4"
    {...props}
  >
    <path d="M74.2 18.6c-.1.8-.1 1.2-.1 1.5 0 33.6 0 67.2.1 100.8 0 4.7.1 9.4.1 14.2 0 2.7-.8 3.8-3.4 4.1-1.4.2-3 .1-4.4-.2-1.5-.2-2.3-1.3-2.4-2.8-.1-1.2 0-2.5 0-3.7V20.1c0-.4-.1-.7-.1-1.4-.5.4-.8.6-1.1.9C44.8 37.8 26.8 56 8.7 74.1c-1.9 1.9-3.1 2-5.2.2-.8-.7-1.6-1.4-2.3-2.1-1.5-1.6-1.5-3.6 0-5.2C15.6 52.6 30 38 44.5 23.6 51.7 16.3 59 9.1 66.3 1.9c2.6-2.5 3.8-2.6 6.3-.1 9.8 9.8 19.7 19.6 29.5 29.4 11.4 11.5 22.9 22.9 34.3 34.5.9.9 1.6 2 2.1 3.1.5 1.2-.1 2.4-1.1 3.2-.9.8-1.7 1.7-2.6 2.5-1.7 1.5-2.7 1.5-4.3-.1-1.9-1.8-3.7-3.7-5.5-5.5L76.3 20.2c-.6-.5-1.2-.9-2.1-1.6z" />
  </svg>
)
export default ArrowUp
