import { useState } from "react";
import { useHistory } from "react-router";
import IconButton from "../IconButton/IconButton";
import { StyledInput } from "../styled/styled-components";
import Icon from "../Icon/icon";

const SearchBar = ({ route, page, searchQuery, value, }:
  {
    route: string;
    page: number | null;
    searchQuery: string;
    value?: string;
  }) => {
  const [searchValue, setSearchValue] = useState<string>(value || "");
  const history = useHistory();

  const isSpaceOnly = (str: string) => str.trim().length === 0;

  const onSubmitSearch = (event: React.KeyboardEvent  | null) => {
    const isButtonClicked = !event;
    if (isButtonClicked || event?.key === "Enter") {
      if (isSpaceOnly(searchValue)) {
        onSearch("");
      } else {
        onSearch(searchValue);
      }
    }
  };

  const onSearch = (input: string) => {
    const newRoute = page
      ? `${route}=${input}&page=${page ?? 1}`
      : `${route}=${input}`;
    history.push(newRoute);
  };

  return (
    <div className="search-bar">
      <form
        onSubmit={(event: React.FormEvent) => {
          event.preventDefault();
        }}
      >
        <StyledInput
          type="text"
          placeholder={searchQuery}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setSearchValue(event.target.value)
          }
          onKeyPress={(event: React.KeyboardEvent) => onSubmitSearch(event)}
          role="searchbox"
          value={searchValue}
        />
        <IconButton onClick={() => onSubmitSearch(null)}>
          <Icon name="SearchIcon" />
        </IconButton>
      </form>
    </div>
  );
};

export default SearchBar;
