import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { subdomainTypes } from "../../../../context/availableSubdomains";

const styles = StyleSheet.create({
  companyInfoContainer: {
    marginLeft: "auto",
  },
  infoLine: {
    marginBottom: 8,
    fontSize: 11,
  },
});

interface Props {
  appBranch: subdomainTypes | "";
}

const branchDescriptions = {
  au: [
    "Fashion Biz (AUST) Pty Ltd",
    "16-20 Salta Drive, Altona North",
    "Tel: +61 3 9368 7800",
    "Email: ausorders@fashbiz.com.au",
  ],
  nz: [
    "Fashion Biz Limited",
    "11 Hautu Drive, Wiri",
    "Auckland 2104, New Zealand",
    "Tel: +64 9 279 8464",
    "Email: orders@fashionbiz.co.nz",
    "GST No.: 52-114-977",
  ],
  ca: [
    "Fashion Biz Wholesale (CAN) Ltd",
    "7898 North Fraser Way, Burnaby",
    "BC V5J0C7, Canada",
    "Tel: +1 855-424-9226",
    "Email: orders@fashionbiz.ca"
  ],
};

const FashionBizInfo = (props: Props) => {
  const { appBranch } = props;
  const descriptions = branchDescriptions[appBranch as subdomainTypes] || [];
  return (
    <View style={styles.companyInfoContainer}>
      <View>
        {descriptions.map((description, index) => (
          <Text key={`description-${index}`} style={styles.infoLine}>
            {description}
          </Text>
        ))}
      </View>
    </View>
  );
};

export default FashionBizInfo;
