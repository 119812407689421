import { useEffect, useState } from "react";

interface Props {
  title: string;
  expanded?: boolean;
  children: any;
}

const Accordion = (props: Props) => {
  const { title, expanded, children } = props;
  const [isExpanded, setIsExpanded] = useState(expanded);

  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  return (
    <div className="accordion">
      <div
        className="accordion__title"
        onClick={() => setIsExpanded(!isExpanded)}
        role="button"
        aria-expanded={isExpanded}
      >
        {title}
        <span>{isExpanded ? "-" : "+"}</span>
      </div>
      <div
        className={
          isExpanded
            ? "accordion__content accordion__content__expanded"
            : "accordion__content"
        }
        aria-hidden={!isExpanded}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
