// NOTE: CalculatedTax is not longer user, instead we use `order.tax_calculated` from Halfback
export const calculateTax = (
  total_amount_before_tax: string,
  freight_fee: number,
  tax_rate: string
): string | null => {
  if (
    !total_amount_before_tax ||
    !tax_rate ||
    Number.isNaN(parseFloat(total_amount_before_tax)) ||
    Number.isNaN(parseFloat(tax_rate))
  ) {
    return null;
  }

  return (
    (parseFloat(total_amount_before_tax) + freight_fee) *
    parseFloat(tax_rate)
  ).toFixed(2);
};

export const formatToCostString = (cost: string | number | null): string => {
  if (typeof cost === "string") {
    if (Number.isNaN(parseFloat(cost))) {
      return "N/A";
    }
    return cost ? `$${parseFloat(cost).toFixed(2)}` : "N/A";
  } else {
    return cost ? `$${cost.toFixed(2)}` : "N/A";
  }
};
