import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { log } from "../../../commonfunctions/logger";
import AuthenticationHOC from "../../../components/AuthenticationHOC/AuthenticationHOC";
import LoaderButton from "../../../components/LoaderButton/LoaderButton";
import {
  Container,
  StyledInput,
  StyledTextArea
} from "../../../components/styled/styled-components";
import { useDocumentTitle } from "../../../hooks/document-title";
import {
  setCurrentOrderShipAddress,
  setExtraInfo,
  updateFreightOption
} from "../../../redux/actions/orders";
import { RootState } from "../../../redux/store";

interface Props {
  generateAuthHeader: () => any;
}

function ExtraInfo({ generateAuthHeader }: Props) {
  useDocumentTitle("Checkout - Extra information");
  const history = useHistory();
  const { push } = history;
  const ORIGINAL_PO_ERROR = "* Required";
  const [PO, setPO] = useState({
    number: "",
    valid: false,
    isChecking: false,
    error: ORIGINAL_PO_ERROR,
    showError: true
  });

  const [comments, setComments] = useState("");
  const [associatedEmail, setAssociatedEmail] = useState({
    address: "",
    error: "* Invalid Email Format",
    showError: false
  });

  const currentOrder = useSelector(
    (state: RootState) => state.orders.currentOrder
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!currentOrder || currentOrder.order_items.length === 0) {
      push("/store/cart");
      return;
    }
  }, [currentOrder, push]);

  useEffect(() => {
    const goToShippingSelectionPage = () => {
      dispatch(
        setExtraInfo({
          po_number: PO.number,
          comments,
          associated_email: associatedEmail.address
        })
      );
      return push("/check-out/shipping-selection");
    };

    if (PO.valid) {
      dispatch(updateFreightOption(null));
      dispatch(setCurrentOrderShipAddress(null));
      goToShippingSelectionPage();
    }
  }, [PO.valid, PO.number, associatedEmail.address, comments, dispatch, push]);

  const handlePOChange = (text: string) => {
    const value = text.trim().replaceAll(" ", "").replaceAll("\t", "");

    if (!value) return setPO({
      ...PO,
      number: value,
      error: ORIGINAL_PO_ERROR,
      showError: true
    });

    const SPECIAL_REGEX =
      /`|~|&|\*|\+|=|\[|\{|\]|\}|\||\\|'|<|,|\.|>|\?|\/|""|;|:/g;
    if (SPECIAL_REGEX.test(value)) return setPO({
      ...PO,
      error: "Special characters entered. Not Allowed.",
      showError: true
    });

    setPO({ ...PO, showError: false, number: value, error: "" });
  };

  const handleAssociatedEmail = (value: string) => {
    const email = value.trim();

    return setAssociatedEmail({
      ...associatedEmail,
      address: email,
      showError: false
    });
  };

  const validateEmailFormat = () => {
    const email = associatedEmail.address;

    if (!email) return setAssociatedEmail({ ...associatedEmail, showError: false });

    const EMAIL_REGEX =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!EMAIL_REGEX.test(email)) return setAssociatedEmail({ ...associatedEmail, showError: true });

    return setAssociatedEmail({
      ...associatedEmail,
      address: email,
      showError: false
    });
  };

  const validatePONumber = async () => {
    if (!PO.number) return setPO({ ...PO, error: ORIGINAL_PO_ERROR, showError: true });
    setPO({ ...PO, isChecking: true });

    const url = `${process.env.REACT_APP_HALFBACK_ROOT_API}/orders/current_order/check_po_number?po_number=${PO.number}`;

    try {
      const res = await fetch(url, generateAuthHeader());

      if (res.ok) {
        const data = await res.json();
        if (data.isExist) {
          setPO({
            ...PO,
            error: "* This PO Number is already taken, please enter a new one.",
            valid: false,
            showError: true,
            isChecking: false
          });
        }
        else {
          setPO({
            ...PO,
            error: ORIGINAL_PO_ERROR,
            valid: true,
            showError: false,
            isChecking: false
          });
        }
      } else {
        const error = await res.text();
        throw error;
      }
    } catch (error) {
      const stringifiedError = JSON.stringify(error).toLowerCase();
      log(stringifiedError, "PO number submission error");

      setPO({
        ...PO,
        error:
          "* Sorry, we can not check this PO. Please try later, or contact us.",
        valid: false,
        showError: true,
        isChecking: false
      });
    }
  };

  return (
    <Container className="extra-info">
      <form>
        <section role="group">
          <div style={{ display: "flex", alignItems: "center" }}>
            <p>
              <strong>PO Number</strong>
            </p>
            {PO.showError ? (
              <div style={{ marginLeft: "1em" }}>
                <span className="warning">{PO.error}</span>
              </div>
            ) : (
              <></>
            )}
            {!PO.isChecking && (
              <span style={{ marginLeft: "1em" }}>
                <small>{PO.number.length}/20</small>
              </span>
            )}
          </div>
          <StyledInput
            type="text"
            maxLength={20}
            value={PO.number}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handlePOChange(event.target.value)
            }
          />
        </section>

        <section role="group">
          <p>
            <strong>Order Comments</strong>
          </p>
          <p>Please attach any extra infomation to this current order.</p>
          <p>
            <small>
              <b>
                <i>
                  Please note this comment is for you and will not be visible to
                  Fashion Biz
                </i>
              </b>
            </small>
          </p>

          <div>
            <StyledTextArea
              rows={4}
              cols={50}
              maxLength={200}
              value={comments}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                setComments(event.target.value)
              }
            />
            <span style={{ marginLeft: "1em" }}>
              <small>{comments.length}/200</small>
            </span>
          </div>
        </section>

        <section role="group">
          <p>
            <strong>Associated Email</strong>
          </p>
          <p>
            <small>
              <i>
                You may include an additional email address to receive this
                order confirmation.
              </i>
            </small>
          </p>
          <Container row aic>
            <StyledInput
              type="text"
              maxLength={255}
              value={associatedEmail.address}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleAssociatedEmail(event.target.value)
              }
              onBlur={(evt) => {
                if (evt.currentTarget === evt.target) {
                  validateEmailFormat();
                }
              }}
            />
            {associatedEmail.showError ? (
              <div style={{ marginLeft: "1em" }}>
                <span className="warning">{associatedEmail.error}</span>
              </div>
            ) : (
              <></>
            )}
          </Container>
        </section>
        <div className="loader-button-wrapper">
          <LoaderButton
            onClick={(event: React.MouseEvent) => {
              validatePONumber();
              event.preventDefault();
            }}
            isLoading={PO.isChecking}
          >
            Continue
          </LoaderButton>
        </div>
      </form>
    </Container>
  );
}

export default AuthenticationHOC(ExtraInfo);
