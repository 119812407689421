import { useState } from "react";
import { generateAuthHeader } from "../../../commonfunctions/authentication";
import { log } from "../../../commonfunctions/logger";
import {
  StyledButton,
  StyledFormField,
  StyledFormLayout
} from "../../../components/styled/styled-components";
import Toast from "../../../components/Toast/Toast";
import { AlertType } from "../../../interfaces/enums";
import { AlertContent } from "../../../interfaces/interfaces";

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [toast, setToast] = useState<AlertContent | null>(null);

  const validatePassword = (password: string) => {
    const re = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])([!@#$%^&*]?)(?=.{6,10})"
    );

    if (re.test(password.trim())) {
      return true;
    } else {
      return false;
    }
  };

  const changePassword = async () => {
    if (!password || !currentPassword || !confirmPassword) {
      setToast({
        message: "Please fill in all of the fields.",
        type: AlertType.Error
      });
      return;
    }
    if (password !== confirmPassword) {
      setToast({ message: "Passwords do not match", type: AlertType.Error });
      return;
    }

    if (!validatePassword(password)) {
      setToast({
        message:
          "Must be 6 - 10 characters, at least 1 uppercase, 1 lowercase and 1 number",
        type: AlertType.Error
      });
      return;
    }

    setToast(null);

    const params = {
      password: currentPassword,
      new_password: password
    };

    try {
      const res = await fetch(
        `${process.env.REACT_APP_HALFBACK_ROOT_API}/current_customer/reset_password`,
        {
          method: "POST",
          body: JSON.stringify(params),
          headers: {
            ...generateAuthHeader().headers,
            "Content-Type": "application/json"
          }
        }
      );

      if (res.ok) {
        const data = await res.json();

        if (data.status === "success") {
          setToast({
            message: "Password changed successfully",
            type: AlertType.Success
          });
        } else if (data.status === "failed") {
          setToast({
            message: "You have entered the wrong password.",
            type: AlertType.Error
          });
        } else {
          setToast({
            message: "Something went wrong, please try again later.",
            type: AlertType.Error
          });
        }
      } else {
        const error = await res.text();
        throw error;
      }
    } catch (error) {
      log(error, "Failed to change stuff");
    }
  };

  return (
    <div className="preferences-sub-section">
      <div className="change-password">
        <div className="title">
          <h5>Change password</h5>
        </div>
        <div className="main-content forms">
          <StyledFormLayout>
            <form autoComplete="off">
              <StyledFormField>
                <label>Current password</label>
                <input
                  type="password"
                  role="textbox"
                  aria-label="current_password"
                  onChange={(event) => setCurrentPassword(event.target.value)}
                />
              </StyledFormField>
              <StyledFormField>
                <label>New password</label>
                <input
                  type="password"
                  role="textbox"
                  aria-label="new_password"
                  onChange={(event) => setPassword(event.target.value)}
                />
              </StyledFormField>
              <StyledFormField>
                <label>Confirm new password</label>
                <input
                  type="password"
                  role="textbox"
                  aria-label="confirm_new_password"
                  onChange={(event) => setConfirmPassword(event.target.value)}
                />
              </StyledFormField>
            </form>
            <StyledButton primary onClick={() => changePassword()}>
              Change password
            </StyledButton>
          </StyledFormLayout>
        </div>
        <Toast content={toast} onClick={() => setToast(null)} />
      </div>
    </div>
  );
};

export default ChangePassword;
