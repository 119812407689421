import { useContext, useState } from "react";
import { ColorSizeStock } from "../interfaces";
import { Context, IContext } from "../../../context/ContextApp";
import Icon from "../../Icon/icon";

interface IProps {
  allSizes: ColorSizeStock[];
  calculateStock: (size: ColorSizeStock) => void;
}

const locationAU = [
  {
    name: "Melbourne",
    regionID: "MEL",
  },
  {
    name: "Perth",
    regionID: "PER",
  },
  {
    name: "Sydney",
    regionID: "SYD",
  },
];

const AUStock = (props: IProps) => {
  const { allSizes, calculateStock } = props;
  const { appBranch }: IContext = useContext(Context);
  const [isStockDetailsOpen, setIsStockDetailsOpen] = useState(false);
  const arrowIconName = isStockDetailsOpen ? "ArrowUp" : "ArrowDown";
  return (
    <>
      {isStockDetailsOpen &&
        locationAU.map((location) => (
          <tr>
            <td>
              <b style={{ whiteSpace: "nowrap" }}>{location.name}</b>
            </td>
            {allSizes.map((size: ColorSizeStock) => (
              <td key={size.sku} style={{ minWidth: "60px" }}>
                {size.stock.map(
                  (stockLevel) =>
                    stockLevel.location === location.regionID &&
                    stockLevel.qtyAvailable
                )}
              </td>
            ))}
          </tr>
        ))}
      {/* Need to do id for the border top in inside <td> otherwise will be overridden by the bootstrap class css */}
      <tr
        className="auStock-header"
        id="totalAUStock"
        onClick={() => setIsStockDetailsOpen(!isStockDetailsOpen)}
      >
        <td className="total-stock-data-cell">
          <b style={{ whiteSpace: "nowrap" }}>
            <Icon name={arrowIconName} className="auStock-Icon" />
            Total{" "}
            <span style={{ textTransform: "uppercase" }}>{appBranch}</span>{" "}
            Stock
          </b>
        </td>
        {allSizes.map((size: ColorSizeStock) => (
          <td
            key={size.sku + "qty"}
            className="total-stock-data-cell"
            style={{ minWidth: "60px" }}
          >
            <b>{calculateStock(size)}</b>
          </td>
        ))}
      </tr>
    </>
  );
};

export default AUStock;
