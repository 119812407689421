interface Props {
  address: string;
}

const PickUpNZ = ({ address }: Props) => (
  <div className="pickUp">
    Pick Up Warehouse Address
    <div className="alert alert-info" style={{ width: "fit-content" }}>
      {address}
    </div>
    <p>
      *** <i>Using a 3rd party to collect this order?</i> ***
    </p>
    <p>
      Go to <b>Address Book</b> or <b>New Address</b> then select{" "}
      <b>
        <i>Third Party Pick Up</i>
      </b>
      .
    </p>
  </div>
);

export default PickUpNZ;
