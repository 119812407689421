const AgreementLinks = () => (
  <div className="agreement-links">
    <span>By using this webstore, you agree to the following:</span>
    <a
      href="https://cdn.fashionbiz.com/terms_and_conditions/Fashion+Biz+Australia+-+Terms+and+Coditions.pdf"
      target="_blank"
      rel="noopener noreferrer"
    >
      Terms and Conditions
    </a>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://cdn.fashionbiz.com/webstore_legal_docs/Fashion+Biz+Terms+of+Use+NZ.pdf"
    >
      Terms of Use
    </a>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://cdn.fashionbiz.com/webstore_legal_docs/Private+Policy.pdf"
    >
      Privacy Policy
    </a>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://cdn.fashionbiz.com/webstore_legal_docs/RETURN+POLICY.pdf"
    >
      Return Policy
    </a>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://cdn.fashionbiz.com/Fashion+Biz+Content+Usage+Agreement.pdf"
    >
      Content Usage Agreement
    </a>
  </div>
);

export default AgreementLinks;
