import axios from "axios";
import cx from "classnames";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { log } from "../../../commonfunctions/logger";
import AuthenticationHOC from "../../../components/AuthenticationHOC/AuthenticationHOC";
import CartItemTable from "../../../components/CartItemTable/CartItemTable";
import {
  Container,
  StyledButton,
  Wrapper,
} from "../../../components/styled/styled-components";
import { CurrentOrder } from "../../../redux/reducers/orders";
import { RootState } from "../../../redux/store";
import ModalSwitcher from "./ModalSwitcher";

interface SaveParams {
  order_id: string;
  current_order_to_draft: boolean;
  current_order_draft_note?: string;
}

interface Props {
  order: any;
  generateAuthHeader: any;
}

const SavedOrderCard = (props: Props) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const currentOrder: CurrentOrder = useSelector(
    (state: RootState) => state.orders.currentOrder
  );
  const [draftNote, setDraftNote] = useState("");
  const { order, generateAuthHeader } = props;

  /* Modal code to show
    0 - None
    1 - Are you sure you want to load this order (when trying to load order with non-empty cart)
    2 - Set order note for current cart
    3 - Are you sure you want to delete this order
  */
  const [modalToShow, setModalToShow] = useState(0);

  const deleteSavedOrder = () => {
    const url = `${process.env.REACT_APP_HALFBACK_ROOT_API}/draft_orders/delete`;
    setIsLoading(true);
    axios
      .post(url, { order_id: order.id }, generateAuthHeader())
      .then(() => {
        setModalToShow(0);
        setIsLoading(false);
        history.go(0);
      })
      .catch((error) => {
        log(error, "Saved order deletion failed.");
        setIsLoading(false);
      });
  };

  const activateSavedOrder = (saveCurrentorder = false) => {
    const url = `${process.env.REACT_APP_HALFBACK_ROOT_API}/draft_orders/activate`;
    const params: SaveParams = {
      order_id: order.id,
      current_order_to_draft: saveCurrentorder,
    };

    if (saveCurrentorder) {
      params.current_order_draft_note = draftNote;
    }

    setIsLoading(true);
    axios
      .post(url, params, generateAuthHeader())
      .then(() => {
        history.push("/store/cart");
        setIsLoading(false);
      })
      .catch((error) => {
        log(error, "Saved order activation failed");
        setIsLoading(false);
      });
  };

  const {
    note,
    total_items: totalItems,
    total_units: totalUnits,
    total_amount_before_tax: totalAmountBeforeTax,
  } = order;
  return (
    <div className="saved-order-card">
      <div
        className={cx({
          "saved-order-card-preview": true,
          "is-open": isOpen,
        })}
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(!isOpen);
        }}
      >
        <div className="preview-line">Note: {note}</div>
        <div className="preview-line">
          {totalItems} items - {totalUnits} units
        </div>
        <div className="preview-line">
          Subtotal: ${parseFloat(totalAmountBeforeTax).toFixed(2)}
        </div>
        <Container row>
          <StyledButton
            success
            style={{ margin: "0px 5px" }}
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(!isOpen);
            }}
          >
            {!isOpen ? "Expand" : "Close"}
          </StyledButton>
          <StyledButton
            info
            style={{ margin: "0px 5px" }}
            onClick={(e) => {
              e.stopPropagation();
              if (currentOrder.order_items.length > 0) {
                setModalToShow(1);
              } else {
                activateSavedOrder();
              }
            }}
          >
            Load order
          </StyledButton>
        </Container>
      </div>
      {isOpen && (
        <div className="saved-order-main-content">
          <Container row margin="1em 1em 1em auto">
            <StyledButton danger onClick={() => setModalToShow(3)}>
              Delete
            </StyledButton>
          </Container>
          <Wrapper padding="0 0.25em">
            <CartItemTable items={order.order_items} />
          </Wrapper>
        </div>
      )}
      <ModalSwitcher
        modalToShow={modalToShow}
        activateSavedOrder={activateSavedOrder}
        deleteSavedOrder={deleteSavedOrder}
        setModalToShow={setModalToShow}
        isLoading={isLoading}
        setDraftNote={setDraftNote}
      />
    </div>
  );
};

export default AuthenticationHOC(SavedOrderCard);
