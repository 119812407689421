import { View } from "@react-pdf/renderer";
import { formatAddress } from "../../../../../commonfunctions/address";
import TitleAndDetailLine from "../../../components/TitleAndDetailLine/TitleAndDetailLine";

interface Props {
  transactionDetails: any;
  credit?: boolean;
}

const TransactionData = (props: Props) => {
  const { transactionDetails, credit } = props;

  if (credit) {
    return (
      <View>
        <TitleAndDetailLine line={["Billing Address", formatAddress(transactionDetails.billingAddress)]} />
        <TitleAndDetailLine line={["Credit note number", transactionDetails.credit_note_no]} />
        <TitleAndDetailLine line={["Original invoice number", transactionDetails.original_inv_no]} />
        <TitleAndDetailLine line={["RAN", transactionDetails.ran]} />
        <TitleAndDetailLine line={["Ret. Doc. number", transactionDetails.ret_doc_no]} />
        <TitleAndDetailLine line={["Date", transactionDetails.date]} />
        <TitleAndDetailLine line={["Note", transactionDetails.note]} />
        <TitleAndDetailLine line={["Comment", transactionDetails.comment]} />
      </View>
    );
  } else return (
    <View>
      <TitleAndDetailLine line={["Transaction number", transactionDetails.invNumber]} />
      <TitleAndDetailLine line={["Order number", transactionDetails.orderNo]} />
      <TitleAndDetailLine line={["Date", transactionDetails.date]} />
      <TitleAndDetailLine line={["Status", transactionDetails.status]} />
      <TitleAndDetailLine line={["PO number", transactionDetails.po]} />
      <TitleAndDetailLine line={["Shipment", transactionDetails.shipmentNo]} />
      <TitleAndDetailLine line={["Note", transactionDetails.note]} />
      <TitleAndDetailLine line={["Billing Address", formatAddress(transactionDetails.billingAddress)]} />
      <TitleAndDetailLine line={["Shipping Address", formatAddress(transactionDetails.shippingAddress)]} />
    </View>
  );
};

export default TransactionData;
