import { subdomainTypes } from "../../context/availableSubdomains";

export interface Field {
  name: string;
  type: string;
  key: string;
  fixedValue?: string;
}

const AUS_FIELDS: Field[] = [
  { name: "Ship to name", key: "ship_to_name", type: "input" },
  { name: "Ship Address Line 1", key: "address_line1", type: "search" },
  { name: "Ship Address Line 2", key: "address_line2", type: "input" },
  { name: "City", key: "address_city", type: "input-disabled" },
  { name: "State", key: "state", type: "input-disabled" },
  { name: "Country", type: "fixed", key: "country", fixedValue: "Australia" },
  { name: "Post code", key: "address_postcode", type: "input-disabled" },
];

const CA_FIELDS: Field[] = [
  { name: "Ship to name", key: "ship_to_name", type: "input" },
  { name: "Ship Address Line 1", key: "address_line1", type: "input" },
  { name: "Ship Address Line 2", key: "address_line2", type: "input" },
  { name: "City", key: "address_city", type: "input" },
  { name: "Province", key: "address_city", type: "select" },
  { name: "Country", type: "fixed", key: "country", fixedValue: "Canada" },
  { name: "Post code", key: "address_postcode", type: "input" },
];

const NZ_FIELDS: Field[] = [
  { name: "Ship to name", key: "ship_to_name", type: "input" },
  { name: "Search address", key: "address", type: "search" },
];

export const getAddressFormFields = (appBranch: subdomainTypes | '') => {
  switch (appBranch) {
    case "nz":
      return NZ_FIELDS;
    case "au":
      return AUS_FIELDS;
    case "ca":
      return CA_FIELDS;
    default:
      return [];
  }
};
