import { SVGProps } from "react"
const ArrowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 138.6 139.4"
    {...props}
  >
    <path d="M64.4 120.7c.1-.8.1-1.2.1-1.5 0-33.6 0-67.2-.1-100.8 0-4.7-.1-9.4-.1-14.2 0-2.7.8-3.8 3.4-4.1 1.4-.2 3-.1 4.4.2 1.5.2 2.3 1.3 2.4 2.8.1 1.2 0 2.5 0 3.7v112.4c0 .4.1.7.1 1.4.5-.4.8-.6 1.1-.9l54.2-54.5c1.9-1.9 3.1-2 5.2-.2.8.7 1.6 1.4 2.3 2.1 1.5 1.6 1.5 3.6 0 5.2-14.4 14.5-28.9 29-43.3 43.5-7.2 7.2-14.5 14.4-21.8 21.6-2.6 2.5-3.8 2.6-6.3.1-9.8-9.8-19.7-19.6-29.5-29.4-11.4-11.5-22.9-23-34.3-34.5-.9-.9-1.6-2-2.1-3.1-.5-1.2.1-2.4 1.1-3.2.9-.8 1.7-1.7 2.6-2.5 1.7-1.5 2.7-1.5 4.3.1 1.9 1.8 3.7 3.7 5.5 5.5l48.7 48.7c.6.5 1.2.9 2.1 1.6z" />
  </svg>
)
export default ArrowDown
