import { Document, Page } from "@react-pdf/renderer";

interface Props {
  title?: string;
  fontSize?: number;
  children: any;
}

const PDFWrapper = (props: Props) => {
  const { title, fontSize, children } = props;

  return (
    <Document title={title ?? ""}>
      <Page
        size="A4"
        orientation="portrait"
        style={{
          fontSize: fontSize ?? 10,
          fontFamily: "Roboto",
          padding: 15,
        }}
      >
        {children}
      </Page>
    </Document>
  );
};

export default PDFWrapper;
