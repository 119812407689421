import { SVGProps } from "react"
const CheckCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 153.9 154"
    {...props}
  >
    <style>{'.checkCircle{fill:#008000}'}</style>
    <path
    d="M77.2 0c11.5.2 22.5 2.3 32.9 7.2 7.9 3.7 14.8 9 21 15.1 8.7 8.5 15.1 18.4 18.9 30 1.9 5.9 3 12 3.6 18.2.7 6.4.2 12.7-.7 19-1.4 10-4.8 19.3-10.2 27.8-5.8 9.2-13 17.2-21.9 23.5-7.9 5.6-16.5 9.4-25.9 11.5-5.1 1.1-10.2 1.9-15.4 1.8-3.7-.1-7.5 0-11.2-.3-10.6-1-20.4-4.4-29.6-9.7-6.1-3.5-11.7-7.7-16.6-12.8-8.1-8.7-14.7-18.4-18.3-29.8C.5 91.1-.6 80.6.3 69.9c.6-7.3 2.2-14.5 4.9-21.3 2.5-6.2 5.7-12 9.8-17.4 5.8-7.5 12.5-14.1 20.5-19.4 9.2-6 19.4-9.5 30.2-10.9C69.5.4 73.4.3 77.2 0zM9.6 77.2c-1.5 35 30 68.6 70.2 67.4 32.4-1 64.6-29.8 64.7-67.2.2-37.2-32-68.4-69.2-67.7-34.7.7-66.9 30.8-65.7 67.5z"
    className="checkCircle"
    />
    <path
    d="M122.4 56.2c-.5.8-1 1.8-1.7 2.5-3.9 4-7.8 7.9-11.7 11.8-14.7 14.6-29.4 29.1-44.2 43.6-1.9 1.9-3 2-4.9.2-3.4-3.2-6.8-6.6-10.1-10-5.5-5.6-11-11.2-16.4-16.8-.4-.4-.8-.8-1.1-1.3-.8-1.2-.8-2.6.2-3.6 1-1.1 2.1-2.1 3.2-3.1 1.9-1.6 3.5-1.5 5.2.3 4.3 4.4 8.6 8.8 13 13.2 2.4 2.5 4.9 4.9 7.2 7.4.9.9 1.4.9 2.3 0l23-23c8.8-8.8 17.5-17.6 26.3-26.3 2-2 3.2-2.1 5.4-.1 1.3 1.2 2.4 2.4 3.6 3.7.4.3.4.8.7 1.5z"
    className="checkCircle"
    />
  </svg>
)
export default CheckCircle
