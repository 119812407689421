import { FreightOption } from "../../../interfaces/types";

export const formatFreightOption = (option: FreightOption | null) => {
  if (!option) {
    return "";
  }

  const options = {
    by_air: "By Air",
    by_ground: "By Ground",
    pickup: "Pick Up",
    // by_pickup: "Pick Up",
  };

  return options[option];
};

export const isEmailValid = (email: string) =>
  String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
