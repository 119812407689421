import { generateAuthHeader } from "../../../../../../commonfunctions/authentication";

interface AddressDetail {
  street_number: string;
  street_name: string;
  city: string;
  post_code: string;
  state: string;
}

interface Response {
  status: "success" | "error";
  result: AddressDetail;
}

const defaultAddressDetail = {
  street_number: "",
  street_name: "",
  city: "",
  post_code: "",
  state: ""
};

export const getAddressDetail = async (
  placeId: string
): Promise<AddressDetail> => {
  const url = `${process.env.REACT_APP_HALFBACK_ROOT_API}/address_check/get_address_details?place_id=${placeId}`;

  try {
    const res = await fetch(url, generateAuthHeader());
    const data: Response = await res.json();


    if (data.status !== "success") {
    // console.error(`Google get address detail error ${data.result}`);
      return defaultAddressDetail;
    }
    return data.result;
  } catch (error) {
    // console.error(`Google get address detail error ${error}`);
    return defaultAddressDetail;
  }
};
