import queryString from "query-string";
import React, { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import AuthenticationHOC, {
  AuthHOCProps
} from "../../components/AuthenticationHOC/AuthenticationHOC";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import OrderForm from "../../components/OrderForm/OrderForm";
import Paginator from "../../components/Paginator/Paginator";
import {
  Container,
  StyledButton,
  StyledInput
} from "../../components/styled/styled-components";
import { useDocumentTitle } from "../../hooks/document-title";
import useProductSearch from "../../hooks/product-search";
import ProductCard from "../products/ProductCard/ProductCard";
import { Context, IContext } from "../../context/ContextApp";
import SearchErrorCard from "../../components/SearchErrorCard/SearchErrorCard";
import { ErrorTypes } from "../../interfaces/enums";

const QuickOrder = (props: AuthHOCProps) => {
  useDocumentTitle("Quick order");
  const [searchField, setSearchField] = useState<string>("");
  const location = useLocation();
  const history = useHistory();
  const qs = queryString.parse(location.search);
  const { generateTokenAuthHeader } = props;
  const { appBranch }: IContext = useContext(Context);
  const { isLoading, error, searchAPIResponse } = useProductSearch(
    qs.q,
    qs.page,
    generateTokenAuthHeader,
    appBranch
  );

  const renderProducts = () => {
    if (searchAPIResponse) {
      const { products } = searchAPIResponse;
      if (products && products.length > 0) {
        return products.map((product) => (
          <div className="quick-order-card" key={product.slug}>
            <Container row>
              <ProductCard product={product} />
              <div className="quick-order-order-form">
                <OrderForm product={product} />
              </div>
            </Container>
          </div>
        ));
      }
    }
  };

  const renderPaginator = () => {
    if (searchAPIResponse && searchAPIResponse.products) {
      return <Paginator products={searchAPIResponse} />;
    }
  };

  const onSearchSubmit = () => {
    history.push(`?q=${searchField}&page=1`);
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSearchSubmit();
    }
  };

  const renderSearchBox = () => (
    <Container row>
      <StyledInput
        onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
          onKeyPress(e)
        }
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setSearchField(event.target.value)
        }
        placeholder="Type product code (e.g. p608ms) and click Search."
        style={{ flex: 1, marginRight: "1em" }}
      />
      <StyledButton primary onClick={() => onSearchSubmit()}>
        Search
      </StyledButton>
    </Container>
  );

  return (
    <>
      <Container margin="1em 0">{renderSearchBox()}</Container>
      {isLoading ? (
        <LoadingSpinner />
      ) : error ? (
        <SearchErrorCard errorType={ErrorTypes.productNotFound} userInput={qs.q} />
      ) : (
        <Container>
          {renderPaginator()}
          {renderProducts()}
          <br />
          {renderPaginator()}
        </Container>
      )}
    </>
  );
};

export default AuthenticationHOC(QuickOrder);
