import axios from "axios";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import LoaderButton from "../../../components/LoaderButton/LoaderButton";
import {
  StyledInput,
  Wrapper
} from "../../../components/styled/styled-components";
import Toast from "../../../components/Toast/Toast";
import { AlertType } from "../../../interfaces/enums";
import { AlertContent } from "../../../interfaces/interfaces";
import { Context, IContext } from "../../../context/ContextApp";

interface Form {
  email: string;
  password: string;
  confirmPassword: string;
}

const initialFormState: Form = {
  email: "",
  password: "",
  confirmPassword: ""
};

const ResetPassword = () => {
  const [form, setForm] = useState<Form>(initialFormState);
  const [isLoading, setIsLoading] = useState(false);
  const [toast, setToast] = useState<AlertContent | null>(null);
  const { token } = useParams<{ token?: string }>();
  const { appBranch }: IContext = useContext(Context);

  const setFormField = (value: string, key: string) => {
    setForm({
      ...form,
      [key]: value
    });
  };

  const sendPasswordResetLink = () => {
    const { email } = form;

    if (!email) {
      setToast({
        message:
          "Please enter the email used to sign into your webstore account.",
        type: AlertType.Error
      });
      return;
    }
    const url = `${process.env.REACT_APP_HALFBACK_ROOT_API}/reset_password/require_reset`;
    const data = {
      email,
      type: "webstore",
      branch: appBranch
    };
    setIsLoading(true);
    axios
      .post(url, data, { headers: { Accept: "application/json" } })
      .then(() => {
        setToast({
          message:
            "Password successfully reset. Please check your email and follow the instructions given.",
          type: AlertType.Success
        });
        setIsLoading(false);
      })
      .catch(() => {
        setToast({
          message:
            "Something went wrong while trying to reset your password. Please try again.",
          type: AlertType.Error
        });
        setIsLoading(false);
      });
  };

  const resetPassword = () => {
    if (!token) {
      return;
    }

    const { password, confirmPassword } = form;
    if (!password || !confirmPassword) {
      setToast({
        message: "Both fields must be filled",
        type: AlertType.Error
      });
      return;
    }

    const url = `${process.env.REACT_APP_HALFBACK_ROOT_API}/reset_password/reset`;
    const headers = {
      headers: {
        Accept: "application/json"
      }
    };

    const data = {
      new_password: password,
      reset_token: token
    };

    setIsLoading(true);

    axios
      .post(url, data, headers)
      .then((response) => {
        if (response.data.result === "success") {
          setToast({
            message: "Password successfully reset.",
            type: AlertType.Success
          });
        } else {
          setToast({
            message: "Failed to reset password. Please try again.",
            type: AlertType.Error
          });
        }
        setIsLoading(false);
      })
      .catch(() => {
        setToast({
          message: "Failed to reset password. Please try again.",
          type: AlertType.Error
        });
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="form-reset-password">
        <h1>Reset password</h1>
        {!token ? (
          <form>
            <StyledInput
              placeholder="Login email"
              onChange={(event) => setFormField(event.target.value, "email")}
            />
            <Wrapper margin="1em 0 0 0">
              <LoaderButton
                onClick={(event: React.MouseEvent) => {
                  event.preventDefault();
                  sendPasswordResetLink();
                }}
                isLoading={isLoading}
              >
                Send reset link
              </LoaderButton>
            </Wrapper>
          </form>
        ) : (
          <form>
            <StyledInput
              placeholder="Password"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setFormField(event.target.value, "password")
              }
              type="password"
            />
            <StyledInput
              style={{ marginTop: "1em" }}
              placeholder="Confirm password"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setFormField(event.target.value, "confirmPassword")
              }
              type="password"
            />
            <Wrapper margin="1em 0 0 0">
              <LoaderButton
                onClick={(event: React.MouseEvent) => {
                  event.preventDefault();
                  resetPassword();
                }}
                isLoading={isLoading}
              >
                Reset password
              </LoaderButton>
            </Wrapper>
          </form>
        )}
      </div>
      <Toast content={toast} onClick={() => setToast(null)} />
    </>
  );
};

export default ResetPassword;
