import { combineReducers, compose, createStore } from "redux";
import orders from "./reducers/orders";
import organization from "./reducers/organization";
import userSession from "./reducers/userSession";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION__ || compose;

const store = createStore(
  combineReducers({
    orders,
    userSession,
    organization,
  }),
  composeEnhancers()
);

export type RootState = ReturnType<typeof store.getState>;

export default store;
