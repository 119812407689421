import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { transactionItemFormatStyles } from "../../../components/styles";

const styles = StyleSheet.create({
  tableHeading: {
    borderBottomColor: "#aaa",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    paddingBottom: 2,
  },
});

const TableHeading = () => (
  <View
    style={{
      ...transactionItemFormatStyles.itemRow,
      ...styles.tableHeading,
      fontWeight: "bold",
    }}
  >
    <Text
      style={{
        ...transactionItemFormatStyles.transactionColumn,
        ...transactionItemFormatStyles.columnCode,
      }}
    >
      Item code
    </Text>
    <Text
      style={{
        ...transactionItemFormatStyles.transactionColumn,
        ...transactionItemFormatStyles.columnDescription,
      }}
    >
      Item description
    </Text>
    <Text
      style={{
        ...transactionItemFormatStyles.transactionColumn,
        ...transactionItemFormatStyles.columnPrice,
      }}
    >
      Unit price
    </Text>
    <Text
      style={{
        ...transactionItemFormatStyles.transactionColumn,
        ...transactionItemFormatStyles.columnQuantity,
      }}
    >
      Quantity
    </Text>
    <Text
      style={{
        ...transactionItemFormatStyles.transactionColumn,
        ...transactionItemFormatStyles.columnTotal,
      }}
    >
      Ext price
    </Text>
  </View>
);

export default TableHeading;
