import { View } from "@react-pdf/renderer";
import { OrderItem } from "../../../../interfaces/interfaces";
import { formatFreightOption } from "../../../../pages/order-history/OrderHistoryCard/util";
import Divider from "../../components/Divider/Divider";
import Heading from "../../components/Heading/Heading";
import ItemLine from "../../components/ItemLine/ItemLine";
import Logo from "../../components/Logo/Logo";
import TableHeading from "../../components/TableHeading/TableHeading";
import TitleAndDetailLine from "../../components/TitleAndDetailLine/TitleAndDetailLine";
import PDFWrapper from "../PDFWrapper";
import OrderSummary from "./components/OrderSummary";
import { subdomainTypes } from "../../../../context/availableSubdomains";

interface Props {
  order: any;
  title?: string;
  appBranch: subdomainTypes | "";
}

const OrderHistoryPDF = (props: Props) => {
  const { order, title, appBranch } = props;
  const {
    order_id,
    order_items,
    po_number,
    submitted_at,
    ship_to_address,
    freight_option,
  } = order;

  return (
    <PDFWrapper title={order_id}>
      <Logo appBranch={appBranch} />
      <View>
        {title && <Heading>{title}</Heading>}
        <View style={{ marginBottom: 10 }}>
          <TitleAndDetailLine line={["Order Number: ", order_id]} />
          <TitleAndDetailLine line={["PO Number: ", po_number]} />
          <TitleAndDetailLine line={["Submitted at: ", submitted_at]} />
          <TitleAndDetailLine
            line={["Shipping method: ", formatFreightOption(freight_option)]}
          />
          <TitleAndDetailLine line={["Shipping to: ", ship_to_address]} />
        </View>
        <View style={{ marginTop: 30 }}>
          <TableHeading />
          {order_items.map((item: OrderItem, index: number) => (
            <ItemLine item={item} key={index} />
          ))}
        </View>
        <Divider />
        <OrderSummary order={order} appBranch={appBranch} />
      </View>
    </PDFWrapper>
  );
};

export default OrderHistoryPDF;
