import { useContext, useEffect, useRef, useState } from "react";
import LiveChat from "react-livechat";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link, useHistory } from "react-router-dom";
import { log } from "../../commonfunctions/logger";
import { setLoginStatus } from "../../redux/actions/userSession";
import { RootState } from "../../redux/store";
import Dropdown from "../Dropdown/Dropdown";
import IconButton from "../IconButton/IconButton";
import SearchBar from "../SearchBar/SearchBar";
import Sidebar from "../Sidebar/Sidebar";
import NotificationBar from "./NotificationBar";
import { Context, IContext } from "../../context/ContextApp";
import Icon from "../Icon/icon";

const Header = () => {
  const currentOrder = useSelector(
    (state: RootState) => state.orders.currentOrder
  );
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const livechat = useRef();
  const location = useLocation();
  const { appBranch }: IContext = useContext(Context);
  const isStockListMode = localStorage.getItem("stocklist_mode") === "true";

  useEffect(() => {
    if (self == top) {
      // Everything checks out, show the page.
      document.documentElement.style.display = 'block';
    } else {
      // Break out of the frame.
      if (top != undefined) {
        top.location = self.location;
      }
    }
    const app = document.getElementById("app");
    if (sidebarCollapsed) {
      if (app) app.style.overflowY = "auto";
    } else {
      if (app) app.style.overflowY = "hidden";
    }
  }, [sidebarCollapsed]);

  useEffect(() => {
    setSidebarCollapsed(true);
  }, [location.search, location.pathname]);

  const logOff = () => {
    destroyLiveChat(livechat.current);
    dispatch(setLoginStatus(false));
    history.push("/");
    // To refresh livechat SPA
    window.location.reload();
  };

  const renderLiveChat = (liveChatBranch: string) => {
    if (
      process.env["REACT_APP_LIVECHAT_LICENSE_ID_" + liveChatBranch.toUpperCase()] &&
      process.env.NODE_ENV === "production"
    ) {
      return (
        <LiveChat
          license={process.env["REACT_APP_LIVECHAT_LICENSE_ID_" + liveChatBranch.toUpperCase()]}
          onChatLoaded={(ref: any) => {
            livechat.current = ref;
            ref.on_after_load = () => {
              ref.minimize_chat_window();
            };
          }}
          visitor={{ email: localStorage.getItem("email") }}
        />
      );
    }
  };

  const destroyLiveChat = (lc: any) => {
    try {
      lc.close_chat();
      lc.hide_chat_window();
    } catch (error) {
      log(error, "Live chat destruction failed.");
    }
  };

  return (
    <>
      {appBranch !== "ca" && renderLiveChat(appBranch)}
      <header className="header">
        {appBranch === "au" && <NotificationBar />}
        <nav>
          <div className="logo" onClick={() => history.push("/store/cart")}>
            {appBranch === "nz" ? (
              <img
                src="https://s3.ap-southeast-2.amazonaws.com/cdn.fashionbiz.com/webstore/Fashion+Biz+DC+NZ.svg"
                alt="webstore-logo"
              />
            ) : appBranch === "au" ? (
              <img
                src="https://s3.ap-southeast-2.amazonaws.com/cdn.fashionbiz.com/webstore/Fashion+Biz+DC+AU.svg"
                alt="webstore-logo"
              />
            ) : (
              <img
                src="https://s3.ap-southeast-2.amazonaws.com/cdn.fashionbiz.com/webstore/Fashion+Biz+DC+CA.svg"
                alt="webstore-logo"
              />
            )}
          </div>

          <div className="actions">
            {!isStockListMode && (
              <div className="action">
                <Link to="/store/cart" className="action-link">
                  {currentOrder ? (
                    <>
                      <IconButton style={{ marginRight: "5px" }} inline>
                        <Icon name="ShoppingCartWhite" />
                      </IconButton>
                      <span>
                        {currentOrder.total_items +
                          " items / " +
                          currentOrder.total_units +
                          " units"}
                      </span>
                      <span>{` -  $${parseFloat(
                        currentOrder.payment_details.total_products
                      ).toFixed(2)}`}</span>
                    </>
                  ) : (
                    "Loading..."
                  )}
                </Link>
              </div>
            )}
            <div className="action">
              <Dropdown
                list={[
                  { title: "Quick order", link: "/quick-order" },
                  { title: "Saved orders", link: "/saved-orders" },
                  { title: "Order import", link: "/order-uploader" },
                  { title: "Order history", link: "/order-history" },
                  { title: "Transactions", link: "/transactions" },
                  { title: "Preferences", link: "/preferences" },
                  { title: "Promotions", link: "/store/cart?tab=promotions" },
                  { title: "Media library", link: "/media-library" },
                ]}
              >
                <>
                  <IconButton style={{ marginRight: "5px" }} inline>
                    <Icon name="CaretCircleDown" />
                  </IconButton>
                  <span>
                    {localStorage.getItem("email") ?? "Not logged in"}
                  </span>
                </>
              </Dropdown>
            </div>

            <div className="action" onClick={() => logOff()}>
              <IconButton inline>
                <Icon name="SignOutAlt" />
              </IconButton>
              Log Out
            </div>
          </div>
        </nav>
        <div
          className="secondary-nav"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            className="menu-opener"
            onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
          >
            <IconButton inline>
              <Icon name="Bars" iconWidth="1.3rem"/>
            </IconButton>
            <span>Menu</span>
          </div>
          <SearchBar route="/search?q" page={1} searchQuery="Search Products" />
          {isStockListMode && (
            <span
              style={{
                color: "#043455",
                marginRight: "60px",
                padding: "5px",
                // border: "1px solid #043455",
              }}
            >
              Stocklist mode
            </span>
          )}
        </div>
      </header>
      <Sidebar
        isSidebarCollapsed={sidebarCollapsed}
        collapseSidebar={() => setSidebarCollapsed(true)}
      />
    </>
  );
};

export default Header;
