import { SVGProps } from "react"
const Star = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 166 158.9"
    {...props}
  >
    <path d="M166 62.3c0 3.3-1 5.5-2.7 7.2-4.7 4.8-9.5 9.5-14.3 14.2-6 5.9-12.1 11.8-18.2 17.7-.4.4-.7 1.2-.6 1.7.6 4.1 1.3 8.2 2 12.3 1.7 10.2 3.5 20.3 5.2 30.5.3 1.8.6 3.5.2 5.4-1.2 5.6-7.8 9.1-13 6.7-5.2-2.5-10.3-5.1-15.4-7.7-7.7-4-15.3-8.1-23-12.1-4-2.1-2.5-2-6.4.1-9.2 4.7-18.4 9.5-27.6 14.3-3.3 1.7-6.8 3.3-10.1 5.2-3.5 2-6.9 1.9-10.1-.6-3.4-2.6-4.8-6-4-10.3.7-3.6 1.3-7.2 1.9-10.9 1.4-8 2.7-15.9 4-23.9.5-2.9 1-5.9 1.5-8.8.2-.8-.1-1.3-.7-1.8C24.5 91 14 80.7 3.5 70.4c-2.9-2.8-4.1-6-3.2-10 .9-3.8 3.7-6.3 7.6-6.9 10.8-1.6 21.7-3.3 32.5-4.9 3.9-.6 7.9-1.2 11.8-1.8 1.2-.2 1.9-.8 2.4-1.9 4-8.1 8-16.3 12-24.4 2.6-5.2 5.1-10.4 7.7-15.5 3-5.8 11.8-7.1 16.2-1.3.9 1.1 1.5 2.5 2.2 3.8 6.1 12.3 12.2 24.6 18.2 37 .8 1.6 1.6 2.2 3.2 2.4 11 1.6 22 3.2 33 4.9 3.9.6 7.8 1.2 11.8 1.9 2.7.5 4.7 2 5.8 4.5.7 1.6 1.1 3.3 1.3 4.1zm-156.8.5c.7.6 1.1.9 1.4 1.2l16.5 16.2c6.1 6 12.3 12 18.4 18 .3.3.4 1 .3 1.4-.9 5.1-1.9 10.2-2.8 15.4l-4.5 26.1c-.4 2.6-.8 5.1-1.3 8 .8-.4 1.5-.6 2-.8 14.2-7.4 28.5-14.9 42.7-22.2.4-.2 1.2-.2 1.6 0 4.2 2.2 8.5 4.4 12.7 6.6 10.1 5.3 20.3 10.5 30.4 15.8.5.2 1 .4 1.8.7-.3-1.8-.6-3.3-.9-4.8-.7-4.5-1.4-9-2.2-13.5-.9-5.3-1.8-10.6-2.8-15.9-.9-5.1-1.9-10.2-2.8-15.3-.1-.5.2-1.1.5-1.6.2-.3.6-.6.9-.9 11.3-11 22.6-22 33.8-33l1.3-1.3c-.5-.1-.7-.2-.9-.2-5.2-.7-10.3-1.3-15.5-2.1-6.1-.9-12.3-1.8-18.4-2.7-5-.7-9.9-1.4-14.9-2.2-.5-.1-1.1-.5-1.3-.9-5.9-12.5-12-25.1-18.2-37.6-1.2-2.4-2.5-4.8-4-7.5-.7 1.1-1.2 2-1.7 2.9-4.1 8.3-8.1 16.6-12.2 24.8-2.8 5.7-5.7 11.4-8.5 17.1-.4.8-.8 1.2-1.7 1.4-6.9 1-13.8 2-20.8 3-8.8 1.3-17.7 2.5-26.5 3.8-.7 0-1.4 0-2.4.1z" />
  </svg>
)
export default Star
