import cx from "classnames";

interface Props {
  onClick?: any;
  children: any;
  style?: any;
  inline?: boolean;
}

const IconButton = (props: Props) => {
  const { onClick, children, style, inline } = props;
  const customStyles = style ? style : {};

  return (
    <div
      className={cx({
        "icon-button": true,
        inline: inline,
      })}
      onClick={() => onClick && onClick()}
      style={customStyles}
      role="button"
    >
      {children}
    </div>
  );
};

export default IconButton;
