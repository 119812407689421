import { Address } from "../interfaces/interfaces";

export const formatAddress = (address?: any) => {
  if (!address) return "";

  const result = [];
  address.name && result.push(address.name);
  address.addressLine1 && result.push(address.addressLine1);
  address.addressLine2 && result.push(address.addressLine2);
  address.city && result.push(address.city);
  address.state && result.push(address.state);
  address.suburb && result.push(address.suburb);

  return result.join(", ");
};

export const sortAddressesByShipToName = (a: Address[]) => {
  const addresses = a.slice();
  addresses.sort((a: Address, b: Address) => {
    if (a.ship_to_name > b.ship_to_name) return 1;
    if (b.ship_to_name > a.ship_to_name) return -1;
    return 0;
  });


  return addresses;
};
