import { Image, View } from "@react-pdf/renderer";
import AuLogo from "./au.png";
import CaLogo from "./ca.png";
import NZLogo from "./nz.png";
import { subdomainTypes } from "../../../../context/availableSubdomains";

interface ILogoProps {
  appBranch: subdomainTypes | "";
}

const Logo = (props: ILogoProps) => {
  const { appBranch } = props;
  const logoSrc = {
    au: AuLogo,
    nz: NZLogo,
    ca: CaLogo,
  };
  const src = logoSrc[appBranch as subdomainTypes] || AuLogo;

  return (
    <View style={{ marginTop: 10, marginBottom: 30 }}>
      <Image src={src} />
    </View>
  );
};

export default Logo;
