import { useEffect, useState } from "react";
import FieldGenerator from "../../../../../../components/Address/AddressFormFieldGenerator";
import { StyledFormLayout } from "../../../../../../components/styled/styled-components";
import { Address as AddressSearcher } from "../../../../../../components/Address/AddressSearcher";
import { Address } from "../../../../../../interfaces/interfaces";
import { getAddressDetail } from "./GooglePlaceAPI";

interface Props {
  updateShippingAddressInRedux: (a: Address | null) => void;
}

interface Form {
  ship_to_name?: string;
  address_line1?: string;
  address_line2?: string;
  address_city?: string;
  address_postcode?: string;
  state: string;
}

function NewAddress({ updateShippingAddressInRedux }: Props) {
  const [form, setForm] = useState<Form>({
    state: "",
  });

  useEffect(() => {
    const getShipAddressParams = () => {
      const {
        ship_to_name,
        address_line1,
        address_line2,
        address_city,
        address_postcode,
        state,
      } = form;

      const shipAddressParams = {
        ship_to_name: ship_to_name ?? "",
        address_line1: address_line1 ? address_line1.charAt(0).toUpperCase() + address_line1.slice(1) : "",
        address_line2: address_line2 ?? "",
        address_line3: "",
        address_line4: "",
        address_city: address_city ?? "",
        state,
        address_country: "AUSTRALIA",
        address_postcode: address_postcode ?? "",
      };

      return shipAddressParams;
    };
    updateShippingAddressInRedux(getShipAddressParams());
  }, [form, updateShippingAddressInRedux]);

  const isAddressSearcher = (object: any): object is AddressSearcher =>
    "FullAddress" in object;

  const onFieldChange = async (
    event: React.ChangeEvent<HTMLInputElement> | AddressSearcher,
    key: string
  ) => {
    // If we're setting the address returned from the address searcher
    if (isAddressSearcher(event)) {
      const addressDetail = await getAddressDetail(event?.placeId || "");

      setForm({
        ...form,
        [key]: event.FullAddress.charAt(0).toUpperCase() + event.FullAddress.slice(1),
        address_city: addressDetail.city,
        address_postcode: addressDetail.post_code,
        state: addressDetail.state,
      });
      // Else if setting ship_to_name
    } else {
      setForm({
        ...form,
        [key]: event.target.value,
      });
    }
  };

  const clearDetailAddress = () => {
    setForm({
      ...form,
      address_city: "",
      address_postcode: "",
      state: "",
    });
  };

  return (
    <div className="new-address-wrapper">
      <StyledFormLayout>
        <FieldGenerator
          form={form}
          onChange={onFieldChange}
          clearForm={clearDetailAddress}
        />
      </StyledFormLayout>
    </div>
  );
}

export default NewAddress;
