import { useEffect, useReducer } from "react";
import { useHistory } from "react-router-dom";
import { sortAddressesByShipToName } from "../commonfunctions/address";
import { Address } from "../interfaces/interfaces";

interface Action {
  type: string;
  savedAddresses?: Address[];
}

interface Reducer {
  savedAddresses: Address[];
  addressLoadCode: number;
}

const initialState = {
  savedAddresses: [],
  addressLoadCode: 0,
};

const reducer = (state: Reducer = initialState, action: Action) => {
  switch (action.type) {
    case "FETCHING_ADDRESSES":
      return {
        ...state,
        addressLoadCode: 0,
        savedAddresses: [],
      };
    case "LOAD_ADDRESSES":
      return {
        ...state,
        savedAddresses: action.savedAddresses ?? [],
        addressLoadCode: 1,
      };
    case "NO_ADDRESSES_FOUND":
      return {
        ...state,
        addressLoadCode: 2,
      };
    case "FETCH_FAIL":
      return {
        ...state,
        addressLoadCode: 3,
      };
    default:
      return state;
  }
};

const useAddressFetch = (generateAuthHeader: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const { push } = history;

  // Address fetching
  useEffect(() => {
    let isMounted = true;

    const fetchSavedAddresses = async () => {
      const url = `${process.env.REACT_APP_HALFBACK_ROOT_API}/ship_to_addresses/saved`;

      try {
        const res = await fetch(url, generateAuthHeader());
        const data = await res.json();

        if (isMounted) {
          if (data.length === 0) {
            dispatch({ type: "NO_ADDRESSES_FOUND" });
          } else {
            const addresses = sortAddressesByShipToName(data);

            dispatch({ type: "LOAD_ADDRESSES", savedAddresses: addresses });
          }
        }
      } catch (error) {
        if (isMounted) {
          dispatch({ type: "FETCH_FAIL" });
        }
      }
    };

    fetchSavedAddresses();

    return () => {
      isMounted = false;
    };

    //eslint-disable-next-line
  }, [push]);

  return {
    ...state,
  };
};

export default useAddressFetch;
