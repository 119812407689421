import { SVGProps } from "react"
const PlaneArrival = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 198.7 159.1"
    {...props}
  >
    <path d="M73.3 57.5c-.3-.9-.6-1.6-.8-2.3-.8-2.9-1.5-5.8-2.3-8.6-1.5-5.4-3-10.8-4.5-16.3L60 9.3C58.7 4.6 62.6.6 66.5-.1c.4-.1.9 0 1.3.1 6.7 1.9 13.3 3.9 20.1 5.7 3.3.9 5.4 2.7 6.9 5.7 5.4 10.6 10.9 21.2 16.4 31.8 4.7 9.1 9.6 18.1 14.2 27.2.7 1.4 1.5 1.8 2.8 2.1 9.8 2.7 19.5 5.5 29.3 8.1 11.1 3 20 9.2 26.9 18.4 2.4 3.2 4.1 6.7 4.3 10.8.3 5.5-2.4 9.6-6.6 12.8-3.3 2.5-7.2 3.8-11.1 4.8-5.9 1.6-11.8 1.6-17.9 1-6.6-.7-12.8-2.8-19.1-4.5-11.1-2.9-22.2-6.1-33.3-9.1-15.7-4.3-31.5-8.6-47.2-12.9-4.3-1.2-8.1-3.3-11.4-6.3-8.9-8.2-17.9-16.3-26.9-24.4-3.8-3.5-5.4-7.7-5.2-12.8.2-10 0-20.2.1-30.4 0-3.9 2.8-7.1 6.7-7.3 1.6-.1 3.3.5 4.9.8 3.2.8 6.4 1.7 9.6 2.5 3.1.8 5.2 2.6 6.3 5.7 2.3 6.2 4.9 12.2 7.2 18.4.6 1.6 1.4 2.2 3 2.6 8 2.1 16 4.3 24 6.5.4.2.8.2 1.5.3zM19.6 31c-.1 1.1-.2 2.1-.2 3.1v26c0 1.7.5 3 1.8 4.2 9.5 8.3 18.9 16.8 28.5 25 1.6 1.4 3.7 2.5 5.7 3.1 15.9 4.5 31.9 8.9 47.9 13.2 12.3 3.4 24.7 6.8 37 10.1 5.2 1.4 10.3 3.1 15.7 3.5 6.5.5 12.8-.3 18.6-3.2 1.4-.7 2.7-1.9 3.9-3 .6-.6 1-1.8.8-2.6-.4-1.6-1.1-3.3-2.1-4.7-4.8-6.7-11-11.8-18.9-14.3-8-2.5-16.2-4.7-24.2-7-4.4-1.3-8.8-2.6-13.2-4-.6-.2-1.3-.7-1.6-1.2-1.5-2.8-2.9-5.6-4.3-8.4-9.6-18.4-19.1-36.7-28.7-55.1-.2-.3-.6-.6-1-.8-4.6-1.3-9.3-2.5-13.9-3.8-.4-.1-.7-.1-1.2-.2 5.7 20.2 11.3 40.1 17 60.2-2.7-.7-5.3-1.3-7.8-1.9-9.6-2.6-19.2-5.3-28.7-7.9-3.4-.9-6.8-1.9-10.2-2.8-1.2-.3-2.2-.8-2.5-2.2-.2-1.1-.8-2.1-1.2-3.1-2.5-6.3-4.9-12.6-7.5-18.9-.2-.5-.7-1-1.2-1.2-2.8-.7-5.5-1.3-8.5-2.1zM99.2 159.1H4.1c-3.6 0-4.1-.5-4-4.1v-3c.1-1.8.6-2.5 2.4-2.7.4-.1.9-.1 1.4-.1h190.6c4 0 4.3.3 4.3 4.2v3.5c0 1.1-.5 1.8-1.6 2-.8.1-1.6.2-2.3.2H99.2z" />
  </svg>
)
export default PlaneArrival
