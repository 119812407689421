import queryString from "query-string";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import AuthenticationHOC, {
  AuthHOCProps
} from "../../components/AuthenticationHOC/AuthenticationHOC";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import Paginator from "../../components/Paginator/Paginator";
import { Container, Wrapper } from "../../components/styled/styled-components";
import { useDocumentTitle } from "../../hooks/document-title";
import useProductSearch from "../../hooks/product-search";
import ProductCard from "../products/ProductCard/ProductCard";
import { Context, IContext } from "../../context/ContextApp";
import SearchErrorCard from "../../components/SearchErrorCard/SearchErrorCard";
import { ErrorTypes } from "../../interfaces/enums";

const Search = ({ generateTokenAuthHeader }: AuthHOCProps) => {
  const location = useLocation();
  const qs = queryString.parse(location.search);
  const { appBranch }: IContext = useContext(Context);
  useDocumentTitle(`"${qs.q}"`);

  const { isLoading, error, searchAPIResponse } = useProductSearch(
    qs.q,
    qs.page,
    generateTokenAuthHeader,
    appBranch
  );

  const renderPaginator = () => {
    if (searchAPIResponse && searchAPIResponse.products) {
      return <Paginator products={searchAPIResponse} />;
    }
  };

  return (
    <>
      {isLoading ? (
        <Wrapper margin="1em 0">
          <LoadingSpinner />
        </Wrapper>
      ) : (error) ? (
        <SearchErrorCard errorType={ErrorTypes.productNotFound} userInput={qs.q} />
      ) : (
        <>
          {renderPaginator()}
          {searchAPIResponse && searchAPIResponse.products && (
            <Container row>
              {searchAPIResponse.products.map((product, index) => (
                <ProductCard product={product} key={index} />
              ))}
            </Container>
          )}
          {renderPaginator()}
        </>
      )}
    </>
  );
};

export default AuthenticationHOC(Search);
