import { generateTokenAuthHeader } from "./authentication";

export const log = async (
  content: any,
  logMessage?: string,
  account?: string
) => {
  const encodeRequestBody = () => {
    const formBody = [];
    formBody.push(
      `${encodeURIComponent("content")}=${encodeURIComponent(content)}`
    );
    if (account) {
      formBody.push(
        `${encodeURIComponent("account")}=${encodeURIComponent(account)}`
      );
    }
    if (logMessage) {
      formBody.push(
        `${encodeURIComponent("message")}=${encodeURIComponent(logMessage)}`
      );
    }
    const localStorageEmail = localStorage.getItem("email");

    if (localStorageEmail) {
      formBody.push(
        `${encodeURIComponent("email")}=${encodeURIComponent(
          localStorageEmail
        )}`
      );
    }

    return formBody.join("&");
  };
  if (process.env.NODE_ENV === "production") {
    await fetch(`${process.env.REACT_APP_HONEYBEE_ROOT_API}/emailagent/log`, {
      method: "POST",
      body: encodeRequestBody(),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: generateTokenAuthHeader().headers.Authorization,
      },
    });
  }
};
