import axios from "axios";
import { useEffect, useState } from "react";
import { log } from "../../commonfunctions/logger";
import Alert from "../../components/Alert/Alert";
import AuthenticationHOC, {
  AuthHOCProps
} from "../../components/AuthenticationHOC/AuthenticationHOC";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { Container, Wrapper } from "../../components/styled/styled-components";
import { useDocumentTitle } from "../../hooks/document-title";
import SavedOrderCard from "./SavedOrderCard/SavedOrderCard";

const SavedOrders = (props: AuthHOCProps) => {
  useDocumentTitle("Your saved orders");
  const [isLoading, setIsLoading] = useState(false);
  const [savedOrders, setSavedOrders] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const { generateAuthHeader } = props;

  useEffect(() => {
    const loadSavedOrders = async () => {
      setIsLoading(true);

      const url = `${process.env.REACT_APP_HALFBACK_ROOT_API}/draft_orders`;
      axios
        .get(url, generateAuthHeader())
        .then((response) => {
          const { total_count, orders } = response.data;
          setSavedOrders(orders);
          setTotalCount(total_count);
          setIsLoading(false);
        })
        .catch((error) => {
          log(error, "Saved orders fetch failed.");
          setIsLoading(false);
        });
    };

    loadSavedOrders();
  }, [generateAuthHeader]);

  const renderOrderList = () => {
    if (savedOrders.length === 0) {
      return (
        <div className="text-center" style={{ position: "relative" }}>
          <div
            className="orderFormspinner orderFormSpinnerCentered"
            style={{ marginTop: "50px" }}
          />
        </div>
      );
    } else {
      return savedOrders.map((order, index) => (
        <SavedOrderCard key={index} order={order} />
      ));
    }
  };

  return (
    <Container margin="1em 0" className="saved-orders-page">
      {isLoading ? (
        <Wrapper>
          <LoadingSpinner />
        </Wrapper>
      ) : (
        <>
          <Alert>You have used {totalCount} out of 30 Saved Orders.</Alert>
          {renderOrderList()}
        </>
      )}
    </Container>
  );
};

export default AuthenticationHOC(SavedOrders);
