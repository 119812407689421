import { View } from "@react-pdf/renderer";

const Divider = () => (
  <View
    style={{
      borderTopWidth: 1,
      borderTopColor: "#aaa",
      borderTopStyle: "solid",
      marginVertical: 15,
    }}
  />
);

export default Divider;
