import { ColorSizeStock } from "../components/OrderForm/interfaces";

const sortSizes = (s: ColorSizeStock[]) => {
  const table = {
    XXS: 10,
    XS: 11,
    S: 12,
    M: 13,
    L: 14,
    XL: 15,
    "2XL": 16,
    "3XL": 17,
    "4XL": 18,
    "5XL": 19,
    "6XL": 20,
    "7XL": 21,
    "S (9-2)": 50,
    "M (2-7)": 51,
    "L (7-11)": 52,
    "XL (12-14)": 53,
    "0": 100,
    "2": 102,
    "4": 104,
    "4-6": 104,
    "6": 106,
    "8": 108,
    "10": 110,
    "12": 112,
    "14": 114,
    "16": 116,
    "18": 118,
    "20": 120,
    "22": 122,
    "24": 124,
    "26": 126,
    "28": 128,
    "30": 130,
    "32": 132,
    "34": 134,
    "36": 136,
    "38": 138,
    "40": 140,
    "42": 142,
    "44": 144,
    "46": 146,
    "48": 148,
    "50": 150,
    "52": 152,
    "54": 154,
    "56": 156,
    "58": 158,
    "72": 172,
    "77": 177,
    "82": 182,
    "87": 187,
    "92": 192,
    "97": 197,
    "102": 202,
    "107": 207,
    "112": 212,
    "117": 217,
    "122": 222,
    "127": 227,
    "132": 232,
    "137": 237,
    "142": 242,
    KS: 300,
    YS: 301,
    AS: 302,
    OS: 303,
  };

  const sizes = s.slice();
  for (let i = 0; i < sizes.length; i++) {
    const s = sizes[i].size;
    const p = table[s];
    sizes[i]["index"] = p;
  }

  sizes.sort(function (size1, size2) {
    if (size1.index && size2.index) {
      return size1.index - size2.index;
    }
    else return 0;
  });
  return sizes;
};

export default sortSizes;
