import { generateAuthHeader } from "../../../commonfunctions/authentication";

export interface ICheckOrderIsAbleToCCPaymentResponse {
  isAvailable: boolean;
  message: string;
}

export const checkOrderIsAbleToCCPayment = async (): Promise<ICheckOrderIsAbleToCCPaymentResponse> => {
  const { headers } = generateAuthHeader();
  try {
    const res = await fetch(
      `${process.env.REACT_APP_HALFBACK_ROOT_API}/session/check_order_is_able_to_cc_payment`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          ...headers,
        },
      }
    );
    if (res.status !== 200) return { isAvailable: false, message: "Error API" };

    const data: ICheckOrderIsAbleToCCPaymentResponse = await res.json();
    return data;
  } catch (error) {
    return { isAvailable: false, message: `${error}` };
  }
};

export default checkOrderIsAbleToCCPayment;
