import { SVGProps } from "react"
const PeopleCarry = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 118.7 158.9"
    {...props}
  >
    <path d="M11.7 38.5c-5.6-6.2-8-13.3-6.2-21.5 1.3-5.8 4.7-10.5 9.8-13.6C25.6-2.7 37.5-.4 44.7 8.9c7.7 9.9 5 23.8-4 31l18.6 18.6c.2-.1.3-.1.5-.2v-3.5c0-6.5-.1-12.9-.1-19.4 0-3.6 2-5.5 5.7-5.5h47.5c.9 0 1.9.2 2.8.5 1.9.7 3 2 3.1 4.1v49.6c0 3.5-2 5.4-5.5 5.4H49.7c-.7 0-1.6-.6-2.2-1.1-2.6-2.5-5.2-5-8-7.7-.1.6-.2 1-.2 1.3 0 3.3-.1 6.6-.1 9.9 0 .6.4 1.4.9 1.9 3.7 3.3 7.5 6.4 11.2 9.7 4.9 4.3 7.5 9.7 8.5 16.1.9 5.7 2.1 11.3 3.1 17 .5 2.6 1 5.1 1.1 7.7.3 7.1-4.8 13-11.7 14.5-8.3 1.8-16-4.7-17.1-11.9-.9-5.9-2.1-11.7-3.2-17.6-.2-1.3-.4-2.6-.8-3.9-.2-.7-.9-1.2-1.4-1.7-.2.1-.3.1-.5.2v2.6c0 6.1.1 12.1 0 18.2-.1 6-3.1 10.3-8.3 13-8.8 4.4-19.8-1.7-20.7-11.5-.3-2.1-.3-4.1-.3-6V61.8c0-2.7 0-5.4.5-8 1.4-6.7 5.4-11.6 11.2-15.3zm18.1 41.2V63c0-1 0-2 1.2-2.5 1.2-.4 2.2-.2 3.1.7.2.2.3.4.5.5 5.5 5.6 11 11.2 16.6 16.8.5.5 1.3.9 1.9.9 5.5.1 11.1.1 16.6 0 3 0 5.2-3 4.5-6-.6-2.5-2.3-4-5.1-4H58c-.7 0-1.5-.3-2-.7-6.5-6.3-12.9-12.6-19.3-19-2.8-2.8-6-4.5-10-5-5.4-.6-9.9 1.2-13.6 5-2.9 3-3.9 6.7-3.9 10.8 0 8.2.1 16.4 0 24.6-.1 5.7 2 10.1 6.2 13.7 7.4 6.2 14.8 12.6 22.1 18.9.9.8 1.9 1.9 2.1 3.1.9 4.3 1.6 8.7 2.4 13.1.7 3.7 1.3 7.3 2.1 11 .6 3 3.1 4.7 6 4.2s4.7-3.2 4.3-6.1c-.1-.8-.2-1.6-.3-2.3-1.2-6.7-2.5-13.4-3.7-20.1-.7-4-2.5-7.3-5.5-9.9-4.6-4-9.3-8-13.9-12-.8-.7-1.2-1.4-1.2-2.5V79.7zm53-.2h24.5c2.1 0 2.1 0 2.1-2.1V40.9c0-.9-.3-1.2-1.2-1.2h-38c-.9 0-1.1.4-1.1 1.2 0 5.8 0 11.7-.1 17.5 0 .9.3 1.2 1.2 1.3 2.9.3 5.5 1.1 7.9 2.9 5.5 4.5 7.1 11.1 4.7 16.9zM27 34.8c6.7.1 12.7-5.3 12.8-11.9.1-7.2-6-13.1-12.8-13-6.8 0-12.7 5.6-12.8 12.1 0 6.9 5.8 12.7 12.8 12.8zM9.5 106.2c-.1.7-.2 1.2-.2 1.6v30.7c0 1.8-.1 3.6 0 5.5.1 3.1 2.2 5.1 5.3 5.2 2.8 0 5.1-2 5.1-5 .1-9.4.1-18.7.1-28.1 0-.5-.3-1.1-.7-1.4-2.4-2.2-4.8-4.3-7.2-6.4-.7-.7-1.4-1.3-2.4-2.1z" />
  </svg>
)
export default PeopleCarry
