import axios from "axios";
import cx from "classnames";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { log } from "../../commonfunctions/logger";
import { OrderItem, ProductImage } from "../../interfaces/interfaces";
import { updateCurrentOrder } from "../../redux/actions/orders";
import AuthenticationHOC from "../AuthenticationHOC/AuthenticationHOC";
import IconButton from "../IconButton/IconButton";
import Modal from "../Modal/Modal";
import {
  Container,
  StyledButton,
  StyledInput,
  StyledNumberInput
} from "../styled/styled-components";
import { Context, IContext } from "../../context/ContextApp";
import Icon from "../Icon/icon";

interface Props {
  generateAuthHeader: any;
  action?: boolean;
  bo?: boolean;
  disableImageLink?: boolean;
  index: number;
  item: OrderItem;
  split?: boolean;
  // Not sure what splittedItems does, so will comment out
  // splittedItems: Product[];
  showStock?: boolean;
}

const CartItem = (props: Props) => {
  const [editMode, setEditMode] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { appBranch }: IContext = useContext(Context);

  const {
    generateAuthHeader,
    action,
    bo,
    disableImageLink,
    index,
    item,
    split,
    // splittedItems,
    showStock,
  } = props;

  const [reference, setReference] = useState(item.reference);
  const [quantity, setQuantity] = useState(item.quantity);

  const update = () => {
    const qty = quantity;
    const ref = reference;
    const { id } = item;
    const req_url = `${process.env.REACT_APP_HALFBACK_ROOT_API}/orders/current_order/items/update`;
    const req_config = generateAuthHeader();

    axios
      .put(
        req_url,
        { item: { id: id, quantity: qty, reference: ref } },
        req_config
      )
      .then((response) => {
        dispatch(updateCurrentOrder(response.data));
        setEditMode(!editMode);
      })
      .catch((error) => {
        log(error, "Update cart call failed.");
        if (typeof error === "string" && error.indexOf("401") !== -1) {
          alert("Your session has expired, please log in again.");
          history.go(0);
        }
      });
  };

  const removeItem = () => {
    const { id } = item;
    const req_url = `${process.env.REACT_APP_HALFBACK_ROOT_API}/orders/current_order/items/delete`;
    const req_config = generateAuthHeader();

    axios
      .put(req_url, { id: id }, req_config)
      .then((response) => {
        dispatch(updateCurrentOrder(response.data));
        setShowModal(false);
      })
      .catch((error) => {
        log(error, "Remove cart item failed.");
        setShowModal(false);
      });
  };

  const renderEditRef = (itemReference: string | null) => (
    <StyledInput
      style={{ maxWidth: "120px", paddingLeft: 5, paddingRight: 5 }}
      defaultValue={itemReference === null ? "" : itemReference}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        setReference(event.target.value)
      }
      maxLength={30}
    />
  );

  const renderEditQty = (qty: number) => (
    <StyledNumberInput
      style={{
        width: "80px",
        textAlign: "center",
      }}
      defaultValue={qty}
      min="1"
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        setQuantity(parseInt(event.target.value))
      }
      type="number"
    />
  );

  const renderActionButtons = () => {
    if (action) {
      return (
        <Container row>
          <div className="button-container">
            {editMode ? (
              <>
                <IconButton
                  onClick={() => {
                    update();
                  }}
                >
                  <Icon name="Check" iconWidth="1.3rem" />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setEditMode(!editMode);
                  }}
                >
                  <Icon name="TimesRed" />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  onClick={() => {
                    setEditMode(!editMode);
                  }}
                >
                  <Icon name="EditIcon" />
                </IconButton>
                <IconButton onClick={() => setShowModal(true)}>
                  <Icon name="TrashRed" />
                </IconButton>
              </>
            )}
          </div>
        </Container>
      );
    }
  };

  const renderRegion = (region: string) => {
    const getBackgroundColor = (region: string) => {
      switch (region) {
        case "SYD":
          return "rgba(0,196,245,.2)";
        case "MEL":
          return "rgba(233,67,64,.2)";
        case "PER":
          return "rgba(0,166,97,.2)";
        default:
          return "";
      }
    };

    if (split) {
      let qty = 0;
      let main = false;
      if (item.warehouses) {
        for (let i = 0; i < item.warehouses.length; i++) {
          if (item.warehouses[i].location === region) {
            qty = parseInt(item.warehouses[i].quantity);
            if (i === 0) main = true;
          }
        }
        return (
          <td
            className="split"
            style={{ backgroundColor: getBackgroundColor(region) }}
          >
            <span className={cx({ main })}>{qty ?? 0}</span>
          </td>
        );
      } else {
        return (
          <td style={{ backgroundColor: getBackgroundColor(region) }}>
            <span className={cx({ main })}>0</span>
          </td>
        );
      }
    }
  };

  const renderBO = () => {
    if (bo) {
      let eta: { eta: string; quantity: number }[] | null;
      if (item.eta === "Pending") {
        eta = null;
      } else {
        try {
          eta = JSON.parse(item.eta.replaceAll("=>", ":"));
        } catch (error) {
          eta = null;
        }
      }

      return (
        <td
          style={{
            backgroundColor: "rgba(37,52,57,.2)",
            color: item.backorder ? "red" : "green",
          }}
        >
          {item.backorder ? (
            <>
              {item.backorder.quantity}
              {eta && eta.length > 0 ? (
                <>
                  <br />
                  <span className="small">{eta[0].eta}</span>
                </>
              ) : (
                <>
                  <br />
                  <span className="small">Pending</span>
                </>
              )}
            </>
          ) : (
            "In Stock"
          )}
        </td>
      );
    }
  };

  const renderStock = () => {
    const { stock } = item;
    if (showStock && stock) {
      let totalStock = 0;
      stock.forEach((warehouse) => (totalStock += warehouse.qtyAvailable));
      return (
        <td
          style={{
            color: totalStock >= item.quantity ? "green" : "#d90000",
          }}
        >
          {totalStock}
        </td>
      );
    }
  };

  // const renderTransferIcon = () => {
  //   if (splittedItems) {
  //     for (let i = 0; i < splittedItems.length; i++) {
  //       if (item.itemNo && splittedItems[i] === item.itemNo) {
  //         return (
  //           <span
  //             className="glyphicon glyphicon-transfer"
  //             style={{ color: "#46b8da", paddingRight: "5px" }}
  //           ></span>
  //         );
  //       }
  //     }
  //   }
  // };

  const renderColorImages = () => {
    let imagesToRender: ProductImage[] = [];

    if (item.images && item.images.length > 0) {
      imagesToRender = item.images;
    } else if (item.color_images && item.color_images.length > 0) {
      imagesToRender = item.color_images;
    }

    let frontImage = imagesToRender[0];
    for (const image of imagesToRender) {
      if (image.front || image.front_image) {
        frontImage = image;
        break;
      }
    }

    const imageLink = frontImage
      ? frontImage.https_attachment_url_product
      : "https://cdn.fashionbiz.com/Image+Not+Found.jpg";

    return (
      <img
        className="previewimage"
        style={{ width: "60px" }}
        src={imageLink}
        alt="preview_image"
      />
    );
  };

  const renderDescription = (shouldEnableLink: boolean) => (
    <div style={{ display: "flex" }}>
      <div
        onClick={() => shouldEnableLink && history.push(getProductLink(item))}
        className={cx({ cursor: shouldEnableLink })}
      >
        {renderColorImages()}
      </div>
      <div style={{ display: "flex", textAlign: "left" }}>
        <div style={{ margin: "auto", paddingLeft: "5px" }}>
          <span className="small">{item.product_name}</span>
          <br />
          <b>
            {/* {renderTransferIcon()} */}
            <span>{item.product_code ? item.product_code : item.itemNo}</span>
          </b>
          <br />
          <span className="small">SKU {item.sku}</span>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <tr
        className={cx({
          "cart-item": true,
          highlight: showModal,
        })}
        role="listitem row"
      >
        {index && <td role="cell">{index}</td>}
        <td>
          {renderDescription(
            item.promotion_code !== "giftCampaign" &&
            !!getProductLink(item) &&
            !disableImageLink
          )}
        </td>
        <td role="cell">{item.color}</td>
        <td role="cell">{item.size}</td>
        {appBranch === "au" && renderRegion("SYD")}
        {appBranch === "au" && renderRegion("MEL")}
        {appBranch === "au" && renderRegion("PER")}
        <td>
          {!editMode
            ? item.reference
              ? item.reference
              : "-"
            : renderEditRef(item.reference)}
        </td>
        <td>
          <div className={split ? "splitQuantity" : ""}>
            {!editMode ? item.quantity : renderEditQty(item.quantity)}
            <br />
          </div>
        </td>
        {appBranch === "au" &&
          <td>
            <div className={split ? "splitQuantity" : ""}>
              {item.available_quantity}
              <br />
            </div>
          </td>}
        {renderBO()}
        {renderStock()}
        <td role="cell">${parseFloat(item.price).toFixed(2)}</td>
        <td role="cell">
          ${appBranch === "au" ?
          item.available_price :
          (item.quantity * parseFloat(item.price)).toFixed(2)}
        </td>

        <td>{renderActionButtons()}</td>
      </tr>
      {showModal && (
        <Modal>
          <div className="cart-item-modal-template">
            Are you sure you want to remove this item?
            <Container>
              <StyledButton
                onClick={() => removeItem()}
                danger
                style={{ margin: "1em 0" }}
              >
                Yes
              </StyledButton>
              <StyledButton onClick={() => setShowModal(false)}>
                No
              </StyledButton>
            </Container>
          </div>
        </Modal>
      )}
    </>
  );
};

const getProductLink = (item: OrderItem) => {
  if (item.brand && item.product) {
    return `/products/${item.brand}/${item.product}`;
  } else if (item.brand_name && item.product_code) {
    return `/products/${item.brand_name}/${item.product_code.toLowerCase()}`;
  } else return "";
};

export default AuthenticationHOC(CartItem);
