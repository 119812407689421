import { useHistory } from "react-router-dom";
import { StyledButton } from "../../../../../components/styled/styled-components";

interface Props {
  hideModal: () => void;
  items: JSX.Element | JSX.Element[];
}

const NonPickup = ({ hideModal, items }: Props) => {
  const history = useHistory();

  return (
    <div>
      <div>
        The following item/s can not be fulfilled from your main warehouse.
        <br />
        {items}
        <br />
        The item/s will ship from an alternate warehouse.
        <br />
        Please Note: Arrival times for items between warehouses will vary.
      </div>
      <StyledButton success onClick={() => hideModal()}>
        Yes, I understand and accept.
      </StyledButton>
      <StyledButton warning onClick={() => history.go(0)}>
        No, I will modify my order now.
      </StyledButton>
    </div>
  );
};

export default NonPickup;
