import { useCallback } from "react";

export interface AuthHOCProps {
  generateAuthHeader: any;
  generateTokenAuthHeader: any;
}

const AuthenticationHOC = (ComposedComponent: any) => {
  const WrappedComponent = (props: any) => {
    const generateAuthHeader = useCallback(() => ({
      headers: {
        "X-Jwt-Token": localStorage.getItem("jwt"),
        "Access-Control-Allow-Origin": "*",
      },
    }), []);

    const generateTokenAuthHeader = useCallback(() => ({
      headers: {
        Authorization: `Token ${process.env.REACT_APP_HONEYBEE_TOKEN}`,
      },
    }), []);

    return (
      <ComposedComponent
        {...props}
        generateAuthHeader={generateAuthHeader}
        generateTokenAuthHeader={generateTokenAuthHeader}
      />
    );
  };

  return WrappedComponent;
};

export default AuthenticationHOC;
