export enum Brands {
  BizCollection = "Biz Collection",
  BizCorporates = "Biz Corporates",
  Syzmik = "Syzmik",
  BizCare = "Biz Care",
}

export enum AlertType {
  Warning = "warning",
  Error = "error",
  Success = "success",
  Info = "info",
}

export enum ErrorTypes {
  orderNotFound,
  productNotFound
}

export enum SalesStatus {
  Normal = "normal",
  NewStyle = "new style",
  NewColor = "new color",
  Clearance = "clearance",
  ClearanceOnSelectedColors = "clearance on selected colors",
  LimitedStock = "limited stock",
  AvailableSoon = "available soon",
  Discontinued = "discontinued",
  NewSize = "new size",
  Unavailable = "unavailable",
  Promotion = "promotion",
}

export enum OrderSubmitStatuses {
  Success = "success",
  Fail = "fail",
}
