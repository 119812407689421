import { ProductImage } from "../../../../interfaces/interfaces";

interface Props {
  currentColorImages: ProductImage[];
  setCurrentColorImages: any;
}

const ColorImageZoom = (props: Props) => {
  const { currentColorImages, setCurrentColorImages } = props;

  if (currentColorImages && currentColorImages.length) {
    const images = currentColorImages.map((image, index) => (
      <img
        className="previewImage"
        src={image.https_attachment_url}
        key={index}
        alt="preview_image"
      />
    ));
    return (
      <div className="fbModal" onClick={() => setCurrentColorImages([])}>
        <div className="modal-content">
          <small style={{ textAlign: "center" }}>
            Scroll down to view more images. Click anywhere to exit preview
            mode.
          </small>
          {images}
        </div>
      </div>
    );
  } else return <></>;
};

export default ColorImageZoom;
