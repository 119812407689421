import { SVGProps } from "react"
const TimesCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 154 153.9"
    {...props}
  >
    <style>{'.timesCircle{fill:#721c24}'}</style>
    <path
    d="M75.5 0c17.4.1 32 3.9 44.9 13 6.1 4.3 11.5 9.3 16.2 15 5.5 6.6 9.8 13.9 12.7 22.1 2.5 7 3.8 14.2 4.4 21.6.6 7.7-.2 15.2-1.7 22.7-2.1 10.4-6.7 19.7-13.1 28.2-5.9 7.9-12.8 14.6-21.1 19.9-8 5.1-16.6 8.4-25.9 10.1-8.1 1.5-16.3 1.7-24.4.8-17.9-2-33.2-9.8-45.7-22.7-8.6-8.8-15-19.1-18.6-31C1.6 94.4.8 88.9.3 83.4c-.7-7-.3-14 1-20.9 1.6-8.8 4.6-17.3 9.4-24.9 8.7-13.8 20.2-24.7 35.4-31.3C52.3 3.6 58.8 1.8 65.6 1c3.8-.6 7.7-.8 9.9-1zm68.9 78.5c1.6-38.3-30.6-67.4-63.9-68.9-40-1.9-71 31.1-70.8 64.9-2.5 37.7 30.6 71 70.3 69.7 32.3-1 64.4-29.5 64.4-65.7z"
    className="timesCircle"
    />
    <path
    d="m85 76.9 19.6 19.6c.9.9 1.7 1.8 2.4 2.8.9 1.3.7 2.7-.4 3.8-1.1 1.1-2.1 2.1-3.2 3.1-1.7 1.5-2.9 1.6-4.6 0-2.2-2-4.4-4.2-6.5-6.3-4.7-4.6-9.3-9.2-13.9-13.9-1.4-1.4-1.4-1.4-2.9.1-5.3 5.3-10.6 10.6-15.9 16-1.3 1.3-2.7 2.6-4 3.9-2 1.8-3.3 1.8-5.3 0-.9-.9-1.9-1.8-2.7-2.7-1.6-1.7-1.5-3.5.1-5.1L68 77.9c.3-.3.6-.7 1.1-1.2-2.4-2.3-4.8-4.6-7.1-6.9-4.6-4.5-9.2-9.1-13.7-13.7-.6-.6-1.1-1.2-1.5-2-.7-1.4-.5-2.4.5-3.5s2-2.1 3-3.1c1.8-1.8 3.1-1.8 4.9-.1 1.9 1.8 3.6 3.6 5.5 5.4 5 5 10 10 15.1 15 1.2 1.2 1.1 1.1 2.3 0 6.6-6.7 13.3-13.3 20-20 .4-.4.9-.8 1.5-1.1 1.5-.8 2.4-.7 3.6.4 1.2 1.1 2.3 2.2 3.4 3.4 1.4 1.4 1.4 3 .1 4.4-2.5 2.7-5.1 5.3-7.6 7.8-4.5 4.5-9.1 9-13.7 13.6-.1.2-.2.3-.4.6z"
    className="timesCircle"
    />
  </svg>
)
export default TimesCircle
