import { SVGProps } from "react"
const Clipboard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 118.7 159"
    {...props}
  >
    <path d="M42.3 19.8C41.1 8.8 48.9.1 59.4 0c5.1-.1 9.4 2.1 12.8 6.1 3.4 3.9 4.7 8.4 4 13.7h26.7c5.8 0 10.5 2.4 13.7 7.3 1.2 1.8 1.8 3.8 1.9 6 .1 2.3.1 4.6.1 6.8v102.9c0 4.9-1.3 9-4.9 12.4-3 2.8-6.7 3.9-10.6 3.9H15.3c-7 0-13.2-4.6-14.8-10.8-.4-1.5-.6-3-.6-4.5 0-35 0-69.9.1-104.9 0-2.4-.1-4.8.2-7.1.8-5.6 5.5-10.3 11.3-11.5 1.7-.3 3.4-.5 5-.5 8-.1 16.1 0 24.1 0h1.7zM9.4 89.4v53.9c0 1 .1 2 .5 2.9 1 2.4 3.1 3 5.6 3h88.9c2.3-.1 4.2-1.4 4.5-3.7.4-2.2.4-4.5.4-6.8V42.9c0-2.6.1-5.2 0-7.8-.1-3.5-2.2-5.4-5.7-5.4H90.1c-1 0-1.3.3-1.3 1.3.1 1.7 0 3.5 0 5.2 0 2-.7 2.8-2.7 3.3-.6.1-1.3.2-2 .2H34.8c-.7 0-1.4-.1-2.1-.2-1.9-.3-2.6-1-2.8-2.9-.2-1.9-.2-3.8-.2-5.7 0-.8-.2-1.2-1-1.2H14.3c-2.8.1-4.7 2-4.9 4.8-.1 1-.1 1.9-.1 2.9.1 17.3.1 34.6.1 52zm42.4-72.1c0 4.2 3.3 7.5 7.6 7.5 4.2 0 7.6-3.3 7.6-7.5 0-4.1-3.4-7.5-7.7-7.5-4.2 0-7.6 3.2-7.5 7.5z" />
  </svg>
)
export default Clipboard
