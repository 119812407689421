import { SVGProps } from "react"
const HourglassEnd = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 118.9 159.1"
    {...props}
  >
    <path d="M45.8 79.6c-1.9-.8-3.5-1.5-5.1-2.2-8.4-3.9-15.2-9.8-20.4-17.5-5.4-7.8-9.3-16.3-11.9-25.4C6.3 26.8 5 18.9 5.2 10.9c0-.7-.2-1-.9-1h-.6C1.1 9.5.4 8.8.1 6.2 0 5.3 0 4.3.1 3.4.2 1.5 1 .6 3 .2 3.8.1 4.6 0 5.4 0h109.3c3.2.2 4.1 1.1 4.1 4.3v1.5c0 2.9-.9 3.9-3.8 4.1-1 .1-1.2.4-1.2 1.3-.1 17.4-4.9 33.4-14.5 47.9-4.5 6.7-10 12.4-17 16.4-2.7 1.5-5.5 2.7-8.4 4.1.2.1.4.3.7.4 8.9 3.3 16.1 8.9 22 16.2 5.1 6.2 8.6 13.3 11.4 20.7 3.8 10 5.9 20.4 5.8 31.2 0 .8.2 1.1 1 1.2.7 0 1.4.2 2.1.4 1.3.4 1.9 1.2 1.9 2.6v3.7c0 1.6-.7 2.4-2.2 2.8-.7.2-1.5.3-2.3.3H4.4c-3.2-.1-4.4-1.3-4.4-4.5v-.7c0-3.2.9-4.3 4.2-4.6.7-.1 1-.2 1-1-.1-4.9.2-9.8 1.1-14.7 1.3-6.9 3.2-13.7 6.1-20.2C15.7 106 19.6 99 25.2 93c3.3-3.5 7-6.7 11.2-9.1 2.4-1.4 5-2.4 7.5-3.5.5-.3 1-.4 1.9-.8zM59.4 9.9H15.9c-1.2 0-1.6.3-1.5 1.5.3 2.9.4 5.9.7 8.8.8 8.3 2.9 16.2 6.5 23.7 3.9 7.9 8.9 15 15.8 20.5 8.1 6.5 17.4 8.9 27.6 7.5 8.7-1.3 15.9-5.7 21.7-12.1 8.7-9.7 14.4-20.9 16.4-33.8.7-4.7 1-9.5 1.4-14.3.1-1.7 0-1.7-1.8-1.7-14.4-.1-28.9-.1-43.3-.1zm.2 139.4h43.7c1.1 0 1.6-.3 1.4-1.5-.2-1.6-.3-3.2-.4-4.8-.4-8.8-2.3-17.3-5.8-25.4-3.3-7.6-7.9-14.3-13.8-20.1-6.3-6.2-13.8-9.8-22.5-10.4-9.2-.7-17.4 1.9-24.7 7.6-8.5 6.7-14.1 15.5-18.1 25.4-3.5 8.8-4.7 18.1-5.1 27.5-.1 1.6 0 1.6 1.6 1.6 14.7.1 29.2.1 43.7.1z" />
    <path d="M59.6 139.2H31.1c-.7 0-1.4-.1-2.1-.3-2-.6-2.9-2.2-2.5-4.2.1-.7.4-1.4.6-2.2.8-2.5 1.6-3.1 4.2-3.2H87.2c.7 0 1.5.1 2.2.2 1.3.3 2.2 1 2.4 2.3.1.7.3 1.4.5 2 .9 3-.8 5.2-4 5.2H74.1c-4.7.2-9.6.2-14.5.2zM59.4 119.3H39.3c-2.3 0-3.8-1.1-4.2-3.1-.1-.7.2-1.6.4-2.3.1-.3.4-.5.5-.7 1.8-2.9 4.3-3.9 7.8-3.8 11.3.2 22.5.1 33.8.1 1.7 0 3 .5 4 1.8.4.5.8 1 1.3 1.5 1.2 1.2 1.6 2.6.9 4.2-.7 1.7-2.2 2.4-4 2.4h-6.1c-4.8-.1-9.5-.1-14.3-.1z" />
  </svg>
)
export default HourglassEnd
