import { useHistory } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import Modal from "../../components/Modal/Modal";
import {
  Container,
  StyledButton,
  StyledInput,
  Wrapper
} from "../../components/styled/styled-components";

interface Props {
  modalToShow: number;
  setModalToShow: React.Dispatch<React.SetStateAction<number>>;
  clearCart: () => void;
  saveOrder: () => void;
  modalMessage: string;
  isLoading: boolean;
  setSaveOrderInput: React.Dispatch<React.SetStateAction<string>>;
}

const ModalSwitcher = ({
  modalToShow,
  setModalToShow,
  clearCart,
  saveOrder,
  modalMessage,
  isLoading,
  setSaveOrderInput,
}: Props) => {
  const history = useHistory();

  switch (modalToShow) {
    case 1:
      return (
        <Modal>
          <Container className="cart-modal-template">
            <h3>Would you like to empty your cart?</h3>
            {isLoading ? (
              <Wrapper margin="1em 0">
                <LoadingSpinner />
              </Wrapper>
            ) : (
              <Container row jcsa margin="2em 0 0 0">
                <StyledButton onClick={() => clearCart()}>Yes</StyledButton>
                <StyledButton onClick={() => setModalToShow(-1)}>
                  No
                </StyledButton>
              </Container>
            )}
          </Container>
        </Modal>
      );
    case 4:
      return (
        <Modal>
          <Container className="cart-modal-template">
            <p>{modalMessage}</p>
            <Container row jcsa>
              <StyledButton onClick={() => history.go(0)}>
                Back to cart
              </StyledButton>
            </Container>
          </Container>
        </Modal>
      );
    case 2:
      return (
        <Modal>
          <Container className="cart-modal-template">
            <p>Enter an order note</p>
            <form>
              <Wrapper margin="1em 0">
                <StyledInput
                  onChange={(event) => setSaveOrderInput(event.target.value)}
                />
              </Wrapper>
              {isLoading ? (
                <Wrapper margin="1em 0">
                  <LoadingSpinner />
                </Wrapper>
              ) : (
                <Container row jcsa>
                  <StyledButton
                    onClick={(event) => {
                      saveOrder();
                      event.preventDefault();
                    }}
                  >
                    Save
                  </StyledButton>
                  <StyledButton onClick={() => setModalToShow(-1)}>
                    Cancel
                  </StyledButton>
                </Container>
              )}
            </form>
          </Container>
        </Modal>
      );
    case 3:
      return (
        <Modal>
          <Container className="cart-modal-template">
            <p>{modalMessage}</p>
            <Container row jcsa>
              <StyledButton onClick={() => history.go(0)}>
                Back to cart
              </StyledButton>
            </Container>
          </Container>
        </Modal>
      );

    default:
      return <></>;
  }
};

export default ModalSwitcher;
