import { useState } from "react";
import { Link } from "react-router-dom";

interface ILink {
  title: string;
  link: string;
  valueSet?: React.Dispatch<React.SetStateAction<boolean>>;
  blank?: boolean;
}

interface Props {
  children: JSX.Element;
  list: ILink[];
}
const Dropdown = ({ children, list }: Props) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  return (
    <div
      className="dropdown"
      role="listbox"
      onMouseEnter={() => setIsCollapsed(false)}
      onMouseLeave={() => setIsCollapsed(true)}
    >
      {children}
      {!isCollapsed && (
        <div className="collapsible-content">
          {list.map((item, index) => (
            <Link
              key={index}
              to={{ pathname: item.link }}
              className="dropdown-link"
              role="option"
              onClick={() => item.valueSet && item.valueSet?.(true)}
              target={item.blank ? "_blank" : ""}
            >
              {item.title}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
