interface Props {
  message?: string;
  height?: number;
}

const LoadingSpinner = (props: Props) => {
  const { message, height } = props;
  const h = height ? height : 25;

  return (
    <div style={{ display: "flex" }} role="progressbar">
      <div
        className="loader"
        style={{ height: h, width: h, borderWidth: h / 5 }}
      />
      {message && <span style={{ marginLeft: "5px" }}>{message}</span>}
    </div>
  );
};
export default LoadingSpinner;
