import { useCallback, useEffect, useState } from "react";
import FieldGenerator from "../../../../../../components/Address/AddressFormFieldGenerator";
import { StyledFormLayout } from "../../../../../../components/styled/styled-components";
import { Address } from "../../../../../../interfaces/interfaces";

interface Props {
  updateShippingAddressInRedux: (address: Address | null) => void;
}

interface Form {
  ship_to_name?: string;
  address_line1?: string;
  address_line2?: string;
  address_city?: string;
  address_postcode?: string;
  state: string;
}

function NewAddress({ updateShippingAddressInRedux }: Props) {
  const [form, setForm] = useState<Form>({ state: "BC" });

  const getShipAddressParams = useCallback(() => {
    const {
      ship_to_name,
      address_line1,
      address_line2,
      address_city,
      address_postcode,
      state
    } = form;

    if (
      !address_line1 ||
      !ship_to_name ||
      !address_postcode ||
      !address_city ||
      !state
    ) {
      return null;
    }

    const shipAddressParams = {
      ship_to_name,
      address_line1,
      address_line2: address_line2 ?? "",
      address_line3: "",
      address_line4: "",
      address_city,
      state,
      address_country: "CANADA",
      address_postcode
    };

    return shipAddressParams;
  }, [form]);

  useEffect(() => {
    updateShippingAddressInRedux(getShipAddressParams());
  }, [getShipAddressParams, updateShippingAddressInRedux]);

  const onFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    setForm({
      ...form,
      [key]: event.target.value
    });
  };

  return (
    <div className="new-address-wrapper">
      <StyledFormLayout>
        <FieldGenerator form={form} onChange={onFieldChange} />
      </StyledFormLayout>
    </div>
  );
}

export default NewAddress;
