import axios from "axios";
import React, { useContext, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { log } from "../../commonfunctions/logger";
import sortSizes from "../../commonfunctions/size-sorter";
import { AlertType, SalesStatus } from "../../interfaces/enums";
import {
  AlertContent,
  ProductColor,
  ProductDetailResponse
} from "../../interfaces/interfaces";
import { FetchStatus } from "../../interfaces/types";
import { updateCurrentOrder } from "../../redux/actions/orders";
import { RootState } from "../../redux/store";
import Alert from "../Alert/Alert";
import AuthenticationHOC from "../AuthenticationHOC/AuthenticationHOC";
import CartItemchecker from "../CartItemChecker/CartItemChecker";
import {
  Container,
  StyledButton,
  StyledInput
} from "../styled/styled-components";
import Toast from "../Toast/Toast";
import ColorSelector from "./ColorSelector/ColorSelector";
import { ColorSizeStock } from "./interfaces";
import SizeDetailTable from "./SizeDetailTable/SizeDetailTable";
import { Context, IContext } from "../../context/ContextApp";

interface ItemPriceDetails {
  price: string;
  currency: string;
}

interface Props {
  generateAuthHeader: any;
  product: ProductDetailResponse;
}

const OrderForm = (props: Props) => {
  const { generateAuthHeader, product } = props;
  const [orderReference, setOrderReference] = useState("");
  const [skuFetchStatus, setSkuFetchStatus] = useState<FetchStatus>(null);
  const [inputRefs, setInputRefs] = useState({});
  const [currentColorSizeStocks, setCurrentColorSizeStocks] = useState<
    ColorSizeStock[]
  >([]);
  const sales_statuses = [SalesStatus.Clearance, SalesStatus.Discontinued]

  const [itemPriceDetails, setItemPriceDetails] =
    useState<ItemPriceDetails | null>(null);

  const [shouldRenderSizeDetail, setShouldRenderSizeDetail] = useState(false);
  const [showLimitMessage, setShowLimitMessage] = useState(false);
  const [actionStatus, setActionStatus] = useState<AlertContent | null>({
    message: "Please select a colour to load stock level.",
    type: AlertType.Info
  });

  const [currentSelectedColor, setCurrentSelectedColor] =
    useState<ProductColor>({} as ProductColor);
  const [toast, setToast] = useState<AlertContent | null>(null);
  const { appBranch }: IContext = useContext(Context);
  const isStockListMode = localStorage.getItem("stocklist_mode") === "true";

  const currentOrder = useSelector(
    (state: RootState) => state.orders.currentOrder
  );

  const history = useHistory();
  const dispatch = useDispatch();
  const latestUrlToFetch = useRef("");

  // const [fetchUrl, setFetchUrl] = useState<string>("");
  // const { isLoading, error, data } = useFetchGET(
  //   fetchUrl,
  //   generateAuthHeader()
  // );

  // useEffect(() => {
  //   let skus = "";
  //   if (currentSelectedColor.sizes.length > 0) {
  //     setShouldRenderSizeDetail(false);
  //     for (let i = 0; i < currentSelectedColor.sizes.length; i++) {
  //       skus += "&skus[]=" + currentSelectedColor.sizes[i].sku;
  //     }
  //   } else {
  //     setActionStatus({
  //       message: `No Skus found for colour ${currentSelectedColor.name}.`,
  //       type: "error",
  //     });
  //     const url = `${process.env.REACT_APP_HALFBACK_ROOT_API}/orders/current_order/items/sizes_detail_by_skus?color_id=${color.id}${skus}`;

  //     setFetchUrl(url);
  //   }
  // }, [currentSelectedColor]);

  // useEffect(() => {

  // }, [data, error])

  const getStock = async (color: any) => {
    let skus = "";

    if (color.sizes.length > 0) {
      setCurrentSelectedColor(color);
      setShouldRenderSizeDetail(false);

      for (let i = 0; i < color.sizes.length; i++) {
        skus += "&skus[]=" + color.sizes[i].sku;
      }
    } else {
      setActionStatus({
        message: `No Skus found for colour ${color.name}.`,
        type: AlertType.Error
      });
    }
    setSkuFetchStatus("loading");
    setActionStatus(null);
    const url = `${process.env.REACT_APP_HALFBACK_ROOT_API}/orders/current_order/items/sizes_detail_by_skus?color_id=${color.id}${skus}`;
    latestUrlToFetch.current = url;

    try {
      const res = await fetch(url, generateAuthHeader());
      if (res.ok) {
        const data = await res.json();
        if (latestUrlToFetch.current === url) {
          if (data.items) {
            const sortedStock = sortSizes(data.items);

            setItemPriceDetails({
              price: data.price,
              currency: data.currency
            });

            setShouldRenderSizeDetail(true);
            setCurrentColorSizeStocks(sortedStock);
            sortedStock.forEach((cs: any) => {
              setInputRefs({
                ...inputRefs,
                [cs.sku]: 0
              });
            });
            setSkuFetchStatus("success");
            // Now scroll the table into view
            const table = document.getElementById("size-detail-table");
            table?.scrollIntoView({ behavior: "smooth" });
          } else {
            setActionStatus({
              message: `Not able to load stock for ${color.name}. Please try again.`,
              type: AlertType.Error
            });
            setSkuFetchStatus("fail");
          }
        }
      } else {
        const error = await res.text();
        throw error;
      }
    } catch (error) {
      if (latestUrlToFetch.current === url) {
        log(error, `Failed to load stock for ${color.name}`);
        const errorString = error as string;
        if (errorString.indexOf("401") !== -1) {
          alert("Your session has expired, please log in again.");
          history.go(0);
        } else {
          setActionStatus({
            message: `Not able to load stock for ${color.name}. Please try again.`,
            type: AlertType.Error
          });
          setSkuFetchStatus("fail");
        }
      }
    }
  };

  const checkCartLimit = (items: ColorSizeStock[]) => {
    const cart = currentOrder.order_items;
    const res = [];
    for (let i = 0; i < cart.length; i++) {
      for (let p = 0; p < items.length; p++) {
        if (cart[i].sku === items[p].sku) {
          res.push(cart[i].sku);
        }
      }
    }
    if (cart.length + (items.length - res.length) > 150) {
      return false;
    } else {
      setShowLimitMessage(false);
      return true;
    }
  };

  const saveToCart = () => {
    const items: any[] = [];

    currentColorSizeStocks.forEach((cs) => {
      if (cs.quantity && cs.quantity > 0 && !cs.errorMessage) {
        items.push({
          sku: cs.sku,
          quantity: cs.quantity,
          itemNo: cs.formattedItemNo,
          product_id: product.id,
          color: currentSelectedColor.name,
          images: currentSelectedColor.images,
          size: cs.size,
          price: itemPriceDetails?.price,
          backorder_qty: cs.backOrderQty ?? 0,
          stock: cs.stock,
          // Fix this later - not sure of typeof etaDate
          eta: cs.etaDate,
          brand_name: product.brand,
          product_code: product.code,
          product_name: product.name,
          product_slug: product.slug
        });
      }
    });

    if (items.length < 1) return; //if no items, break

    const flag = checkCartLimit(items);

    if (flag) {
      const req_url = `${process.env.REACT_APP_HALFBACK_ROOT_API}/orders/current_order/items/create`;
      const params = {
        items,
        reference: orderReference
      };

      axios
        .post(req_url, params, generateAuthHeader())
        .then((response) => {
          setToast({
            message: "Item added to cart.",
            type: AlertType.Success
          });
          dispatch(updateCurrentOrder(response.data));
          const colorSizeDetailReset: ColorSizeStock[] = [];

          currentColorSizeStocks.forEach((cs) => {
            /* Resetting values */
            cs.backOrderQty = 0;
            cs.errorMessage = "";
            cs.etaDate = "";
            cs.quantity = 0;

            setInputRefs((inputRefs) => ({
              ...inputRefs, [cs.sku]: 0
            }));
            colorSizeDetailReset.push(cs);
          });

          setCurrentColorSizeStocks(colorSizeDetailReset);
          setOrderReference("");
        })
        .catch((error) => {
          if (error.response && error.response.status === "401") {
            alert("Your session is expired. Please login again.");
            history.push("/");
          }
          setToast({
            message: "Could not add item to cart.",
            type: AlertType.Error
          });
        });
    } else {
      setShowLimitMessage(true);
    }
  };

  const renderAddButton = () => {
    if (currentColorSizeStocks.length > 0) {
      return (
        <div className="add-to-cart-container">
          <div>
            <StyledInput
              type="text"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setOrderReference(event.target.value)
              }
              value={orderReference}
              placeholder="Enter Order Reference"
              maxLength={30}
            />
            <StyledButton primary onClick={() => saveToCart()}>
              Add to Cart
            </StyledButton>
          </div>
          <small style={{ color: "gray" }}>
            *NOTE: Stock availability may have changed by the time you submit
            the order.
          </small>
        </div>
      );
    }
  };

  const renderAddToCartInfo = () => {
    if (showLimitMessage) {
      return <Alert>You reached the maximum limit of lines.</Alert>;
    }
  };

  return (
    <Container padding="0 0 3em 0">
      <Container row nowrap={product.colors.length < 3}>
        <ColorSelector
          product={product}
          skuFetchStatus={skuFetchStatus}
          getStock={getStock}
          currentSelectedColor={currentSelectedColor}
        />
      </Container>
      {actionStatus && <Alert content={actionStatus} />}
      {!isStockListMode && (itemPriceDetails && (
        <b>
          {itemPriceDetails.currency} $
          {parseFloat(itemPriceDetails.price).toFixed(2)}
        </b>
      ))}
      <Container
        margin="0 0 1em 0"
        style={{ overflowX: "auto", width: "100%" }}
      >
        {
          <SizeDetailTable
            shouldRenderSizeDetail={shouldRenderSizeDetail}
            currentSelectedColor={currentSelectedColor}
            currentColorSizeStocks={currentColorSizeStocks}
            itemPrice={itemPriceDetails?.price}
            setActionStatus={setActionStatus}
            product={product}
            setCurrentColorSizeStocks={setCurrentColorSizeStocks}
            inputRefs={inputRefs}
            setInputRefs={setInputRefs}
          />
        }
      </Container>
      {itemPriceDetails && (
        <>
          {sales_statuses.includes(product.sales_status) ? (
            <Alert>
              This is a clearance/discontinued product. Back order is <b>NOT</b>{" "}
              available.
            </Alert>
          ) : (appBranch === "nz" && (
            <Alert>
              Back orders are processed by our CS team and stock shipped from alternate warehouses if available.
              For urgent orders please contact our CS team directly.
            </Alert>
          )
          )}

          {!isStockListMode && <Container row>{renderAddButton()}</Container>}
          {!isStockListMode && <Container>{renderAddToCartInfo()}</Container>}
          <Container row margin="1em 0">
            <CartItemchecker slug={product.slug} />
          </Container>
        </>
      )}

      <Toast content={toast} onClick={() => setToast(null)} />
    </Container>
  );
};

export default AuthenticationHOC(OrderForm);
