import styled from "styled-components";

const getColor = (props: any) => {
  if (props.primary) {
    return "#7793ab";
  } else if (props.success) {
    return "#6ba7a8";
  } else if (props.danger) {
    return "#721c24";
  } else if (props.warning) {
    return "#d9af4b";
  } else if (props.info) {
    return "#d9af4b";
  } else {
    return "#7793ab";
  }
};

export const StyledButton = styled.button<{
  primary?: boolean;
  success?: boolean;
  danger?: boolean;
  warning?: boolean;
  info?: boolean;
  sharp?: boolean;
  full?: boolean;
}>`
  border-radius: 0;
  padding: 0.5em 1.5em;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s ease;
  color: white;
  background-color: ${(props) => getColor(props)};
  width: ${(props) => (props.full ? "100%" : "auto")};

  &:hover {
    background-color: transparent;
    border-color: ${(props) => getColor(props)};
    color: ${(props) => getColor(props)};
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }
  &:disabled,
  &[disabled]{
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }
`;

export const StyledFormLayout = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

export const StyledFormField = styled.div`
  label {
    width: 40%;
    padding-right: 1em;
  }

  input,
  select {
    width: 60%;
    display: inline-block;
    margin-bottom: 1em;
    border-radius: 3px;
    border: 1px solid #aaa;
    padding: 0.5em 1.5em;
    transition: 0.3s ease;

    &:focus {
      border-color: #80bdff;
      box-shadow: 0 0 5px #80bdff;
      outline: none;
    }
  }
`;

export const StyledInput = styled.input`
  width: 100%;
  display: inline-block;
  border-radius: 3px;
  border: 1px solid #aaa;
  padding: 0.5em 1.5em;
  transition: 0.3s ease;

  &:focus {
    border-color: #80bdff;
    box-shadow: 0 0 5px #80bdff;
    outline: none;
  }
`;

export const StyledNumberInput = styled.input`
  width: 100%;
  display: inline-block;
  border-radius: 3px;
  border: 1px solid #aaa;
  padding: 0.5em 1px 0.5em 10px;
  transition: 0.3s ease;

  &:focus {
    border-color: #80bdff;
    box-shadow: 0 0 5px #80bdff;
    outline: none;
  }
`;

export const StyledTextArea = styled.textarea`
  width: 100%;
  display: inline-block;
  border-radius: 3px;
  border: 1px solid #aaa;
  padding: 0.5em 1.5em;
  transition: 0.3s ease;

  &:focus {
    border-color: #80bdff;
    box-shadow: 0 0 5px #80bdff;
    outline: none;
  }
`;

export const StyledSelect = styled.select`
  width: 100%;
  display: inline-block;
  border-radius: 3px;
  border: 1px solid #aaa;
  padding: 0.5em 1.5em;
  transition: 0.3s ease;

  &:focus {
    border-color: #80bdff;
    box-shadow: 0 0 5px #80bdff;
    outline: none;
  }
`;

/* Flex props are the first letters of each word of the original css properties
- e.g. : jcsa - "J"ustify "C"ontent "S"pace "A"round
*/
export const Container = styled.div<{
  row?: boolean;
  margin?: string;
  padding?: string;
  jcsb?: boolean;
  jcfe?: boolean;
  jcc?: boolean;
  jcsa?: boolean;
  aifs?: boolean;
  aife?: boolean;
  aic?: boolean;
  nowrap?: boolean;
  h?: string | number;
  colmobile?: boolean;
}>`
  display: flex;
  flex-direction: ${(props) => (props.row ? "row" : "column")};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  justify-content: ${(props) => {
    if (props.jcsb) {
      return "space-between";
    } else if (props.jcsa) {
      return "space-around";
    } else if (props.jcfe) {
      return "flex-end";
    } else if (props.jcc) {
      return "center";
    } else {
      return "flex-start";
    }
  }};
  align-items: ${(props) => {
    if (props.aifs) {
      return "flex-start";
    } else if (props.aife) {
      return "flex-end";
    } else if (props.aic) {
      return "center";
    } else {
      return "stretch";
    }
  }};
  flex-wrap: ${(props) => (props.nowrap ? "" : "wrap")};

  height: ${(props) => (props.h ? props.h : "auto")};

  ${(props) =>
    props.colmobile &&
    `@media (max-width: 992px) {
    flex-direction: column
  }`};
`;

export const Wrapper = styled.div<{ padding?: string; margin?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
`;
