import { useEffect, useReducer } from "react";
import { ProductListAPIResponse } from "../interfaces/interfaces";
import { subdomainTypes } from "../context/availableSubdomains";

const { REACT_APP_HONEYBEE_ROOT_API } = process.env;

interface Action {
  type: string;
  searchAPIResponse?: ProductListAPIResponse;
  error?: string;
}

interface Reducer {
  searchAPIResponse: ProductListAPIResponse | null;
  error: string | undefined;
  isLoading: boolean;
}

const initialState = {
  searchAPIResponse: null,
  error: "",
  isLoading: false,
};

const reducer = (state: Reducer, action: Action) => {
  switch (action.type) {
    case "FETCH_START":
      return {
        isLoading: true,
        error: "",
        searchAPIResponse: null,
      };
    case "FETCH_SUCCESS":
      return {
        isLoading: false,
        error: "",
        searchAPIResponse: action.searchAPIResponse ?? null,
      };
    case "FETCH_FAIL":
      return {
        isLoading: false,
        error: action.error,
        searchAPIResponse: null,
      };
    default:
      return state;
  }
};

const useProductSearch = (
  query: string,
  pageNumber = "1",
  generateTokenAuthHeader: any,
  appBranch: subdomainTypes | ''
) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { isLoading, searchAPIResponse, error } = state;

  useEffect(() => {
    const searchProducts = async () => {
      dispatch({ type: "FETCH_START" });
      const url = `${REACT_APP_HONEYBEE_ROOT_API}/products/${appBranch}/?search=${query}&page=${pageNumber}&brand=biz-collection&brand=biz-corporates&brand=syzmik&brand=biz-care&caller=webstore&target=size`;

      try {
        const res = await fetch(url, generateTokenAuthHeader());
        const data = await res.json();
        data.products = data.products.filter((item: { brand: any; }) => localStorage.getItem("brands")?.includes(item.brand))

        if (data.total_count < 1) {
          dispatch({ type: "FETCH_FAIL", error: "No products found." });
        } else {
          dispatch({ type: "FETCH_SUCCESS", searchAPIResponse: data });
        }
      } catch (error) {
        dispatch({
          type: "FETCH_FAIL",
          error: "Sorry, something is wrong, please try again or contact us.",
        });
      }
    };

    if (query || query === "") {
      searchProducts();
    }
  }, [query, pageNumber, generateTokenAuthHeader, appBranch]);

  return {
    isLoading,
    error,
    searchAPIResponse,
  };
};

export default useProductSearch;
