import React from "react";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import Modal from "../../../components/Modal/Modal";
import {
  Container,
  StyledButton,
  StyledInput,
  Wrapper
} from "../../../components/styled/styled-components";

interface Props {
  modalToShow: number;
  activateSavedOrder: (a?: boolean) => void;
  deleteSavedOrder: () => void;
  setModalToShow: React.Dispatch<React.SetStateAction<number>>;
  isLoading: boolean;
  setDraftNote: React.Dispatch<React.SetStateAction<string>>;
}

const ModalSwitcher = (props: Props) => {
  const {
    modalToShow,
    activateSavedOrder,
    deleteSavedOrder,
    setModalToShow,
    isLoading,
    setDraftNote,
  } = props;

  switch (modalToShow) {
    case 1:
      return (
        <Modal>
          <div className="modal-template">
            <p>There are items in your cart.</p>
            <p>
              <b>To load this order, you must empty your cart or save it.</b>
            </p>
            {isLoading ? (
              <Wrapper margin="1em 0">
                <LoadingSpinner />
              </Wrapper>
            ) : (
              <Container row jcsa margin="1em 0">
                <StyledButton onClick={() => activateSavedOrder()}>
                  Empty cart
                </StyledButton>
                <StyledButton onClick={() => setModalToShow(2)}>
                  Save cart
                </StyledButton>
                <StyledButton danger onClick={() => setModalToShow(-1)}>
                  Cancel
                </StyledButton>
              </Container>
            )}
          </div>
        </Modal>
      );
    case 2:
      return (
        <Modal>
          <div className="modal-template">
            <p>Set an order note for the current cart.</p>
            <form>
              <StyledInput
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setDraftNote(event.target.value)}
              />
              {isLoading ? (
                <Wrapper margin="1em 0">
                  <LoadingSpinner />
                </Wrapper>
              ) : (
                <Container row jcsa margin="1em 0">
                  <StyledButton
                    onClick={(event) => {
                      activateSavedOrder(true);
                      event.preventDefault();
                    }}
                  >
                    Save cart
                  </StyledButton>
                  <StyledButton danger onClick={() => setModalToShow(-1)}>
                    Cancel
                  </StyledButton>
                </Container>
              )}
            </form>
          </div>
        </Modal>
      );
    case 3:
      return (
        <Modal>
          <div className="modal-template">
            <p>Are you sure you want to delete this saved order?</p>
            <p>
              <b>This action cannot be undone.</b>
            </p>
            {isLoading ? (
              <Wrapper>
                <LoadingSpinner />
              </Wrapper>
            ) : (
              <Container row jcsa margin="1em 0">
                <StyledButton onClick={() => deleteSavedOrder()}>
                  Yes
                </StyledButton>
                <StyledButton onClick={() => setModalToShow(-1)}>
                  No
                </StyledButton>
              </Container>
            )}
          </div>
        </Modal>
      );
    default:
      return <></>;
  }
};

export default ModalSwitcher;
