import { Text, View } from "@react-pdf/renderer";
import { formatToCostString } from "../../../../../../commonfunctions/cost-calculation";
import { transactionItemFormatStyles } from "../../../../components/styles";

interface Props {
  item: any;
}

const TransactionItemLine = (props: Props) => {
  const { item } = props;
  const { itemCode, itemDesc, unitPrice, qty, extPrice } = item;

  return (
    <View style={transactionItemFormatStyles.itemRow}>
      <Text
        style={{
          ...transactionItemFormatStyles.transactionColumn,
          ...transactionItemFormatStyles.columnCode,
        }}
      >
        {itemCode}
      </Text>
      <Text
        style={{
          ...transactionItemFormatStyles.transactionColumn,
          ...transactionItemFormatStyles.columnDescription,
        }}
      >
        {itemDesc}
      </Text>
      <Text
        style={{
          ...transactionItemFormatStyles.transactionColumn,
          ...transactionItemFormatStyles.columnPrice,
        }}
      >
        {formatToCostString(unitPrice)}
      </Text>
      <Text
        style={{
          ...transactionItemFormatStyles.transactionColumn,
          ...transactionItemFormatStyles.columnQuantity,
        }}
      >
        {qty ? parseInt(qty) : ""}
      </Text>
      <Text
        style={{
          ...transactionItemFormatStyles.transactionColumn,
          ...transactionItemFormatStyles.columnTotal,
        }}
      >
        {formatToCostString(extPrice)}
      </Text>
    </View>
  );
};

export default TransactionItemLine;
