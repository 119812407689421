import { SVGProps } from "react"
const OnlinePaymentIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 178.8 139.1"
    {...props}
  >
    <path d="M89.4 139.1H15.2c-8.6 0-15.2-6.5-15.2-15.2C0 89 0 54.1.1 19.3c0-2.6-.1-5.2.3-7.7C1.4 5.1 7.5.7 13.1.3 16.1 0 19 0 22 0h135.6c3.5 0 7.2 0 10.6.8 6.1 1.3 10.4 7 10.5 13.2.1 1.9 0 3.7 0 5.6v103.5c0 6.1-2.4 10.7-7.4 14.1-2.1 1.4-4.4 1.9-6.8 1.9h-55.7c-6.4-.1-12.9-.1-19.4 0 0-.1 0 0 0 0zm-.1-9.7h75.2c2.9-.2 4.9-2.5 4.9-5.3v-108c0-4.2-2-6.3-6.2-6.4H16.8c-1.3 0-2.6.2-3.8.5-2.2.6-3.3 2.2-3.5 4.3-.1 1-.1 2-.1 3v105.9c0 .9.1 1.9.4 2.8 1 2.4 3 3.2 5.5 3.2h74z" />
    <path d="M139.2 49.5c-3.9 0-7.7-.2-11.6 0-3.8.2-8-2.3-8.1-7.7-.1-5.1 0-10.2.1-15.3.1-3.1 2.8-5.9 6-6.4.8-.1 1.6-.2 2.5-.2h22.4c3 0 5.7.9 7.3 3.7.5.9 1 1.9 1 2.9.1 5.6.1 11.1 0 16.7-.1 3.3-3.4 6.3-6.9 6.3h-12.7zm.2-9.7h9c.8 0 1.2-.2 1.2-1.2-.1-2.6-.1-5.3 0-7.9 0-.9-.3-1.1-1.1-1.1h-18.3c-.9 0-1.2.3-1.1 1.2 0 2.6.1 5.1 0 7.7 0 1.1.3 1.4 1.3 1.4 2.9-.1 6-.1 9-.1zM133.9 89.3h-10.6c-2.8 0-3.7-.8-3.7-3.6-.1-2.9 0-5.7 0-8.6 0-1.3-.1-2.6-.1-3.9.1-2.5.9-3.4 3.5-3.6h21c4.2 0 4.9.6 4.9 4.8 0 3.7 0 7.4-.1 11.1 0 2.8-.9 3.7-3.7 3.7-3.7.1-7.5.1-11.2.1zM94.1 109.2H73.6c-2.7 0-3.6-.7-3.8-3.4-.1-1.3 0-2.7.2-4.1.1-1.1.8-1.8 1.9-2 .8-.1 1.6-.2 2.3-.2h39.7c.9 0 1.7.1 2.6.2 1.8.3 2.4.9 2.5 2.7.1 1.3.1 2.7 0 4-.1 1.5-.7 2.3-2.2 2.6-.6.1-1.3.2-2 .2H94.1zM71.7 89.3h-8.1c-2.9 0-3.7-.8-3.8-3.7-.1-2.8 0-5.6 0-8.5 0-1.3-.1-2.6-.1-3.9 0-2.6 1-3.5 3.5-3.6 1.6-.1 3.2 0 4.7 0h12.5c2.9.2 3.6.9 3.6 3.8v12.1c0 2.7-.9 3.6-3.6 3.6-2.8.2-5.8.2-8.7.2zM101.6 89.3H93c-2.2 0-3-.7-3.3-2.9-.1-.6-.1-1.2-.1-1.9V73.4c0-2.7.9-3.7 3.6-3.8H110c.5 0 1 .1 1.5.2 1.7.3 2.4.9 2.4 2.6v14.1c0 1.4-.7 2.2-2.1 2.5-.6.1-1.2.2-1.8.2-2.8.1-5.6.1-8.4.1zM41.8 89.3h-8.3c-2.5 0-3.4-.8-3.5-3.3-.1-2.4 0-4.8 0-7.2 0-1.7-.1-3.5-.1-5.2 0-2.8.9-3.8 3.7-3.8h16.7c.4 0 .8.1 1.2.1 2.2.4 2.7.9 2.7 3.1v13.4c0 1.7-.7 2.6-2.4 2.7-2.5.2-5 .1-7.5.2h-2.5zM44.3 109.2H33.5c-2.4 0-3.2-.6-3.5-2.9-.2-1.5 0-3 .1-4.6.1-1 .8-1.7 1.9-1.9.8-.1 1.6-.3 2.3-.3h20c4.3 0 4.9.6 4.9 4.9v1.2c-.1 2.5-.9 3.5-3.6 3.5-3.8.1-7.5.1-11.3.1z" />
  </svg>
)
export default OnlinePaymentIcon
