import { FetchedOrderUploadProduct } from "./OrderUploader";


export interface IItem {
  sku: string;
  quantity: number;
  reference: string;
}

export const constructJSON = (data: string[][]): IItem[] => {
  const items = [];

  for (let i = 0; i < data.length; i++) {
    items.push({
      sku: data[i][0],
      quantity: parseInt(data[i][1]),
      reference: data[i][2],
    });
  }

  return items;
};

export const checkCSVForErrors = (data: string[][]) => {
  const failed: number[] = [];
  const validFieldsCount = 3;

  for (let i = 0; i < data.length; i++) {
    // Each CSV line should have 3 valid fields
    if (data[i].length < validFieldsCount) {
      failed.push(i + 1);
      continue;
    }
    // Fails if first(SKU) or second(Qty) field is empty, third(ref) can be empty
    if (!data[i][0] || !data[i][1]) {
      failed.push(i + 1);
      continue;
    } else if (
      Number.isNaN(parseInt(data[i][0])) ||
      Number.isNaN(parseInt(data[i][1]))
    ) {
      failed.push(i + 1);
      continue;
    }
  }

  return failed;
};

export const sortProducts = (
  products: any[],
  sort: any = { ascending: true, type: "index" }
) => {
  if (!products) return [];

  if (sort.type === "none") {
    return products;
  }

  if (sort && sort.type === "index") {
    products.sort((a, b) => {
      if (sort.ascending) {
        return parseInt(a.index) - parseInt(b.index);
      } else {
        return parseInt(b.index) - parseInt(b.index);
      }
    });
  }

  if (sort && sort.type === "stock") {
    products.sort((a: any, b: any) => {
      let totalStockA = 0;
      a.stock &&
        a.stock.forEach(
          (warehouse: any) => (totalStockA += warehouse.qtyAvailable)
        );

      let totalStockB = 0;
      b.stock &&
        b.stock.forEach(
          (warehouse: any) => (totalStockB += warehouse.qtyAvailable)
        );
      if (sort.ascending) {
        return totalStockA - totalStockB;
      } else {
        return totalStockB - totalStockA;
      }
    });
  }

  if (sort && sort.type === "b/o") {
    products.sort((a: any, b: any) => {
      if (!a.backorder && b.backorder) {
        if (sort.ascending) {
          return 1;
        } else return -1;
      } else if (a.backorder && !b.backorder) {
        if (sort.ascending) {
          return -1;
        } else return 1;
      } else if (!a.backorder && !b.backorder) {
        return 0;
      }

      const backorderA = [a.backorder];
      let numberOnBackorderA = 0;
      backorderA.forEach(
        (warehouse: any) => (numberOnBackorderA += warehouse.quantity)
      );

      const backorderB = [b.backorder];
      let numberOnBackorderB = 0;
      backorderB.forEach(
        (warehouse: any) => (numberOnBackorderB += warehouse.quantity)
      );
      if (sort.ascending) {
        return numberOnBackorderA - numberOnBackorderB;
      } else {
        return numberOnBackorderA - numberOnBackorderB;
      }
    });
  }

  return products;
};

export const findNumberOfInvalidProducts = (
  products: FetchedOrderUploadProduct[]
) => {
  const invalidProducts = products.filter((product) => !product || !product.price || !product.itemNo);

  return invalidProducts.length;
};

export const removeSkuDuplicates = (items: IItem[]) => {
  const cleanItems: IItem[] = [];

  items.forEach(item => {
    const indexDuplicate = cleanItems.findIndex(cleanItem => cleanItem.sku === item.sku);

    if (indexDuplicate === -1) { // new element
      cleanItems.push(item);
    } else { // already exist, added quantity
      cleanItems[indexDuplicate].quantity += item.quantity
    }
  })

  return cleanItems;
}
