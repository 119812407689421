import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AuthenticationHOC from "../../components/AuthenticationHOC/AuthenticationHOC";
import { StyledButton } from "../../components/styled/styled-components";
import { useDocumentTitle } from "../../hooks/document-title";
import { setSelectedOffice } from "../../redux/actions/organization";
import { RootState } from "../../redux/store";
import DisplaySelectedOffice from "./DisplaySelectedOffice";

interface Props {
  generateAuthHeader: () => any;
}

const BranchSelection = ({ generateAuthHeader }: Props) => {
  useDocumentTitle("Select your organization branch");
  const [originalOrganizationOfficeList, setOriginalOrganizationOfficeList] =
    useState<any[]>([]);
  const [organizationOfficeList, setOrganizationOfficeList] = useState<any[]>(
    []
  );
  const [currentSelectedOfficeID, setCurrentSelectedOfficeID] =
    useState<string>("");
  const [isOfficeChanged, setIsOfficeChanged] = useState(false);
  const organization = useSelector((state: RootState) => state.organization);

  const history = useHistory();
  const { push } = history;
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchOrganizationUsers = async (organization_key: string) => {
      const url = `${process.env.REACT_APP_HALFBACK_ROOT_API}/organization/admin/users/get_organization_users`;

      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            ...generateAuthHeader().headers,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ organization_key })
        });

        const data = await res.json();
        const sortedOfficeList = sortOfficeList(data.users);

        setOriginalOrganizationOfficeList(sortedOfficeList);
        setOrganizationOfficeList(sortedOfficeList);

        const selected_office_id = localStorage.getItem("selected_office_id");

        if (selected_office_id) {
          sortedOfficeList.forEach((office: any) => {
            if (office.id === JSON.parse(selected_office_id)) {
              dispatch(setSelectedOffice(office));
              localStorage.setItem("jwt", office.jwt);
              setCurrentSelectedOfficeID(office.id);
            }
          });
        } else {
          sortedOfficeList.forEach((office: any) => {
            const customer_id = localStorage.getItem("customer_id") ?? "";
            const customer_jwt = localStorage.getItem("customer_jwt") ?? "";

            if (office.id === JSON.parse(customer_id)) {
              dispatch(setSelectedOffice(office));
              localStorage.setItem("selected_office_id", office.id);
              localStorage.setItem("jwt", customer_jwt);
              setCurrentSelectedOfficeID(office.id);
            }
          });
        }
      } catch (error) { }
    };

    if (!organization.is_webstore_admin || !organization.organization_key) {
      return push("/store/cart");
    }

    const organization_key = localStorage.getItem("organization_key");
    if (organization_key === null) {
      return;
    } else {
      fetchOrganizationUsers(organization_key);
    }
  }, [
    generateAuthHeader,
    push,
    dispatch,
    organization.is_webstore_admin,
    organization.organization_key
  ]);

  function sortOfficeList(offices: any[]) {
    offices.sort((a, b) => {
      if (a.company_name && b.company_name) {
        const nameA = a.company_name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.company_name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      }
      return 0;
    });
    return offices;
  }

  const renderOfficeList = () => {
    if (organizationOfficeList != null) {
      return (
        <div className="container">
          {organizationOfficeList.map((office) => renderEachOffice(office))}
        </div>
      );
    }
  };

  const renderEachOffice = (office: any) => (
    <div
      key={office.id}
      className={
        currentSelectedOfficeID === office.id ? "office selected" : "office"
      }
      onClick={() => {
        setIsOfficeChanged(true);
        dispatch(setSelectedOffice(office));
        getSelectedOfficeJWT(office.jwt, office.id);
        localStorage.setItem("selected_office_id", office.id);
        setCurrentSelectedOfficeID(office.id);
      }}
    >
      <div>
        <b>{office.company_name}</b>
      </div>
    </div>
  );

  const renderOfficeChangeNotification = () => {
    if (!isOfficeChanged) return;

    return (
      <div
        className="modal"
        onClick={() => setIsOfficeChanged(false)}
        aria-hidden="true"
      >
        <div className="modalContent" style={{ height: "auto" }}>
          <DisplaySelectedOffice />

          <div>
            <StyledButton
              className="btn btn-primary"
              style={{ width: "150px", marginBottom: "1em" }}
              onClick={() => history.push("/store/cart")}
            >
              Start Order
            </StyledButton>
            <StyledButton
              className="btn btn-warning"
              style={{ marginLeft: "10px", width: "150px" }}
              onClick={() => setIsOfficeChanged(false)}
            >
              Cancel
            </StyledButton>
          </div>
        </div>
      </div>
    );
  };

  const getSelectedOfficeJWT = (jwt: string, office_id: string) => {
    if (jwt != null) {
      localStorage.setItem("jwt", jwt);
    } else {
      const req_url =
        process.env.REACT_APP_HALFBACK_ROOT_API +
        "/organization/admin/users/get_organization_user_jwt";

      const requestConfig = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "X-Jwt-Token": localStorage.getItem("customer_jwt")
        }
      };

      const data = {
        user_id: office_id
      };

      axios.post(req_url, data, requestConfig).then((response) => {
        localStorage.setItem("jwt", response.data.jwt);
        localStorage.setItem("selected_office_id", office_id);
        setCurrentSelectedOfficeID(office_id);
      });
    }

    setCurrentSelectedOfficeID(office_id);
  };

  const renderSelectedOfficeInfo = () => {
    if (organization.selected_office == null) return (
      <div>No selected user. Please select one of the following branch to continue.</div>
    );

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
        className="alert alert-warning"
      >
        <div>
          <div>
            <b>*** PLEASE SELECT OFFICE TO PLACE ORDER ***</b>
          </div>
          <DisplaySelectedOffice />
        </div>
        <div>
          <input
            style={{
              width: "300px",
              height: "35px",
              marginLeft: "20px",
              padding: "0 12px"
            }}
            autoFocus
            onChange={(e) => search(e.target.value)}
            placeholder="Quick search by office, contact or email"
          />
        </div>
      </div>
    );
  };

  const search = (input: string) => {
    if (input) {
      const filteredList = organizationOfficeList.filter(function (office) {
        return (
          office.company_name.toLowerCase().indexOf(input.toLowerCase()) !==
          -1 ||
          office.first_name.toLowerCase().indexOf(input.toLowerCase()) !== -1 ||
          office.last_name.toLowerCase().indexOf(input.toLowerCase()) !== -1 ||
          office.email.toLowerCase().indexOf(input.toLowerCase()) !== -1
        );
      });

      setOrganizationOfficeList(filteredList);
    } else {
      setOrganizationOfficeList(originalOrganizationOfficeList);
    }
  };

  return (
    <div className="branch-selection-page">
      {renderSelectedOfficeInfo()}
      {renderOfficeList()}
      {renderOfficeChangeNotification()}
    </div>
  );
};

export default AuthenticationHOC(BranchSelection);
