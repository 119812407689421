import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { generateAuthHeader } from "../../../commonfunctions/authentication";
import { log } from "../../../commonfunctions/logger";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import {
  Container,
  StyledButton,
  Wrapper,
} from "../../../components/styled/styled-components";
import { useDocumentTitle } from "../../../hooks/document-title";
import { OrderSubmitStatuses } from "../../../interfaces/enums";
import {
  Address,
  OrderSplitAPIResponse,
  OrderSplitResultItem,
} from "../../../interfaces/interfaces";
import { FreightOption } from "../../../interfaces/types";
import {
  setCurrentOrderShipAddress,
  updateFreightOption,
} from "../../../redux/actions/orders";
import { CurrentOrder } from "../../../redux/reducers/orders";
import { RootState } from "../../../redux/store";
import CartInformation from "./CartInformation/CartInformation";
import ClientInformation from "./ClientInformation/ClientInformation";
import ModalSwitcher from "./ModalSwitcher/ModalSwitcher";
import SplitFailedError from "./SplitFailedError/SplitFailedError";
import {
  formatOrderSplitParams,
  getSubmitOrderParams,
  mergeData,
} from "./util";
import checkOrderIsAbleToCCPayment from "../payment-selection/checkOrderIsAbleToCCPayment";

const TIMER_DURATION = 120;

const OrderReview = () => {
  useDocumentTitle("Place your order");
  /* Split item variables */
  const [splitAPIResponse, setSplitAPIResponse] =
    useState<OrderSplitAPIResponse | null>();
  const [splitItemNumbers, setSplitItemNumbers] = useState<string[]>([]);

  /* Airbag related variables */
  // const TEST_AIRBAG_ERROR = "BXC123 Order Release Id: Errors: 2big4plane";
  const [airbagError, setAirbagError] = useState<string | null>("");
  const [showRecalculateButton, setShowRecalculateButton] =
    useState<boolean>(false);

  /* UI loading/error variables */
  const [splitAPIError, setSplitAPIError] = useState<string | null>("");
  const [APILoading, setAPILoading] = useState<boolean>(false);

  /* Modal shown is controlled by modalToShow
   0 - Result of order submission
   1 - Airbag error
   2 - Timed out modal for stock hold
   3 - Pick up notice
   4 - Order split modal for pick up
   5 - Order split modal
   6 - Confirmation of order submission
  */
  const [modalToShow, setModalToShow] = useState(-1);

  const [showSyd, setShowSyd] = useState(false);
  const [showMel, setShowMel] = useState(false);
  const [showPer, setShowPer] = useState(false);
  const [showAuk, setShowAuk] = useState(false);
  const [showCan, setShowCan] = useState(false);

  /* Order submit variables */
  const [orderAccpacNumber, setOrderAccpacNumber] = useState<string | null>("");
  const [orderId, setOrderId] = useState<number>(0);
  const [submitStatus, setSubmitStatus] = useState<OrderSubmitStatuses | null>(
    null
  );
  const [orderSubmitLoading, setOrderSubmitLoading] = useState(false);

  /* Timer */
  const [timerId, setTimerId] = useState<NodeJS.Timer | null>();
  const [counter, setCounter] = useState(TIMER_DURATION);

  /* Check if Order is available to pay with CC  customer or not */
  const [isOrderAvailableToCCPayment, setIsOrderAvailableToCCPayment] =
    useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const extraInfo = useSelector((state: RootState) => state.orders.extraInfo);
  const freightOption: FreightOption = useSelector(
    (state: RootState) => state.orders.freightOption
  );
  const currentOrder: CurrentOrder = useSelector(
    (state: RootState) => state.orders.currentOrder
  );
  const currentOrderShipAddress: Address = useSelector(
    (state: RootState) => state.orders.currentOrderShipAddress
  );
  const isWebstoreAdmin: boolean = useSelector(
    (state: RootState) => state.organization.is_webstore_admin
  );
  const selectedOffice: string = useSelector(
    (state: RootState) => state.organization.selected_office
  );

  const clearTimer = useCallback(() => {
    if (timerId) {
      clearInterval(timerId);
      setTimerId(null);
    }
  }, [timerId]);

  const checkSplitting = useCallback(
    (items: OrderSplitResultItem[], freightOption: FreightOption) => {
      if (items) {
        const numbers = [];
        for (const item of items) {
          if (item.warehouses) {
            if (item.warehouses.length > 1) {
              numbers.push(item.itemNo);
            }
          }
        }

        setSplitItemNumbers(numbers);

        if (numbers.length > 0) {
          if (freightOption === "pickup") {
            setModalToShow(4);
          } else {
            setModalToShow(5);
          }
        } else {
          if (freightOption === "pickup") {
            setModalToShow(3);
          }
        }
      }
    },
    []
  );

  useEffect(() => {
    const orderAvailableToCCPaymentVerification = async () => {
      const response = await checkOrderIsAbleToCCPayment();
      setIsOrderAvailableToCCPayment(response.isAvailable);
    };

    if (
      !extraInfo ||
      !extraInfo.po_number ||
      !currentOrderShipAddress ||
      !freightOption
    ) {
      history.push("/store/cart");
      return;
    }

    if (currentOrder) callSplitService();

    if (!isOrderAvailableToCCPayment) orderAvailableToCCPaymentVerification();

    return () => {
      // SUDO call release when unmount
      clearTimer();
      callReleaseService();
      dispatch(setCurrentOrderShipAddress(null));
      dispatch(updateFreightOption(null));
    };
    //eslint-disable-next-line
  }, [currentOrder]);

  useEffect(() => {
    if (counter <= 0) {
      clearTimer();
      callReleaseService();
      setModalToShow(2);
    }
    //eslint-disable-next-line
  }, [counter]);

  const startTimer = () => {
    setCounter(TIMER_DURATION);

    const id = setInterval(() => {
      setCounter((counter) => counter - 1);
    }, 1000);
    setTimerId(id);
  };

  const callSplitService = useCallback(async () => {
    setModalToShow(-1);
    const params = formatOrderSplitParams(
      currentOrderShipAddress,
      freightOption
    );

    setAPILoading(true);
    try {
      const res = await fetch(
        `${process.env.REACT_APP_HALFBACK_ROOT_API}/orders/current_order/order_split`,
        {
          method: "POST",
          body: JSON.stringify(params),
          headers: {
            "Content-Type": "application/json",
            ...generateAuthHeader().headers,
          },
        }
      );

      const data = await res.json();

      if (data.status === "failed") {
        setModalToShow(1);
        setAirbagError(data.error);
      } else {
        setAirbagError(null);
        setSplitAPIError(null);

        /* Merge item details from response.data.current_order into response.data.result */
        const responseData = mergeData(data);
        setSplitAPIResponse(responseData);

        for (const item of responseData.result.items) {
          if (item.warehouses && item.warehouses.length) {
            for (const warehouse of item.warehouses) {
              if (warehouse.location === "SYD") {
                setShowSyd(true);
              } else if (warehouse.location === "MEL") {
                setShowMel(true);
              } else if (warehouse.location === "PER") {
                setShowPer(true);
              } else if (warehouse.location === "AUK") {
                setShowAuk(true);
              } else if (warehouse.location === "YVR") {
                setShowCan(true);
              }
            }
          }
        }
        checkSplitting(responseData.result.items, freightOption);
        startTimer();
      }
      setAPILoading(false);
    } catch (error: any) {
      setSplitAPIError(error);
      setAPILoading(false);
      setAirbagError(null);
    }
  }, [checkSplitting, freightOption, currentOrderShipAddress]);

  const callReleaseService = async () => {
    try {
      await fetch(
        `${process.env.REACT_APP_HALFBACK_ROOT_API}/orders/current_order/release_stock`,
        {
          method: "POST",
          headers: {
            ...generateAuthHeader().headers,
          },
        }
      );
    } catch (_) {
      //TODO
      // consume the error
    }
  };

  const submitOrder = async () => {
    clearTimer();
    setOrderSubmitLoading(true);

    const params = getSubmitOrderParams(
      currentOrderShipAddress,
      splitAPIResponse,
      extraInfo,
      freightOption
    );

    if (isWebstoreAdmin && selectedOffice) {
      params["is_admin_order"] = isWebstoreAdmin;
      params["admin_order_id"] = localStorage.getItem("customer_id");
    }

    const url = `${process.env.REACT_APP_HALFBACK_ROOT_API}/orders/current_order/submit`;

    try {
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          ...generateAuthHeader().headers,
          "Content-Type": "application/json",
        },
      });

      if (res.ok) {
        const data = await res.json();
        log(
          JSON.stringify({ orderSubmitParams: params, response: data }),
          "order submitted"
        );
        if (data.status === "submitted") {
          // No, this is not a typo. The back-end response actually spells it as 'accapc_id'
          setOrderAccpacNumber(data.order_accapc_id);
          setOrderId(data.order_id);
          setModalToShow(0);
          setOrderSubmitLoading(false);
          setSubmitStatus(OrderSubmitStatuses.Success);
        } else if (data.status === "failed") {
          setOrderAccpacNumber(null);
          setModalToShow(0);
          setOrderSubmitLoading(false);
          setSubmitStatus(OrderSubmitStatuses.Fail);
        }
      } else {
        const error = await res.text();
        throw error;
      }
    } catch (error) {
      const stringifiedError = JSON.stringify(error).toLowerCase();
      log(
        JSON.stringify({ orderSubmitParams: params, error: stringifiedError }),
        "order submitted with error"
      );
      setModalToShow(0);
      setOrderSubmitLoading(false);
      setSubmitStatus(OrderSubmitStatuses.Fail);
      setOrderAccpacNumber(null);
    }
  };

  return (
    <Container margin="1em 0" className="order-review-page">
      {splitAPIError ? (
        <SplitFailedError errorMessage={splitAPIError} />
      ) : (
        <>
          <ModalSwitcher
            modalToShow={modalToShow}
            splitItemNumbers={splitItemNumbers}
            currentOrderShipAddress={currentOrderShipAddress}
            showSyd={showSyd}
            showMel={showMel}
            showPer={showPer}
            showAuk={showAuk}
            showCan={showCan}
            orderAccpacNumber={orderAccpacNumber}
            orderId={orderId}
            order={splitAPIResponse?.current_order || null}
            submitStatus={submitStatus}
            callSplitService={callSplitService}
            airbagErrorMessage={airbagError}
            showRecalculateButton={showRecalculateButton}
            setShowRecalculateButton={() => setShowRecalculateButton(true)}
            setModalToShow={setModalToShow}
            submitOrder={submitOrder}
            orderSubmitLoading={orderSubmitLoading}
          />
          {splitAPIResponse && splitAPIResponse.result.items ? (
            <>
              <div>
                <ClientInformation
                  currentOrder={splitAPIResponse.current_order}
                  extraInfo={extraInfo}
                  currentOrderShipAddress={currentOrderShipAddress}
                  freightOption={freightOption}
                  counter={counter}
                  customerCOD={isOrderAvailableToCCPayment}
                />
                <CartInformation
                  splitAPIResponse={splitAPIResponse}
                  isOrderAvaiableToCCPayment={isOrderAvailableToCCPayment}
                />
                {!APILoading && timerId ? (
                  <div className="submit-order">
                    <StyledButton onClick={() => setModalToShow(6)}>
                      Submit order
                    </StyledButton>
                  </div>
                ) : APILoading ? (
                  <div className="spinner-wrapper">
                    <LoadingSpinner />
                  </div>
                ) : (
                  <div className="submit-order">
                    <StyledButton warning onClick={() => callSplitService()}>
                      Recheck stock
                    </StyledButton>
                  </div>
                )}
              </div>
            </>
          ) : (
            <Wrapper>
              <LoadingSpinner />
            </Wrapper>
          )}
        </>
      )}
    </Container>
  );
};

export default OrderReview;
