import { useHistory } from "react-router";
import Modal from "../../../../components/Modal/Modal";
import { StyledButton } from "../../../../components/styled/styled-components";

interface Props {
  errorMessage: string;
}

const SplitFailedError = ({ errorMessage }: Props) => {
  const history = useHistory();

  return (
    <Modal>
      <div className="split-failed-error">
        <div>
          <p>Sorry, we failed to process this order.</p>
          <p>Please try again.</p>
          <p>Error message: {errorMessage}</p>
        </div>
        <StyledButton warning onClick={() => history.go(0)}>
          Back To Cart
        </StyledButton>
      </div>
    </Modal>
  );
};

export default SplitFailedError;
