import { SVGProps } from "react"
const EditIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 178.6 159"
    {...props}
  >
    <style>{'.editIcon{fill:#d6b24a}'}</style>
    <path
    d="M178.6 29.1c.1 3.5-1.2 6.5-3.6 9-6.4 6.5-12.8 13.1-19.3 19.6l-71.2 71.2c-1.9 1.9-4 3.8-5.9 5.7-1.4 1.4-3.2 1.3-4.9 1.5-7.7.9-15.5 1.7-23.2 2.6-1.8.2-3.6.5-5.3.2-3.3-.4-5.5-3.2-5.2-6.5.5-6.1 1.2-12.3 1.9-18.4.4-4.1.8-8.2 1.3-12.3.1-.5.3-1.1.6-1.4C51 93 58.3 85.8 65.5 78.5 90.3 53.8 115.1 29 139.9 4.3c5.7-5.7 13.6-5.7 19.2 0l15.3 15.3c2.7 2.6 4.3 5.7 4.2 9.5zm-32.4 24.5c-.3-.4-.5-.6-.8-.9-6.4-6.4-12.7-12.7-19.1-19-1.1-1.1-1.2-1.1-2.4 0-4.3 4.3-8.5 8.7-12.8 13-9.6 9.6-19.1 19.2-28.7 28.8-9.1 9.1-18.2 18.3-27.4 27.3-1.7 1.7-2.8 3.2-3 5.7-.4 6.2-1.2 12.4-1.9 18.7-.2 1.9-.2 1.9 1.7 1.7 6.2-.7 12.4-1.4 18.6-2.3 1.3-.2 2.7-.6 3.8-1.3 1.3-.9 2.3-2.2 3.4-3.3 20.4-20.4 40.9-40.8 61.3-61.3 2.4-2.3 4.8-4.6 7.3-7.1zm-14-27.9s.2.2.3.4c2.6 2.6 5.1 5.2 7.7 7.7 3.9 3.9 7.9 7.8 11.8 11.8.5.5 1 1.1 1.8.3 4.7-4.8 9.5-9.5 14.2-14.3 1.8-1.8 1.8-2.8 0-4.5-5.4-5.5-10.9-10.9-16.4-16.3-1.7-1.6-3.2-1.5-4.9.1-2 2-4.1 4-6.1 6-2.7 2.9-5.5 5.9-8.4 8.8z"
    className="editIcon"
    />
    <path
    d="M.1 88.6v-53c0-2.9.3-5.8 1.8-8.4C4.5 23 8.3 20.7 13.1 20c1.3-.2 2.6-.2 4-.2h86.7c.4 0 .9-.1 1.2 0 .7.3 1.6.6 1.8 1.2.2.5-.1 1.5-.6 1.9-2 2.1-4 4.1-6.1 6-.5.4-1.3.6-2 .6h-83c-2.4 0-4.2.8-5.2 3-.3.6-.4 1.4-.4 2-.1 1.4-.1 2.9-.1 4.3v104.1c0 4 2 6 6.1 6h108.4c3.4 0 5.5-1.9 5.5-5.3.1-8.3.1-16.6.1-24.9 0-6.4 0-12.8-.1-19.1 0-.8.2-1.4.8-1.9 1.7-1.5 3.2-3.2 4.9-4.8.4-.4.9-.9 1.4-1.2 1.1-.7 2.2-.1 2.3 1.2.1.7.1 1.3.1 2v48.4c0 4.8-1.6 8.7-5.1 11.9-3.2 2.8-7 3.7-11 3.7H18.7c-2.4 0-4.8-.1-7.2-.6-7-1.5-11.5-7.6-11.5-14.8 0-18.3 0-36.6.1-54.9-.1 0 0 0 0 0z"
    className="editIcon"
    />
  </svg>
)
export default EditIcon
