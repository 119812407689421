import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useAddressFetch from "../../../hooks/address-fetch";
import { Address } from "../../../interfaces/interfaces";
import { updateFreightOption } from "../../../redux/actions/orders";
import AuthenticationHOC from "../../AuthenticationHOC/AuthenticationHOC";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import { formatDisplayAddress } from "../util";
import { Context, IContext } from "../../../context/ContextApp";

interface Props {
  generateAuthHeader: any;
  updateShippingAddressInRedux: (a: Address | null) => void;
}

const AddressBook = ({
  generateAuthHeader,
  updateShippingAddressInRedux
}: Props) => {
  const { savedAddresses, addressLoadCode } =
    useAddressFetch(generateAuthHeader);
  const [selectValue, setSelectValue] = useState(0);
  const dispatch = useDispatch();
  const { appBranch }: IContext = useContext(Context);

  useEffect(() => {
    if (savedAddresses.length > 0) {
      setSelectValue(0);
      savedAddresses[0].is_primary_address = false;
      updateShippingAddressInRedux(savedAddresses[0]);
    }
  }, [savedAddresses, updateShippingAddressInRedux]);

  const checkoutBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      dispatch(updateFreightOption("pickup"));
    } else {
      dispatch(updateFreightOption(null));
    }
  };

  const onAddressSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = parseInt(event.target.value);
    setSelectValue(selectedValue);

    /* Ignore blank value */
    const address = savedAddresses[selectedValue];

    address.is_primary_address = false;
    updateShippingAddressInRedux(address);

    // Reset freight option when selecting new address for non-AU branches
    if (appBranch !== "au") {
      const checkbox = document.getElementById("pickup") as HTMLInputElement | null
      dispatch(updateFreightOption(null));
      if (checkbox != null) {
        checkbox.checked = false;
      }
    }
  };

  const renderAddressSelector = () => {
    const addressBook = savedAddresses.slice();

    return (
      <select
        className="form-control"
        onChange={(event) => onAddressSelect(event)}
        value={selectValue}
        data-testid="select"
      >
        {addressBook.map((address: Address, index: number) => (
          <option value={index} key={index}>
            {formatDisplayAddress(address)}
          </option>
        ))}
      </select>
    );
  };

  const renderSavedAddressesForm = () => {
    const selectedAddress = savedAddresses[selectValue] ?? ({} as Address);

    return (
      <div className="addressTableWrapper">
        <table className="addressTable">
          <tbody>
            <tr>
              <th>My Addresses</th>
              <td>{renderAddressSelector()}</td>
            </tr>
            {selectedAddress && (
              <>
                <tr>
                  <th>Ship To Name</th>
                  <td>{selectedAddress.ship_to_name}</td>
                </tr>
                <tr>
                  <th>Ship Address</th>
                  <td>{selectedAddress.address_line1}</td>
                </tr>
                {appBranch === "nz" ? (
                  <tr>
                    <th>Suburb</th>
                    <td>{selectedAddress.address_line4}</td>
                  </tr>
                ) : (
                  selectedAddress.address_line4 && (
                    <tr>
                      <th>Ship Address Line 2</th>
                      <td>{selectedAddress.address_line4}</td>
                    </tr>
                  )
                )}
                <tr>
                  <th>Ship Address Line 2</th>
                  <td>{selectedAddress.address_line2}</td>
                </tr>
                <tr>
                  <th>City</th>
                  <td>{selectedAddress.address_city}</td>
                </tr>

                {appBranch === "au" && selectedAddress.state && (
                  <tr>
                    <th>State</th>
                    <td>{selectedAddress.state}</td>
                  </tr>
                )}
                <tr>
                  <th>Country</th>
                  <td>{selectedAddress.address_country}</td>
                </tr>
                <tr>
                  <th>Post Code</th>
                  <td>{selectedAddress.address_postcode}</td>
                </tr>
              </>
            )}
            {appBranch === "nz" && (
              <tr>
                <th>Third Party Pick Up</th>
                <td>
                  <input
                    type="checkbox"
                    style={{ width: "auto", marginRight: "10px" }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      checkoutBox(event)
                    }
                    id="pickup"
                  />
                  <span style={{ color: "red" }}>
                    *Using a 3rd party to collect this order? Click here.
                  </span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  switch (addressLoadCode) {
    case 1:
      return renderSavedAddressesForm();
    case 2:
      return <div>No addresses found.</div>;
    case 3:
      return <div>Error: Could not load addresses.</div>;
    default:
      return <LoadingSpinner />;
  }
};

export default AuthenticationHOC(AddressBook);
