import { Link } from "react-router-dom";
import IconWrapper from "../../../../components/IconWrapper/IconWrapper";
import { StyledButton } from "../../../../components/styled/styled-components";
import {
  Address,
  OrderSplitCurrentOrder,
} from "../../../../interfaces/interfaces";
import { FreightOption } from "../../../../interfaces/types";
import Counter from "../Counter/Counter";
import { formatFreightOption } from "./util";
import { renderPaymentMethod } from "../../payment-selection/sendPaymentSelection";
import Icon from "../../../../components/Icon/icon";
import { useContext } from "react";
import { Context, IContext } from "../../../../context/ContextApp";

interface Props {
  currentOrder: OrderSplitCurrentOrder;
  currentOrderShipAddress: Address | null;
  extraInfo: any;
  freightOption: FreightOption;
  counter: number;
  customerCOD: boolean;
}

const ClientInformation = ({
  currentOrder,
  currentOrderShipAddress,
  extraInfo,
  freightOption,
  counter,
  customerCOD,
}: Props) => {
  const { appBranch }: IContext = useContext(Context);
  return (
    <>
      <div className="order-details-container">
        <div className="extra-info-container">
          <table>
            <tbody>
              <tr>
                <th>PO Number</th>
                <td>{extraInfo.po_number}</td>
              </tr>
              <tr>
                <th>Created By</th>
                <td>{localStorage.getItem("email")}</td>
              </tr>
              {extraInfo.comments ? (
                <tr>
                  <th>Comments</th>
                  <td>{extraInfo.comments}</td>
                </tr>
              ) : null}
              {extraInfo.associated_email ? (
                <tr>
                  <th>Associated Email</th>
                  <td>{extraInfo.associated_email}</td>
                </tr>
              ) : null}
            </tbody>
          </table>
          <Link replace to="/check-out/extra-info">
            <StyledButton>Edit information</StyledButton>
          </Link>
        </div>

        <div className="extra-info-container">
          <table>
            <tbody>
              <tr>
                <th>Shipping Method</th>
                <td>{formatFreightOption(freightOption)}</td>
              </tr>
              <tr>
                <th>Ship To</th>
                <td>{currentOrderShipAddress?.ship_to_name}</td>
              </tr>
              <tr>
                <th>Shipping Address</th>
                <td>
                  <div>{formatAddress(currentOrderShipAddress)}</div>
                  <div>
                    {/* Labelling to fix halfback problem later */}
                    {currentOrderShipAddress?.state}{" "}
                    {currentOrderShipAddress?.address_postcode}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <Link replace to="/check-out/shipping-selection">
            <StyledButton primary>Edit shipping information</StyledButton>
          </Link>
        </div>
        {customerCOD && (
          <div className="extra-info-container">
            <table>
              <tbody>
                <tr>
                  <th>Payment Method</th>
                  <td>{renderPaymentMethod[currentOrder.payment_method]}</td>
                </tr>
              </tbody>
            </table>
            <Link replace to="/check-out/payment-selection">
              <StyledButton>Edit Payment Method</StyledButton>
            </Link>
          </div>
        )}
      </div>
      <div className="order-summary">
        <div className="icon-group">
          <div className="icon-container-with-text">
            <IconWrapper
              header="Items / Units"
              text={`${currentOrder.total_items} / ${currentOrder.total_units}`}
            >
              <Icon name="ShoppingCartBlack" />
            </IconWrapper>
            <small>Max 150 items in one order</small>
          </div>
          <div className="icon-container-with-text">
            <IconWrapper
              header={appBranch === "au" ? "Total Products": "Subtotal"}
              text={`$${currentOrder.payment_details.total_products}`}
            >
              <Icon name="DollarSign" />
            </IconWrapper>
            <small>Tax & shipping excluded</small>
          </div>
          <div className="icon-container-with-text">
            <IconWrapper
              header="Shipping Cost"
              text={`$${currentOrder.payment_details.shipping_cost}`}
            >
              <Icon name="ShippingFast" />
            </IconWrapper>
            <small>Tax excluded</small>
          </div>
          {appBranch === "au" && (
            <div className="icon-container-with-text">
              <IconWrapper
                header="Order Total"
                text={`$${currentOrder.payment_details.grand_total}`}
              >
                <Icon name="DollarSign" />
              </IconWrapper>
              <small>Order Total including all taxes</small>
            </div>
          )}
          <Counter counter={counter} />
        </div>
      </div>
    </>
  );
};

const formatAddress = (currentOrderShipAddress: Address | null) => {
  if (!currentOrderShipAddress) {
    return "";
  }

  const {
    address_line1,
    address_line2,
    address_line3,
    address_line4,
    address_city,
  } = currentOrderShipAddress;
  let address = "";

  if (address_line1) {
    address += address_line1;
  }
  if (address_line2) {
    address += `, ${address_line2}`;
  }
  if (address_line3) {
    address += `, ${address_line3}`;
  }
  if (address_line4) {
    address += `, ${address_line4}`;
  }
  if (address_city) {
    address += `, ${address_city}`;
  }
  return address;
};

export default ClientInformation;
