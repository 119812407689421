import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { StyledButton, Wrapper } from "../styled/styled-components";

interface Props {
  children: any;
  isLoading: boolean;
  onClick: any;
  style?: any;
  type?: "button" | "reset" | "submit";
  disabled?: boolean;
}

const LoaderButton = (props: Props) => {
  const { children, onClick, isLoading, type, style, disabled = false } = props;

  if (isLoading) {
    return (
      <div style={{ margin: "10px" }}>
        <Wrapper>
          <LoadingSpinner />
        </Wrapper>
      </div>
    );
  } else return (
      <StyledButton
        style={style}
        type={type ?? "button"}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </StyledButton>
    );
};

export default LoaderButton;
