import { SVGProps } from "react"
const FileUpload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 118.8 159.2"
    {...props}
  >
    <style>{'.fileIcon{fill:#FFFFFF}'}</style>
    <path
    d="M.1 78.8v-63C.1 12.9.4 10 2 7.4 4.5 3.2 8.3 1 13.1.3c1.3-.2 2.6-.2 4-.2h59.3c4.9 0 9.2 1.4 12.7 5 8.3 8.5 16.9 16.9 25.3 25.3 2.9 2.9 4.4 6.4 4.4 10.6V143.7c0 5.2-2 9.3-6 12.5-2.9 2.2-6.3 3-9.8 3H15.7c-1.2 0-2.5-.2-3.7-.4-7.3-1.6-12-7.5-12-14.9 0-21.8 0-43.5.1-65.1-.1 0 0 0 0 0zm9.3.7v63.9c0 1 .1 2 .5 2.9 1 2.4 3.1 3 5.6 3H104.4c2.4-.1 4.3-1.4 4.7-3.7.4-2.3.4-4.6.4-6.9V58.8c0-2.4 0-4.9-.1-7.3 0-1.8-.1-1.8-1.9-1.8H77.7c-3.2 0-5.9-1.3-7.2-4.4-.6-1.4-.8-3.1-.8-4.7-.1-9.7-.1-19.4 0-29.1 0-1.4-.5-1.6-1.7-1.6H14.3c-2.7.2-4.5 2.1-4.7 4.7-.1 1-.1 2-.1 3-.1 20.7-.1 41.3-.1 61.9zM79.3 9.9c-.1.8-.1 1.1-.2 1.5 0 9.1-.1 18.2-.1 27.3 0 1.1.6 1.1 1.4 1.1H108c.4 0 .8 0 1.4-.1-.5-.6-.7-1.1-1.1-1.5-8.8-8.8-17.5-17.6-26.3-26.4-.7-.7-1.7-1.2-2.7-1.9z"
    className="fileIcon"
    />
    <path
    d="M64.1 118.9v12.9c0 1.3-.5 2.2-1.9 2.3-1.8.1-3.6.2-5.4.1-1.1-.1-1.9-.8-1.9-2 0-2.2-.1-4.5-.1-6.7V108c0-.9-.1-1.7-.1-2.6 0-1-.7-.9-1.3-.9h-14c-5.2 0-8.5-2.7-9.5-7.8-.5-2.6.4-4.9 2.2-6.7 7-7.4 14.1-14.8 21.3-22.2 3.9-4 8.5-3.9 12.4.1 7 7.2 13.9 14.4 20.9 21.7 3.3 3.5 3.3 8.8 0 12.4-1.8 1.9-4.2 2.5-6.7 2.5-4.8.1-9.6 0-14.4 0-1.1 0-1.4.3-1.4 1.4 0 4.3-.1 8.6-.1 13zm14-24.4C71.8 88 65.6 81.6 59.4 75.2 53.1 81.7 47 88 40.8 94.5h37.3z"
    className="fileIcon"
    />
  </svg>
)
export default FileUpload
