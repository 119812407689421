import { LoginDetails } from "../../interfaces/interfaces";

export function setLoginStatus(status: boolean, loginStatus?: LoginDetails) {
  if (status) {
    if (loginStatus) {
      localStorage.setItem("email", loginStatus.email);
      if (loginStatus.webstore_admin) {
        localStorage.setItem("customer_jwt", loginStatus.jwt);
        localStorage.setItem("jwt", loginStatus.jwt);
      } else {
        localStorage.setItem("jwt", loginStatus.jwt);
      }

      localStorage.setItem("customer_id", `${loginStatus.customer_id}`);
      localStorage.setItem("allow_webstore", `${loginStatus.allow_webstore}`);
      localStorage.setItem("brands", `${loginStatus.brands}`);
      localStorage.setItem("branch", loginStatus.branch);
      localStorage.setItem("remember_me", `${loginStatus.remember_me}`);
      localStorage.setItem("features", `${loginStatus.features}`);
      localStorage.setItem(
        "webstore_order_enabled",
        `${loginStatus.webstore_order_enabled}`
      );

      localStorage.setItem("company_accpac_no", loginStatus.company_accpac_no);
      // To manage multiple branches
      localStorage.setItem("webstore_admin", `${loginStatus.webstore_admin}`);
      localStorage.setItem("organization_key", loginStatus.organization_key);
      localStorage.setItem("stocklist_mode", `${loginStatus.stocklist_mode}`);
    }
  } else {
    localStorage.clear();
  }
  return {
    type: "SET_LOGIN_STATUS",
    payload: status,
  };
}

export const setIsWebstoreOrderEnabled = (boolean: boolean) => ({
  type: "SET_IS_WEBSTORE_ORDER_ENABLED",
  payload: boolean,
});
