import { subdomainTypes } from "../../context/availableSubdomains";

interface State {
  value: string;
  name: string;
}

const AUS_STATES: State[] = [
  {
    value: "QLD",
    name: "Queensland",
  },
  {
    value: "SA",
    name: "South Australia",
  },
  {
    value: "TAS",
    name: "Tasmania",
  },
  {
    value: "VIC",
    name: "Victoria",
  },
  {
    value: "NSW",
    name: "New South Wales",
  },
  {
    value: "WA",
    name: "Western Australia",
  },
  {
    value: "NT",
    name: "Northern Territory",
  },
  {
    value: "ACT",
    name: "Australian Capital Territory",
  },
];

const CA_STATES: State[] = [
  {
    value: "AB",
    name: "Alberta",
  },
  {
    value: "BC",
    name: "British Columbia",
  },
  {
    value: "MB",
    name: "Manitoba",
  },
  {
    value: "NB",
    name: "New Brunswick",
  },
  {
    value: "NL",
    name: "Newfoundland and Labrador",
  },
  {
    value: "NS",
    name: "Nova Scotia",
  },
  {
    value: "ON",
    name: "Ontario",
  },
  {
    value: "PE",
    name: "Prince Edward Island",
  },
  {
    value: "QC",
    name: "Quebec",
  },
  {
    value: "SK",
    name: "Saskatchewan",
  },
];

export const getStates = (appBranch: subdomainTypes | "") => {
  switch (appBranch) {
    case "ca":
      return CA_STATES;
    case "au":
      return AUS_STATES;
    default:
      return [];
  }
};
