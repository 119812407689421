import { ErrorTypes } from "../../interfaces/enums";

interface Props {
  errorType?: ErrorTypes;
  userInput?: string | undefined;
}

const SearchErrorCard = ({ errorType, userInput }: Props) => {
  const renderMessage = () => {
    if (errorType === ErrorTypes.orderNotFound) {
      return (
        <h2>
          We can't find orders with the PO Number:{" "}
          <strong>{userInput}</strong>
        </h2>
      );
    }
    if (errorType === ErrorTypes.productNotFound) {
      return (
        <h2>We couldn't find <strong>{userInput}</strong> from Products</h2>
      );
    }
  }

  return (
    <div className="error-card">
      {renderMessage()}
    </div>
  );
}

export default SearchErrorCard;
