import IconWrapper from "../../../../../components/IconWrapper/IconWrapper";
import { Container } from "../../../../../components/styled/styled-components";
import { ShippingAddressType } from "../../../../../interfaces/types";
import Icon from "../../../../../components/Icon/icon";

interface Props {
  shippingAddressType: ShippingAddressType;
  handleShippingAddressOptionChange: (option: ShippingAddressType) => void;
}

const AddressOptions = ({
  handleShippingAddressOptionChange,
  shippingAddressType,
}: Props) => (
  <Container row margin="1em 1em 1em 0">
    <IconWrapper
      text="Primary Address"
      isSelected={shippingAddressType === "Primary Address"}
      isMultiChoice
      onClick={() => {
        handleShippingAddressOptionChange("Primary Address");
      }}
    >
      <Icon name="StarBlack" />
    </IconWrapper>
    <div style={{ marginLeft: "1rem", marginRight: "1rem" }}>
      <IconWrapper
        text="Address Book"
        isSelected={shippingAddressType === "Address Book"}
        isMultiChoice
        onClick={() => {
          handleShippingAddressOptionChange("Address Book");
        }}
      >
        <Icon name="HomeIcon" />
      </IconWrapper>
    </div>
    <IconWrapper
      text="New Address"
      isSelected={shippingAddressType === "New Address"}
      isMultiChoice
      onClick={() => {
        handleShippingAddressOptionChange("New Address");
      }}
    >
      <Icon name="PlusIcon" />
    </IconWrapper>
  </Container>
);

export default AddressOptions;
