interface Props {
  address: string;
}

export default function PickUpCA({ address }: Props) {
  return (
    <div className="pickUp">
      Pick Up Warehouse Address
      <div className="alert alert-info" style={{ width: "fit-content" }}>
        {address}
      </div>
    </div>
  );
}
