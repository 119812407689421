import { SVGProps } from "react"
const CreditCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 179.7 139.8"
    {...props}
  >
    <style>{'.creditCard{fill:#FFFFFF}'}</style>
    <path
    d="M89.7 139.8H23.2c-15.1 0-23.2-8-23.2-23.2V23.2C.1 8 8.1 0 23.2 0h133.5c15.2 0 23 7.9 23 23.2v93.5c0 15.3-7.8 23.1-23 23.2-22.3-.1-44.7-.1-67-.1zM165 60.2H14.7V119c0 4.8 3 6 7.4 6 45.1-.1 90.3 0 135.4-.1 6.2 0 7.4-1.2 7.4-7.2.1-10.8 0-21.7 0-32.5.1-8.2.1-16.5.1-25zm0-30.5v-4.5c.1-10.1-.2-10.4-10.3-10.4H27.8c-13.3 0-13.6.3-12.9 13.8 0 .3.3.6.5 1.1H165z"
    className="creditCard"
    />
    <path
    d="M95.1 109.8c-5.3 0-10.6.1-16 0-5.6-.1-8.9-3-8.9-7.4s3.3-7.3 8.9-7.4c10.5-.1 21-.1 31.4 0 5.7 0 8.9 2.7 9.1 7.1.2 4.6-3.2 7.5-9.1 7.6-5.1.2-10.3.1-15.4.1zM44.8 109.7c-2.5 0-5 .2-7.5-.1-4.1-.5-6.7-2.8-7-7.1-.2-3.9 2.6-7.2 7.1-7.5 5-.3 10-.3 14.9 0 4.5.3 7.4 3.4 7.3 7.3-.2 4.6-3.1 6.8-7.4 7.3-2.4.4-4.9.2-7.4.1 0 .1 0 .1 0 0z"
    className="creditCard"
    />
  </svg>
)
export default CreditCard
