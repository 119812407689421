interface Props {
  children: any;
}

const Modal = (props: Props) => {
  const { children } = props;

  return (
    <div className="modal-overlay" aria-modal="true">
      {children}
    </div>
  );
};

export default Modal;
