import { Branch, Brand } from "../../../interfaces/types";

export interface Catalogue {
  branch: Branch;
  link: string;
}

export interface BrandMenuObject {
  name: Brand,
  title: string;
  tags?: string[];
  catalogueList?: Catalogue[]
}

export const allBrands: BrandMenuObject[] = [
  {
    name: "direct-group",
    title: "Direct Group",
  },
  {
    name: "hve",
    title: "High Vis Everywhere",
  },
  {
    name: "biz-collection",
    title: "Biz Collection",
    tags: [
      "polos",
      "tees",
      "shirts",
      "biz separates",
      "knitwear",
      "hoodies-fleece",
      "activewear",
      "jackets",
      "hospitality",
      "health",
      "clearance",
      "catalogue",
    ],
    catalogueList: [
      {
        branch: "nz",
        link: "https://drive.google.com/open?id=17V36uJrZHaZGlf-AS5qXEjwvZaMnw4C7",
      },
      {
        branch: "au",
        link: "https://drive.google.com/open?id=13t9zRVtdPpMTj87sNt_BVSqxHQT_5VLi",
      },
    ],
  },
  {
    name: "biz-corporates",
    title: "Biz Corporates",
    tags: [
      "tops",
      "shirts",
      "jackets",
      "vests",
      "pants",
      "skirts",
      "dresses",
      "culottes",
      "shorts",
      "knitwear",
      "outerwear",
      "blouses",
      "advatex",
      "coats",
      "accessories",
      "comfort wool stretch",
      "cool stretch plain",
      "rococo",
      "boulevard",
      "siena suiting",
      "fabric packs",
      "clearance",
      "catalogue",
    ],
    catalogueList: [
      {
        branch: "nz",
        link: "https://drive.google.com/open?id=1tixOviunr7hu5Io50YyH3J_yuM3LlIhD",
      },
      {
        branch: "au",
        link: "https://drive.google.com/open?id=1xnPCRpjfVxcdaWOCctbEBayz0UudJK5M",
      },
    ],
  },
  {
    name: "syzmik",
    title: "Syzmik",
    tags: [
      "polos",
      "tees",
      "shirts",
      "pants",
      "shorts",
      "fleece-hoodies",
      "jackets",
      "vests",
      "overalls",
      "fire-armour",
      "clearance",
      "catalogue",
      "accessories",
    ],
    catalogueList: [
      {
        branch: "nz",
        link: "https://drive.google.com/open?id=1qsicuim-9G0jv0FTlICYZll0XdQOQzzC",
      },
      {
        branch: "au",
        link: "https://drive.google.com/open?id=1f7fQEQB6hdp60DriBOfKXZd3k4TkDaZ1",
      },
    ],
  },
  {
    name: "biz-care",
    title: "Biz Care",
    tags: [
      "polos",
      "tops",
      "shirts",
      "tunics",
      "pants",
      "shorts",
      "skirts",
      "scrubs",
      "knitwear",
      "vests",
      "fleece",
      "jackets",
      "cardigans",
      "T-Tops",
      "pink products",
      "catalogue",
      "accessories",
      "clearance",
    ],
    catalogueList: [
      {
        branch: "nz",
        link: "https://drive.google.com/open?id=1c6C63uBKDNQexmEKCYDQo881jdivl-fw",
      },
      {
        branch: "au",
        link: "https://drive.google.com/open?id=1B_72PFjxtlmiFJXWSwYMBt2K2M4xHM_B",
      },
    ],
  },
];

export const transformName = (name: string) => {
  switch (name) {
    case "hoodies-fleece":
      return "Hoodies & Fleece";
    case "fleece-hoodies":
      return "Hoodies & Fleece";
    case "health":
      return "Health and Beauty";
    case "fire-armour":
      return "Fire Armour";
    default:
      return name;
  }
};
