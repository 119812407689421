export interface OrganizationReducer {
  selected_office?: string;
  organization_key?: string;
  is_webstore_admin: boolean;
}

interface Action {
  type: string;
  payload: any;
}

const initial: OrganizationReducer = {
  selected_office: "",
  organization_key: "",
  is_webstore_admin: false,
};

function organization(state = initial, action: Action) {
  switch (action.type) {
    case "SET_SELECTED_OFFICE":
      return {
        ...state,
        selected_office: action.payload,
      };
    case "SET_ORGANISATION_KEY":
      return {
        ...state,
        organization_key: action.payload,
      };
    case "SET_IS_WEBSTORE_ADMIN":
      return {
        ...state,
        is_webstore_admin: action.payload,
      };
    default:
      return state;
  }
}

export default organization;
