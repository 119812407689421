import { useContext, useState } from "react";
import {
  OrderDetailItem,
  OrderSplitResultItem
} from "../../interfaces/interfaces";
import { sortProducts } from "../../pages/order-uploader/util";
import CartItem from "../CartItem/CartItem";
import IconButton from "../IconButton/IconButton";
import { StyledButton } from "../styled/styled-components";
import { Context, IContext } from "../../context/ContextApp";
import Icon from "../Icon/icon";

interface Props {
  items: OrderSplitResultItem[] | OrderDetailItem[];
  action?: boolean;
  showStock?: boolean;
  showIndex?: boolean;
  checkout?: boolean;
  bo?: boolean;
  split?: boolean;
}

interface Sort {
  ascending: boolean;
  type: string;
}

const CartItemTable = (props: Props) => {
  const { items, action, showStock, showIndex, checkout, bo, split } = props;
  const [sort, setSort] = useState<Sort>({
    ascending: true,
    type: "none"
  });
  const { appBranch }: IContext = useContext(Context);

  if (items && items.length > 0) {
    const sortedItems = sortProducts(items.slice(), sort);

    return (
      <>
        {(bo || showStock) && (
          <StyledButton
            onClick={() => setSort({ ascending: true, type: "none" })}
          >
            Sort by original order
          </StyledButton>
        )}
        <table className="cart-item-table">
          <tbody>
            <tr>
              {showIndex && <th>Line</th>}
              <th className="description">Description</th>
              <th>Colour</th>
              <th>Size</th>
              {checkout && appBranch === "au" && (
                <>
                  <th style={{ backgroundColor: "rgb(0, 196, 245)" }}>SYD</th>
                  <th style={{ backgroundColor: "rgb(233, 67, 64)" }}>MEL</th>
                  <th style={{ backgroundColor: "rgb(0, 166, 97)" }}>PER</th>
                </>
              )}
              <th>Reference</th>
              {appBranch === "au" ?
              <>
                <th>Order Qty</th>
                <th>Available Qty</th>
              </>
              :
              <th>Qty</th>
              }
              {bo && (
                <th
                  style={{ backgroundColor: "rgb(37,52,57)", color: "white" }}
                >
                  B/O
                  <IconButton
                    inline
                    style={{ marginLeft: "1em" }}
                    onClick={() =>
                      setSort({ ascending: !sort.ascending, type: "b/o" })
                    }
                  >
                    {sort.ascending ? (
                      <Icon name="ArrowUp" />
                    ) : (
                      <Icon name="ArrowDown" />
                    )}
                  </IconButton>
                </th>
              )}
              {showStock && (
                <th>
                  Available stock
                  <IconButton
                    inline
                    style={{ marginLeft: "1em" }}
                    onClick={() =>
                      setSort({ ascending: !sort.ascending, type: "stock" })
                    }
                  >
                    {sort.ascending ? (
                      <Icon name="ArrowUp" />
                    ) : (
                      <Icon name="ArrowDown" />
                    )}
                  </IconButton>
                </th>
              )}
              <th>Price</th>
              <th>Amount</th>
              {action && <th />}
            </tr>
            {sortedItems.map((product, index) => (
              <CartItem
                key={index}
                item={product}
                action={action}
                bo={bo}
                split={split}
                showStock={showStock}
                index={
                  showIndex &&
                  product.index &&
                  `${parseInt(product.index) + 1}`
                }
              />
            ))}
          </tbody>
        </table>
      </>
    );
  } else return <></>;
};

export default CartItemTable;
