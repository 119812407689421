import { StyleSheet, View } from "@react-pdf/renderer";
import TitleAndDetailLine from "../../../components/TitleAndDetailLine/TitleAndDetailLine";
import { getPaymentDetails } from "../../../../../utils/OrderPaymentDetails";
import { subdomainTypes } from "../../../../../context/availableSubdomains";

const styles = StyleSheet.create({
  orderSummaryContainer: {
    marginLeft: "auto",
    width: "20%",
  },
});

interface Props {
  order: any;
  appBranch: subdomainTypes | "";
}

const OrderSummary = (props: Props) => {
  const { order, appBranch } = props;
  const paymentDetails = order ? getPaymentDetails(order, appBranch) : [];

  return (
    <View style={styles.orderSummaryContainer}>
      {paymentDetails.map((paymentDetail, index) => (
        <TitleAndDetailLine
          key={index}
          line={[`${paymentDetail.title}:`, paymentDetail.value]}
        />
      ))}
    </View>
  );
};

export default OrderSummary;
