import { SVGProps } from "react"
const HourglassHalf = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 118.8 159.2"
    {...props}
  >
    <path d="M73.5 79.5c.5.3.7.5 1 .6 13.5 5.1 22.6 14.8 29.2 27.3 2.7 5.2 4.9 10.6 6.6 16.2 2.3 8 3.7 16.1 3.6 24.4 0 1 .1 1.5 1.3 1.4.6 0 1.2.2 1.8.4 1.3.4 1.9 1.2 1.9 2.6v3.7c0 1.6-.7 2.4-2.1 2.8-.7.2-1.5.3-2.3.3H4.6c-3.2-.1-4.4-1.3-4.4-4.5v-.7c0-3.3.9-4.3 4.2-4.6.7-.1 1.1-.2 1-1-.1-4.9.2-9.8 1.1-14.7 1.3-7 3.2-13.7 6-20.2 3.1-7.2 6.9-13.9 12.2-19.8 3.4-3.8 7.3-7.1 11.7-9.7 2.4-1.4 5-2.4 7.5-3.5.5-.2 1.1-.4 1.8-.7-.4-.3-.7-.5-1-.6-12.2-4.5-21.1-12.8-27.5-24-4.9-8.4-8.4-17.3-10.3-26.9C5.8 22.6 5 16.8 5.2 11c0-.8-.2-1.1-1-1.2C1 9.6.1 8.6 0 5.4-.1 1.1 1 0 5.3 0h108.5c.5 0 1 0 1.5.1 2.8.4 3.5 1.2 3.5 4.1v2c0 2.4-.6 3.1-2.9 3.6-2.1.5-2.1.5-2.1 2.6 0 6.7-1.1 13.3-2.7 19.9-2.2 9.5-6.2 18.1-11.5 26.3-5.8 9-13.4 15.8-23.3 20-.7.2-1.6.5-2.8.9zM59.4 9.9H15.8c-1.1 0-1.5.3-1.4 1.4.3 3.1.5 6.3.7 9.4.5 5.6 1.8 11 3.6 16.3C21.2 44.3 25 51 30 57c6.3 7.6 13.9 13 23.9 14.7 10.4 1.8 19.7-.9 27.8-7.4C89.7 58 95 49.6 99 40.4c2.1-4.8 3.6-9.9 4.2-15.1.5-4.5.9-9.1 1.3-13.7.1-1.7 0-1.7-1.7-1.7H59.4zm.1 139.4h43.4c1.8 0 1.8 0 1.6-1.7-.1-1.3-.3-2.6-.3-3.9-.3-7.3-1.6-14.5-4-21.4-3.1-9.2-8.2-17.2-14.9-24.1-6.3-6.5-13.9-10.3-22.9-11-8.5-.7-16.4 1.4-23.3 6.4-9.5 6.8-15.5 16.2-19.7 26.9-3.4 8.7-4.6 17.9-5 27.2-.1 1.7 0 1.7 1.6 1.7 14.5-.1 29-.1 43.5-.1z" />
    <path d="M59.5 39.8h19.9c1.5 0 2.9.3 3.9 1.6 1 1.2 1 2.9.1 4.2-2 3.1-4.6 4.3-8.4 4.2-11-.3-22-.1-33-.1-2 0-3.4-.6-4.6-2.2l-1.5-1.8c-1.6-2.1-.7-5 1.9-5.7.7-.2 1.4-.2 2.1-.2h19.6zM59.5 139.3H31c-.7 0-1.3-.1-2-.3-2.1-.6-3.1-2.2-2.6-4.4.2-1.1.5-2.2.9-3.2.3-.6.9-1.3 1.5-1.5.9-.4 1.9-.5 2.9-.5h55.4c.9 0 1.8.1 2.7.4 2.1.8 3.5 5.8 2.1 7.7-1 1.3-2.3 1.8-3.9 1.8H59.5z" />
  </svg>
)
export default HourglassHalf
