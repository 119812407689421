import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import Modal from "../../../../components/Modal/Modal";
import {
  Container,
  StyledButton,
  Wrapper,
} from "../../../../components/styled/styled-components";
import { Address, OrderSplitCurrentOrder } from "../../../../interfaces/interfaces";
import AirbagError from "./AirbagError/AirbagError";
import OrderResult from "./OrderResult/OrderResult";
import OrderSplitModal from "./OrderSplitModal/OrderSplitModal";
import PickupNotice from "./PickupNotice/PickupNotice";
import TimedoutModal from "./TimedOutModal/TimedOutModal";
import { OrderSubmitStatuses } from "../../../../interfaces/enums";
import BankTransferInfoModal from "../../../../components/PaymentInfo/BankTransferInfoModal";
import { useHistory } from "react-router-dom";
import EmailTransferInfoModal from "../../../../components/PaymentInfo/EmailTrasnferInfoModal";

interface Props {
  modalToShow: number;
  splitItemNumbers: string[];
  currentOrderShipAddress: Address;
  showSyd: boolean;
  showMel: boolean;
  showPer: boolean;
  showAuk: boolean;
  showCan: boolean;
  orderAccpacNumber: string | null;
  orderId: number;
  order: OrderSplitCurrentOrder | null;
  airbagErrorMessage: string | null;
  submitStatus: OrderSubmitStatuses | null;
  callSplitService: () => void;
  showRecalculateButton: boolean;
  setShowRecalculateButton: () => void;
  submitOrder: () => void;
  orderSubmitLoading: boolean;
  setModalToShow: React.Dispatch<React.SetStateAction<number>>;
}

const ModalSwitcher = ({
  modalToShow,
  splitItemNumbers,
  currentOrderShipAddress,
  showSyd,
  showMel,
  showPer,
  showAuk,
  showCan,
  orderAccpacNumber,
  orderId,
  order,
  submitStatus,
  callSplitService,
  airbagErrorMessage,
  showRecalculateButton,
  setShowRecalculateButton,
  submitOrder,
  orderSubmitLoading,
  setModalToShow,
}: Props) => {
  const GetModal = () => {
    const history = useHistory();

    switch (modalToShow) {
      case 0:
        return (
          <OrderResult
            orderAccpacNumber={orderAccpacNumber}
            order={order}
            orderId={orderId}
            submitStatus={submitStatus}
            showSyd={showSyd}
            showMel={showMel}
            showPer={showPer}
            showAuk={showAuk}
            showCan={showCan}
            changeModalToShow={(modalToShow) => setModalToShow(modalToShow)}
          />
        );
      case 1:
        return (
          <AirbagError
            callSplitService={callSplitService}
            errorMessage={airbagErrorMessage}
            showRecalculateButton={showRecalculateButton}
            setShowRecalculateButton={setShowRecalculateButton}
          />
        );
      case 2:
        return (
          <TimedoutModal
            callSplitService={callSplitService}
            hideModal={() => {
              setModalToShow(-1);
            }}
          />
        );
      case 3:
        return (
          <PickupNotice
            state={currentOrderShipAddress.state}
            showSyd={showSyd}
            showMel={showMel}
            showPer={showPer}
          />
        );
      case 4:
        return (
          <OrderSplitModal
            items={splitItemNumbers}
            hideModal={() => setModalToShow(-1)}
            currentOrderShipAddress={currentOrderShipAddress}
            pickup
          />
        );
      case 5:
        return (
          <OrderSplitModal
            items={splitItemNumbers}
            hideModal={() => setModalToShow(-1)}
            currentOrderShipAddress={currentOrderShipAddress}
          />
        );
      case 6:
        return (
          <Modal>
            <div className="modal-template">
              <h5>Are you sure you want to submit this order?</h5>
              <Container>
                {orderSubmitLoading ? (
                  <Wrapper>
                    <LoadingSpinner />
                  </Wrapper>
                ) : (
                  <>
                    <StyledButton
                      onClick={() => submitOrder()}
                      style={{ margin: "1em 0" }}
                    >
                      Yes
                    </StyledButton>
                    <StyledButton onClick={() => setModalToShow(-1)}>
                      No
                    </StyledButton>
                  </>
                )}
              </Container>
            </div>
          </Modal>
        );
      case 7:
        return <BankTransferInfoModal
          onCloseModal={() => history.push('/order-history')}
          closeButtonName="Order Details"
          />
      case 8:
        return <EmailTransferInfoModal
          onCloseModal={() => history.push('/order-history')}
          closeButtonName="Order Details"
          />

      default:
        return <></>;
    }
  };

  return GetModal();
};

export default ModalSwitcher;
