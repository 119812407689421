import { SVGProps } from "react"
const Times = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 99.4 99.5"
    {...props}
  >
    <path
    d="M60.1 49.6c7.5 7.6 14.9 15 22.3 22.5l15 15c2.5 2.5 2.6 2.9 0 5.5-1.9 2-3.9 3.9-5.9 5.9-1.4 1.3-2.1 1.3-3.4 0L50.9 61.3c-.4-.4-.8-.7-1.4-1.2-.9 1-1.7 1.9-2.6 2.8-10.2 10.3-20.5 20.5-30.7 30.8-1.7 1.7-3.5 3.3-5.2 5-1.2 1.1-2 1.1-3.1 0-2.4-2.3-4.7-4.7-7-7-1.1-1.2-1.1-2.2.2-3.4C11.9 77.5 22.7 66.8 33.4 56l5.3-5.3c.6-.6.7-1 0-1.6C26.4 36.8 14.2 24.6 2 12.5l-.6-.6C-.5 10-.5 9.3 1.4 7.4c2-2 4-4.1 6.1-6.1 1.6-1.6 2.5-1.5 4.1.1L48 37.8c1.7 1.7 1.4 2 3.4 0L88 1.2c1.4-1.4 2.1-1.6 3.3-.4 2.5 2.4 5 4.9 7.3 7.4 1.1 1.2 1 1.8-.2 3.1-1.3 1.4-2.7 2.8-4.1 4.2L61.8 48c-.6.6-1.2 1.2-1.7 1.6z"
    />
  </svg>
)
export default Times
