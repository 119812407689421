import { SVGProps } from "react"
const ExternalLink = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 138.7 139.2"
    {...props}
  >
    <path d="M138.7 69.5v54c0 4.8-1.5 8.7-5 11.9-3.2 2.9-7 3.8-11.1 3.8h-106c-1 0-2.1-.1-3.1-.2-8-1-13.4-7.2-13.4-15.2C0 87.8.1 51.9.1 16c0-3 .2-5.8 1.8-8.5C4.5 3.2 8.3 1 13.1.3c1.3-.2 2.6-.2 4-.3h105.6c5.8 0 10.5 2.2 13.9 7.1 1.3 1.9 2 4 2.1 6.3.1 4.3.1 8.7.1 13.1-.1 14.3-.1 28.6-.1 43zm-69.4 59.8h54.6c3.2 0 5.2-2 5.4-5.1.1-1.8.1-3.6.1-5.5V23.8c0-2.9.1-5.7 0-8.6-.1-3.5-2.2-5.4-5.7-5.4H15.3c-3.8 0-5.7 1.8-5.8 5.6-.1 6.4-.1 12.8-.1 19.1v88.8c0 1 .1 2 .5 2.9 1 2.4 3 3 5.5 3 18 .1 35.9.1 53.9.1z" />
    <path d="M108.8 54.8c0 5.2.1 10.4 0 15.5-.1 3.5-1.8 6.1-4.9 7.8-3.4 1.8-6.8 2-10.1-.2-1.1-.7-2-1.7-3-2.6l-8.4-8.4c-1.2-1.2-1.2-1.2-2.5 0-4.2 4.2-8.4 8.4-12.6 12.5-9.8 9.7-19.5 19.4-29.3 29-1.5 1.5-2.8 1.6-4.4.2-1.2-1.1-2.3-2.2-3.4-3.4-1.3-1.5-1.2-2.6.1-4 13.8-14 27.7-28 41.5-42 .2-.2.3-.4.5-.5.8-.6.7-1.1 0-1.8-3-2.9-6-5.9-8.8-8.9-1.2-1.2-2.3-2.5-3.1-4-2.7-5 .4-13 7.5-13.8 1.2-.1 2.4-.2 3.6-.2 8.9 0 17.8-.1 26.7 0 4.3 0 7.6 1.9 9.6 5.7.6 1.1.9 2.4.9 3.7.2 5 .1 10.2.1 15.4zm-9.7 14.8c.1-.1.3-.2.4-.3 0-.2.1-.4.1-.6-.1-9.2-.1-18.5-.1-27.7 0-1-.3-1.3-1.3-1.3H70.4c-.3 0-.6.1-1.3.2 5.3 5.2 10.2 10 15.2 14.9 4.9 4.9 9.7 10 14.8 14.8z" />
  </svg>
)
export default ExternalLink
