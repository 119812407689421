import { View } from "@react-pdf/renderer";
import React from "react";
import { OrderItem } from "../../../../interfaces/interfaces";
import Heading from "../../components/Heading/Heading";
import ItemLine from "../../components/ItemLine/ItemLine";
import Logo from "../../components/Logo/Logo";
import TableHeading from "../../components/TableHeading/TableHeading";
import TitleAndDetailLine from "../../components/TitleAndDetailLine/TitleAndDetailLine";
import PDFWrapper from "../PDFWrapper";
import { subdomainTypes } from "../../../../context/availableSubdomains";

interface Props {
  order: OrderItem[];
  title?: string;
  appBranch: subdomainTypes | "";
}

const CartPDF = (props: Props) => {
  const { order, title, appBranch } = props;

  let subtotal = 0;
  for (const item of order) {
    subtotal += parseFloat(item.price) * item.quantity;
  }
  return (
    <PDFWrapper title={title}>
      <Logo appBranch={appBranch} />
      {title && <Heading>{title}</Heading>}
      <TableHeading />
      {order.map((item, index) => (
        <ItemLine key={index} item={item} />
      ))}
      <View
        style={{
          width: "15%",
          marginLeft: "auto",
          marginRight: 30,
          marginTop: 20,
        }}
      >
        <TitleAndDetailLine line={["Subtotal", `$${subtotal.toFixed(2)}`]} />
      </View>
    </PDFWrapper>
  );
};

export default CartPDF;
