import IconWrapper from "../../../../components/IconWrapper/IconWrapper";
import Icon from "../../../../components/Icon/icon";

interface Props {
  counter: number;
}

const Counter = ({ counter }: Props) => (
  <div role="timer" className="counter icon-container-with-text">
    {counter > 0 ? (
      <IconWrapper
        header="Releasing in"
        textColor={getColor(counter)}
        text={`${counter} seconds`}
      >
        <Icon name={`Hourglass${getIconState(counter)}`} />
      </IconWrapper>
    ) : (
      <>
        <IconWrapper
          header="Stock status"
          textColor={getColor(counter)}
          text="Released"
        >
          <Icon name={`Hourglass${getIconState(counter)}`} />
        </IconWrapper>
        {counter === 0 && (
          <small color="red">Recheck stock to continue</small>
        )}
      </>
    )}
  </div>
);

const getColor = (counter: number) => {
  if (counter >= 30) {
    return "green";
  } else if (counter < 30 && counter >= 10) {
    return "rgb(255, 82, 53)";
  } else if (counter < 10 && counter > 0) {
    return "rgb(114, 28, 36)";
  } else return "rgb(114, 28, 36)";
};

const getIconState = (counter: number) => {
  if (counter >= 30) {
    return "Start";
  } else if (counter < 30 && counter >= 10) {
    return "Half";
  } else return "End";
};

export default Counter;
