export interface UserSession {
  isAuthenticated: boolean;
  isWebstoreOrderEnabled: boolean;
}

interface Action {
  type: string;
  payload: boolean;
}

const defaultState: UserSession = {
  isAuthenticated: false,
  isWebstoreOrderEnabled: false,
};

function userSession(state = defaultState, action: Action) {
  switch (action.type) {
    case "SET_LOGIN_STATUS":
      return Object.assign({}, state, { isAuthenticated: action.payload });
    case "SET_IS_WEBSTORE_ORDER_ENABLED":
      return Object.assign({}, state, {
        isWebstoreOrderEnabled: action.payload,
      });
    default:
      return state;
  }
}

export default userSession;
