import { SVGProps } from "react"
const SearchPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 159.2 159.1"
    {...props}
  >
    <style>{'.searchPlus{fill:#FFFFFF}'}</style>
    <path
    d="M108.1 112.3c-19.5 16.6-41.5 20.9-65.7 12.9-14.5-4.9-25.5-14.6-33.4-27.7C-5.9 73-2.6 38.2 21.1 16.4c21.5-19.7 56.7-21.8 79.4-5.8 10.3 7.3 18.4 16.4 23.3 28.1 4.9 11.7 5.9 23.8 4.2 36.2-1.8 12.6-7.6 23.3-15.9 32.8.1.1.2.3.2.4.9 0 1.9-.1 2.7 0 .9.2 2 .4 2.6 1.1 13.2 13.1 26.4 26.2 39.5 39.3 2.4 2.4 2.8 2.9-.1 5.9-1.1 1.2-2.3 2.3-3.5 3.4-1.7 1.5-2.6 1.5-4.3-.1l-12.3-12.3c-8.9-8.9-17.7-17.7-26.6-26.5-1.8-1.8-2.6-3.6-2.2-6.1.2 0 .1-.2 0-.5zm-44.5 6.9c29.1 1.6 56-24.2 56-54.6 0-29.9-24.7-54-53.3-55.1-30.1-1.1-57.9 24.1-57 56.6.8 28.1 24.9 53.3 54.3 53.1z"
    className="searchPlus"
    />
    <path
    d="M59.6 59.5c.1-.6.1-1.1.1-1.6V34.5c0-4.2 3.8-6.4 7.5-4.3 1.4.8 2 2 2 3.5v25.7h25c1.8 0 3.5.3 4.4 2 .9 1.9.9 4.1-.1 6-.8 1.4-2.2 1.9-3.8 1.9H69.3v1.5c0 7.8 0 15.5-.1 23.3 0 4.2-2.9 6.2-6.9 4.7-1.5-.6-2.4-1.6-2.4-3.3-.1-2.4-.1-4.9-.1-7.3 0-5.9-.1-11.8 0-17.7 0-1-.3-1.3-1.3-1.3H34.9c-.7 0-1.5-.1-2.2-.3-1.8-.5-2.6-1.7-2.6-3.7 0-2.2-.4-4.6 2.4-5.5.8-.3 1.8-.4 2.7-.4h23c.3.2.8.2 1.4.2z"
    className="searchPlus"
    />
  </svg>
)
export default SearchPlus
