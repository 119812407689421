import { SVGProps } from "react"
const BankTransferIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 66.2 60.5"
    {...props}
  >
    <path d="M55 33.5c-1.6 0-4.3.4-3.4-1.8 2-3.6 4.1-7.2 6.1-10.8 1.5-2.3 2.8 1.1 3.5 2.3 1.6 2.8 3.1 5.5 4.7 8.3 1.2 2.2-1.7 2-3.1 2C61.1 58.2 30.2 70 13.2 51.3c-.9-1-.7-2.2.4-2.9 2.3-.6 3.9 2.6 6 3.3C34.4 61.8 54 51 55 33.5zM3.2 27C5.2 2.2 36-9.5 53 9.3c1.7 1.6-.7 4-2.5 2.4C45 6.3 37.2 3.9 29.7 5.4c-9.1 1.4-16.2 9-18 17.8 0 1-1.2 3.9.3 3.9 5.5-.8.5 4.3-.3 6.6C6.1 45.1 7 39.6 2 32c-1.6-3-4.1-5.3 1.2-5z" />
    <path d="M36 45.4v2.3c0 .9-.7 1.6-1.6 1.6h-1.1c-.9 0-1.6-.7-1.6-1.6v-2.2c0-.7-.4-1.3-1.1-1.6-3-1-5.7-1.9-8.1-4-.8-.8.2-2.2 1-3.6.5-.8 1.6-1 2.4-.5 2.5 1.7 5 3.2 8.3 2.9 3.2.2 5.6-3.2 2.4-5.1-.1 0-.1-.1-.2-.1-3.7-1.7-8.1-1.9-11-4.9-4.3-4.5-.1-12.3 5.5-12.4 1.1-.3.8-2 .7-3.5-.1-.9.6-1.7 1.6-1.8h1.1c.9 0 1.7.7 1.7 1.6v2c0 .8.5 1.4 1.3 1.6 1.9.4 3.7 1 5.3 1.9.7.4 1 1.3.7 2.1-2.5 5.7-1.5 2.7-7.5 1.3-2.3-.6-7.8-.4-5.7 3.2.1.2.2.3.3.4 3 2.5 7.4 2.3 10.6 4.6 6 4.4 2.5 12.4-3.8 14-.7.4-1.2 1.1-1.2 1.8z" />
  </svg>
)
export default BankTransferIcon
