export const bcRates = [
  {
    key: "Up to $200",
    value: "$15 Freight Charge",
  },
  {
    key: "$201 - $500",
    value: "$20 Freight Charge",
  },
  {
    key: "$501 and Above",
    value: "Free Freight",
  },
];

export const albertaRates = [
  {
    key: "Up to $200",
    value: "$20 Freight Charge",
  },
  {
    key: "$201 - $500",
    value: "$25 Freight Charge",
  },
  {
    key: "$501 and Above",
    value: "Free Freight",
  },
];

export const otherRatesAir = [
  {
    key: "Up to $250 in order value",
    value: "$16.50 Freight Charge",
  },
  {
    key: "$251 - $450 in order value",
    value: "$21.50 Freight Charge",
  },
  {
    key: "$451 - $750 in order value",
    value: "$26.50 Freight Charge",
  },
  {
    key: "$751 in order value and above",
    value: "FREE AIR FREIGHT",
  },
];

export const otherRatesGround = [
  {
    key: "Up to $250 in order value",
    value: "$20 Freight Charge",
  },
  {
    key: "$251 - $450 in order value",
    value: "$25 Freight Charge",
  },
  {
    key: "$451 - $750 in order value",
    value: "$30 Freight Charge",
  },
  {
    key: "$751 in order value and above",
    value: "FREE GROUND FREIGHT",
  },
];
