import { View } from "@react-pdf/renderer";
import TableHeading from "./TableHeading";
import TransactionItemLine from "./TransactionItemLine/TransactionItemLine";

interface Props {
  transactionDetails: any;
}

const TransactionCosts = (props: Props) => {
  const { transactionDetails } = props;
  return (
    <View style={{ marginTop: 20 }}>
      <TableHeading />
      {transactionDetails.items.map((item: any, index: number) => (
        <TransactionItemLine key={index} item={item} />
      ))}
    </View>
  );
};

export default TransactionCosts;
