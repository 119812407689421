import { generateAuthHeader } from "../../commonfunctions/authentication";
import {
  IGetTransactions,
  TransactionResponseTypes,
} from "./types";

export default async function getTransactionsByPO(
  pONumber: string,
): Promise<IGetTransactions> {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_HALFBACK_ROOT_API}/current_customer/transactions/search_by_po_number?po_number=${pONumber}`,
      generateAuthHeader()
    );

    if (res.ok) {
      const data = await res.json();

      if (data.status === "success") {
        return {
          type: TransactionResponseTypes.SUCCESS,
          transactions: data.result.transactions,
        };
      }
      return {
        type: TransactionResponseTypes.FAIL,
        message: data.result.Message,
      };
    }
    throw new Error();
  } catch (error) {
    return {
      type: TransactionResponseTypes.FAIL,
      message: "Failed to load Transactions",
    };
  }
}
