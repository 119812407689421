import { SVGProps } from "react"
const HomeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 159 139.2"
    {...props}
  >
    <path d="M19.7 58.7c-.6.4-1.2.7-1.7 1.1-3.6 2.9-7.2 5.7-10.8 8.6-1.7 1.3-2.3 1.3-3.7-.4-1-1.2-1.9-2.4-2.8-3.6-1-1.3-.9-2 .3-3.1 2.3-2 4.7-4 7.2-6 13.1-10.7 26.3-21.4 39.4-32.1C55.7 16.5 63.8 10 71.9 3.4c.9-.7 1.8-1.4 2.8-2 3.9-2.2 7.6-1.7 11.1 1.1 5.5 4.4 11 8.8 16.5 13.3 8.4 6.8 16.8 13.7 25.2 20.5.6.5 1.3.9 2.2 1.5v-4.1c0-3.7-.1-7.5-.1-11.2 0-2.2.4-2.6 2.6-2.6h4.6c1.9.1 2.3.5 2.3 2.3v21.6c0 1.2.4 2 1.3 2.8l16.5 13.5c2.9 2.4 3 2.7.6 5.6l-1.8 2.4c-1.5 1.8-2 1.9-3.8.5-2.4-1.9-4.8-3.9-7.2-5.9-1.7-1.4-3.4-2.8-5.4-4.4-.1.8-.2 1.3-.2 1.8v63.8c0 3.2-.2 6.5 0 9.7.2 3.4-2.7 5.6-5.7 5.6H95.6c-3.7 0-5.7-2-5.7-5.7s.1-7.5.1-11.2V97.9c0-.5 0-1-.1-1.5-.2-2-.2-2-2.3-2H70.7c-1 0-1.3.3-1.3 1.3 0 12.6-.1 25.2 0 37.8 0 3.7-2.9 5.8-5.9 5.7-4.1-.1-8.3 0-12.5 0H26.2c-4.1 0-6.2-2.1-6.2-6.2 0-22.7.1-45.4.1-68v-5.9c-.1-.2-.2-.3-.4-.4zm109.9 31.2s.1 0 0 0v-2.6c0-11.9 0-23.8-.1-35.7 0-.5-.2-1.2-.6-1.5-7.5-6.2-15-12.3-22.5-18.5C98 24.7 89.6 17.9 81.1 11c-1.7-1.4-1.8-1.4-3.1-.3L49.8 33.8C44 38.7 38 43.6 32 48.3c-2.6 2.1-2.7 2-2.6 5.3v74c0 1.6 0 1.6 1.6 1.6h26.4c2.4 0 2.5 0 2.5-2.4V90.2c0-3.7 1.9-5.8 5.6-5.8h28.1c3 0 5.8 1.6 5.6 5.5v1.7c0 12 0 24.1-.1 36.1 0 1.1.3 1.5 1.5 1.5h27c2 0 2 0 2-2V89.9z" />
  </svg>
)
export default HomeIcon
