import { SVGProps } from "react"
const ExclamationCirlce = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 153.8 153.9"
    {...props}
  >
    <path d="M76.2 0c16.6.1 31.2 3.9 44.1 13 6.1 4.3 11.6 9.3 16.3 15 7.8 9.4 13.1 20.2 15.6 32.2 2 9.7 2.3 19.4.8 29.1-1.9 12.3-6.6 23.3-14.1 33.2-5.9 7.8-12.8 14.6-21.2 19.9-7.9 5-16.6 8.3-25.7 10.1-8 1.5-16.2 1.7-24.3.8-18.1-2-33.4-9.9-46-22.9-7.6-7.9-13.5-17-17.3-27.5C2.6 97.8 1.5 92.6.8 87.2c-.7-5.7-1-11.3-.5-17 .6-7 2-13.8 4.4-20.4 2.9-7.9 7-15.1 12.4-21.5 8-9.5 17.3-17.5 29-22.3C52.9 3.2 59.9 1.3 67.2.5c3.3-.3 6.6-.4 9-.5zm68.1 78.6c1.6-38.4-30.5-67.4-63.9-69-40-1.9-71 31.1-70.8 64.9-2.5 38 30.8 71.2 69.2 69.6 33.1.4 65.5-29.3 65.5-65.5z" />
    <path d="M83.5 49.3c-.4 11-.9 21.9-1.3 32.9-.2 3.7-.4 7.4-.5 11.2-.1 2-.7 2.9-2.6 3.2-1.4.2-3 .1-4.4-.1-1.5-.2-2.1-1.2-2.2-2.9-.2-3.9-.5-7.8-.7-11.6-.2-4.7-.2-9.4-.4-14-.3-7.2-.6-14.4-1-21.6-.1-1.8-.3-3.6-.3-5.4 0-1.9.8-3.2 2.6-3.3 2.7-.2 5.5-.3 8.3-.1 2.3.2 2.8 1.2 2.8 3.4v8.4c-.1-.1-.2-.1-.3-.1zM68.6 111.5c-.5-4.9 4.1-8.3 8.3-8.4 4.6-.1 8.3 3.6 8.4 8 .2 5.2-3.9 9.1-8.2 9.1-4.5 0-8.9-3.7-8.5-8.7z" />
  </svg>
)
export default ExclamationCirlce
