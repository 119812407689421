import LoadingSpinner from "../../../../../components/LoadingSpinner/LoadingSpinner";
import Modal from "../../../../../components/Modal/Modal";
import {
  StyledButton,
  Wrapper,
} from "../../../../../components/styled/styled-components";
import Icon from "../../../../../components/Icon/icon";

interface Props {
  callSplitService: () => void;
  hideModal: () => void;
  loading?: boolean;
}

const TimedOutModal = ({ callSplitService, hideModal, loading }: Props) => (
  <Modal>
    <div className="timed-out-modal">
      <div>
        <Icon name="ExclamationSquare" iconWidth="2rem" />
      </div>
      <p>Stock has been released.</p>
      {loading ? (
        <Wrapper>
          <LoadingSpinner />
        </Wrapper>
      ) : (
        <>
          <StyledButton warning onClick={() => callSplitService()}>
            Recheck stock
          </StyledButton>
          <StyledButton
            danger
            style={{ marginTop: "10px" }}
            onClick={() => {
              hideModal();
            }}
          >
            Cancel
          </StyledButton>
        </>
      )}
    </div>
  </Modal>
);

export default TimedOutModal;
