import { StyleSheet } from "@react-pdf/renderer";

export const itemFormatStyles = StyleSheet.create({
  itemRow: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 10,
  },
  column: {
    marginRight: 5,
  },
  transactionColumn: {
    marginRight: "auto",
  },
  columnName: {
    width: "20%",
  },
  columnCode: {
    width: "10%",
  },
  columnReference: {
    width: "20%",
  },
  columnColor: {
    width: "15%",
  },
  columnSize: {
    width: "5%",
  },
  columnQuantity: {
    width: "5%",
  },
  columnPrice: {
    width: "8%",
  },
  columnTotal: {
    width: "10%",
  },
});

export const transactionItemFormatStyles = StyleSheet.create({
  itemRow: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 10,
  },
  transactionColumn: {
    marginRight: 5,
  },
  columnCode: {
    width: "18%",
  },
  columnDescription: {
    width: "40%",
  },
  columnQuantity: {
    width: "10%",
  },
  columnPrice: {
    width: "10%",
  },
  columnTotal: {
    width: "15%",
  },
});
