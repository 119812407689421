import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const DisplaySelectedOffice = () => {
  const selectedOffice = useSelector(
    (state: RootState) => state.organization.selected_office
  );

  return (
    <div>
      {selectedOffice && (
        <div>
          <div>
            Current Selected Office: <b>{selectedOffice.company_name}</b>
          </div>
          <div>
            Current Office Account Number:{" "}
            <b>{selectedOffice.account_number}</b>
          </div>
        </div>
      )}
    </div>
  );
};

export default DisplaySelectedOffice;
