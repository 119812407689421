import { Address, IPaymentMethod, OrderItem, TrackingInformation } from "../../interfaces/interfaces";
import { FreightOption } from "../../interfaces/types";

interface Action {
  type: string;
  payload: any;
}

export interface CurrentOrder {
  admin_order_id: number | string | null;
  calculated_at: number;
  comment: string | null;
  courier: string | null;
  customer: number;
  discount_by_freight: number;
  discount_by_freight_amount: number;
  distributor_state: string;
  freight_fee: number;
  freight_option: string | null;
  has_promoted_item: boolean;
  id: number;
  is_admin_order: boolean | null;
  note: string | null;
  order_id: number | string | null;
  order_items: OrderItem[];
  po_number: string;
  ship_to_address: string | null;
  status: string | null;
  submitted_at: string | null;
  tax_amount: number;
  tax_rate: string;
  total_amount_before_tax: string;
  total_amount_after_tax: string;
  total_items: number;
  total_units: number;
  total_amount: number;
  //This could be the wrong type, have not verified
  track_numbers: TrackingInformation[];
  transaction_fee: string;
  payment_method: IPaymentMethod;
  grand_total: string;
}

interface Orders {
  currentOrder: CurrentOrder | null;
  currentOrderShipAddress: Address | null;
  freightOption: FreightOption | null;
  extraInfo: any;
}

const defaultState: Orders = {
  currentOrder: null,
  currentOrderShipAddress: null,
  freightOption: null,
  extraInfo: null,
};

function orders(state: Orders = defaultState, action: Action) {
  switch (action.type) {
    case "SET_CURRENT_ORDER_SHIP_ADDRESS":
      return {
        ...state,
        currentOrderShipAddress: action.payload,
      };
    case "UPDATE_CURRENT_ORDER":
      return {
        ...state,
        currentOrder: action.payload,
      };
    case "UPDATE_FREIGHT_OPTION":
      return {
        ...state,
        freightOption: action.payload,
      };
    case "UPDATE_EXTRA_INFO":
      return {
        ...state,
        extraInfo: action.payload,
      };
    default:
      return state;
  }
}

export default orders;
