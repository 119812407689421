import { Text } from "@react-pdf/renderer";

interface Props {
  children: any;
  size?: number;
}

const Heading = (props: Props) => {
  const { children, size } = props;

  return (
    <Text
      style={{ fontSize: size ?? 15, fontWeight: "bold", marginVertical: 10 }}
    >
      {children}
    </Text>
  );
};

export default Heading;
