import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { itemFormatStyles } from "../styles";

const styles = StyleSheet.create({
  tableHeading: {
    borderBottomColor: "#aaa",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    paddingBottom: 2,
  },
});

const TableHeading = () => (
  <View style={{ ...itemFormatStyles.itemRow, ...styles.tableHeading }}>
    <Text
      style={{ ...itemFormatStyles.columnName, ...itemFormatStyles.column }}
    >
      Name
    </Text>
    <Text
      style={{ ...itemFormatStyles.columnCode, ...itemFormatStyles.column }}
    >
      Code
    </Text>
    <Text
      style={{
        ...itemFormatStyles.columnReference,
        ...itemFormatStyles.column,
      }}
    >
      Reference
    </Text>
    <Text
      style={{ ...itemFormatStyles.columnColor, ...itemFormatStyles.column }}
    >
      Colour
    </Text>
    <Text
      style={{ ...itemFormatStyles.columnSize, ...itemFormatStyles.column }}
    >
      Size
    </Text>
    <Text
      style={{
        ...itemFormatStyles.columnQuantity,
        ...itemFormatStyles.column,
      }}
    >
      Qty
    </Text>
    <Text
      style={{ ...itemFormatStyles.columnPrice, ...itemFormatStyles.column }}
    >
      Price
    </Text>
    <Text
      style={{ ...itemFormatStyles.columnTotal, ...itemFormatStyles.column }}
    >
      Amount
    </Text>
  </View>
);

export default TableHeading;
