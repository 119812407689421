import cx from "classnames";

interface Props {
  children: any;
  style?: any;
  novel?: boolean;
  old?: boolean;
  promo?: boolean;
}

const Badge = (props: Props) => {
  const { children, novel, old, promo, style } = props;

  return (
    <span
      className={cx({
        "custom-badge": true,
        new: novel || (getBadgeType(children) === "new" && !promo && !old),
        old: old || (getBadgeType(children) === "old" && !novel && !promo),
        promo: promo || (getBadgeType(children) === "promo" && !novel && !old)
      })}
      style={{ ...style }}
      role="status"
    >
      {children}
    </span>
  );
};

const getBadgeType = (t: string | null) => {
  if (!t) {
    return "";
  } else {
    const tag = t.toLowerCase();
    if (tag === "new style" || tag === "new color" || tag === "new colour") {
      return "new";
    } else if (
      tag === "clearance" ||
      tag === "discontinued" ||
      tag === "non-returnable"
    ) {
      return "old";
    } else {
      return "promo";
    }
  }
};

export default Badge;
