import { SVGProps } from "react"
const HourglassStart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 118.9 159.1"
    {...props}
  >
    <path d="M73 79.5c1.9.8 3.5 1.5 5.1 2.2 8.4 3.9 15.2 9.8 20.4 17.5 5.4 7.8 9.3 16.3 11.9 25.4 2.1 7.7 3.4 15.6 3.2 23.6 0 .7.2 1 .9 1h.6c2.6.4 3.3 1.1 3.6 3.7.1.9.1 1.9 0 2.8-.1 1.9-.9 2.8-2.9 3.2-.8.1-1.6.2-2.4.2H4.1C.9 158.9 0 158 0 154.8v-1.5c0-2.9.9-3.9 3.8-4.1 1-.1 1.2-.4 1.2-1.3.1-17.4 4.9-33.4 14.5-47.9 4.5-6.7 10-12.4 17-16.4 2.7-1.5 5.5-2.7 8.4-4.1-.2-.1-.4-.3-.7-.4-8.9-3.3-16.1-8.9-22-16.2-5.1-6.2-8.6-13.3-11.4-20.7C7 32.2 4.9 21.8 5 11c0-.8-.2-1.1-1-1.2-.7 0-1.4-.2-2.1-.4C.6 9 0 8.2 0 6.8V3.1C0 1.5.7.7 2.2.3 2.9.1 3.7 0 4.5 0h109.9c3.2.1 4.4 1.3 4.4 4.5v.7c0 3.2-.9 4.3-4.2 4.6-.7.1-1 .2-1 1 .1 4.9-.2 9.8-1.1 14.7-1.3 6.9-3.2 13.7-6.1 20.2-3.3 7.4-7.2 14.4-12.8 20.4-3.3 3.5-7 6.7-11.2 9.1-2.4 1.4-5 2.4-7.5 3.5-.5.3-1 .4-1.9.8zm-13.6 69.7h43.5c1.2 0 1.6-.3 1.5-1.5-.3-2.9-.4-5.9-.7-8.8-.8-8.3-2.9-16.2-6.5-23.7-3.9-7.9-8.9-15-15.8-20.5-8.1-6.5-17.4-8.9-27.6-7.5-8.7 1.3-15.9 5.7-21.7 12.1-8.7 9.7-14.4 20.9-16.4 33.8-.7 4.7-1 9.5-1.4 14.3-.1 1.7 0 1.7 1.8 1.7 14.4.1 28.9.1 43.3.1zM59.2 9.8H15.5c-1.1 0-1.6.3-1.4 1.5.2 1.6.3 3.2.4 4.8.4 8.8 2.3 17.3 5.8 25.4 3.3 7.6 7.9 14.3 13.8 20.1 6.3 6.2 13.8 9.8 22.5 10.4 9.2.7 17.4-1.9 24.7-7.6 8.5-6.7 14.1-15.5 18.1-25.4 3.5-8.8 4.7-18.1 5.1-27.5.1-1.6 0-1.6-1.6-1.6-14.7-.1-29.2-.1-43.7-.1z" />
    <path d="M59.2 19.9h28.5c.7 0 1.4.1 2.1.3 2 .6 2.9 2.2 2.5 4.2-.1.7-.4 1.4-.6 2.2-.8 2.5-1.6 3.1-4.2 3.2H31.6c-.7 0-1.5-.1-2.2-.2-1.3-.3-2.2-1-2.4-2.3-.1-.7-.3-1.4-.5-2-.9-3 .8-5.2 4-5.2h14.2c4.7-.2 9.6-.2 14.5-.2zM59.4 39.8h20.1c2.3 0 3.8 1.1 4.2 3.1.1.7-.2 1.6-.4 2.3-.1.3-.4.5-.5.7-1.8 2.9-4.3 3.9-7.8 3.8-11.3-.2-22.5-.1-33.8-.1-1.7 0-3-.5-4-1.8-.4-.5-.8-1-1.3-1.5-1.2-1.2-1.6-2.6-.9-4.2.7-1.7 2.2-2.4 4-2.4h6.1c4.8.1 9.5.1 14.3.1z" />
  </svg>
)
export default HourglassStart
