import { useHistory } from "react-router-dom";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import Modal from "../../../components/Modal/Modal";
import {
  Container,
  StyledButton,
  Wrapper
} from "../../../components/styled/styled-components";


export type OrderUploadResult = "progressBar" | "addingToCart" | "goToCart" | "clearCart" | "csvErrors" | "failedResult";

interface Props {
  modalToShow: OrderUploadResult | null;
  setModalToShow: React.Dispatch<React.SetStateAction<OrderUploadResult | null>>;
  clearCart: () => void;
  progressLevel: number;
  totalItems: number;
  isLoading: boolean;
  csvErrors: number[];
  errorMessage?: string;
}

const ModalSwitcher = ({
  modalToShow,
  setModalToShow,
  clearCart,
  progressLevel,
  totalItems,
  isLoading,
  csvErrors,
  errorMessage,
}: Props) => {
  const history = useHistory();
  return (
    <>
      {modalToShow === "progressBar" && (
        <Modal>
          <div className="modalContent">
            <p>Please wait while we import your order</p>
            <p>
              Loaded {progressLevel} out of {totalItems} items
            </p>
            <div className="loadingSpinnerWrapper">
              <LoadingSpinner />
            </div>
            <div className="progress">
              <div
                className="progress-bar progress-bar-striped bg-info"
                role="progressbar"
                style={{
                  width: `${(progressLevel / totalItems) * 100}%`,
                }}
                aria-valuenow={progressLevel}
                aria-valuemin={0}
                aria-valuemax={totalItems}
              />
            </div>
          </div>
        </Modal>
      )}

      {modalToShow === "addingToCart" && (
        <Modal>
          <div className="modalContent">
            <p>Please wait while we add your items to your cart</p>
            <div className="loadingSpinnerWrapper">
              <LoadingSpinner />
            </div>
          </div>
        </Modal>
      )}

      {modalToShow === "goToCart" && (
        <Modal>
          <div className="modalContent">
            <p>Items added to cart.</p>
            <Wrapper>
              <StyledButton onClick={() => history.push("/store/cart")}>
                View Cart
              </StyledButton>
            </Wrapper>
          </div>
        </Modal>
      )}

      {modalToShow === "clearCart" && (
        <Modal>
          <div className="modalContent">
            {isLoading ? (
              <>
                <p>Removing items from cart</p>
                <Wrapper>
                  <LoadingSpinner />
                </Wrapper>
              </>
            ) : (
              <>
                <p>You have items in your current cart.</p>
                <p>Please clear your current cart to add these ones.</p>
                <Container>
                  <StyledButton
                    onClick={() => clearCart()}
                    style={{ margin: "1em 0" }}
                    warning
                  >
                    Clear cart
                  </StyledButton>
                  <StyledButton onClick={() => setModalToShow(null)}>
                    Cancel
                  </StyledButton>
                </Container>
              </>
            )}
          </div>
        </Modal>
      )}
      {modalToShow === "csvErrors" && (
        <Modal>
          <div className="modalContent">
            {csvErrors.length > 1 ? (
              <p>Errors in CSV on lines: </p>
            ) : (
              <p>Error in CSV on line: </p>
            )}
            {csvErrors.map((line, index) => (
              <p key={index}>{line}</p>
            ))}
            <Wrapper>
              <StyledButton onClick={() => setModalToShow(null)}>OK</StyledButton>
            </Wrapper>
          </div>
        </Modal>
      )}
      {modalToShow === "failedResult" && (
        <Modal>
          <div className="modalContent">
            <span className="failedContent">Error: {errorMessage}</span>
            <Wrapper>
              <StyledButton onClick={() => setModalToShow(null)}>OK</StyledButton>
            </Wrapper>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ModalSwitcher;
