import axios from "axios";
import cx from "classnames";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  generateAuthHeader,
  generateTokenAuthHeader
} from "../../commonfunctions/authentication";
import { log } from "../../commonfunctions/logger";
import { AlertType } from "../../interfaces/enums";
import { AlertContent } from "../../interfaces/interfaces";
import { updateCurrentOrder } from "../../redux/actions/orders";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import {
  Container,
  StyledButton,
  StyledNumberInput
} from "../styled/styled-components";
import Toast from "../Toast/Toast";
import { Context, IContext } from "../../context/ContextApp";

interface Props {
  cartItems: any;
  updatePromotionResult: any;
}

const BOGOPromotion = ({ cartItems, updatePromotionResult }: Props) => {
  const [bogoPromotion, setBogoPromotion] = useState<any>();
  const [displayedItemType, setDisplayedItemType] = useState<string>("gifted");
  const [isFetchingItems, setIsFetchingItems] = useState<boolean>(false);
  const [fetchedItems, setFetchedItems] = useState<any[]>([]);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [selectedItemColor, setSelectedItemColor] = useState<any>({});
  const [isFetchingItemColorDetails, setIsFetchingItemColorDetails] =
    useState<boolean>(false);
  const [fetchedItemColorDetails, setFetchedItemColorDetails] = useState<any>(
    {}
  );
  const [selectedSKUWithQuantity, setSelectedSKUWithQuantity] = useState<any>(
    {}
  );

  // Promotion Calculation
  const [qualifiedItemCount, setQualifiedGiftItemCount] = useState(0);
  const [giftItemCountInCart, setGiftItemCountInCart] = useState(0);

  const [toast, setToast] = useState<AlertContent | null>(null);

  const dispatch = useDispatch();
  const { appBranch }: IContext = useContext(Context);

  useEffect(() => {
    const req_url = `${process.env.REACT_APP_HONEYBEE_ROOT_API}/promotions/${appBranch}`;

    axios
      .get(req_url, generateTokenAuthHeader())
      .then((response) => {
        if (response.data.promotions.length) {
          setBogoPromotion(response.data.promotions[0]);
        }
      })
      .catch((error) => log(error, "Failed to load promotions"));
  }, [appBranch]);

  useEffect(() => {
    if (!cartItems.length) {
      setSelectedItem({});
      setSelectedItemColor({});
      setFetchedItemColorDetails({});
      setSelectedSKUWithQuantity({});
      setQualifiedGiftItemCount(0);
      setGiftItemCountInCart(0);
    }

    if (cartItems && cartItems.length >= 0) {
      calculateQualifiedGiftItemCount(
        bogoPromotion.selected_items,
        cartItems,
        bogoPromotion.purchase_amount
      );
      calculateGiftItemCountInCart(bogoPromotion.gifted_items, cartItems);
    }
    //eslint-disable-next-line
  }, [cartItems]);

  useEffect(() => {
    if (bogoPromotion && Object.keys(bogoPromotion).length >= 0) {
      calculateQualifiedGiftItemCount(
        bogoPromotion.selected_items,
        cartItems,
        bogoPromotion.purchase_amount
      );
      calculateGiftItemCountInCart(bogoPromotion.gifted_items, cartItems);
      fetchItemDetails(bogoPromotion.gifted_items);
    }
    //eslint-disable-next-line
  }, [bogoPromotion]);

  useEffect(() => {
    if (displayedItemType === "gifted") {
      fetchItemDetails(bogoPromotion.gifted_items);
    } else if (displayedItemType === "promoted") {
      fetchItemDetails(bogoPromotion.selected_items);
    }
    //eslint-disable-next-line
  }, [displayedItemType]);

  useEffect(() => {
    if (selectedItem && Object.keys(selectedItem).length >= 0) {
      setSelectedItemColor({});
      setFetchedItemColorDetails({});
      setSelectedSKUWithQuantity({});
    }
  }, [selectedItem]);

  useEffect(() => {
    if (selectedItemColor && Object.keys(selectedItemColor).length > 0) {
      setFetchedItemColorDetails({});
      fetchSelectedItemColorDetails(selectedItemColor);
      setSelectedSKUWithQuantity({});
    }
  }, [selectedItemColor]);

  useEffect(() => {
    if (qualifiedItemCount >= giftItemCountInCart) {
      updatePromotionResult({ passable: true, message: "passed" });
    } else {
      updatePromotionResult({
        passable: false,
        message: `You have ${giftItemCountInCart - qualifiedItemCount
          } more gift items than qualified for this order. Please remove some of them to check out.`,
      });
    }
    //eslint-disable-next-line
  }, [qualifiedItemCount, giftItemCountInCart]);

  function fetchItemDetails(slugs: any) {
    if (!slugs || slugs.length <= 0) return;

    setFetchedItems([]);
    setSelectedItem({});
    setSelectedItemColor({});
    setFetchedItemColorDetails({});
    setSelectedSKUWithQuantity({});
    setIsFetchingItems(true);

    Promise.all(
      slugs.map(async (slug: any) => {
        const req_url = `${process.env.REACT_APP_HALFBACK_ROOT_API}/bee_products/biz-corporates/${appBranch}/${slug}`;

        await axios
          .get(req_url, generateAuthHeader())
          .then((response) => {
            if (fetchedItems.find((item) => item.id === response.data.id)) return;
            setFetchedItems((prevState) => [...prevState, response.data]);
          })
          .catch((error) => log(error));
      })
    ).then(() => {
      setIsFetchingItems(false);
    });
  }

  function renderItemOptions(items: any) {
    if (!items || items.length <= 0) return;

    return (
      <>
        {(qualifiedItemCount > 0 || displayedItemType === "promoted") && (
          <h5>* Please select a product.</h5>
        )}

        <div className="giftItemOptions">
          {items.map((item: any, index: any) => (
            <div
              key={index}
              className={cx({
                giftItem: true,
                "gift-item-hover":
                  qualifiedItemCount > 0 || displayedItemType === "promoted",
                "gift-item-no-hover":
                  qualifiedItemCount <= 0 && displayedItemType === "gifted",
                selected: selectedItem && selectedItem.id === item.id,
              })}
              onClick={() => {
                if (
                  qualifiedItemCount > 0 ||
                  displayedItemType === "promoted"
                ) {
                  selectItem(item);
                }
              }}
            >
              <div className="giftItem__image">
                <img
                  src={item.images[0].https_attachment_url_product}
                  alt="product"
                />
              </div>

              <div className="giftItem__description">
                <span className="small">{item.name}</span>
                <span>
                  <b>{item.code}</b>
                </span>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }

  function selectItem(item: any) {
    if (!item || Object.keys(item).length <= 0) return;
    setSelectedItem(item);
  }

  function renderSelectedItemColorOptions(colors: any) {
    if (!colors || colors.length <= 0) return;

    return (
      <>
        <h5>* Please select the color</h5>

        <div className="giftItemColorOptions">
          {colors.map((color: any, index: number) => (
            <div
              key={index}
              className={
                selectedItemColor &&
                  Object.keys(selectedItemColor).length > 0 &&
                  selectedItemColor.id === color.id
                  ? "giftItemColor selected"
                  : "giftItemColor"
              }
              onClick={() => selectItemColor(color)}
            >
              <div className="giftItemColor__image">
                <img
                  src={color.images[0].https_attachment_url_product}
                  alt="product"
                />
              </div>
              <span>{color.name}</span>
            </div>
          ))}
        </div>
      </>
    );
  }

  function selectItemColor(color: any) {
    if (!color || Object.keys(color).length <= 0) return;
    setSelectedItemColor(color);
  }

  function fetchSelectedItemColorDetails(color: any) {
    if (!color || Object.keys(color).length <= 0) return;
    if (color.sizes.length <= 0) return;

    let skus = "";
    for (const size of color.sizes) {
      skus += `&skus[]=${size.sku}`;
    }

    const req_url = `${process.env.REACT_APP_HALFBACK_ROOT_API}/orders/current_order/items/sizes_detail_by_skus?color_id=${color.id}${skus}`;
    setIsFetchingItemColorDetails(true);

    axios
      .get(req_url, generateAuthHeader())
      .then((response) => {
        setFetchedItemColorDetails(response.data);
      })
      .catch((error) =>
        log(error, "fetchSelectedItemColorDetails() call failed.")
      )
      .then(() => {
        setIsFetchingItemColorDetails(false);
      });
  }

  function renderItemColorDetails(fetchedDetails: any, colorDetails: any) {
    if (!fetchedDetails || Object.keys(fetchedDetails).length <= 0) return;
    if (!colorDetails || Object.keys(colorDetails).length <= 0) return;

    const sorted_color_details_sizes = sortSizeByDBIndex(colorDetails.sizes);
    const sorted_fetched_details_by_skus = compareAndSortSizeBySKU(
      sorted_color_details_sizes,
      fetchedDetails.items
    );

    return (
      <div className="giftItemColorDetails">
        <h5>* Please choose the size and enter the count</h5>
        <span>
          {fetchedDetails.currency} $
          {parseFloat(fetchedDetails.price).toFixed(2)}
        </span>

        <table>
          <thead>
            <tr>
              <th>Size:</th>

              {sorted_color_details_sizes.map((size: any, index: number) => <th key={index}>{size.size}</th>)}
            </tr>
          </thead>

          <tbody>
            <tr>
              <th>{appBranch.toUpperCase()} Stock:</th>
              {sorted_fetched_details_by_skus.map(
                (detail: any, index: number) => {
                  const stockLevel = detail.stock[0].qtyAvailable;
                  return <td key={index}>{stockLevel > 0 ? stockLevel : 0}</td>;
                }
              )}
            </tr>

            <tr>
              <th />

              {sorted_fetched_details_by_skus.map(
                (detail: any, index: number) => (
                  <td key={index}>
                    <StyledNumberInput
                      type="number"
                      value={selectedSKUWithQuantity[detail.sku] || 0}
                      max={detail.stock[0].qtyAvailable}
                      onChange={(event) =>
                        onQuantityInputChange(event, detail.sku)
                      }
                    />
                  </td>
                )
              )}
            </tr>
          </tbody>
        </table>

        <StyledButton onClick={handleAddToCartOnClick}>
          Add To Cart
        </StyledButton>
      </div>
    );
  }

  function onQuantityInputChange(event: any, sku: any) {
    const input_value =
      event.target.value === "" ? 0 : parseInt(event.target.value, 10);
    const max_value = parseInt(event.target.max, 10);

    if (input_value >= 0) {
      if (displayedItemType === "gifted" && input_value > max_value) {
        return setSelectedSKUWithQuantity((prevState: any) => ({
          ...prevState,
          [sku]: max_value,
        }));
      }

      return setSelectedSKUWithQuantity((prevState: any) => ({
        ...prevState,
        [sku]: input_value,
      }));
    }
  }

  function calculateQualifiedGiftItemCount(
    promotedItems: any,
    cartItems: any,
    divider: any
  ) {
    if (!promotedItems || promotedItems.length <= 0) return;
    if (!cartItems || cartItems.length <= 0) return;

    let promoted_item_count = 0;

    cartItems.map((item: any) => {
      if (promotedItems.includes(item.product)) {
        promoted_item_count += item.quantity;
      }
    });

    const qualified_gift_item_count = Math.trunc(promoted_item_count / divider);

    return setQualifiedGiftItemCount(qualified_gift_item_count);
  }

  function calculateGiftItemCountInCart(giftItems: any, cartItems: any) {
    if (!giftItems || giftItems.length <= 0) return;
    if (!cartItems || cartItems.length <= 0) return;

    let count = 0;

    cartItems.forEach((item: any) => {
      if (giftItems.includes(item.product)) {
        count += item.quantity;
      }
    });

    return setGiftItemCountInCart(count);
  }

  const displayPromotionEligibility = () => (
    <Container margin="1em 0">
      {displayedItemType === "gifted" ? (
        qualifiedItemCount > 0 ? (
          <>
            <h4 style={{ color: "green" }}>Congratulations!</h4>
            <h4>
              This order qualifies you for{" "}
              <span style={{ color: "green" }}>{qualifiedItemCount}</span>{" "}
              free Biz Corporates belts.
            </h4>

            <p>
              Remaining belts to select:{" "}
              {qualifiedItemCount - giftItemCountInCart}
            </p>
          </>
        ) : (
          <>
            <h4>
              Please click on "View promoted items" and follow the
              instructions to receive offers on these gifted items.
            </h4>
          </>
        )
      ) : (
        <>
          <h4>
            Please purchase any of the promoted items below to qualify for
            this promotion:
          </h4>
          {/* TODO: Make this promo text dynamic */}
          <h5>Get a free belt with every two promoted items purchased.</h5>
        </>
      )}
    </Container>
  );

  function handleAddToCartOnClick() {
    if (
      !selectedSKUWithQuantity ||
      Object.keys(selectedSKUWithQuantity).length <= 0
    ) return setToast({
      message: "Please enter gift item quantity to add.",
      type: AlertType.Error,
    });

    let total_item_quantity = 0;
    for (const sku in selectedSKUWithQuantity) {
      total_item_quantity += selectedSKUWithQuantity[sku];
    }

    if (!total_item_quantity) return setToast({
      message: "Please enter gift item quantity to add.",
      type: AlertType.Error,
    });

    if (
      displayedItemType === "gifted" &&
      qualifiedItemCount === giftItemCountInCart
    ) return setToast({
      message: "You have already added all gift items.",
      type: AlertType.Error,
    });

    if (
      displayedItemType === "gifted" &&
      qualifiedItemCount < giftItemCountInCart + total_item_quantity
    ) {
      setToast({
        message: `You have entered too many gift items. Remaining count is ${qualifiedItemCount - giftItemCountInCart
          }`,
        type: AlertType.Error,
      });
      return;
    }

    const items = [];

    for (const sku in selectedSKUWithQuantity) {
      if (selectedSKUWithQuantity[sku] <= 0) continue;

      const accpac_details = fetchedItemColorDetails.items.find(
        (element: any) => element.sku === sku
      );

      items.push({
        sku: sku,
        quantity: selectedSKUWithQuantity[sku],
        itemNo: accpac_details.formattedItemNo,
        product_id: selectedItem.id,
        color: selectedItemColor.name,
        images: selectedItemColor.images,
        size: accpac_details.size,
        price: fetchedItemColorDetails.price,
        backorder_qty: 0,
        stock: accpac_details.stock,
        eta: accpac_details,
        brand_name: selectedItem.brand,
        product_code: selectedItem.code,
        product_name: selectedItem.name,
        product_slug: selectedItem.slug,
        promotion_code: displayedItemType === "gifted" ? "giftCampaign" : "",
      });
    }

    if (items && items.length > 0) {
      const req_url = `${process.env.REACT_APP_HALFBACK_ROOT_API}/orders/current_order/items/create`;
      const data = {
        items: items,
        reference: displayedItemType === "gifted" ? "Gift Campaign" : "",
      };

      axios
        .post(req_url, data, generateAuthHeader())
        .then((response) => {
          dispatch(updateCurrentOrder(response.data));
          setToast({
            message: "Gift items are successfully added to your cart.",
            type: AlertType.Success,
          });
          setSelectedSKUWithQuantity({});
        })
        .catch((error) => log(error, "handleAddToCartOnClick() call failed."));
    }
  }

  function sortSizeByDBIndex(sizes: any) {
    if (!sizes || sizes.length <= 0) return;

    sizes.sort((a: any, b: any) => a.index - b.index);

    return sizes;
  }

  function compareAndSortSizeBySKU(value: any, target: any) {
    if (!value || value.length <= 0) return;
    if (!target || target.length <= 0) return;

    target.sort((a: any, b: any) => (
      value.map((size: any) => size.sku).indexOf(a.sku) -
      value.map((size: any) => size.sku).indexOf(b.sku)
    ));

    return target;
  }

  function updateDisplayedItemType(type: any) {
    if (!type) return;
    if (isFetchingItems) return;
    if (type !== "gifted" && type !== "promoted") return;

    setDisplayedItemType(type);
  }

  return (
    <div className="bogoPromotion">
      <div>
        {displayedItemType !== "gifted" ? (
          <StyledButton
            disabled={isFetchingItems}
            onClick={() => updateDisplayedItemType("gifted")}
            style={{ marginRight: "1em" }}
          >
            View gift items
          </StyledButton>
        ) : (
          <StyledButton
            disabled={isFetchingItems}
            onClick={() => updateDisplayedItemType("promoted")}
          >
            View promoted items
          </StyledButton>
        )}
      </div>

      {displayPromotionEligibility()}

      {isFetchingItems ? (
        <LoadingSpinner message={`Loading ${displayedItemType} items...`} />
      ) : (
        renderItemOptions(fetchedItems)
      )}

      {renderSelectedItemColorOptions(selectedItem.colors)}

      {isFetchingItemColorDetails ? (
        <LoadingSpinner message="Loading item price and available stocks..." />
      ) : (
        renderItemColorDetails(fetchedItemColorDetails, selectedItemColor)
      )}

      <Toast content={toast} onClick={() => setToast(null)} />
    </div>
  );
};

export default BOGOPromotion;
