import cx from "classnames";
import React from "react";
import { AlertType } from "../../interfaces/enums";
import { AlertContent } from "../../interfaces/interfaces";
import IconButton from "../IconButton/IconButton";
import Icon from "../Icon/icon";

interface Props {
  content: AlertContent | null;
  onClick: React.Dispatch<React.SetStateAction<AlertContent | null>>;
}

const Toast = ({ content, onClick }: Props) => {
  if (content && content.type && content.message) {
    const { type, message } = content;
    const success = type === AlertType.Success;
    const warning = type === AlertType.Warning;
    const error = type === AlertType.Error;
    return (
      <div
        className={cx({
          toast: true,
          success,
          warning,
          error
        })}
        onClick={() => onClick(null)}
        role="dialog"
      >
        <div className="toast-icon">
          {success && (
            <Icon name="CheckCircle" iconWidth="1.5rem"/>
          )}
          {warning && (
            <Icon name="ExclamationCirlce" iconWidth="1.5rem"/>
          )}
          {error && (
            <Icon name="TimesCircle" iconWidth="1.5rem" />
          )}
        </div>
        {message}
        <div className="close-button">
          <IconButton>
            <Icon name="Times" />
          </IconButton>
        </div>
      </div>
    );
  } else return <></>;
};

export default Toast;
