import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Modal from "../../../../../components/Modal/Modal";
import { StyledButton } from "../../../../../components/styled/styled-components";
import { updateFreightOption } from "../../../../../redux/actions/orders";
import { formatErrorMessage } from "./util";

interface Props {
  showRecalculateButton: boolean;
  setShowRecalculateButton: () => void;
  errorMessage: string | null;
  callSplitService: any;
}

const AirbagError = ({
  showRecalculateButton,
  setShowRecalculateButton,
  errorMessage,
  callSplitService,
}: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <Modal>
      <div className="airbag-error-modal">
        <div>{formatErrorMessage(errorMessage)}</div>
        <p>
          You can fix this by changing your freight method or shipping address or by removing items in
          your cart causing the error.
        </p>
        {showRecalculateButton ? (
          <StyledButton success onClick={() => callSplitService()}>
            Recalculate On-Road cost
          </StyledButton>
        ) : (
          <StyledButton
            success
            onClick={() => {
              dispatch(updateFreightOption("by_ground"));
              setShowRecalculateButton();
            }}
          >
            Change to road freight
          </StyledButton>
        )}
        <StyledButton warning onClick={() => history.go(0)}>
          Modify order
        </StyledButton>
      </div>
    </Modal>
  );
};

export default AirbagError;
