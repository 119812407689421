export const setSelectedOffice = (office: string) => ({
  type: "SET_SELECTED_OFFICE",
  payload: office,
});

export const setOrganizationKey = (key: string) => ({
  type: "SET_ORGANISATION_KEY",
  payload: key,
});

export const setIsWebstoreAdmin = (boolean: boolean) => ({
  type: "SET_IS_WEBSTORE_ADMIN",
  payload: boolean,
});
