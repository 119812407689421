import { useContext, useEffect, useRef, useState } from "react";
import { generateAuthHeader } from "../../../../commonfunctions/authentication";
import { log } from "../../../../commonfunctions/logger";
import AddressStateOptionSelector from "../../../../components/Address/AddressStateOptionSelector";
import {
  formatDisplayAddress,
  formatShipParamsForSave
} from "../../../../components/Address/util";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import {
  StyledButton,
  StyledFormField,
  StyledFormLayout
} from "../../../../components/styled/styled-components";
import Toast from "../../../../components/Toast/Toast";
import { Context, IContext } from "../../../../context/ContextApp";
import { AlertType } from "../../../../interfaces/enums";
import { Address, AlertContent } from "../../../../interfaces/interfaces";


const EditableAddressBook = () => {
  const [savedAddresses, setSavedAddresses] = useState<Address[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectValue, setSelectValue] = useState<number>(0);
  const [toast, setToast] = useState<AlertContent | null>(null);
  const { appBranch: branch }: IContext = useContext(Context);

  const isMounted = useRef(true);

  useEffect(() => {
    fetchSavedAddresses();

    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* GENERAL FUNCTION STARTS */
  const fetchSavedAddresses = async () => {
    setIsLoading(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_HALFBACK_ROOT_API}/ship_to_addresses/saved`,
        generateAuthHeader()
      );
      const data = await res.json();
      if (isMounted.current) {
        setIsLoading(false);
        if (data.length === 0) {
          setToast({ message: "No addresses found.", type: AlertType.Warning });
        } else {
          const addresses: Address[] = data;

          addresses.sort((a, b) => {
            if (a.ship_to_name > b.ship_to_name) return 1;
            if (b.ship_to_name > a.ship_to_name) return -1;
            return 0;
          });
          setSelectValue(0);
          setSavedAddresses(addresses);
          setSelectedAddress(addresses[0]);
        }
      }
    } catch (error) {
      if (isMounted.current) {
        log(error, "Failed to fetch saved addresses in /preferences");
        setIsLoading(false);
        setToast({
          message: "Failed to load addresses.",
          type: AlertType.Error
        });
      }
    }
  };

  const deleteAddress = async () => {
    if (!window.confirm("Are you sure you want to delete this address?")) return;

    if (selectedAddress) {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_HALFBACK_ROOT_API}/ship_to_addresses/delete`,
          {
            method: "POST",
            body: JSON.stringify({ address_id: selectedAddress.id }),
            headers: {
              ...generateAuthHeader().headers,
              "Content-Type": "application/json"
            }
          }
        );

        if (res.ok) {
          const data = await res.json();
          setSavedAddresses(data.ship_addresses);
          setSelectedAddress(data.selected_address);
          setToast({
            message: "Address successfully deleted.",
            type: AlertType.Success
          });
          if (!data.ship_addresses.length) {
            setToast({
              message: "No addresses found.",
              type: AlertType.Warning
            });
          }
        } else {
          const error = await res.text();
          throw error;
        }
      } catch (error) {
        log(error, "Failed to delete address in /preferences");
        setToast({
          message: "Failed to delete address.",
          type: AlertType.Error
        });
      }
    }
  };

  const updateAddress = async () => {
    if (selectedAddress) {
      const params = formatShipParamsForSave(selectedAddress, branch);

      try {
        const res = await fetch(
          `${process.env.REACT_APP_HALFBACK_ROOT_API}/ship_to_addresses/update`,
          {
            method: "POST",
            body: JSON.stringify(params),
            headers: {
              ...generateAuthHeader().headers,
              "Content-Type": "application/json"
            }
          }
        );

        if (res.ok) {
          fetchSavedAddresses();
          setToast({
            message: "Successfully updated address.",
            type: AlertType.Success
          });
        } else {
          const error = await res.text();
          throw error;
        }
      } catch (error) {
        log(error, "Failed to edit address in /preferences");
        setToast({
          message: "Failed to update address.",
          type: AlertType.Error
        });
      }
    }
  };

  const onAddressSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const index = parseInt(event.target.value);

    const address = savedAddresses[index];
    setSelectedAddress(address);
    setSelectValue(index);

    if (selectValue > 0) {
      address.is_primary_address = false;
    }
  };

  const renderAddressSelector = () => {
    const addressBook = savedAddresses.slice();

    return (
      <select onChange={onAddressSelect} value={selectValue}>
        {addressBook.map((address, index) => (
          <option value={index} key={index}>
            {formatDisplayAddress(address)}
          </option>
        ))}
      </select>
    );
  };

  const editFormField = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>,
    key: string
  ) => {
    if (selectedAddress) {
      setSelectedAddress({
        ...selectedAddress,
        [key]: event.target.value
      });
    }
  };

  const renderEditForm = () => (
    <div className="address-book-editor">
      <StyledFormLayout>
        <StyledFormField>
          <label>My Addresses</label>
          {renderAddressSelector()}
        </StyledFormField>
        {selectedAddress && (
          <>
            <StyledFormField>
              <label>Ship To Name</label>

              <input
                value={selectedAddress.ship_to_name}
                onChange={(event) => editFormField(event, "ship_to_name")}
              />
            </StyledFormField>
            <StyledFormField>
              <label>Ship Address</label>
              <input
                value={selectedAddress.address_line1}
                onChange={(event) => editFormField(event, "address_line1")}
              />
            </StyledFormField>
            <StyledFormField>
              {branch !== "nz" ? (
                <>
                  <label>Ship Address Line 2</label>
                  <input
                    value={selectedAddress.address_line2}
                    onChange={(event) =>
                      editFormField(event, "address_line2")
                    }
                  />
                </>
              ) : (
                <>
                  <label>Suburb</label>
                  {selectedAddress.address_line4 || "-"}
                </>
              )}
            </StyledFormField>

            <StyledFormField>
              <label>City</label>
              {branch !== "nz" ? (
                <input
                  value={selectedAddress.address_city}
                  onChange={(event) => editFormField(event, "address_city")}
                />
              ) : (
                <span>{selectedAddress.address_city}</span>
              )}
            </StyledFormField>
            {branch !== "nz" && selectedAddress && selectedAddress.state && (
              <StyledFormField>
                {/* Canada uses "Province", Australia uses "State" */}
                <label>{branch === "ca" ? "Province" : "State"}</label>

                <AddressStateOptionSelector
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                    editFormField(event, "state")
                  }
                  selectedValue={selectedAddress.state}
                />
              </StyledFormField>
            )}
            <StyledFormField>
              <label>Country</label>
              {branch !== "nz" ? (
                <input
                  value={selectedAddress.address_country}
                  onChange={(event) =>
                    editFormField(event, "address_country")
                  }
                />
              ) : (
                <span>{selectedAddress.address_country}</span>
              )}
            </StyledFormField>
            <StyledFormField>
              <label>Post Code</label>
              {branch !== "nz" ? (
                <input
                  value={selectedAddress.address_postcode}
                  onChange={(event) =>
                    editFormField(event, "address_postcode")
                  }
                />
              ) : (
                <span>{selectedAddress.address_postcode}</span>
              )}
            </StyledFormField>
          </>
        )}
      </StyledFormLayout>
      <div className="button-container">
        <StyledButton
          primary
          onClick={() => {
            updateAddress();
          }}
        >
          Update
        </StyledButton>
        <StyledButton
          danger
          onClick={() => {
            deleteAddress();
          }}
        >
          Delete
        </StyledButton>
      </div>
    </div>
  );

  return (
    <>
      {isLoading ? (
        <div className="center-spinner">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          {savedAddresses.length > 0 && renderEditForm()}
          <Toast content={toast} onClick={() => setToast(null)} />
        </>
      )}
    </>
  );
};

export default EditableAddressBook;
