import { useState, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { log } from "../../../commonfunctions/logger";
import LoaderButton from "../../../components/LoaderButton/LoaderButton";
import { StyledInput } from "../../../components/styled/styled-components";
import Toast from "../../../components/Toast/Toast";
import { Context, IContext } from "../../../context/ContextApp";
import { AlertType } from "../../../interfaces/enums";
import { AlertContent } from "../../../interfaces/interfaces";
import ReCAPTCHA from "react-google-recaptcha";
import {
  setIsWebstoreAdmin,
  setOrganizationKey,
} from "../../../redux/actions/organization";
import {
  setIsWebstoreOrderEnabled,
  setLoginStatus,
} from "../../../redux/actions/userSession";
import { subdomainTypes } from "../../../context/availableSubdomains";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const [toast, setToast] = useState<AlertContent | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { appBranch }: IContext = useContext(Context);
  const [isValidCaptcha, setIsValidCaptcha] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!appBranch) setToast({
        message: "Please you must add a subdomain branch into the link.",
        type: AlertType.Error,
      });
    if (appBranch === subdomainTypes.CA) setIsValidCaptcha(true);
  }, [appBranch]);

  const isFieldValid = (fieldName: string) => {
    if (fieldName === "email") {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    } else if (fieldName === "password") {
      return password.trim().length > 0;
    }
  };

  const login = async () => {
    if (!isFieldValid("email")) {
      setToast({
        message: "Please enter a valid email.",
        type: AlertType.Error,
      });
      return;
    } else if (!isFieldValid("password")) {
      setToast({
        message: "Please fill in both fields.",
        type: AlertType.Error,
      });
      return;
    }

    setIsLoading(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_HALFBACK_ROOT_API}/session/webstore_login`,
        {
          method: "POST",
          body: JSON.stringify({
            email,
            password,
            remember_me: rememberMe,
            branch: appBranch,
          }),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      if (res.ok) {
        const data = await res.json();

        if (data.webstore_admin) {
          dispatch(setOrganizationKey(data.organization_key));
          dispatch(setIsWebstoreAdmin(data.webstore_admin));
        }
        if (data.allow_webstore) {
          dispatch(setLoginStatus(true, data));
          dispatch(setIsWebstoreOrderEnabled(data.webstore_order_enabled));
          history.push("/store/cart");
        } else {
          throw "you do not have access";
        }
      } else {
        const error = await res.text();
        throw error;
      }
    } catch (error) {
      const stringifiedError = JSON.stringify(error).toLowerCase();
      if (stringifiedError.includes("you do not have access to")) {
        setToast({
          message: "You do not have access to the webstore",
          type: AlertType.Error,
        });
      } else if (stringifiedError.includes("invalid email")) {
        setToast({
          message: "Invalid email or password",
          type: AlertType.Error,
        });
      } else {
        setToast({
          message: "Something went wrong, please try again.",
          type: AlertType.Error,
        });
      }
      log(error, "Failed to log in");
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeCaptcha = () => setIsValidCaptcha(true);

  return (
    <>
      <script src="https://www.google.com/recaptcha/api.js" async defer />
      <div className="form-signin">
        <h1>Login</h1>
        <StyledInput
          type="email"
          name="username"
          placeholder="Email Address"
          onChange={(event) => setEmail(event.target.value)}
        />
        <StyledInput
          type="password"
          style={{ margin: "1rem 0" }}
          name="password"
          placeholder="Password"
          onChange={(event) => setPassword(event.target.value)}
        />
        <div className="extra-actions">
          <Link to="/reset_password" style={{ display: "inline-block" }}>
            Forgot Password?
          </Link>

          <div>
            <input
              type="checkbox"
              id="rememberMe"
              name="rememberMe"
              checked={rememberMe}
              onChange={() => setRememberMe(!rememberMe)}
            />
            <label className="checkbox" style={{ display: "inline-block" }}>
              Remember me
            </label>
          </div>

          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SECRET_KEY}
            onChange={onChangeCaptcha}
          />
        </div>
        {appBranch && (
          <LoaderButton
            style={{ width: "100%", marginTop: "10px" }}
            onClick={(event: React.MouseEvent) => {
              appBranch && isValidCaptcha && login();
              event.preventDefault();
            }}
            disabled={!appBranch || !isValidCaptcha}
            isLoading={isLoading}
          >
            Login
          </LoaderButton>
        )}
      </div>
      <Toast content={toast} onClick={() => setToast(null)} />
    </>
  );
};

export default Login;
