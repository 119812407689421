import { SVGProps } from "react"
const Pencil = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 158.6 159"
    {...props}
  >
    <style>{'.pencilIcon{fill:#FFFFFF}'}</style>
    <path
    d="M158.6 31.6c.2 5.7-2.4 10.5-6.3 14.5-8.2 8.3-16.5 16.6-24.8 24.9-27.8 27.8-55.7 55.6-83.5 83.3-.6.6-1.8.8-2.8.9-5.6.7-11.2 1.3-16.8 1.9-5.6.6-11.1 1.3-16.7 1.8-4 .4-7.8-3.1-7.7-7.1.1-3.4.5-6.8.8-10.1l2.4-21.6c.2-1.4.3-2.7.5-4.1.1-.5.4-1.1.7-1.5C23.3 95.8 42.1 77 60.9 58.3 78 41.1 95.2 24 112.3 6.9c4.1-4.1 8.8-6.7 14.6-6.9 5.3-.1 10.1 1.5 14 5.3 4 4 8 7.9 12 11.9 3.9 4 5.8 8.7 5.7 14.4zM53.7 131c.9-.9 1.5-1.4 2.1-2 15.6-15.5 31.1-31.1 46.7-46.6l20.7-20.7c.6-.6.2-.9-.2-1.3L98.7 36.1c-.8-.8-1.3-.8-2.1 0-2.8 2.8-5.5 5.6-8.3 8.4C68.4 64.4 48.5 84.2 28.6 104c-.2.2-.4.5-.8 1 .7 0 1.1.1 1.6.1 2.7 0 5.4.1 8.1 0 .9 0 1.2.2 1.2 1.2v12.6c0 1 .3 1.2 1.2 1.2h12.6c.9 0 1.2.2 1.2 1.2-.1 2.7-.1 5.3-.1 8 0 .3 0 .8.1 1.7zm51.4-102.5c-.2-.1-.3-.3-.5-.4.1.1.2.3.3.4L115 38.6c4.8 4.8 9.6 9.6 14.4 14.3 1.3 1.3 1.4 1.3 2.6 0L145.9 39c4.3-4.3 4.7-10.2.6-14.5-4.1-4.3-8.3-8.4-12.7-12.5-2.9-2.7-7.9-3.2-11.3-1.1-1.8 1.1-3.3 2.6-4.8 4.1l-12.6 13.5zm-74.6 99.9v-2.3c0-3.8-.1-7.5 0-11.3 0-1-.3-1.2-1.2-1.2-3 0-6 0-9 .1-.7 0-1.5.4-2 .8-1.8 1.6-3.5 3.2-5.1 4.9-.3.3-.5.8-.6 1.2-.6 5.5-1.2 11-1.7 16.4-.1.7.2 1.6.7 2.1 2.6 2.8 5.2 5.4 7.9 8.1.4.4 1.1.6 1.6.6 5.5-.5 11-1 16.5-1.6.7-.1 1.5-.4 2.1-.9 1.6-1.5 2.9-3.1 4.5-4.6.9-.8 1.2-1.7 1.1-2.8-.1-2.6-.2-5.1 0-7.7.1-1.5-.5-1.6-1.6-1.6H31.6c-.3-.1-.7-.2-1.1-.2z"
    className="pencilIcon"
    />
  </svg>
)
export default Pencil
