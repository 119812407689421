import cx from "classnames";
import { Link } from "react-router-dom";
import { Wrapper } from "../styled/styled-components";
import { Context, IContext } from "../../context/ContextApp";
import { useContext } from "react";
import Icon from "../Icon/icon";
import { IconNames } from "../Icon/types";

interface Category {
  title: string;
  category: string;
  route: string;
  icon: IconNames;
}

const CATEGORIES: Category[] = [
  {
    title: "Cart",
    category: "cart",
    route: "/store/cart",
    icon: "ShoppingCartWhite",
  },
  {
    title: "Quick Order",
    category: "quickorder",
    route: "/quick-order",
    icon: "Pencil",
  },
  {
    title: "Saved Orders",
    category: "savedorders",
    route: "/saved-orders",
    icon: "Save",
  },
  {
    title: "Order Import",
    category: "orderimport",
    route: "/order-uploader",
    icon: "FileUpload",
  },
  {
    title: "Order History",
    category: "orderhistory",
    route: "/order-history",
    icon: "BoxOpen",
  },
  {
    title: "Transactions",
    category: "transactions",
    route: "/transactions",
    icon: "CreditCard",
  },
  {
    title: "Preferences",
    category: "preferences",
    route: "/preferences",
    icon: "Cog",
  },
  {
    title: "Promotions",
    category: "promotions",
    route: "/store/cart?tab=promotions",
    icon: "StarWhite",
  },
  {
    title: "Media Library",
    category: "medialibrary",
    route: "/media-library",
    icon: "PhotoVideo",
  },
];

const CATEGORIES_CA: Category[] = [
  {
    title: "Cart",
    category: "cart",
    route: "/store/cart",
    icon: "ShoppingCartWhite",
  },
  {
    title: "Quick Order",
    category: "quickorder",
    route: "/quick-order",
    icon: "Pencil",
  },
  {
    title: "Saved Orders",
    category: "savedorders",
    route: "/saved-orders",
    icon: "Save",
  },
  {
    title: "Order History",
    category: "orderhistory",
    route: "/order-history",
    icon: "BoxOpen",
  },
  {
    title: "Transactions",
    category: "transactions",
    route: "/transactions",
    icon: "CreditCard",
  },
  {
    title: "Preferences",
    category: "preferences",
    route: "/preferences",
    icon: "Cog",
  },
  {
    title: "Promotions",
    category: "promotions",
    route: "/store/cart?tab=promotions",
    icon: "StarWhite",
  },
  {
    title: "Media Library",
    category: "medialibrary",
    route: "/media-library",
    icon: "PhotoVideo",
  },
];

interface Props {
  webstoreExpanded: boolean;
  currentCategory: string;
  setCurrentCategory: React.Dispatch<React.SetStateAction<string>>;
  setWebstoreExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

const WebstoreMenu = ({
  webstoreExpanded,
  currentCategory,
  setCurrentCategory,
  setWebstoreExpanded,
}: Props) => {
  const { appBranch }: IContext = useContext(Context);
  const webstoreMenuLinks = appBranch === "ca" ? CATEGORIES_CA : CATEGORIES;

  const returnLinks = () => webstoreMenuLinks.map((item, index) => (
    <Link
      key={index}
      className={cx({
        selected: webstoreExpanded && currentCategory === item.category,
        icons: true,
      })}
      to={item.route}
      onClick={() => {
        setCurrentCategory(item.category);
      }}
    >
      <Wrapper style={{ width: "15%" }}>
        <Icon name={item.icon} />
      </Wrapper>
      {item.title}
    </Link>
  ));
  return (
    <div className="sidebar-menu">
      <div
        className="menu-label"
        onClick={() => setWebstoreExpanded(!webstoreExpanded)}
      >
        Webstore
        <div className="expand-icon">{webstoreExpanded ? "-" : "+"}</div>
      </div>
      {webstoreExpanded && <div className="sub-menu">{returnLinks()}</div>}
    </div>
  );
};

export default WebstoreMenu;
