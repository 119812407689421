import React from "react";
import { Link } from "react-router-dom";
import { Brand } from "../../../interfaces/types";
import { transformName } from "./brands";
import { subdomainTypes } from "../../../context/availableSubdomains";

interface Props {
  currentBrand: Brand | null;
  currentCategory: string;
  setCurrentBrand: React.Dispatch<React.SetStateAction<Brand | null>>;
  setCurrentCategory: React.Dispatch<React.SetStateAction<string>>;
  tags: string[] | undefined;
  brandName: Brand;
  branch: subdomainTypes | '';
}

const GenericSubmenu = ({
  currentBrand,
  currentCategory,
  setCurrentBrand,
  setCurrentCategory,
  tags,
  brandName,
  branch,
}: Props) => {


  // canada not exist categories

  const generateSubMenuLink = (brand: Brand, category: string, branch: string) => {
    // Currently no products for the health and beauty section for CA webstore, this is only for Biz-corporates and Biz-collection
    const caCategories = ["health", "culottes", "knitwear", "outerwear", "advatex", "accessories", "rococo", "siena suiting", "fabric packs"]
    // Currently no products for the health and beauty section for CA webstore, this is only for Syzmik
    const caCategoriesSyzmik = ["tees", "vests", "overalls", "fire-armour", "clearance", "catalogue"]
    const link = `/products/${brand}?tag=${category}&page=1`;

    if (category === "pink products") {
      if (branch === "au") {
        return (
          <Link
            className={
              brand === currentBrand && category === currentCategory
                ? "selected"
                : ""
            }
            to={link}
            onClick={() => {
              setCurrentBrand(null);
              setCurrentCategory(category);
            }}
          >
            <span style={{ textTransform: "capitalize" }}>
              {transformName(category)}
            </span>
          </Link>
        );
      }
      return (<></>)

    }

    if ((branch === "ca" && caCategoriesSyzmik.includes(category) && brand === "syzmik")) {
      return (
        <></>
      );
    }

    if (!(branch === "ca" && caCategories.includes(category))) {
      return (
        <Link
          className={
            brand === currentBrand && category === currentCategory
              ? "selected"
              : ""
          }
          to={link}
          onClick={() => {
            setCurrentBrand(null);
            setCurrentCategory(category);
          }}
        >
          <span style={{ textTransform: "capitalize" }}>
            {transformName(category)}
          </span>
        </Link>
      );
    }
  };

  const generateSubmenu = (): JSX.Element | JSX.Element[] => {
    const list = tags ? (
      tags.map((category, index) => (
        <div key={index} role="menuitem">
          {generateSubMenuLink(brandName, category, branch)}
        </div>
      ))
    ) : (
      <></>
    );

    return list;
  };

  return <>{generateSubmenu()}</>;
};

export default GenericSubmenu;
