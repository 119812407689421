import { useContext } from "react";
import { Context, IContext } from "../../context/ContextApp";
import { getStates } from "./states";

interface Props {
  onChange: any;
  selectedValue: string;
  role?: string;
}

const StateSelector = (props: Props) => {
  const { onChange, selectedValue } = props;
  const { appBranch }: IContext = useContext(Context);

  return (
    <select onChange={onChange} value={selectedValue} data-testid="state-select" role="select">
      {getStates(appBranch).map((option, index) => (
        <option key={index} value={option.value}>
          {option.name}
        </option>
      ))}
    </select>
  );
};

export default StateSelector;
