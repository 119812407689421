import { SVGProps } from "react"
const PhotoVideo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 198.8 159.2"
    {...props}
  >
    <style>{'.photoVideoIcon{fill:#FFFFFF}'}</style>
    <path
    d="M69.6 159.2H11c-2.5 0-4.8-.7-6.9-2.2-2.9-2.2-4.1-5.1-4.1-8.7C0 119.2.1 90.1.1 61c0-7.2 4.1-11.2 11.4-11.2h116.7c4.4 0 7.9 1.9 9.9 5.9.7 1.3.9 3 .9 4.6.1 8.6 0 17.2 0 25.9v61.9c0 2.9-.5 5.5-2.6 7.7-2.2 2.4-5 3.5-8.2 3.5-19.6-.1-39.1-.1-58.6-.1zm59.6-45.7c.1-.1.3-.2.4-.2V65.5c0-1.3 0-2.7-.1-4-.1-1.9-.1-1.9-2-1.9H10.9c-1 0-1.5.1-1.5 1.3v67.2c0 .3.1.7.1 1.3.7-.4 1.2-.7 1.6-1l22.2-16.8c.3-.2 1-.3 1.4-.1 2.8 1.3 5.5 2.7 8.2 4.1 4.8 2.4 9.6 4.8 14.4 7.3.9.5 1.7.6 2.6-.1 3.7-2.8 7.5-5.6 11.3-8.4 8.8-6.7 17.7-13.3 26.5-20 .7-.5 1.2-.9 2.2-.3 8.5 5.8 17 11.5 25.6 17.2 1.3.7 2.5 1.5 3.7 2.2zm-59.6 35.8h58c2 0 2 0 2-2v-21.2c0-.4-.3-.9-.6-1.1-8.6-5.7-17.3-11.4-25.9-17.1-1.1-.7-2.2-1.9-3.3-1.9-1.1.1-2.2 1.3-3.3 2.1-11.9 8.9-23.7 17.7-35.6 26.6-.6.4-1 .5-1.7.1l-6-3c-5.6-2.8-11.1-5.6-16.6-8.4-1-.5-1.7-.4-2.6.3-8 5.9-16 11.7-24 17.6-.4.3-.7.9-.7 1.4-.1 1.7 0 3.4 0 5.1 0 1.7 0 1.7 1.6 1.7 19.6-.2 39.2-.2 58.7-.2z"
    className="photoVideoIcon"
    />
    <path
    d="M79.1 39.7H39.9v-3.5c0-8.7.1-17.4 0-26.1 0-5.4 3-8.9 8.4-9.7 1.1-.3 2.3-.4 3.4-.4h136c4.1 0 7.7 1.3 10 4.9.5.9.9 2 1 3 .2 2.1.1 4.2.1 6.3v84.6c0 6.5-4 10.5-10.5 10.5h-37.4c-.4 0-.7 0-1.3-.1v-9.8h8.5c1.2 0 1.5-.3 1.4-1.6-.1-4.4 0-8.9 0-13.3V15.6c0-1.6-.1-3.2 0-4.8 0-.8-.3-1.1-1-1H81c-1.8 0-1.8 0-1.8 1.8v27.1c-.1.3-.1.6-.1 1zm89.7 15v13.6c0 1.1.3 1.4 1.4 1.4 5.7-.1 11.4 0 17.1 0 2.1 0 2.1 0 2.1-2.1V41c0-.9-.3-1.3-1.2-1.3H170c-.9 0-1.1.3-1.1 1.2-.1 4.6-.1 9.2-.1 13.8zM59.5 29.9h9.2c.7 0 1.1-.1 1.1-1-.1-5.9-.1-11.8-.1-17.6 0-1-.2-1.3-1.2-1.3H50.3c-.8 0-1.1.3-1.1 1.1v17.7c0 1 .4 1.3 1.3 1.2 3.1-.2 6.1-.1 9-.1zm119.6 0h9.2c.7 0 1.1-.1 1.1-.9-.1-6-.1-11.9-.1-17.9 0-.9-.3-1.2-1.1-1.2h-18.3c-.7 0-1 .3-1 1v17.8c0 .9.3 1.2 1.2 1.2 3-.1 6 0 9 0zm0 69.6h9.2c.7 0 1.1-.1 1.1-.9-.1-6-.1-11.9-.1-17.9 0-.9-.3-1.2-1.2-1.2h-18.3c-.7 0-1 .3-1 1v17.8c0 .9.3 1.2 1.2 1.2h9.1zM49.3 84.5c.3 7.1-5.6 14.6-14.5 14.8-8 .2-14.7-6.7-14.8-14.7-.1-9.1 6.9-14.8 14.6-14.9 8.3 0 15.1 7.1 14.7 14.8zm-14.6 5.1c2.8.1 5.2-2.2 5.1-4.9-.1-2.9-1.8-4.9-4.9-5-3-.1-5.4 2-5.5 4.9 0 2.7 2.2 4.9 5.3 5z"
    className="photoVideoIcon"
    />
  </svg>
)
export default PhotoVideo
