import { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { generateTokenAuthHeader } from "../../commonfunctions/authentication";
import { log } from "../../commonfunctions/logger";
import { transformBrandToUserReadable } from "../../commonfunctions/text-transform";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import Paginator from "../../components/Paginator/Paginator";
import { Container } from "../../components/styled/styled-components";
import Toast from "../../components/Toast/Toast";
import { useDocumentTitle } from "../../hooks/document-title";
import { AlertType } from "../../interfaces/enums";
import {
  AlertContent,
  ProductListAPIResponse
} from "../../interfaces/interfaces";
import ProductCard from "./ProductCard/ProductCard";
import { Context, IContext } from "../../context/ContextApp";

const ProductList = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<ProductListAPIResponse>();
  const [toast, setToast] = useState<AlertContent | null>(null);
  const location = useLocation();
  const params = useParams<{ brand: string; slug: string }>();
  useDocumentTitle(`Browse ${transformBrandToUserReadable(params.brand)} products`);
  const { appBranch }: IContext = useContext(Context);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname, location.search]);

  useEffect(() => {
    let isMounted = true;
    const fetchProducts = async () => {
      setIsLoading(true);

      try {
        const res = await fetch(
          `${process.env.REACT_APP_HONEYBEE_ROOT_API}/products/${appBranch}/${location.search}&brand=${params.brand}&caller=webstore`,
          generateTokenAuthHeader()
        );
        if (res.ok) {
          const data = await res.json();
          if (isMounted) {
            if (data) {
              setProducts(data);
              setIsLoading(false);
            }
          }
        } else {
          const error = await res.text();
          if (isMounted) {
            throw error;
          }
        }
      } catch (error) {
        if (isMounted) {
          setIsLoading(false);
          log(error, "Failed to load products");
          setToast({
            message: "Failed to load products.",
            type: AlertType.Error
          });
        }
      }
    };

    fetchProducts();

    return () => {
      isMounted = false;
    };
  }, [location.search, params.brand, appBranch]);

  return (
    <div className="product-list-page">
      {isLoading ? (
        <div className="spinner-wrapper">
          <LoadingSpinner />
        </div>
      ) : products ? (
        <>
          <Paginator products={products} />
          <Container row>
            {products &&
              products.products.map((product, index) => {
                if (!product.slug.includes("-promo")) {
                  return <ProductCard product={product} key={index} />;
                } else return <></>;
              })}
          </Container>
          <Paginator products={products} />
        </>
      ) : (
        <Toast content={toast} onClick={() => setToast(null)} />
      )}
    </div>
  );
};

export default ProductList;
