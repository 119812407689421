import { subdomainTypes } from "../context/availableSubdomains";

export const formatDateWithSlashes = (eta: string, appBranch: subdomainTypes | '') => {
  if (!eta) {
    return "";
  }

  let formattedETADate = eta;
  if (formattedETADate === "Pending ETA") {
    formattedETADate = "Pending";
  } else if (appBranch === "nz" || appBranch === "au") {
    const a = formattedETADate.split("-");
    formattedETADate = a[2] + "-" + a[1] + "-" + a[0];
  } else if (appBranch === "ca") {
    // For some reason, dates when returned for CA are in a really messed up order, so we have to edit the order (YYYY-MM-DD) -> (MM/DD/YYYY)
    const a = formattedETADate.split("-");
    formattedETADate = a[1] + "-" + a[2] + "-" + a[0];
  }

  formattedETADate = formattedETADate.replaceAll("-", "/");

  return formattedETADate;
};

interface ETA {
  eta: string;
  quantity: number;
}

export const sortETADates = (etas: ETA[], appBranch: subdomainTypes | '') => {
  const regex = new RegExp(/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/);
  const sortedDates = etas
    .slice()
    .filter(
      (eta: ETA) =>
        eta.eta &&
        (regex.test(eta.eta) ||
          eta.eta === "Pending ETA" ||
          eta.eta === "Pending")
    );

  sortedDates.sort((a: ETA, b: ETA) => {
    const date1 = formatDateWithSlashes(a.eta, appBranch).split("/").reverse().join("/");
    const date2 = formatDateWithSlashes(b.eta, appBranch).split("/").reverse().join("/");
    if (date1 > date2) {
      return 1;
    } else return -1;
  });

  const formattedDates = sortedDates.map((a: ETA) => ({
    ...a, eta: formatDateWithSlashes(a.eta, appBranch),
  }));

  return formattedDates;
};
