import { SVGProps } from "react"
const ExclamationSquare = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 138.8 139.3"
    {...props}
  ><style>{'.exclamationSquare{fill:#FF0000}'}</style>
    <path
    d="M138.7 69.5v54.1c0 5.3-1.9 9.5-6.1 12.7-3 2.3-6.4 3-10.1 3H15.7c-4.1 0-7.8-1.1-10.8-3.9-3.4-3.1-5-6.9-5-11.5 0-33.3 0-66.5.1-99.8 0-3.3.1-6.6.1-10C.1 7 5.6 1.7 11.8.5c1.6-.3 3.3-.5 5-.5h105.9c5.8 0 10.5 2.1 13.8 7 1.4 2 2.1 4.2 2.2 6.5.1 7.4.1 14.8.1 22.1 0 11.3-.1 22.6-.1 33.9zm-69.3 59.9h54.4c3.2 0 5.3-2 5.4-5.2.1-1.8.1-3.6.1-5.3V23.6c0-2.7.1-5.5 0-8.2-.1-2.1-.8-4-2.9-4.9-1-.4-2.2-.6-3.3-.6H14.6c-3 .1-5 2-5.2 5-.1 1.4-.1 2.9-.1 4.4v104.1c0 1 .1 2 .5 2.9 1 2.5 3.1 3.1 5.6 3.1h54z"
    className="exclamationSquare"
    />
    <path
    d="M69.5 29.8c1.4.1 2.9.1 4.3.4 1.8.3 3 2.1 2.9 3.8l-1.2 22.8c-.5 9.8-1 19.5-1.4 29.3-.1 1.9-.7 2.8-2.5 3.1-1.5.2-3 .1-4.5-.1-1.4-.2-2-1.2-2.1-2.6-.2-3.1-.5-6.1-.7-9.2-.3-4-.3-8.2-.5-12.3-.3-7.2-.6-14.3-1-21.5-.2-3-.5-5.9-.6-8.9-.1-3.1 1.3-4.6 4.4-4.7h2.9v-.1zM61.1 104.3c-.5-4.9 4.1-8.3 8.3-8.4 4.6-.1 8.3 3.6 8.4 8 .2 5.2-3.9 9.1-8.2 9.1-4.5 0-8.9-3.7-8.5-8.7z"
    className="exclamationSquare"
    />
  </svg>
)
export default ExclamationSquare
