import React, { useState } from "react";
import CartItem from "../../../components/CartItem/CartItem";
import IconButton from "../../../components/IconButton/IconButton";
import { StyledButton } from "../../../components/styled/styled-components";
import { FetchedOrderUploadProduct } from "../OrderUploader";
import { sortProducts } from "../util";
import Icon from "../../../components/Icon/icon";

interface Props {
  products: FetchedOrderUploadProduct[];
  reloadItemDetails: (indexInCSVFile: number) => Promise<void>;
  removeFetchedItem: (indexInCSVFile: number) => void;
  csvEachLineArray: string[][];
}

interface Sort {
  ascending: boolean;
  type: string;
}

const ImportTable = ({
  products,
  reloadItemDetails,
  removeFetchedItem,
  csvEachLineArray,
}: Props) => {
  const [sort, setSort] = useState<Sort>({
    ascending: true,
    type: "index",
  });

  const sortedProducts = sortProducts(products);
  const invalidProducts = sortedProducts.filter(
    (product: FetchedOrderUploadProduct) => !product.price || !product.itemNo
  );
  const validProducts = sortProducts(
    sortedProducts.filter(
      (product: FetchedOrderUploadProduct) => product.price && product.itemNo
    ),
    sort
  );

  return (
    <table className="import-table">
      <tbody>
        {invalidProducts.length > 0 && (
          <tr>
            <th className="table-heading failed">Unimported items</th>
          </tr>
        )}
        {invalidProducts.map((product, index: number) => {
          const sku = csvEachLineArray[parseInt(product.index)][0];
          return (
            <tr key={index} className="reload-order-item cart-item">
              <td>{`${parseInt(product.index) + 1}`}</td>
              <td colSpan={7}>
                <StyledButton
                  onClick={() => reloadItemDetails(parseInt(product.index))}
                >
                  Reload
                </StyledButton>{" "}
                or{" "}
                <StyledButton
                  danger
                  onClick={() => removeFetchedItem(parseInt(product.index))}
                >
                  Remove
                </StyledButton>{" "}
                product SKU {sku}. (Error Code 2)
              </td>
            </tr>
          );
        })}
        {validProducts.length > 0 && (
          <>
            <tr>
              <th className="table-heading">Imported items</th>
            </tr>
            <tr>
              <th style={{ textAlign: "center" }}>Line</th>
              <th>Description</th>
              <th style={{ textAlign: "center" }}>Color</th>
              <th style={{ textAlign: "center" }}>Size</th>
              <th style={{ textAlign: "center" }}>Reference</th>
              <th style={{ textAlign: "center" }}>Qty</th>
              <th style={{ textAlign: "center" }}>
                Available stock
                <IconButton
                  inline
                  style={{ marginLeft: "1em" }}
                  onClick={() =>
                    setSort({ ascending: !sort.ascending, type: "stock" })
                  }
                >
                  {sort.ascending ? (
                    <Icon name="ArrowUp" />
                    ) : (
                    <Icon name="ArrowDown" />
                  )}
                </IconButton>
              </th>
              <th style={{ textAlign: "center" }}>Price</th>
              <th style={{ textAlign: "center" }}>Amount</th>
            </tr>
          </>
        )}
        {validProducts.map((product, index: number) => (
          <CartItem
            key={index}
            item={product}
            index={`${parseInt(product.index) + 1}`}
            showStock
          />
        ))}
      </tbody>
    </table>
  );
};

export default ImportTable;
