import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { OrderItem } from "../../interfaces/interfaces";
import { CurrentOrder } from "../../redux/reducers/orders";
import { RootState } from "../../redux/store";
import AuthenticationHOC from "../AuthenticationHOC/AuthenticationHOC";
import CartItemTable from "../CartItemTable/CartItemTable";

interface Props {
  slug: string;
}

const CartItemChecker = (props: Props) => {
  const [cart, setCart] = useState<OrderItem[]>([]);
  const currentOrder = useSelector(
    (state: RootState) => state.orders.currentOrder
  );
  const { slug } = props;

  useEffect(() => {
    const checkItemInCart = (currentOrder: CurrentOrder) => {
      const matchingItems = currentOrder
        ? currentOrder.order_items.filter((item) => item.product === slug)
        : [];

      setCart(matchingItems);
    };

    checkItemInCart(currentOrder);
  }, [slug, currentOrder]);

  if (cart.length > 0) {
    return (
      <>
        <h3>Items in your cart with this product code: </h3>
        <CartItemTable items={cart} action />
      </>
    );
  } else return <></>;
};

export default AuthenticationHOC(CartItemChecker);
