import { Brands } from "../interfaces/enums";

export const transformBrandToUserReadable = (
  brand: string | undefined
): string => {
  if (!brand) {
    return "N/A";
  }
  switch (brand) {
    case "biz-collection":
      return Brands.BizCollection;
    case "biz-corporates":
      return Brands.BizCorporates;
    case "biz-care":
      return Brands.BizCare;
    case "syzmik":
      return Brands.Syzmik;
    default:
      return brand;
  }
};
