import { FreightOption } from "../../../../interfaces/types";

export const formatFreightOption = (freightOption: FreightOption) => {
  const options = {
    by_air: "By Air",
    by_ground: "By Ground",
    pickup: "Pick Up",
    // pickup: "Third party pick up",
  };
  return options[freightOption];
};
