import { useHistory } from "react-router-dom";
import { StyledButton } from "../../../../../components/styled/styled-components";
import { Address } from "../../../../../interfaces/interfaces";

interface Props {
  currentOrderShipAddress: Address;
  hideModal: () => void;
  items: any;
}

const Pickup = ({ currentOrderShipAddress, hideModal, items }: Props) => {
  const history = useHistory();

  return (
    <div>
      <div>
        The following items can not be fulfilled from your main warehouse.
        <br />
        {items}
        <br />
        You may still collect the available stock from our warehouse, and we
        will ship the rest of your order to <br />
        <div
          style={{
            marginTop: "20px",
            color: "rgb(0,125,177)",
            fontWeight: "bold",
          }}
        >
          <div>{currentOrderShipAddress.address_line1}</div>
          <div>{currentOrderShipAddress.address_line2}</div>
          <div>{currentOrderShipAddress.address_line3}</div>
          <div>{currentOrderShipAddress.address_line4}</div>
          <div>{currentOrderShipAddress.address_city}</div>
          <div>
            {currentOrderShipAddress.state ??
              currentOrderShipAddress.address_state}
          </div>
          <div>{currentOrderShipAddress.address_postcode}</div>
        </div>
      </div>
      <StyledButton success onClick={() => hideModal()}>
        Yes, I am OK with it.
      </StyledButton>
      <StyledButton warning onClick={() => history.go(0)}>
        No, I will modify my order now.
      </StyledButton>
    </div>
  );
};

export default Pickup;
