import axios from "axios";
import { useState } from "react";
import { log } from "../../../../commonfunctions/logger";
import sortSizes from "../../../../commonfunctions/size-sorter";
import { AlertType } from "../../../../interfaces/enums";
import { ProductColor } from "../../../../interfaces/interfaces";
import { Branch } from "../../../../interfaces/types";
import Alert from "../../../Alert/Alert";
import AuthenticationHOC from "../../../AuthenticationHOC/AuthenticationHOC";
import LoaderButton from "../../../LoaderButton/LoaderButton";
import { ColorSizeStock } from "../../interfaces";

interface Props {
  generateAuthHeader: any;
  col: number;
  color: ProductColor;
  branch: Branch;
}

const OverseaStock = (props: Props) => {
  const { generateAuthHeader, col, color, branch } = props;
  const [shouldDisplayLoadingStockError, setShouldDisplayLoadingStockError] =
    useState<boolean>(false);
  const [isLoadingStock, setIsLoadingStock] = useState<boolean>(false);
  const [colorSizeStocks, setColorSizeStocks] = useState<ColorSizeStock[]>([]);

  const getStock = (color: any) => {
    setIsLoadingStock(true);
    let skus = "";

    if (color.sizes.length > 0) {
      for (let i = 0; i < color.sizes.length; i++) {
        skus += "&skus[]=" + color.sizes[i].sku;
      }
    }
    const url = `${process.env.REACT_APP_HALFBACK_ROOT_API}/orders/current_order/items/sizes_detail_by_skus?branch=${branch}&color_id=${color.id}${skus}`;
    axios
      .get(url, generateAuthHeader())
      .then((response) => {
        if (response.data.items) {
          const sortedStock = sortSizes(response.data.items);
          setColorSizeStocks(sortedStock);
          setIsLoadingStock(false);
        } else {
          setIsLoadingStock(false);
          setShouldDisplayLoadingStockError(true);
        }
      })
      .catch((error) => {
        log(error, `Failed to load stock for ${url}`);
        setIsLoadingStock(false);
        setShouldDisplayLoadingStockError(true);
      });
  };

  const renderStockLevel = () => {
    if (colorSizeStocks.length) {
      const sizes = colorSizeStocks.map((size: any, index: number) => (
        <td key={index} style={{ paddingLeft: "10px", minWidth: "60px" }}>
          {getTotalStockForSize(size)}
        </td>
      ));
      return (
        <tr>
          <th style={{ whiteSpace: "nowrap" }}>
            <span style={{ textTransform: "uppercase" }}>{branch}</span> Stock
          </th>{" "}
          {sizes}
        </tr>
      );
    }
  };

  const getTotalStockForSize = (size: ColorSizeStock) => {
    if (size) {
      if (size.stock) {
        let total = 0;
        for (let i = 0; i < size.stock.length; i++) {
          total += size.stock[i].qtyAvailable;
        }
        return <div>{total}</div>;
      }
    }
    return <div>N/A</div>;
  };

  return (
    <>
      {color && (
        <>
          {colorSizeStocks.length === 0 ? (
            <tr>
              <td colSpan={col + 1}>
                {shouldDisplayLoadingStockError ? (
                  <Alert
                    content={{
                      message:
                        "Failed to check stock. Please contact our customer support team for stock availablity.",
                      type: AlertType.Error
                    }}
                  />
                ) : (
                  <LoaderButton
                    onClick={() => getStock(color)}
                    isLoading={isLoadingStock}
                  >
                    <span>
                      Check{" "}
                      <b style={{ textTransform: "uppercase" }}>{branch}</b>{" "}
                      stock
                    </span>
                  </LoaderButton>
                )}
              </td>
            </tr>
          ) : (
            renderStockLevel()
          )}
        </>
      )}
    </>
  );
};

export default AuthenticationHOC(OverseaStock);
