import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Context, IContext } from "../../../context/ContextApp";
import { useDocumentTitle } from "../../../hooks/document-title";
import { RootState } from "../../../redux/store";
import ShippingOptionsAU from "./AU/ShippingOptions";
import ShippingOptionsCA from "./CA/ShippingOptions";
import ShippingOptionsNZ from "./NZ/ShippingOptions";

const BaseShippingPage = () => {
  useDocumentTitle('Checkout - Select shipping address')
  const extraInfo = useSelector((state: RootState) => state.orders.extraInfo);
  const history = useHistory();
  const { push } = history;
  const { appBranch }: IContext = useContext(Context);

  useEffect(() => {
    if (!extraInfo || !extraInfo.po_number) {
      push("/");
    }
  }, [push, extraInfo]);

  switch (appBranch) {
    case "nz":
      return <ShippingOptionsNZ />;
    case "au":
      return <ShippingOptionsAU />;
    case "ca":
      return <ShippingOptionsCA />;
    default:
      return <h1>Please make sure the branch is set.</h1>;
  }
};

export default BaseShippingPage;
