import axios from "axios";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { log } from "../../commonfunctions/logger";
import { updateCurrentOrder } from "../../redux/actions/orders";
import { setLoginStatus } from "../../redux/actions/userSession";
import AuthenticationHOC from "../AuthenticationHOC/AuthenticationHOC";

interface Props {
  generateAuthHeader: () => any;
  setIsTokenChecked: () => void;
}

const TokenChecker = ({ generateAuthHeader, setIsTokenChecked }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { push } = history;

  // useEffect(() => {
  //   if (location.pathname.includes("reset_password")) {
  //     setIsTokenChecked();
  //     return;
  //   }
  // }, [location.pathname, setIsTokenChecked]);

  useEffect(() => {
    const redirectToLogin = () => {
      dispatch(setLoginStatus(false));
      push("/");
      setIsTokenChecked();
    };

    const getCart = async () => {
      const req_url = `${process.env.REACT_APP_HALFBACK_ROOT_API}/orders/current_order`;
      axios.get(req_url, generateAuthHeader()).then((response) => {
        dispatch(updateCurrentOrder(response.data));
      });
    };

    const checkToken = () => {
      const tokenForCheck = getTokenForCheck();

      if (!tokenForCheck) {
        redirectToLogin();
        return;
      }

      const req_url = `${process.env.REACT_APP_HALFBACK_ROOT_API}/session/check_jwt?jwt=${tokenForCheck}`;

      axios
        .get(req_url)
        .then((response) => {
          if (
            response.data &&
            response.data.status === "success" &&
            response.data.customer_data.allow_webstore
          ) {
            dispatch(setLoginStatus(true, response.data.customer_data));
            if (process.env.BRANCH === "ca") {
              push("/store/welcome");
            }

            getCart();
            setIsTokenChecked();
          } else {
            redirectToLogin();
          }
        })
        .catch((error) => {
          log(error, "Failed to verify JWT token");
          redirectToLogin();
        });
    };
    if (location.pathname.includes("reset_password")) {
      setIsTokenChecked();
      return;
    }
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, push, generateAuthHeader, setIsTokenChecked]);

  return <span />;
};

const getTokenForCheck = () => {
  if (localStorage.getItem("webstore_admin") == "true") return localStorage.getItem("customer_jwt");
  if (localStorage.getItem("webstore_admin") == "false") return localStorage.getItem("jwt");
};

export default AuthenticationHOC(TokenChecker);
