import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// https://github.com/Hacker0x01/react-datepicker/blob/main/docs/datepicker.md

interface Props {
  selectedDate?: Date;
  minDate?: Date;
  maxDate?: Date;
  onChange: (date: Date) => void;
}

const DatePicker = (props: Props) => {
  const { selectedDate = new Date(), minDate, maxDate, onChange } = props;
  return (
    <ReactDatePicker
      className="date-picker"
      dateFormat="dd/MM/yyyy"
      disabledKeyboardNavigation={true}
      selected={selectedDate}
      minDate={minDate}
      maxDate={maxDate}
      onChange={onChange}
    />
  );
};

export default DatePicker;
