import { SVGProps } from "react"
const BoxOpen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 188.6 138.9"
    {...props}
  >
    <style>{'.boxIcon{fill:#FFFFFF}'}</style>
    <path
    d="M18.9 80.2V48.8c0-3 .2-2.3-2.4-3.2-4.1-1.3-8.2-2.5-12.3-3.8C.2 40.5-.9 37.9.9 34c2.5-5.2 4.8-10.4 7.2-15.7 2.4-5.1 4.8-10.3 7.2-15.4C16.4.6 18.3-.4 21 0c10 1.3 20 2.7 30.1 4C61 5.4 70.9 6.7 80.8 8c4 .5 8 1.3 12 1.5 3.1.2 6.2-.2 9.3-.6 8.6-1.1 17.3-2.2 25.9-3.4 8.9-1.2 17.7-2.4 26.6-3.6L168.4.1c2.4-.3 3.7.4 4.8 2.7 2.1 4.3 4.1 8.6 6.1 12.9 2.9 6.2 5.7 12.4 8.6 18.6 1.2 2.6 1 4.8-.7 6.2-.7.6-1.6 1-2.5 1.3L170.9 46c-.8.2-1.1.6-1.1 1.5.1 20.4.1 40.8.1 61.1 0 1.5-.1 3-.3 4.5-.6 4.4-3.1 7.3-7.3 8.6-5.2 1.5-10.5 2.9-15.7 4.2-12.3 3.2-24.6 6.5-36.9 9.7-4.1 1.1-8.3 2-12.4 3-3 .8-5.9.1-8.7-.6-18.5-4.8-36.9-9.6-55.4-14.4-2.8-.7-5.7-1.5-8.4-2.6-3.8-1.7-5.8-4.8-5.8-9V80.3c0-.1-.1-.1-.1-.1zm80-62.5c-.1.8-.2 1.2-.2 1.6 0 1.9-.1 3.9-.1 5.8 0 34.1-.1 68.1-.1 102.2 0 .8.2 1.2 1.1 1 .3-.1.6-.1 1-.2l29.7-7.8c9.5-2.5 19.1-5 28.6-7.5 1.3-.3 1.8-.8 1.8-2.2-.1-6.3 0-12.6 0-18.9v-41c0-.7.2-1.5-1.1-1.1-3.3 1-6.7 2-10 2.9-7.5 2.2-14.9 4.4-22.4 6.5-2.7.8-4.2.1-5.6-2.3-6.3-11.1-12.7-22.1-19-33.2-1.1-1.9-2.3-3.7-3.7-5.8zm-9.2.6h-.4c-.1.2-.3.4-.4.6-6 10.3-11.9 20.6-17.9 31-1.4 2.4-2.7 4.8-4.2 7.2-1.3 2.1-2.8 2.6-5.1 1.9L32 50.3c-1.3-.4-2.7-.8-4.1-1.2 0 .6-.1.9-.1 1.3 0 20 .1 39.9 0 59.9 0 1.4.4 2 1.8 2.4 7.7 2 15.3 4.1 22.9 6.1 7.4 2 14.8 3.9 22.2 5.8 4.5 1.2 9 2.3 13.5 3.4 1.5.4 1.5.3 1.5-1.3V20.3c.1-.7 0-1.4 0-2zm19.9-.4c.5.8.8 1.2 1.1 1.7 2.2 3.8 4.3 7.6 6.5 11.3 3 5.3 6.1 10.6 9 15.9 1.1 1.9 1.2 2.1 3.4 1.5 14.9-4.5 29.9-8.9 44.8-13.4 1.1-.3 2.2-.7 3.3-1.1-.8-1.7-1.5-3.2-2.2-4.8-2.9-6-5.8-12.1-8.7-18.1-.3-.6-.6-1.1-1.4-.9-2.5.4-5.1.8-7.6 1.1-3.6.5-7.2 1.1-10.8 1.6-5.9.8-11.9 1.6-17.8 2.4-3.8.5-7.6 1.1-11.4 1.6-2.7.4-5.3.8-8.2 1.2zM10.8 33.7c.4.2.7.3 1 .4 5.5 1.6 11.1 3.2 16.6 4.8 10.3 3.1 20.5 6.2 30.8 9.3 1.9.6 1.9.6 3.2-1.7l15.6-27c.3-.5.6-1 1-1.7-2.6-.3-4.9-.6-7.3-.9-8.1-1.1-16.2-2.3-24.2-3.4-8.1-1.2-16.2-2.3-24.3-3.5-.8-.1-1.1.2-1.5.9-1.7 3.8-3.5 7.6-5.3 11.4-1.8 3.8-3.7 7.5-5.6 11.4z"
    className="boxIcon"
    />
  </svg>
)
export default BoxOpen
