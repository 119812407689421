import { StyleSheet, View } from "@react-pdf/renderer";
import { formatToCostString } from "../../../../../commonfunctions/cost-calculation";
import TitleAndDetailLine from "../../../components/TitleAndDetailLine/TitleAndDetailLine";

const styles = StyleSheet.create({
  summary: {
    marginLeft: "auto",
    marginRight: 20,
    width: "15%",
  },
});

interface Props {
  transactionDetails: any;
  credit?: boolean;
}

const TransactionSummary = (props: Props) => {
  const { transactionDetails, credit } = props;

  if (credit) {
    const { balance } = transactionDetails;
    const { sub_total, total_misc, less_disc, gst, total } = balance;
    return (
      <View style={styles.summary}>
        <TitleAndDetailLine line={["Subtotal", formatToCostString(sub_total)]} />
        <TitleAndDetailLine line={["Total misc", formatToCostString(total_misc)]} />
        <TitleAndDetailLine line={["Less disc", formatToCostString(less_disc)]} />
        <TitleAndDetailLine line={["G.S.T", formatToCostString(gst)]} />
        <TitleAndDetailLine line={["Total", formatToCostString(total)]} />
      </View>
    );
  } else return (
    <View style={styles.summary}>
      <TitleAndDetailLine line={["Sales tax", formatToCostString(transactionDetails.salesTax)]} />
      <TitleAndDetailLine line={["Total", formatToCostString(transactionDetails.total)]} />
    </View>
  );
};

export default TransactionSummary;
