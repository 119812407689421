import { useState } from "react";
import { StyledButton } from "../../../components/styled/styled-components";
import EditAddressBook from "./EditAddress/EditAddressBook";
import NewAddress from "./NewAddress/NewAddress";

const AddressBookContainer = () => {
  const [editMode, setEditMode] = useState<boolean>(true);

  return (
    <div className="preferences-sub-section">
      <div className="title">
        <h5>Address Book</h5>
        <StyledButton primary onClick={() => setEditMode(!editMode)}>
          {editMode ? "Add a new address": "Edit an address"}
        </StyledButton>
      </div>
      <div className="main-content forms">
        {editMode ? (
          <EditAddressBook />
        ) : (
          <NewAddress setEditMode={setEditMode} />
        )}
      </div>
    </div>
  );
};

export default AddressBookContainer;
