import React, { useReducer, useState } from "react";
import AuthenticationHOC from "../AuthenticationHOC/AuthenticationHOC";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { StyledFormField, StyledFormLayout } from "../styled/styled-components";

const WAIT_INTERVAL = 700;

export interface Address {
  FullAddress: string;
  placeId?: string;
  // has other stuff but we don't use it
}

const initialState = {
  addresses: [],
  errorMessage: "",
  isLoading: false,
};

interface Action {
  type: string;
  addresses?: Address[];
}

interface Reducer {
  addresses: Address[];
  isLoading: boolean;
  errorMessage: string;
}

const reducer = (state: Reducer = initialState, action: Action) => {
  switch (action.type) {
    case "FETCH_START":
      return {
        errorMessage: "",
        addresses: [],
        isLoading: true,
      };
    case "LOAD_ADDRESSES":
      return {
        ...state,
        addresses: action.addresses ?? [],
        isLoading: false,
      };
    case "CLEAR_ADDRESSES":
      return {
        addresses: [],
        isLoading: false,
        errorMessage: "",
      };
    case "ADDRESS_NOT_FOUND":
      return {
        ...state,
        isLoading: false,
        errorMessage: "Could not find related addresses",
      };
    case "FETCH_FAIL":
      return {
        ...state,
        isLoading: false,
        errorMessage: "Something went wrong. Please try again",
      };
    default:
      return state;
  }
};

interface Props {
  setSearchValue: any;
  generateAuthHeader: any;
  clearForm: any;
  label?: string;
}

const AddressSearcher = (props: Props) => {
  const [timeoutID, setTimeoutID] = useState<number>();
  const [searchInput, setSearchInput] = useState<string>("");
  const [hideAddresses, setHideAddresses] = useState<boolean>(false);

  const [state, dispatch] = useReducer(reducer, initialState);
  const { isLoading, addresses, errorMessage } = state;

  const {
    setSearchValue,
    generateAuthHeader,
    clearForm,
    label = "Search address",
  } = props;

  const selectAddress = (address: Address) => {
    setSearchInput(address.FullAddress.charAt(0).toUpperCase() + address.FullAddress.slice(1));
    setSearchValue(address);
    setHideAddresses(true);
  };

  const callAPI = async (input: string) => {
    dispatch({ type: "FETCH_START" });
    const url = `${process.env.REACT_APP_HALFBACK_ROOT_API}/address_check?address_line_1=${input}`;

    try {
      const res = await fetch(url, generateAuthHeader());
      const data = await res.json();
      if (data.result.length && data.status === 'success') {
        dispatch({
          type: "LOAD_ADDRESSES",
          addresses: data.result,
        });
        setHideAddresses(false);
      } else {
        dispatch({ type: "ADDRESS_NOT_FOUND" });
      }
    } catch (error) {
      dispatch({ type: "FETCH_FAIL" });
    }
  };

  const searchAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    setSearchInput(input);
    clearForm();

    if (!input) {
      dispatch({ type: "CLEAR_ADDRESSES" });
      return;
    }

    const newTimeoutID = window.setTimeout(() => {
      callAPI(input);
    }, WAIT_INTERVAL);

    /* Clear old search */
    if (timeoutID) {
      window.clearTimeout(timeoutID);
    }

    /* Set new search timeout */
    setTimeoutID(newTimeoutID);
  };

  return (
    <StyledFormLayout>
      <StyledFormField>
        <label>{label}</label>
        <input
          aria-label="address-search-field"
          value={searchInput}
          onChange={searchAddress}
        />
      </StyledFormField>
      {isLoading ? (
        <LoadingSpinner />
      ) : errorMessage ? (
        <span>{errorMessage}</span>
      ) : (
        addresses.length > 0 &&
        !hideAddresses && (
          <div className="address-searcher">
            {addresses.map((address: Address, index) => (
              <StyledFormField key={index}>
                <div
                  className="address-suggestion"
                  role="option"
                  onClick={() => selectAddress(address)}
                >
                  {address.FullAddress}
                </div>
              </StyledFormField>
            ))}
          </div>
        )
      )}
    </StyledFormLayout>
  );
};

export default AuthenticationHOC(AddressSearcher);
