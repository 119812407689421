import cx from "classnames";
import { useContext, useEffect, useState } from "react";
import { generateAuthHeader } from "../../../commonfunctions/authentication";
import { log } from "../../../commonfunctions/logger";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import PDFDownloadButton from "../../../components/PDFDownloadButton/PDFDownloadButton";
import CreditNotePDF from "../../../components/PDFTemplates/Templates/TransactionPDF/CreditNotePDF";
import InvoicePDF from "../../../components/PDFTemplates/Templates/TransactionPDF/InvoicePDF";
import {
  Container,
  StyledButton,
} from "../../../components/styled/styled-components";
import {
  TransactionDetails,
  TransactionSummary,
} from "../../../interfaces/interfaces";
import { Context, IContext } from "../../../context/ContextApp";

interface Props {
  transaction: TransactionSummary;
}

const TransactionCard = ({ transaction }: Props) => {
  const [isPrintable, setIsPrintable] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPrintPopup, setShowPrintPopup] = useState<boolean>(false);
  const [transactionDetails, setTransactionDetails] =
    useState<TransactionDetails | null>(null);
  const { appBranch }: IContext = useContext(Context);

  useEffect(() => {
    if (transaction.type === "Invoice" || transaction.type === "CreditNote") setIsPrintable(true);
  }, [transaction.type]);

  const getTransactionDetail = async (t: TransactionSummary) => {
    setIsLoading(true);
    let url = `${process.env.REACT_APP_HALFBACK_ROOT_API}/current_customer/transactions/`;
    if (t.type === "Invoice") url += `invoice_detail?invoiceNo=${t.transactionNumber}`;
    if (t.type === "CreditNote") url += `credit_note_detail?creditNoteNo=${t.transactionNumber}`;

    try {
      const res = await fetch(url, generateAuthHeader());

      if (res.ok) {
        const data = await res.json();
        setTransactionDetails(data);
      } else {
        const error = await res.text();
        throw error;
      }
    } catch (error) {
      log(error, "Failed to load transaction details");
    } finally {
      setIsLoading(false);
    }
  };

  function renderPrinterPopup() {
    if (!showPrintPopup) return;

    if (isLoading) {
      return (
        <div className="popup-modal">
          <div className="popup-modal-content" style={{ display: "flex" }}>
            <LoadingSpinner />
          </div>
        </div>
      );
    } else {
      return (
        <div className="popup-modal">
          <div className="popup-modal-content">
            {transaction.type === "CreditNote" ? (
              <div>
                <h2>Credit Note</h2>
                <p>
                  Transaction No. <b>{transaction.transactionNumber}</b>
                </p>
                <p>
                  Order No. <b>{transaction.orderNo}</b>
                </p>
              </div>
            ) : (
              <div>
                <h2>Invoice</h2>
                <p>
                  Transaction No. <b>{transaction.transactionNumber}</b>
                </p>
                <p>
                  Order No. <b>{transaction.orderNo}</b>
                </p>
              </div>
            )}

            <div className="popup-modal-buttons">
              <Container>
                <PDFDownloadButton
                  document={
                    transaction.type === "Invoice" ? (
                      <InvoicePDF
                        transactionDetails={transactionDetails}
                        appBranch={appBranch}
                      />
                    ) : (
                      <CreditNotePDF
                        transactionDetails={transactionDetails}
                        appBranch={appBranch}
                      />
                    )
                  }
                >
                  <StyledButton>View</StyledButton>
                </PDFDownloadButton>
                <StyledButton
                  danger
                  style={{ marginTop: 13 }}
                  onClick={() => {
                    setShowPrintPopup(false);
                    setTransactionDetails(null);
                  }}
                >
                  Cancel
                </StyledButton>
              </Container>
            </div>
          </div>
        </div>
      );
    }
  }

  function handleOnClick() {
    setShowPrintPopup(true);
    getTransactionDetail(transaction);
  }

  return (
    <>
      <td
        className={cx({
          printableTransactionHeader: isPrintable,
        })}
        onClick={() => isPrintable && handleOnClick()}
      >
        {transaction.transactionNumber}
      </td>
      <td>{transaction.type}</td>
      <td>{transaction.date}</td>
      <td>{transaction.status}</td>
      <td>{transaction.orderNo}</td>
      <td>{transaction.debit}</td>

      {renderPrinterPopup()}
    </>
  );
};

export default TransactionCard;
