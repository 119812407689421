import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "./components/Header/Header";
import { Container } from "./components/styled/styled-components";
import TokenChecker from "./components/TokenChecker/TokenChecker";
import ContextApp from "./context/ContextApp";
import { useDocumentTitle } from "./hooks/document-title";
import AuthPage from "./pages/auth/AuthPage";
import BranchSelection from "./pages/branch-selection/BranchSelection";
import CartPage from "./pages/cart/CartPage";
import ExtraInfo from "./pages/check-out/extra-info/ExtraInfo";
import OrderReview from "./pages/check-out/order-review/OrderReview";
import BaseShippingPage from "./pages/check-out/shipping-selection/BaseShippingPage";
import MediaLibrary from "./pages/media-library/MediaLibrary";
import NotFound from "./pages/not-found/NotFound";
import OrderHistory from "./pages/order-history/OrderHistory";
import OrderUploader from "./pages/order-uploader/OrderUploader";
import Preferences from "./pages/preferences/Preferences";
import ProductDetail from "./pages/product/ProductDetail";
import ProductList from "./pages/products/ProductList";
import QuickOrder from "./pages/quick-order/QuickOrder";
import SavedOrders from "./pages/saved-orders/SavedOrders";
import Search from "./pages/search/Search";
import Transactions from "./pages/transactions/Transactions";
import { getSubdomain } from "./context/ContextApp";
import {
  setIsWebstoreAdmin,
  setOrganizationKey,
} from "./redux/actions/organization";
import {
  setIsWebstoreOrderEnabled,
  setLoginStatus,
} from "./redux/actions/userSession";
import { RootState } from "./redux/store";
import "./styles/index.scss";
import PaymentSelection from "./pages/check-out/payment-selection/PaymentSelection";
import MaintenanceMode from "./MaintencanceMode";

const App = () => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.userSession.isAuthenticated
  );
  const [isTokenChecked, setIsTokenChecked] = useState(false);
  const dispatch = useDispatch();
  const appBranch = getSubdomain();
  const isWebstoreOrderEnable =
    localStorage.getItem("webstore_order_enabled") === "true";
  const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === "true";

  useDocumentTitle(
    `Fashion Biz Webstore${appBranch ? ` ${appBranch.toUpperCase()}` : ""}`
  );

  useEffect(() => {
    if (self == top) {
      // Everything checks out, show the page.
      document.documentElement.style.display = "block";
    } else {
      // Break out of the frame.
      if (top != undefined) {
        top.location = self.location;
      }
    }
    try {
      const localStorageWebStoreAdmin = localStorage.getItem("webstore_admin");
      if (localStorageWebStoreAdmin) {
        dispatch(setIsWebstoreAdmin(JSON.parse(localStorageWebStoreAdmin)));
      }

      const localStorageOrganizationKey =
        localStorage.getItem("organization_key");
      if (localStorageOrganizationKey) {
        if (localStorageOrganizationKey === "null") {
          dispatch(setOrganizationKey(JSON.parse(localStorageOrganizationKey)));
        }
        dispatch(setOrganizationKey(localStorageOrganizationKey));
      }

      const localStorageWebStoreOrderEnabled = localStorage.getItem(
        "webstore_order_enabled"
      );
      if (localStorageWebStoreOrderEnabled) {
        dispatch(
          setIsWebstoreOrderEnabled(
            JSON.parse(localStorageWebStoreOrderEnabled)
          )
        );
      }
    } catch (error) {
      setLoginStatus(false);
    }
  }, [dispatch]);

  const setTokenCheckedIsTrue = useCallback(() => {
    setIsTokenChecked(true);
  }, []);

  if (isMaintenanceMode) {
    return <MaintenanceMode />;
  }

  return (
    <ContextApp>
      <Router>
        <TokenChecker setIsTokenChecked={setTokenCheckedIsTrue} />
        {!isTokenChecked ? (
          <div className="waiting-page" />
        ) : !isAuthenticated ? (
          <React.Fragment>
            <Switch>
              <Route
                path="/reset_password/:token?"
                render={() => <AuthPage reset />}
              />
              <Route exact path="/" component={AuthPage} />
            </Switch>
          </React.Fragment>
        ) : (
          <div id="app">
            <Header />
            <Container row colmobile style={{ height: "100%" }}>
              <div className="app-main-content">
                <Switch>
                  <Route exact path="/" component={CartPage} />
                  <Route exact path="/store/cart" component={CartPage} />
                  <Route
                    exact
                    path="/products/:brand"
                    component={ProductList}
                  />
                  <Route
                    exact
                    path="/products/:brand/:slug"
                    component={ProductDetail}
                  />
                  <Route exact path="/quick-order" component={QuickOrder} />
                  <Route exact path="/search" component={Search} />
                  {isWebstoreOrderEnable && (
                    <Route
                      exact
                      path="/check-out/extra-info"
                      component={ExtraInfo}
                    />
                  )}
                  <Route
                    exact
                    path="/check-out/shipping-selection"
                    component={BaseShippingPage}
                  />
                  <Route
                    exact
                    path="/check-out/payment-selection"
                    component={PaymentSelection}
                  />
                  <Route
                    exact
                    path="/check-out/order-review"
                    component={OrderReview}
                  />
                  <Route exact path="/saved-orders" component={SavedOrders} />
                  <Route exact path="/order-history" component={OrderHistory} />
                  <Route
                    exact
                    path="/branch-selection"
                    component={BranchSelection}
                  />
                  <Route exact path="/transactions" component={Transactions} />
                  <Route exact path="/preferences" component={Preferences} />
                  <Route
                    exact
                    path="/order-uploader"
                    component={OrderUploader}
                  />

                  <Route exact path="/media-library" component={MediaLibrary} />
                  <Route path="*">
                    <NotFound />
                  </Route>
                </Switch>
              </div>
            </Container>
          </div>
        )}
      </Router>
    </ContextApp>
  );
};

export default App;
