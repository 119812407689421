import { SVGProps } from "react"
const Save = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 138.8 139.3"
    {...props}
  >
    <style>{'.saveIcon{fill:#FFFFFF}'}</style>
    <path
    d="M.1 68.8V15.9c0-3.1.3-6.1 2.1-8.8 2.6-4 6.4-6.2 11-6.8 1.3-.2 2.6-.2 4-.2C43.6 0 70 0 96.4 0c4.9 0 9.1 1.5 12.7 5.1 8.4 8.5 17 16.9 25.5 25.4 2.8 2.9 4.3 6.3 4.3 10.4v83.4c0 8.8-6.5 15-15.6 15.1H16c-6.5 0-11.6-2.8-14.4-8.8-.9-2-1.4-4.4-1.4-6.6C0 105.5 0 87.1.1 68.8c-.1 0 0 0 0 0zM99.6 9.9c-.2.1-.4.1-.6.2V14c0 9.5 0 19-.1 28.5 0 1-.3 2-.7 2.8-1.5 3.2-4.3 4.3-7.7 4.3h-62c-5.7 0-8.8-3.2-8.8-8.9.1-9.9 0-19.9 0-29.8 0-.8-.3-1.1-1-1.1-1.5 0-3.1-.1-4.6 0-2.7.2-4.6 2.1-4.8 4.8-.1 1-.1 2-.1 3v105.9c0 2 .5 3.9 2.3 4.9 1.2.6 2.7.9 4 .9h108.9c2.2-.1 3.9-1.5 4.5-3.6.2-1 .3-2 .3-3.1 0-25.2 0-50.3.1-75.5v-5.8c0-1.5-.5-2.7-1.6-3.8-2.5-2.4-4.8-4.8-7.2-7.2-6-5.9-11.9-11.9-17.9-17.8-.8-.9-1.9-1.7-3-2.6zM59.5 39.8h27.9c2.2 0 2.2 0 2.2-2.2 0-8.8-.1-17.6 0-26.4 0-1.1-.3-1.4-1.4-1.4H30.6c-1 0-1.3.3-1.3 1.3v27.1c0 1.6 0 1.6 1.6 1.6h28.6z"
    className="saveIcon"
    />
    <path
    d="M69.6 116.7c-13.3.7-27.7-11.7-27.3-27.9.3-13.2 11.7-26.2 26.9-26.4 15.1-.2 27.5 12.8 27.5 27-.1 14.5-12.4 27.9-27.1 27.3zM51.9 89.8c-.5 9.1 8.4 17 16.9 17.3 9.8.3 18-8 18.3-16.6.4-10.1-8-17.9-16.8-18.4-9.5-.6-19.1 7.9-18.4 17.7z"
    className="saveIcon"
    />
  </svg>
)
export default Save
