import { Address } from "../../interfaces/interfaces";
import { FreightOption } from "../../interfaces/types";
import { CurrentOrder } from "../reducers/orders";

export function setCurrentOrderShipAddress(address: Address | null) {
  return {
    type: "SET_CURRENT_ORDER_SHIP_ADDRESS",
    payload: address,
  };
}

export function updateCurrentOrder(order: CurrentOrder) {
  return {
    type: "UPDATE_CURRENT_ORDER",
    payload: order,
  };
}

export function updateFreightOption(option: FreightOption | null) {
  return {
    type: "UPDATE_FREIGHT_OPTION",
    payload: option,
  };
}
export function setExtraInfo(info: any) {
  return {
    type: "UPDATE_EXTRA_INFO",
    payload: info,
  };
}
