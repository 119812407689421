import { Font } from "@react-pdf/renderer";
import RobotoBold from "./components/PDFTemplates/Roboto-Bold.ttf";
import RobotoLight from "./components/PDFTemplates/Roboto-Light.ttf";

export const registerFont = () => {
  Font.register({
    family: "Roboto",
    fonts: [
      {
        src: RobotoLight,
      },
      {
        src: RobotoBold,
        fontWeight: "bold",
      },
    ],
  });
};
