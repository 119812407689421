import cx from "classnames";
import React from "react";

interface Props {
  children: any;
  header?: string;
  text: string;
  isMultiChoice?: boolean;
  textColor?: string;
  backgroundColor?: string;
  isSelected?: boolean;
  onClick?: any;
  size?: string;
  role?: string;
}

const IconWrapper = (props: Props) => {
  const {
    children,
    header,
    text,
    isMultiChoice,
    textColor,
    backgroundColor,
    isSelected,
    onClick,
    role
  } = props;

  let { size } = props;

  size = size ? size : "2x";
  const iconRole = role || (onClick ? "button" : "figure");

  return (
    <div

      className={cx({
        "icon-wrapper": true,
        "is-selected": isSelected,
        "transparent-on-not-selected": isMultiChoice && !isSelected
      })}
      style={{ backgroundColor: backgroundColor ? backgroundColor : "white" }}
      onClick={() => {
        if (!isSelected && onClick) {
          onClick();
        }
      }}
      role={iconRole}
    >
      <div className="icon-wrapper-icon">
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement<any>(child, { size });
          }
          return child;
        })}
      </div>
      <div
        className="icon-wrapper-text"
        style={{ color: textColor ? textColor : "black" }}
      >
        {header && <div className="header">{header}</div>}
        {text && <div className="subtext">{text}</div>}
      </div>
    </div>
  );
};

export default IconWrapper;
