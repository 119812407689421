import { Text, View } from "@react-pdf/renderer";
import { formatToCostString } from "../../../../commonfunctions/cost-calculation";
import { OrderItem } from "../../../../interfaces/interfaces";
import { itemFormatStyles } from "../styles";

interface Props {
  item: OrderItem;
}

const ItemLine = (props: Props) => {
  const { item } = props;

  const {
    product_code,
    product_name,
    reference,
    color,
    size,
    quantity,
    price,
  } = item;

  return (
    <View style={itemFormatStyles.itemRow}>
      <Text
        style={{ ...itemFormatStyles.columnName, ...itemFormatStyles.column }}
      >
        {product_name}
      </Text>
      <Text
        style={{ ...itemFormatStyles.columnCode, ...itemFormatStyles.column }}
      >
        {product_code}
      </Text>
      <Text
        style={{
          ...itemFormatStyles.columnReference,
          ...itemFormatStyles.column,
        }}
      >
        {reference}
      </Text>
      <Text
        style={{ ...itemFormatStyles.columnColor, ...itemFormatStyles.column }}
      >
        {color}
      </Text>
      <Text
        style={{ ...itemFormatStyles.columnSize, ...itemFormatStyles.column }}
      >
        {size}
      </Text>
      <Text
        style={{
          ...itemFormatStyles.columnQuantity,
          ...itemFormatStyles.column,
        }}
      >
        {quantity}
      </Text>
      <Text
        style={{ ...itemFormatStyles.columnPrice, ...itemFormatStyles.column }}
      >{`${formatToCostString(price)}`}</Text>
      <Text
        style={{ ...itemFormatStyles.columnTotal, ...itemFormatStyles.column }}
      >{`${formatToCostString(parseFloat(price) * quantity)}`}</Text>
    </View>
  );
};

export default ItemLine;
