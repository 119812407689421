import { createContext } from 'react';
import { getAvailableSubdomain, subdomainTypes } from './availableSubdomains';

export const getSubdomain = () => {
  const subdomain = window.location.host.split('.')[0];

  return getAvailableSubdomain(subdomain);
}

export interface IContext {
  appBranch: subdomainTypes | ''
}

interface IContextAppProps {
  children?: string | JSX.Element | JSX.Element[] | (() => JSX.Element)
}

const defaultContext: IContext = {
  appBranch: ''
}

export const Context = createContext<IContext>(defaultContext);

const ContextApp = ({ children }: IContextAppProps) => {

  const appBranch = getSubdomain();

  return <Context.Provider value={{ appBranch }}>
    {children}
  </Context.Provider>

}

export default ContextApp;
