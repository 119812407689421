import { TransactionSummary } from "../../interfaces/interfaces";

export const transactionSearchTypes: TransactionSearchType[] = [
  "Search",
  "Date Time",
];

export const transactionTypes: TransactionType[] = [
  "All",
  "Invoice",
  "Credit",
  "Refund",
  "Prepay",
  "Receipt",
];

export type TransactionSearchType =
  | "Search"
  | "Date Time";

export type TransactionType =
  | "All"
  | "Invoice"
  | "Credit"
  | "Refund"
  | "Prepay"
  | "Receipt";

export enum TransactionResponseTypes {
  SUCCESS = "success",
  FAIL = "fail",
}

export interface IGetTransactions {
  type: TransactionResponseTypes;
  transactions?: TransactionSummary[];
  message?: string;
}
