import { StyleSheet, Text, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  line: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 5,
  },
  title: {
    fontWeight: "bold",
  },
  detail: {
    marginLeft: "auto",
  },
});

interface Props {
  line: string[];
}

const TitleAndDetailLine = (props: Props) => {
  const { line } = props;
  
  return (
    <View style={styles.line}>
      <Text style={styles.title}>{line[0]}</Text>
      <Text style={styles.detail}>{line[1] || "-"}</Text>
    </View>
  );
};

export default TitleAndDetailLine;
