import { SVGProps } from "react"
const ShippingFast = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 198.6 149"
    {...props}
  >
    <path d="M130 119.4H68.8c.1 1.5.3 2.9.4 4.4.7 9.9-6.8 20.7-16 23.8-15.4 5.3-29.8-4.5-32.7-18.2-.4-1.8-.5-3.7-.5-5.6V79.7h9.4v24.7c.7-.3 1.4-.6 1.9-.9 3.6-2.1 7.3-3.5 11.5-3.8 5-.4 9.8.5 14 3.2 2.4 1.6 4.6 3.7 6.8 5.6.7.6 1.3.9 2.2.9h52.4c1.2 0 1.4-.3 1.4-1.6-.1-5.1 0-10.2 0-15.3V18.9c0-1.1.1-2.2.1-3.4 0-3.3-2.2-5.5-5.4-5.7H35.7c-1.2 0-2.5.3-3.6.8-1.6.9-2.6 2.3-2.6 4.3 0 1.2 0 2.4-.1 3.6-.1 1 .3 1.3 1.3 1.3 2.7-.1 5.5 0 8.2 0h35.9c.9 0 1.9.1 2.8.2 1.1.1 1.6.7 1.6 1.8v5.6c0 1.1-.4 1.7-1.5 1.9-.6.1-1.3.3-2 .3H3.5c-.6 0-1.2-.1-1.7-.2-.9-.2-1.5-.8-1.5-1.7C.1 26 0 24.2 0 22.4c0-1.6.6-2.1 2.2-2.4.5-.1 1.1-.1 1.6-.1h14.9c1.1 0 1.3-.3 1.3-1.4-.1-2.3-.2-4.7.2-6.9C21.3 5.7 25.3 2.4 30.8.8c2-.5 4-.8 6-.8h75.9c6.3 0 11.5 2.4 14.7 8.1.8 1.5 1.4 3.3 1.4 5.1.2 5 .1 9.9.1 14.9 0 1.7 0 1.7 1.7 1.7h11.1c6 .1 11.3 2 15.6 6.4 8.4 8.6 17 17 25.5 25.5 3.8 3.8 5.9 8.2 5.9 13.6v32.1c0 1.9 0 1.9 1.9 1.9 2.1 0 4.2.1 6.3.2 1.2.1 1.8.8 1.7 2v5.5c0 1.1-.5 1.8-1.6 2-.7.1-1.3.2-2 .2h-16.8c1.8 8.9-.8 16.3-6.8 22.7-4.6 4.8-10.4 7-16.9 7-7.8 0-14.3-3-19.3-9-5-5.9-6.5-12.8-5.2-20.5zm24.1-39.9h-23.9c-1 0-1.4.3-1.3 1.3V106c0 3.4 0 3.3 3.3 3.4 1.5 0 2.5-.4 3.4-1.5 4.8-5.6 11.1-8.2 18.3-8.3 7.2-.1 13.5 2.5 18.3 8 1.3 1.5 2.6 2 4.5 1.9 2.5-.2 2.5 0 2.5-2.4 0-8.7-.1-17.3 0-26 0-1.3-.4-1.6-1.6-1.6h-23.5zm22.9-10c-.4-.6-.6-.9-.9-1.2l-3.8-3.8c-7.2-7.2-14.4-14.4-21.7-21.6-2.1-2.1-4.6-3.2-7.6-3.2h-12.8c-1 0-1.3.4-1.3 1.3v27.1c0 1.2.3 1.6 1.5 1.5h44.9c.5 0 .9 0 1.7-.1zM29.4 124.3c-.2 8 6.5 14.7 14.6 15 8.2.3 15.6-6.2 15.8-14.5.2-8.2-6.5-15.1-14.7-15.4-7.5-.4-15.9 5.8-15.7 14.9zm124.8 15c7.7.2 15-6.3 15.1-14.7.1-8.5-6.7-15.1-15.2-15.3-7.1-.1-15.4 5.8-15.2 15.3.2 8.1 6.9 14.7 15.3 14.7z" />
    <path d="M49.4 49.7h-37c-1.6-.2-2.2-.7-2.3-2.3-.1-1.6-.2-3.3-.1-5 .1-1.6.6-2.2 2.3-2.5.5-.1 1.1-.1 1.6-.1h70.8c4.2 0 4.4.2 4.4 4.4v3.5c0 .9-.5 1.5-1.3 1.7-.8.2-1.6.3-2.3.3H49.4zM39.4 69.6h-37C.8 69.4.2 68.9.1 67.3 0 65.6 0 63.9 0 62.2c.1-1.6.6-2.2 2.3-2.5.5-.1 1.1-.1 1.6-.1h70.8c4.2 0 4.4.2 4.4 4.4v3.5c0 .9-.5 1.5-1.3 1.7-.8.2-1.6.3-2.3.3-12.1.1-24.1.1-36.1.1z" />
  </svg>
)
export default ShippingFast
