import { SVGProps } from "react"
const SearchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 158.8 159.2"
    {...props}
  >
    <path d="M111.6 108.4h2.1c1.7 0 3.2.6 4.5 1.8 12.5 12.6 25 25.1 37.4 37.7.9.9 1.7 1.8 2.5 2.8 1 1.3.9 2.5-.1 3.6-1.2 1.3-2.4 2.5-3.7 3.7-1.8 1.6-2.7 1.6-4.5-.2-3-2.9-5.9-5.9-8.8-8.8-10-10-20.1-19.9-30.2-29.8-1.7-1.7-2.7-3.5-2.4-5.9.1-.9-.4-.9-1-.6-.6.4-1.1.8-1.6 1.2-8.9 7.9-19.4 12.5-31.1 14.5-6.1 1-12.2.8-18.3.2-12.8-1.2-23.7-6.5-33.4-14.5-8-6.7-14-14.9-18.2-24.5C.9 80.7-.2 71.4 0 61.8.2 55 1.4 48.5 3.7 42.3c3.3-9 8.5-16.8 15.3-23.6C28.5 9.1 39.9 3.2 53.3 1 59.7-.1 66.1-.3 72.6.5c10.6 1.4 20.4 4.8 29 11.2 10.6 7.9 18.8 17.7 23.4 30.3 3.6 10 4.7 20.2 3.5 30.6-1.6 13.4-7 25.1-16.2 35-.3.2-.4.4-.7.8zM9.3 63.6c-1 29.8 24.1 54.9 53.4 56 29.1 1 56.1-23.2 56.8-53.3C120.2 35 95.1 11 67 9.7 36 8.3 9.3 33.4 9.3 63.6z" />
  </svg>
)
export default SearchIcon
