import React, { useContext } from "react";
import { Context, IContext } from "../../context/ContextApp";
import { StyledFormField } from "../styled/styled-components";
import AddressSearcher, { Address } from "./AddressSearcher";
import AddressStateOptionSelector from "./AddressStateOptionSelector";
import { Field, getAddressFormFields } from "./fields";

interface Props {
  onChange: any;
  form: any;
  clearForm?: () => void;
}

const FieldGenerator = (props: Props) => {
  const { form, onChange, clearForm } = props;
  const { appBranch }: IContext = useContext(Context);

  const getTag = (field: Field, defaultValue: string) => {
    switch (field.type) {
      case "input-disabled":
      case "input":
        return (
          <div role="input">
            <label>{field.name}</label>
            <input
              name={field.key}
              disabled={field.type === "input-disabled"}
              value={defaultValue}
              aria-label={field.key}
              onChange={(event) => {
                onChange(event, field.key);
              }}
            />
          </div>
        );
      case "select":
        return (
          <>
            <label>{field.name}</label>
            <AddressStateOptionSelector
              selectedValue={form.state}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                onChange(event, "state")
              }
            />
          </>
        );
      case "fixed":
        return (
          <div role="fixed">
            <label>{field.name}</label>
            <span>{field.fixedValue}</span>
          </div>
        );
      case "search":
        return (
          <div role="search">
            <AddressSearcher
              role="search"
              label={field.name}
              clearForm={() => clearForm && clearForm()}
              setSearchValue={(address: Address) =>
                onChange(address, field.key)
              }
            />
          </div>
        );

      default:
        return (
          <>
            <label>{field.name}</label>
            <input
              onChange={(event) => {
                onChange(event, field.key);
              }}
            />
          </>
        );
    }
  };
  return (
    <>
      {getAddressFormFields(appBranch).map((field, index) => {
        const defaultValue = form[field.key];
        return (
          <StyledFormField key={index}>
            {getTag(field, defaultValue)}
          </StyledFormField>
        );
      })}
    </>
  );
};

export default FieldGenerator;
