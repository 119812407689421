import { SVGProps } from "react"
const Print = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 158.7 159.2"
    {...props}
  >
    <style>{'.printIcon{fill:#FFFFFF}'}</style>
    <path
    d="M79.3 159.2H36c-.5 0-1.1 0-1.6-.1-3.1-.5-4.6-2.4-4.5-5.9 0-8.3.1-16.5.1-24.8 0-2.5-.1-5.1-.1-7.6 0-1.2-.4-1.5-1.5-1.5H6c-.5 0-1 0-1.5-.1-3.1-.5-4.6-2.4-4.5-5.9 0-8.3.1-16.5.1-24.8 0-2.7-.1-5.5.3-8.2.8-5.4 3.3-9.9 7.2-13.7 5-4.9 11.2-6.7 18-7h3.5c.7 0 .9-.3.9-1 0-6.6-.1-13.1-.1-19.7 0-9.6.1-19.2 0-28.7C29.8 4.1 34.6 0 40.3 0h54.2c3.1 0 5.7 1.2 7.9 3.4 5.3 5.3 10.6 10.5 15.9 15.8l7.3 7.3c2.2 2.1 3.3 4.6 3.2 7.7-.1 8 0 15.9-.1 23.9 0 1.7 0 1.8 1.7 1.7 4-.1 7.9.1 11.6 1.5 7.8 2.9 13 8.3 15.6 16.2.9 2.6 1 5.4 1 8.1 0 9.5 0 18.9-.1 28.4 0 3.6-2 5.5-5.6 5.5H130c-1 0-1.4.2-1.4 1.3v33.1c0 3.5-2 5.4-5.5 5.4h-20.2c-7.7-.1-15.6-.1-23.6-.1zm.1-49.7h67.4c2.4 0 2.5 0 2.5-2.4 0-7.3.1-14.6.1-21.9 0-9.5-7.7-15.7-15.8-15.7-36.3.2-72.6.1-108.9.1-5.2 0-9.2 2.3-12.4 6.3-2.3 2.9-2.8 6.4-2.8 10v21.9c0 1.8 0 1.8 1.8 1.8 22.6-.1 45.4-.1 68.1-.1zm0-49.8h39.3c.7 0 .9-.3.9-1-.1-5.8-.2-11.7-.1-17.5 0-1.3-.5-1.5-1.6-1.5h-17.7c-6.1 0-10.6-4.6-10.6-10.6V11.2c0-1.1-.3-1.4-1.3-1.4H40.5c-.9 0-1.2.3-1.2 1.2v47.2c0 1.2.4 1.5 1.6 1.5h38.5zm-.1 89.6h37.9c2.2 0 2.2 0 2.2-2.2 0-8.8-.1-17.6 0-26.4 0-1.1-.4-1.4-1.4-1.4H40.5c-1 0-1.3.3-1.3 1.3v27c0 1.7 0 1.7 1.6 1.7h38.5zm35.9-119.4c-.1.1-.2.1-.4.2-5.1-5.4-10.3-10.7-15.6-16.2-.1.6-.2 1-.2 1.4 0 4.5 0 9-.1 13.4 0 1 .4 1.3 1.3 1.3h13.7c.5-.1.9-.1 1.3-.1z"
    className="printIcon"
    />
    <path
    d="M124.3 96.8c-4.1 0-7.4-3.3-7.3-7.3 0-3.8 3.1-6.9 6.8-7.1 4.5-.2 7.8 3.1 7.7 7.1-.1 4.3-3.5 7.4-7.2 7.3z"
    className="printIcon"
    />
  </svg>
)
export default Print
