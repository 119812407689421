import { SVGProps } from "react"
const TrashRed = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 138.7 159.3"
    {...props}
  >
    <style>{'.trashIcon{fill:#ff5235}'}</style>
    <path
    d="M18.4 20h14.8c1.1 0 1.8-.4 2.5-1.3 3.3-4.4 6.7-8.8 10-13.2C48.2 2.2 51.6.8 55.5.2c1.4-.2 2.8-.2 4.2-.3 6.9 0 13.8-.1 20.8 0C83 0 85.7.4 88 1.3c1.9.7 3.6 2.3 4.9 3.9 3.5 4.2 6.7 8.6 9.9 12.9.9 1.3 2 1.7 3.6 1.7 9.3-.1 18.7 0 28 0 4.2 0 4.5.2 4.4 4.4v3.4c0 1.1-.5 1.8-1.5 1.9-1.7.2-3.5.3-5.2.2-1.2 0-1.6.4-1.7 1.6-.8 9.1-1.6 18.2-2.4 27.4-.8 9-1.5 18.1-2.3 27.1-.5 5.8-1.1 11.5-1.6 17.3-.7 8.3-1.4 16.6-2.2 24.9-.6 6.3-1 12.7-2 18.9-.9 5.8-4.5 9.7-10.1 11.4-1.8.6-3.8.8-5.8.8-23 .1-46.1 0-69.1 0-9.2 0-15.4-5.7-16.2-14.9l-2.1-22.8c-.8-9.4-1.5-18.7-2.3-28.1-.6-6.5-1.2-13-1.8-19.6-.7-8.5-1.4-16.9-2.2-25.4-.7-5.7-1.3-11.7-1.9-17.6-.1-.6-.3-.9-.9-.9H3c-2.1 0-2.8-.6-2.9-2.7-.1-1.5-.1-3-.1-4.5.1-1.7.7-2.3 2.3-2.5.5-.1 1-.1 1.5-.1 4.9-.1 9.7 0 14.6 0zm51 9.8H19.5c-1.2 0-1.6.3-1.5 1.5.5 5.6.9 11.2 1.4 16.8.8 9.3 1.8 18.6 2.6 28 .8 9.2 1.5 18.3 2.3 27.5.7 8 1.4 16 2.2 24 .5 5.4 1 10.7 1.5 16.1.4 4.1 2.2 5.6 6.3 5.6h70.6c3.4 0 5.4-1.9 5.7-5.2.5-5.3.9-10.6 1.4-16l1.5-15c.5-5.9.9-11.9 1.4-17.8.5-5.4 1.1-10.7 1.6-16.1.5-5.7.9-11.4 1.4-17.1.5-5.6 1.1-11.2 1.6-16.8.4-4.6.7-9.2 1.1-13.7.2-1.8.2-1.8-1.6-1.8H69.4zm22.9-9.9c-2.3-2.9-4.4-5.6-6.5-8.2-1-1.2-2.4-1.7-4-1.7H56.9c-1.6 0-2.9.6-3.9 1.8-1.1 1.3-2.1 2.5-3.1 3.8-1.1 1.4-2.1 2.7-3.2 4.3h45.6z"
    className="trashIcon"
    />
  </svg>
)
export default TrashRed
