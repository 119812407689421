import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { generateAuthHeader } from "../../../../../../commonfunctions/authentication";
import LoadingSpinner from "../../../../../../components/LoadingSpinner/LoadingSpinner";
import { Address } from "../../../../../../interfaces/interfaces";

interface Props {
  updateShippingAddressInRedux: (a: Address | null) => void;
}

interface AddressPrimary {
  shipToName: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressLine4: string;
  city: string;
  state: string;
  country: string;
  postCode: string;
  locationCode: string;
  description: string;
}

const PrimaryAddress = ({ updateShippingAddressInRedux }: Props) => {
  const history = useHistory();
  const { push } = history;
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedAddress, setSelectedAddress] = useState<AddressPrimary | null>(
    null
  );
  const [primaryAddresses, setPrimaryAddresses] = useState<AddressPrimary[]>(
    []
  );

  const setShipAddress = useCallback(
    (address) => {
      const shipAddressFormatted = address
        ? {
          is_primary_address: true,
          address_city: address.city,
          address_contact: null,
          address_country: address.country,
          address_fax: null,
          // No clue why but halfback returns camelCase instead of snake_case
          address_line1: address.addressLine1,
          address_line2: address.addressLine2,
          address_line3: address.addressLine3,
          address_line4: address.addressLine4,
          address_phone: null,
          address_postcode: address.postCode,
          ship_code: address.locationCode,
          ship_to_name: address.shipToName,
          state: address.state,
        }
        : null;

      updateShippingAddressInRedux(shipAddressFormatted);
    },
    [updateShippingAddressInRedux]
  );

  useEffect(() => {
    let isMounted = true;

    const loadPrimaryAddresses = () => {
      axios
        .get(
          `${process.env.REACT_APP_HALFBACK_ROOT_API}/ship_to_addresses/primary`,
          generateAuthHeader()
        )
        .then((response) => {
          if (isMounted) {
            if (response.status === 200) {
              setIsLoading(false);
              setErrorMessage("");
              setPrimaryAddresses(response.data.result);
              setSelectedAddress(response.data.result[0]);
              setShipAddress(response.data.result[0]);
            } else if (response.status === 401) {
              alert("Your session is expired. Please login again.");
              push("/");
            }
          }
        })
        .catch(() => {
          if (isMounted) {
            setIsLoading(false);
            setErrorMessage("Sorry, we can not load your primary addresses.");
          }
        });
    };

    loadPrimaryAddresses();

    return () => {
      isMounted = false;
    };
  }, [push, setShipAddress]);

  const onAddressSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const index: number = parseInt(event.target.value);
    if (typeof index === "number") {
      const address = primaryAddresses[index];

      setSelectedAddress(address);
      setShipAddress(address);
    }
  };

  const renderAddressSelector = () => {
    if (primaryAddresses.length > 0) {
      return (
        <select className="form-control" onChange={onAddressSelect}>
          {primaryAddresses.map((address, index) => {
            if (address.locationCode.indexOf("3RDP") === -1) {
              return (
                <option value={index} key={index}>
                  {address.locationCode} {address.description}
                </option>
              );
            }
            return null;
          })}
        </select>
      );
    }
  };

  const renderErrorMessage = () => errorMessage && <div>{errorMessage}</div>;

  const renderAddressDetail = () => primaryAddresses.length > 0 ? (
    <div className="addressTableWrapper">
      <table className="addressTable">
        <tbody>
          <tr>
            <th>Select Primary Address</th>
            <td>{renderAddressSelector()}</td>
          </tr>
          {selectedAddress && (
            <>
              <tr>
                <th>Ship To Name</th>
                <td>{selectedAddress.shipToName}</td>
              </tr>
              <tr>
                <th>Ship Address Line 1</th>
                <td>{selectedAddress.addressLine1}</td>
              </tr>
              {selectedAddress.addressLine2 && (
                <tr>
                  <th>Ship Address Line 2</th>
                  <td>{selectedAddress.addressLine2}</td>
                </tr>
              )}
              {selectedAddress.addressLine3 && (
                <tr>
                  <th>Ship Address Line 3</th>
                  <td>{selectedAddress.addressLine3}</td>
                </tr>
              )}
              {selectedAddress.addressLine4 && (
                <tr>
                  <th>Ship Address Line 4</th>
                  <td>{selectedAddress.addressLine4}</td>
                </tr>
              )}
              <tr>
                <th>City</th>
                <td>{selectedAddress.city}</td>
              </tr>
              <tr>
                <th>State</th>
                <td>{selectedAddress.state}</td>
              </tr>
              <tr>
                <th>Country</th>
                <td>{selectedAddress.country}</td>
              </tr>
              <tr>
                <th>Post Code</th>
                <td>{selectedAddress.postCode}</td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </div>
  ) : (
    <span>No primary addresses found.</span>
  );

  return (
    <div>
      {renderErrorMessage()}
      {isLoading ? <LoadingSpinner /> : renderAddressDetail()}
    </div>
  );
};

export default PrimaryAddress;
