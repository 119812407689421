import { useDispatch, useSelector } from "react-redux";
import IconWrapper from "../../../../components/IconWrapper/IconWrapper";
import { Container } from "../../../../components/styled/styled-components";
import { Address } from "../../../../interfaces/interfaces";
import { FreightOption } from "../../../../interfaces/types";
import { updateFreightOption } from "../../../../redux/actions/orders";
import { RootState } from "../../../../redux/store";
import Icon from "../../../../components/Icon/icon";

const FreightOptions = () => {
  const freightOption: FreightOption = useSelector(
    (state: RootState) => state.orders.freightOption
  );

  const currentOrderShipAddress: Address = useSelector(
    (state: RootState) => state.orders.currentOrderShipAddress
  );

  const dispatch = useDispatch();

  const setFreightOption = (freightOption: FreightOption) => {
    dispatch(updateFreightOption(freightOption));
  };

  if (
    currentOrderShipAddress.state?.toUpperCase() === "NI" ||
    parseInt(currentOrderShipAddress.address_postcode.charAt(0)) < 7
  ) {
    return (
      <Container row margin="1em 1em 1em 0">
        <IconWrapper
          text="Road"
          isSelected={freightOption === "by_ground"}
          onClick={() => setFreightOption("by_ground")}
        >
          <Icon name="ShippingFast" />
        </IconWrapper>
      </Container>
    );
  } else if (
    currentOrderShipAddress.state?.toUpperCase() === "SI" ||
    parseInt(currentOrderShipAddress.address_postcode.charAt(0)) > 6
  ) {
    return (
      <Container row margin="1em 1em 1em 0">
        <IconWrapper
          text="Road"
          isSelected={freightOption === "by_ground"}
          onClick={() => setFreightOption("by_ground")}
          isMultiChoice
        >
          <Icon name="ShippingFast" />
        </IconWrapper>
        <div style={{ marginLeft: "1rem" }}>
          <IconWrapper
            text="Air"
            isSelected={freightOption === "by_air"}
            onClick={() => setFreightOption("by_air")}
            isMultiChoice
          >
            <Icon name="PlaneArrival" />
          </IconWrapper>
        </div>
      </Container>
    );
  } else return <></>;
};

export default FreightOptions;
