import { Link } from "react-router-dom";
import CartItemTable from "../../../../components/CartItemTable/CartItemTable";
import { StyledButton } from "../../../../components/styled/styled-components";
import { OrderSplitAPIResponse } from "../../../../interfaces/interfaces";
import {
  getPaymentDetails,
  PaymentDetailsId,
  IDetail,
} from "../../../../utils/OrderPaymentDetails";
import { Context, IContext } from "../../../../context/ContextApp";
import { useContext } from "react";

interface Props {
  splitAPIResponse: OrderSplitAPIResponse;
  isOrderAvaiableToCCPayment: boolean;
}

const CartInformation = ({
  splitAPIResponse,
  isOrderAvaiableToCCPayment,
}: Props) => {
  const { appBranch }: IContext = useContext(Context);
  const paymentDetails = getPaymentDetails(
    splitAPIResponse.current_order,
    appBranch
  );

  const isEnablePaymentLink = (paymentDetail: IDetail) => {
    if (!isOrderAvaiableToCCPayment) {
      return false;
    }
    const isTransactionFee =
      paymentDetail.id === PaymentDetailsId.transactionFee;
    const isBankTransferAndSubtotal =
      splitAPIResponse.current_order.payment_method === "bank_transfer" &&
      paymentDetail.id === PaymentDetailsId.grandTotal;
    return isTransactionFee || isBankTransferAndSubtotal;
  };

  return (
    <div className="cart-information">
      <div className="order-list">
        <CartItemTable
          items={splitAPIResponse.current_order.order_items}
          bo
          split
          checkout
        />
        <div role="group" className="final-row">
          <Link replace to="/store/cart">
            <StyledButton>Edit cart</StyledButton>
          </Link>
          <table className="total-costs">
            <tbody>
              {paymentDetails.map((paymentDetail) => (
                <tr key={`paymentDetail_${paymentDetail.title}`}>
                  {isEnablePaymentLink(paymentDetail) ? (
                    <th>
                      <Link replace to="/check-out/payment-selection">
                        Edit Payment Method
                      </Link>
                    </th>
                  ) : (
                    <th />
                  )}

                  <th role="rowheader">
                    {paymentDetail.boldTitle ? (
                      <b>{paymentDetail.title}</b>
                    ) : (
                      paymentDetail.title
                    )}
                    :
                  </th>
                  <td role="cell">{paymentDetail.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {
          // TODO: hide this for phase 2 of CC Payment
        }
        {isOrderAvaiableToCCPayment && false && (
          <div className="payment-info">
            NOTE: The total for this order <u>excludes</u> any items on back
            order.
          </div>
        )}
      </div>
    </div>
  );
};

export default CartInformation;
