import { SVGProps } from "react"
const ShoppingCartBlack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 178.8 159"
    {...props}
  >
    <path
    d="M54.6 99.4c.8 4.5 1.7 8.8 2.5 13.1.4 2 .8 3.9 1.1 5.9.1.8.4 1.1 1.2 1h89.8c1.5 0 3.1.3 4.6.7 1.9.6 3.1 2.1 3.8 3.9.4 1 1.1 1.9 1.9 2.7 6.1 6.7 5.8 19-1.3 26.4-9.1 9.5-27.6 7.4-32.7-7.9-1.7-5-1.4-10.1 1.2-14.8.1-.2.2-.4.3-.7v-.5H81.4c.6 1.4 1.3 2.6 1.7 3.8 2.1 6.1 1.1 11.9-2.3 17.2-3.9 6-9.8 8.8-16.8 8.5-7.9-.3-13.7-4.2-17.2-11.2-2.2-4.5-2.3-9.2-1.2-13.9.6-2.7 2-5.1 3.9-7.1.6-.6.7-1.2.5-2.1-1-4.7-1.8-9.4-2.7-14.1-1.2-6.1-2.3-12.1-3.5-18.2-.9-5-1.8-9.9-2.7-14.9-1.6-8.4-3.1-16.8-4.7-25.3-1.5-7.9-3-15.9-4.5-23.8-1.1-5.7-2.2-11.5-3.2-17.2-.2-1-.6-1.2-1.5-1.2H5.3C1.1 9.9 0 8.8 0 4.8V3.6C.1 1.6.9.6 2.9.2 3.7.1 4.4 0 5.2 0H29c4.8 0 7.7 2.6 8.5 7.3.6 3.9 1.4 7.7 2.1 11.6.1.4.6.8 1 .9.5.2 1.1 0 1.6 0h128.5c3.5 0 6.1 1.5 7.7 4.7.7 1.5.5 3.1.1 4.6-1 4.6-2 9.1-2.9 13.7-2 9.6-3.9 19.1-5.9 28.7-1.5 7.2-3.1 14.3-4.5 21.5-.7 3.4-4 6.3-8.3 6.3H58.2c-1.2.1-2.4.1-3.6.1zM41.4 29.9c.2.7.4 1.4.6 2l3 16.2c1.2 6.4 2.3 12.9 3.6 19.3 1.3 6.7 2.6 13.4 3.8 20.1.4 2 .3 2 2.4 2h100.5c.9 0 1.3-.4 1.4-1.4.5-2.8 1.1-5.5 1.7-8.3.8-3.9 1.7-7.8 2.5-11.8.8-4 1.6-8 2.5-12 .9-4.1 1.7-8.3 2.6-12.4.5-2.5 1.1-4.9 1.6-7.4.4-2.1.8-4.2 1.2-6.4-42.6.1-84.9.1-127.4.1zm23.1 119.3c4.8.3 10.4-4.1 10.3-10-.1-5.9-5.3-10-10.4-10-5.4.1-10.1 4.8-10.1 10.2 0 5.2 4.9 9.8 10.2 9.8zm79.7 0c4.6.2 10.3-4 10.2-9.9 0-5.8-5.3-10.1-10.4-10-5.4.1-10.1 4.8-10 10.3.1 5 4.9 9.6 10.2 9.6z"
    />
  </svg>
)
export default ShoppingCartBlack
