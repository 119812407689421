export const generateAuthHeader = (): any => ({
  headers: {
    "X-Jwt-Token": localStorage.getItem("jwt"),
    "Access-Control-Allow-Origin": "*",
  },
});

export const generateTokenAuthHeader = (): any => ({
  headers: {
    Authorization: `Token ${process.env.REACT_APP_HONEYBEE_TOKEN}`,
  },
});
