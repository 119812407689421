import { useContext } from "react";
import { sortETADates } from "../../../../commonfunctions/date";
import { subdomainTypes } from "../../../../context/availableSubdomains";
import { Context, IContext } from "../../../../context/ContextApp";
import { ColorSizeStock, ETA } from "../../interfaces";

interface Props {
  size: ColorSizeStock;
}

const BackOrderInfo = (props: Props) => {
  const { size } = props;
  const { appBranch }: IContext = useContext(Context);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      {size.backOrderQty ? (
        <div style={{ color: "red", paddingLeft: 30 }}>{size.backOrderQty}</div>
      ) : (
        <br />
      )}
      {etaDateTransform(size.eta, appBranch)}
    </div>
  );
};

const etaDateTransform = (etaDates: ETA[], appBranch: subdomainTypes | '') => {
  const isExistEta = etaDates && etaDates.length > 0;
  if (!isExistEta) {
    return "N/A";
  }

  const sortedDates = sortETADates(etaDates, appBranch);

  const stockDates = sortedDates.map((etaObject: any, index: number) => {
    if (etaObject.eta) {
      return (
        <div
          style={{
            display: "inline-block",
            whiteSpace: "nowrap",
            fontWeight: "lighter",
            letterSpacing: -1,
            fontSize: "0.9em"
          }}
          className="small"
          key={index}
          role="listitem"
        >
          <span style={{ display: "block" }}>{etaObject.eta}</span>
          <span style={{ color: "green" }}>{`  ${etaObject.quantity}`}</span>
        </div>
      );
    }
  });

  return stockDates;
};

export default BackOrderInfo;
