import { SVGProps } from "react"
const Check = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 137.9 104.4"
    {...props}
  >
    <style>{'.checkIcon{fill:#008000}'}</style>
    <path
      d="M137.9 7.4c-.6 1-1 1.9-1.7 2.6-2.4 2.5-4.8 5-7.2 7.4-27.1 27.1-54.3 54.2-81.4 81.4-1.5 1.5-3 2.9-4.6 4.4-1.8 1.7-3.2 1.7-4.9 0-11-10.9-21.9-21.9-32.9-32.8-1.4-1.4-2.7-2.8-4-4.2-1.6-1.7-1.5-3.5.2-5.1L5 57.8c2-1.8 2.9-1.8 4.7.1 4.8 4.8 9.6 9.7 14.5 14.5l14.7 14.7c1.7 1.6 1.7 1.6 3.3 0 9.1-9 18.2-18 27.2-27.1 15-14.9 30-29.9 45-44.8l13.5-13.5c1.9-1.9 2.8-1.9 4.7-.2 1.2 1.1 2.5 2.2 3.6 3.4.7.5 1.1 1.5 1.7 2.5z"
      className="checkIcon"
    />
  </svg>
)
export default Check
