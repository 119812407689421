import React, { useContext } from "react";
import { AlertType, SalesStatus } from "../../../interfaces/enums";
import {
  AlertContent,
  ProductColor,
  ProductDetailResponse,
} from "../../../interfaces/interfaces";
import { StyledNumberInput } from "../../styled/styled-components";
import { ColorSizeStock } from "../interfaces";
import BackOrderInfo from "./BackOrderInfo/BackOrderInfo";
import OverseaStock from "./OverseaStock/OverseaStock";
import { Context, IContext } from "../../../context/ContextApp";
import AUStock from "../Stocks/AUStock";

interface Props {
  shouldRenderSizeDetail: boolean;
  currentSelectedColor: ProductColor;
  currentColorSizeStocks: ColorSizeStock[];
  setCurrentColorSizeStocks: React.Dispatch<
    React.SetStateAction<ColorSizeStock[]>
  >;
  itemPrice: string | undefined;
  setActionStatus: React.Dispatch<React.SetStateAction<AlertContent | null>>;
  product: ProductDetailResponse;
  inputRefs: any;
  setInputRefs: any;
}

const SizeDetailTable = (props: Props) => {
  const {
    shouldRenderSizeDetail,
    currentSelectedColor,
    currentColorSizeStocks,
    itemPrice,
    setActionStatus,
    product,
    setCurrentColorSizeStocks,
    inputRefs,
    setInputRefs,
  } = props;

  const { appBranch }: IContext = useContext(Context);
  const qtyAvailableSalesStatus = [
    SalesStatus.Discontinued,
    SalesStatus.LimitedStock,
    SalesStatus.Clearance,
  ];
  const backOrderSalesStatus = [
    SalesStatus.Clearance,
    SalesStatus.Discontinued,
  ];
  const isStockListMode = localStorage.getItem("stocklist_mode") === "true";

  const handleSizeQuantityFocus = (event: any) => {
    event.target.select();
  };

  const handleSizeQuantityBlur = (event: any, size: ColorSizeStock) => {
    if (!event.target.value) {
      size.quantity = 0;
      event.target.value = 0;
      size.errorMessage = "";
    }
  };

  const calculateStock = (singleSize: ColorSizeStock) => {
    let sizeTotalQty = 0;
    singleSize.stock.forEach((st) => {
      sizeTotalQty += st.qtyAvailable;
    });
    if (sizeTotalQty < 0) sizeTotalQty = 0;
    return sizeTotalQty;
  };

  const renderStockLevel = (size: ColorSizeStock[]) => (
    <>
      <tr>
        <td>
          <b style={{ whiteSpace: "nowrap" }}>
            <span style={{ textTransform: "uppercase" }}>{appBranch}</span>{" "}
            Stock
          </b>
        </td>
        {size.map((size: ColorSizeStock) => (
          <td key={size.sku + "qty"} style={{ minWidth: "60px" }}>
            {calculateStock(size)}
          </td>
        ))}
      </tr>
    </>
  );

  const renderBackOrderRow = () => {
    if (product.slug.indexOf("clearance") < 0) {
      return (
        <tr>
          <td
            style={{
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            B/O:
            <br />
            <span className="small">ETA/Qty</span>:
          </td>
          {currentColorSizeStocks.map((size: ColorSizeStock) => (
            <td key={size.sku + "input"}>{<BackOrderInfo size={size} />}</td>
          ))}
        </tr>
      );
    }
  };

  const renderInput = (size: ColorSizeStock) => {
    const stockAvailable = size.stock.reduce(
      (previousValue, st) => previousValue + st.qtyAvailable,
      0
    );

    /* We don't know exactly if this rule need it */
    // let clearance = false;
    // if (product.slug.indexOf("clearance") > -1) {
    //   clearance = true;
    // }
    // if (clearance && stockAvailable <= 0) {
    //   return <div style={{ paddingTop: "7px" }}>N/A</div>;
    // }

    // if (product.sales_status === "discountinued" && stockAvailable <= 0) {
    //   return <div style={{ paddingTop: "7px" }}>N/A</div>;
    // }
    const isExistEta = size?.eta && size.eta.length > 0;
    if (
      stockAvailable <= 0 &&
      (!isExistEta || product.sales_status === SalesStatus.Clearance)
    ) {
      return <div style={{ paddingTop: "7px" }}>N/A</div>;
    }

    return (
      <StyledNumberInput
        style={{
          textAlign: "center",
          margin: "auto",
        }}
        type="number"
        min="0"
        onBlur={(event: React.FocusEvent<HTMLInputElement>) =>
          handleSizeQuantityBlur(event, size)
        }
        onFocus={(event: React.FocusEvent<HTMLInputElement>) =>
          handleSizeQuantityFocus(event)
        }
        value={inputRefs[size.sku] ?? 0}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          onQuantityInputChange(event, size)
        }
      />
    );
  };

  const onQuantityInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    size: ColorSizeStock
  ) => {
    size.errorMessage = "";
    size.backOrderQty = 0;
    const refKey = size.sku;
    let value = parseInt(event.target.value);

    let stockAvailable = 0;
    size.stock.forEach((st) => {
      stockAvailable += st.qtyAvailable;
    });

    let stockForBOCalulation = 0;

    if (stockAvailable > 0) {
      stockForBOCalulation = stockAvailable;
    }

    let clearance = false;
    if (product.slug.indexOf("clearance") > -1) {
      clearance = true;
    }
    if (clearance && value > stockAvailable) {
      setInputRefs({
        ...inputRefs,
        [refKey]: stockAvailable,
      });

      value = stockAvailable;
    }

    if (
      qtyAvailableSalesStatus.includes(product.sales_status) &&
      value > stockAvailable
    ) {
      setInputRefs({
        ...inputRefs,
        [refKey]: stockAvailable,
      });
      value = stockAvailable;
    }

    if (stockAvailable < value) {
      size.quantity = value;

      size.backOrderQty = size.quantity - stockForBOCalulation;
    } else {
      size.quantity = value;
    }

    const sizes = currentColorSizeStocks;
    for (let i = 0; i < sizes.length; i++) {
      if (sizes[i].sku === size.sku) {
        sizes[i] = size;
      }
      break;
    }

    setInputRefs({
      ...inputRefs,
      [refKey]: value,
    });
    setCurrentColorSizeStocks(sizes);
  };

  if (shouldRenderSizeDetail && currentColorSizeStocks.length > 0) {
    if (itemPrice) {
      return (
        <>
          <table className="table size-detail-table" id="size-detail-table">
            <thead>
              <tr>
                <td>
                  <b>Size</b>
                </td>
                {currentColorSizeStocks.map((size: ColorSizeStock) => (
                  <th style={{ textAlign: "center" }} key={size.sku}>
                    {size.size}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {appBranch === "au"
                ? <AUStock allSizes={currentColorSizeStocks} calculateStock={calculateStock}/>
                : renderStockLevel(currentColorSizeStocks)}
              <tr>
                <th />
                {!isStockListMode &&
                  currentColorSizeStocks.map((size) => (
                    <td key={size.sku}>{renderInput(size)}</td>
                  ))}
              </tr>
              {appBranch === "nz" && (
                <>
                  <OverseaStock
                    branch="au"
                    color={currentSelectedColor}
                    col={currentColorSizeStocks.length}
                  />
                  <OverseaStock
                    branch="ca"
                    color={currentSelectedColor}
                    col={currentColorSizeStocks.length}
                  />
                </>
              )}
              {!backOrderSalesStatus.includes(product.sales_status) &&
                renderBackOrderRow()}
            </tbody>
          </table>
        </>
      );
    } else {
      setActionStatus({
        message:
          "There is no price set up for this product. Please contact customer support.",
        type: AlertType.Error,
      });
      return <></>;
    }
  } else return <></>;
};

export default SizeDetailTable;
