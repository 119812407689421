import Modal from "../../../../../components/Modal/Modal";
import { Address } from "../../../../../interfaces/interfaces";
import NonPickup from "./NonPickup";
import Pickup from "./Pickup";

interface Props {
  currentOrderShipAddress: Address;
  pickup?: boolean;
  items: any[];
  hideModal: () => void;
}

const OrderSplitModal = ({
  currentOrderShipAddress,
  pickup,
  items,
  hideModal,
}: Props) => {
  const renderSplitItems = () => {
    let output = "";
    for (const item of items) {
      output += item + ", ";
    }
    return (
      <div
        style={{
          color: "red",
          fontWeight: "bold",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        {output}
      </div>
    );
  };

  return (
    <Modal>
      <div className="order-split-modal">
        {pickup ? (
          <Pickup
            hideModal={hideModal}
            items={renderSplitItems()}
            currentOrderShipAddress={currentOrderShipAddress}
          />
        ) : (
          <NonPickup hideModal={hideModal} items={renderSplitItems()} />
        )}
      </div>
    </Modal>
  );
};

export default OrderSplitModal;
