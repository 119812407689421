import cx from "classnames";
import { Link, useLocation } from "react-router-dom";
import { ProductListAPIResponse } from "../../interfaces/interfaces";

interface Props {
  products?: ProductListAPIResponse;
  numPages?: number;
  currentPage?: string;
}

const Paginator = (props: Props) => {
  const { products, numPages = 0, currentPage = "1" } = props;

  const location = useLocation();
  let current_page = 0;
  let total_count = 0;
  let total_page = 0;
  let search: any[] = [];
  let pathname = "";
  const per_page = 16;

  if (products) {
    ({ total_count } = products);
    current_page = products.current;
    total_page = Math.ceil(total_count / per_page);
  }
  if (location) {
    search = location.search.split("&");
    ({ pathname } = location);
    for (let i = 0; i < search.length; i++) {
      if (search[i].indexOf("page=") > -1) {
        search.splice(i, 1);
      }
    }
  }

  const createPageLink = (isSelected: boolean, destination: string, pageNumber: number) =>
    <Link key={pageNumber} className={cx({"paginator-link": true, selected: isSelected, })} to={destination}>
        {pageNumber}
    </Link>



  const links = Array.from(Array(total_page), (e, i) => {
    let to = `${pathname}${search}`;
    if (search.length > 0) {
      to += `&page=${i + 1}`;
    } else {
      to += `?page=${i + 1}`;
    }
    return (
      createPageLink((current_page === i + 1), to, i + 1)
    );
  });

  const renderPagination = () => (
    <>
      {parseInt(currentPage) > 2 &&
        <>
        {createPageLink(false, `/order-history?page=1`, 1)}
          <span>
          ...
          </span>
          </>
        }
        {parseInt(currentPage) > 1 && createPageLink(false, `/order-history?page=${parseInt(currentPage) - 1}`, (parseInt(currentPage) - 1))}
        {createPageLink(true, `/order-history?page=${parseInt(currentPage)}`, parseInt(currentPage))}
        {(numPages - parseInt(currentPage)) > 1 && createPageLink(false, `/order-history?page=${parseInt(currentPage) + 1}`, (parseInt(currentPage) + 1))}
      {(numPages - parseInt(currentPage)) > 1 &&
          <>
          <span>
          ...
          </span>
          {createPageLink(false, `/order-history?page=${numPages}`, numPages)}
          </>
        }
      </>
    )

  const getLinks = () => {
    const links = [];
    for (let i = 0; i < numPages; i++) {
      links.push(
        createPageLink((parseInt(currentPage) === i + 1), `/order-history?page=${i + 1}`, i+1)
      );
    }

    return links;
  };

  if (products) return <div className="paginator">{links}</div>;

  if (numPages <= 10 && numPages > 0) return <div className="paginator">{getLinks()}</div>;

  if (numPages > 10) return <div className="paginator">{renderPagination()}</div>;

  return <></>;
};

export default Paginator;
