export const formatDateToString = (date: Date): string => {
  const month = "" + (date.getMonth() + 1);
  const day = "" + date.getDate();
  const year = date.getFullYear();

  return [day, month, year].join("-");
}

export const getDateRangeByDays = (days = 7) => {
  const endDate = new Date();
  const startDate = new Date(endDate);
  startDate.setDate(endDate.getDate() - days);
  return { startDate: startDate, endDate: endDate };
};
