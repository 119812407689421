import { SVGProps } from "react"
const SignOutAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 158.7 119.3"
    {...props}
  >
    <style>{'.signOutAlt{fill:#FFFFFF}'}</style>
    <path
    d="M71 32.8h17.2c1.2 0 1.5-.2 1.5-1.5-.1-6.3-.1-12.5 0-18.8.1-5.9 3.5-9.1 9.4-11 4.7-1.5 8.4.5 11.6 3.7l12.4 12.4c10 10 19.9 20 29.9 30 2.5 2.5 4.7 5 5.4 8.6.9 4.8.1 9.2-3.2 12.9-3.5 3.9-7.2 7.5-10.9 11.3l-33.4 33.7c-2.4 2.5-5.3 4.4-8.9 4.1-6.3-.5-12.4-4.3-12.4-12.6V87.5c0-.3-.5-.8-.8-.9-1.1-.1-2.3-.1-3.5-.1-10.5 0-21.1 0-31.6-.1-5.9-.1-11.9-5-13.3-10.6-.3-1.4-.6-2.9-.6-4.4 0-8.2 0-16.3.1-24.5 0-4.4 1.8-8 5.2-10.7 2.7-2.2 5.8-3.4 9.3-3.4H71zm28 44.1V105c0 1.3.2 2.6 1.6 3.2 1.5.6 2.6.1 3.7-1 3.6-3.7 7.2-7.3 10.8-11C126 85 136.9 74 147.8 62.9c2.3-2.4 2.3-4.3 0-6.7l-.7-.7L111.9 20c-2.7-2.7-5.4-5.4-8-8.1-.9-.9-2-1.1-3.2-.7-1.2.4-1.6 1.3-1.6 2.5 0 1.1-.1 2.2-.1 3.2v24.2c0 1-.3 1.2-1.3 1.2H54.9c-2.4 0-4.5.9-5.2 3.3-.4 1.2-.4 2.4-.4 3.7v21.3c0 3.9 2.1 6.1 6.1 6.1h26c5.7.2 11.6.2 17.6.2z"
    className="signOutAlt"
    />
    <path
    d="M.1 58.8v-43C.1 12.9.4 10 2 7.4 4.6 3.2 8.4 1 13.2.3c1.3-.2 2.6-.2 4-.2C29.4 0 41.7 0 54 0h1.2c3.6.4 4 1.5 3.9 4.2v2.4c-.1 1.7-.7 2.6-2.4 3-.8.2-1.6.3-2.5.3H15.1c-2.4 0-4.3.8-5.3 3.1-.3.6-.4 1.4-.4 2-.1 1.7-.1 3.4-.1 5.1v83.4c0 3.9 2 5.9 5.9 5.9H55c3.1.2 4 1.2 4 4.2v2.4c-.1 1.7-.7 2.6-2.4 3-.7.2-1.4.3-2.1.3-13 0-26 .1-39 0-6.4 0-11.4-2.8-14.1-8.8-.9-2-1.4-4.4-1.4-6.6 0-15 0-30.1.1-45.1-.1 0 0 0 0 0z"
    className="signOutAlt"
    />
  </svg>
)
export default SignOutAlt
