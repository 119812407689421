import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { generateAuthHeader } from "../../commonfunctions/authentication";
import { log } from "../../commonfunctions/logger";
import {
  Container,
  StyledButton
} from "../../components/styled/styled-components";
import Toast from "../../components/Toast/Toast";
import { useDocumentTitle } from "../../hooks/document-title";
import { AlertType } from "../../interfaces/enums";
import {
  AlertContent,
  OrderUploadItemDetailsResponse
} from "../../interfaces/interfaces";
import { updateCurrentOrder } from "../../redux/actions/orders";
import { CurrentOrder } from "../../redux/reducers/orders";
import { RootState } from "../../redux/store";
import ImportTables from "./ImportTable/ImportTable";
import ModalSwitcher, { OrderUploadResult } from "./ModalSwitcher/ModalSwitcher";
import {
  checkCSVForErrors,
  constructJSON,
  findNumberOfInvalidProducts,
  removeSkuDuplicates,
  sortProducts
} from "./util";

export type FetchedOrderUploadProduct =
  | OrderUploadItemDetailsResponse
  | { index: string; itemNo?: string; price?: string };

/*
  -----------------WARNING-----------------
  If you are new here and need to work on this file or its direct children components, read this.
  You will need to understand that when re-fetching/reloading an item, the product.index is different to the sorted arrays array index.
  If you don't understand how this works, you will probably suffer bugs that will make you want to rip your hair out.
*/

const OrderUploader = () => {
  useDocumentTitle("Import an order");
  const [modalToShow, setModalToShow] = useState<OrderUploadResult | null>(null);
  const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [csvEachLineArray, setCsvEachLineArray] = useState<string[][]>([]);
  const [csvErrorLineNumberArray, setCsvErrorLineNumberArray] = useState<
    number[]
  >([]);
  const [totalItems, setTotalItems] = useState(0);
  const [fetchedProducts, setFetchedProducts] = useState<
    FetchedOrderUploadProduct[]
  >([]);
  const [toast, setToast] = useState<AlertContent | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const currentOrder: CurrentOrder = useSelector(
    (state: RootState) => state.orders.currentOrder
  );

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (totalItems > 0 && fetchedProducts.length === totalItems) {
      setModalToShow(null);
      const numInvalidProducts = findNumberOfInvalidProducts(fetchedProducts);
      if (numInvalidProducts > 0) {
        setFailedToLoadProductsToastError(numInvalidProducts);
      } else {
        setToast({
          message:
            "Your items have been imported successfully. You may now add them to your cart.",
          type: AlertType.Success
        });
      }
    }
  }, [fetchedProducts, totalItems, csvErrorLineNumberArray]);

  const clearState = () => {
    // This can be replaced by creating all state in object and initial values
    setIsFileUploaded(false);
    setCsvEachLineArray([]);
    setCsvErrorLineNumberArray([]);
    setTotalItems(0);
    setFetchedProducts([]);
  };

  const previewFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event) {
      return;
    } else {
      const uploadedCSV =
        event.target.files &&
        event.target.files.length > 0 &&
        event.target.files[0];
      if (uploadedCSV) {
        clearState();

        return new Promise((resolve, reject) => {
          const csv = uploadedCSV;
          const reader = new FileReader();

          reader.onload = (evt) => {
            const result = evt && evt.target && evt.target.result;

            if (typeof result === "string") {
              const lines = result.split("\n");
              for (let i = 1; i < lines.length - 1; i++) {
                resolve(
                  setCsvEachLineArray((prev) => [...prev, lines[i].split(",")])
                );
              }
            }
          };

          reader.onerror = (e) => {
            reject(e);
          };

          reader.readAsText(csv);
        });
      }
    }
  };

  const uploadFile = async () => {
    const data = csvEachLineArray;
    if (data.length <= 0) {
      setToast({
        message: "Please upload a file first.",
        type: AlertType.Error
      });
      return;
    }

    const invalidLines = checkCSVForErrors(data);

    if (invalidLines.length > 0) {
      setCsvErrorLineNumberArray(invalidLines);
      setModalToShow("csvErrors");
    } else {
      setModalToShow("progressBar");
      const constructedData = constructJSON(data);
      const cleanedConstructedData = removeSkuDuplicates(constructedData);
      setTotalItems(cleanedConstructedData.length);

      cleanedConstructedData.map(async (data, index) => {
        const fetchedProduct = await fetchItemDetails(data, index);
        if (fetchedProduct) {
          setFetchedProducts((fetchedProducts) => [
            ...fetchedProducts,
            fetchedProduct
          ]);
        } else {
          setFetchedProducts((fetchedProducts) => [
            ...fetchedProducts,
            { index: index.toString() }
          ]);
        }
      });

      setIsFileUploaded(true);
    }
  };

  const fetchItemDetails = async (
    csv: { sku: any; quantity: any; reference: any },
    index: number
  ): Promise<OrderUploadItemDetailsResponse | false> => {
    const url = `${process.env.REACT_APP_HONEYBEE_ROOT_API}/bulk_orders/load_items_detail/`;

    const data = new FormData();
    data.append("index", index.toString());
    data.append("jwt", localStorage.getItem("jwt") ?? "");
    data.append("branch", localStorage.getItem("branch") ?? "");
    data.append("customer_id", localStorage.getItem("customer_id") ?? "");
    data.append("accpac_code", localStorage.getItem("company_accpac_no") ?? "");
    data.append("sku", [csv][0].sku.trim());
    data.append("quantity", [csv][0].quantity);
    data.append("reference", [csv][0].reference);

    const requestConfig = {
      method: "POST",
      body: data
    };

    try {
      const res = await fetch(url, requestConfig);

      if (res.status === 200) {
        const returnData: OrderUploadItemDetailsResponse = await res.json();
        return returnData;
      } else {
        const error = await res.text();
        throw error;
      }
    } catch (error: any) {
      log(error, "Order upload failed at item fetch");
      return false;
    }
  };

  const reloadItemDetails = async (indexInCSVFile: number) => {
    const data = constructJSON(new Array(csvEachLineArray[indexInCSVFile]));
    const cleanedData = removeSkuDuplicates(data);
    const reloadedItemDetails = await fetchItemDetails(cleanedData[0], indexInCSVFile);

    if (reloadedItemDetails) {
      const products = fetchedProducts.slice();

      for (let i = 0; i < products.length; i++) {
        if (parseInt(products[i].index) === indexInCSVFile) {
          products[i] = reloadedItemDetails;
        }
      }
      setFetchedProducts(sortProducts(products));
    }
  };

  const removeFetchedItem = (indexInCSVFile: number) => {
    const products = fetchedProducts.slice();

    for (let i = 0; i < products.length; i++) {
      if (parseInt(products[i].index) === indexInCSVFile) {
        products.splice(i, 1);
      }
    }

    setTotalItems((items) => items - 1);
    setFetchedProducts(sortProducts(products));
  };

  const setFailedToLoadProductsToastError = (numInvalidProducts: number) => {
    const product = numInvalidProducts > 1 ? "products" : "product";
    setToast({
      message: `${numInvalidProducts} ${product} could not be loaded. Please reload those items or remove them from the list.`,
      type: AlertType.Error
    });
  };

  const clearCart = () => {
    const req_url = `${process.env.REACT_APP_HALFBACK_ROOT_API}/orders/current_order/clear_cart`;
    const req_config = generateAuthHeader();

    setIsLoading(true);
    axios
      .post(req_url, {}, req_config)
      .then((response) => {
        setIsLoading(false);
        dispatch(updateCurrentOrder(response.data));
        setModalToShow(null);
      })
      .catch(() => {
        setIsLoading(false);
        setModalToShow(null);
      });
  };

  const addToCart = async () => {
    const numInvalidProducts = findNumberOfInvalidProducts(fetchedProducts);
    if (numInvalidProducts > 0) {
      setFailedToLoadProductsToastError(numInvalidProducts);
      return;
    }

    if (
      currentOrder &&
      currentOrder.order_items &&
      currentOrder.order_items.length > 0
    ) {
      setModalToShow("clearCart");
      return;
    }

    const url = `${process.env.REACT_APP_HALFBACK_ROOT_API}/bulk_orders/add_items/`;
    setModalToShow("addingToCart");
    axios
      .post(
        url,
        {
          items: fetchedProducts
        },
        generateAuthHeader()
      )
      .then((response) => {
        if (response.data.result === "failed") {
          setErrorMessage(response.data.message)
          setModalToShow("failedResult");
        }
        if (response.data.result === "success") {
          setModalToShow("goToCart");
        }
      })
      .catch((error) => {
        log(error, "Order upload failed at adding items to cart");
        setModalToShow("failedResult");
      });
  };

  return (
    <Container margin="1em 0" className="order-uploader">
      <div className="alert alert-info" role="alert">
        Upload an order from a CSV template.{" "}
        <a
          href="https://cdn.fashionbiz.com/webstore/FashionBizOrderExport.csv"
          target="_blank"
          rel="noopener noreferrer"
        >
          Click for example template
        </a>
      </div>

      <div className="utils">
        <div className="uploader">
          {!isFileUploaded ? (
            <>
              <div className="util">
                <input
                  type="file"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    previewFile(event)
                  }
                />
              </div>

              <div className="util">
                <StyledButton onClick={() => uploadFile()}>Import</StyledButton>
              </div>
            </>
          ) : (
            <div className="util">
              <StyledButton onClick={() => addToCart()}>
                Add to Cart
              </StyledButton>
            </div>
          )}

          <div className="util">
            <StyledButton danger onClick={() => history.go(0)}>
              Reset
            </StyledButton>
          </div>
        </div>
      </div>

      {fetchedProducts &&
        fetchedProducts.length > 0 &&
        fetchedProducts.length === totalItems && (
          <ImportTables
            products={fetchedProducts}
            removeFetchedItem={removeFetchedItem}
            reloadItemDetails={reloadItemDetails}
            csvEachLineArray={csvEachLineArray}
          />
        )}

      <ModalSwitcher
        modalToShow={modalToShow}
        setModalToShow={setModalToShow}
        progressLevel={fetchedProducts.length}
        totalItems={totalItems}
        isLoading={isLoading}
        clearCart={clearCart}
        csvErrors={csvErrorLineNumberArray}
        errorMessage={errorMessage}
      />
      <Toast content={toast} onClick={() => setToast(null)} />
    </Container>
  );
};

export default OrderUploader;
