import { Container } from "../../components/styled/styled-components";
import { useDocumentTitle } from "../../hooks/document-title";
import AddressBookContainer from "./AddressBook/AddressBookContainer";
import ChangePassword from "./ChangePassword/ChangePassword";
import CustomerInfo from "./CustomerInfo/CustomerInfo";

const Preferences = () => {
  useDocumentTitle("Account settings");

  return (
    <Container margin="1em 0" className="preferences">
      <CustomerInfo />
      <ChangePassword />
      <AddressBookContainer />
    </Container>
  );
};

export default Preferences;
