import { useEffect, useState } from "react";
import AuthenticationHOC, {
  AuthHOCProps
} from "../AuthenticationHOC/AuthenticationHOC";

interface Feed {
  branch_id: number;
  content: string;
  created_at: string;
  id: number;
  image_content_type?: string;
  image_file_name?: string;
  image_file_size?: string;
  image_updated_at?: string;
  title: string;
  updated_at: string;
}

interface Response {
  feeds: Feed[];
}

const NotificationBar = (props: AuthHOCProps) => {
  const [feeds, setFeeds] = useState<Feed[]>([]);
  const { generateAuthHeader } = props;

  useEffect(() => {
    const fetchFeed = async () => {
      const url = `${process.env.REACT_APP_HALFBACK_ROOT_API}/feeds/au`;

      try {
        const res = await fetch(url, generateAuthHeader());
        const data: Response = await res.json();
        setFeeds(data.feeds);
      } catch (error) { }
    };

    fetchFeed();
  }, [generateAuthHeader]);

  const createMarkup = (html: any) => ({ __html: html });

  return (
    <>
      {feeds.length > 0 && (
        <div className="notification-bar">
          <span>{feeds[0].title}: </span>
          <span dangerouslySetInnerHTML={createMarkup(feeds[0].content)} />
        </div>
      )}
    </>
  );
};

export default AuthenticationHOC(NotificationBar);
