import * as React from "react"
import { SVGProps } from "react"

const Plane = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 11.20 8.48"
    {...props}
  >
    <path d="M7.9 8.1c-.2-1.4-.3-2.9-.4-4.4 0-.2.1-.5.2-.7.4-.5.9-.9 1.4-1.4.2-.3.4-.6.4-1 .1-.4-.2-.7-.6-.6-.4.1-.8.3-1.1.6L6.5 1.9c-.2.1-.4.2-.6.2-1.5-.1-3-.3-4.6-.5-.7 0-.9.1-1.3.8 1.4.6 2.7 1.2 4.1 1.9-.3.3-.6.5-.8.8-.4.4-.8.7-1.4.5-.6-.2-1.1-.1-1.4.6l1.2.6c.5.2.9.5 1 1 .1.4.4.9.6 1.3.9-.4.7-1.1.6-1.7 0-.2-.1-.5.1-.7.4-.5.8-.9 1.2-1.3.7 1.4 1.3 2.8 1.9 4.2.6-.4.9-.8.8-1.5" />
  </svg>
)
export default Plane;
