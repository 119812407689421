import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  generateAuthHeader,
  generateTokenAuthHeader,
} from "../../commonfunctions/authentication";
import { log } from "../../commonfunctions/logger";
import Accordion from "../../components/Accordion/Accordion";
import BOGOPromotion from "../../components/BOGOPromotion/BOGOPromotion";
import CartItemTable from "../../components/CartItemTable/CartItemTable";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import PDFDownloadButton from "../../components/PDFDownloadButton/PDFDownloadButton";
import CartPDF from "../../components/PDFTemplates/Templates/CartPDF/CartPDF";
import {
  Container,
  StyledButton,
  Wrapper,
} from "../../components/styled/styled-components";
import Toast from "../../components/Toast/Toast";
import { useDocumentTitle } from "../../hooks/document-title";
import { AlertType } from "../../interfaces/enums";
import { AlertContent } from "../../interfaces/interfaces";
import { updateCurrentOrder } from "../../redux/actions/orders";
import { RootState } from "../../redux/store";
import ModalSwitcher from "./ModalSwitcher";
import { Context, IContext } from "../../context/ContextApp";
import Icon from "../../components/Icon/icon";

const CartPage = () => {
  useDocumentTitle("Cart");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>("");
  const [saveOrderInput, setSaveOrderInput] = useState<string>("");
  const [isPromotionRunning, setIsPromotionRunning] = useState<boolean>(false);
  const [promotionResult, setPromotionResult] = useState<{
    passable: boolean;
    message: string;
  }>({
    passable: false,
    message: "",
  });
  const [toast, setToast] = useState<AlertContent | null>(null);
  const [modalToShow, setModalToShow] = useState<number>(-1);
  const location = useLocation();
  const isWebstoreOrderEnable =
    localStorage.getItem("webstore_order_enabled") === "true";

  const currentOrder = useSelector(
    (state: RootState) => state.orders.currentOrder
  );

  const selectedOffice = useSelector(
    (state: RootState) => state.organization.selected_office
  );
  const isWebstoreAdmin = useSelector(
    (state: RootState) => state.organization.is_webstore_admin
  );
  const organizationKey = useSelector(
    (state: RootState) => state.organization.organization_key
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const { push } = history;
  const { appBranch }: IContext = useContext(Context);
  const isStockListMode = localStorage.getItem("stocklist_mode") === "true";

  useEffect(() => {
    if (isWebstoreAdmin && organizationKey && !selectedOffice) {
      push("/branch-selection");
    }
  }, [isWebstoreAdmin, organizationKey, selectedOffice, push]);

  useEffect(() => {
    const checkBOGOPromotion = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_HONEYBEE_ROOT_API}/promotions/${appBranch}`,
          generateTokenAuthHeader()
        );
        const data = await res.json();

        if (data.promotions.length) {
          setIsPromotionRunning(true);
        }
      } catch (error) {
        log(error, " Failed to fetch bogo promotion");
      }
    };

    const getCurrentOrder = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_HALFBACK_ROOT_API}/orders/current_order`,
          generateAuthHeader()
        );
        if (res.ok) {
          const data = await res.json();
          dispatch(updateCurrentOrder(data));
        } else {
          const error = await res.text();
          throw error;
        }
      } catch (error) {
        log(error, "Failed to get cart at CartPage");
      }
    };

    getCurrentOrder();

    if (["au", "nz"].includes(appBranch)) checkBOGOPromotion();
  }, [dispatch, appBranch]);

  const clearCart = (): void => {
    const req_url = `${process.env.REACT_APP_HALFBACK_ROOT_API}/orders/current_order/clear_cart`;
    const req_config = generateAuthHeader();

    setIsLoading(true);
    axios
      .post(req_url, {}, req_config)
      .then((response) => {
        setIsLoading(false);
        dispatch(updateCurrentOrder(response.data));
        setModalToShow(-1);
      })
      .catch((error) => {
        log(error, "Clear cart failed");
        setIsLoading(false);
        setModalToShow(-1);
      });
  };

  const saveOrder = (): void => {
    const url = `${process.env.REACT_APP_HALFBACK_ROOT_API}/draft_orders/save`;
    setIsLoading(true);
    axios
      .post(url, { order_note: saveOrderInput }, generateAuthHeader())
      .then(() => {
        setModalMessage("Order saved.");
        setModalToShow(3);
        setIsLoading(false);
      })
      .catch((error) => {
        log(error, "Save order from cart failed");
        setIsLoading(false);
        setModalToShow(3);
        setModalMessage(
          "We could not save your order. Please try again later."
        );
      });
  };

  const renderCart = () => {
    const { note, order_items } = currentOrder;

    if (order_items && order_items.length > 0) {
      return (
        <>
          {note && <h5 style={{ marginBottom: "1em" }}>Order note: {note}</h5>}
          <CartItemTable items={order_items} action showStock />
        </>
      );
    } else {
      return (
        <Wrapper margin="2.5em 0">
          <h2>Your cart is empty.</h2>
        </Wrapper>
      );
    }
  };

  const handleCheckOutButton = () => {
    if (!promotionResult.passable && isPromotionRunning) return setToast({
        message:
          promotionResult.message ||
          "Could not proceed to checkout page. Please contact webmaster@fashionbiz.co.nz",
        type: AlertType.Error,
      });

    if (!isWebstoreOrderEnable) return setToast({
        message:
          promotionResult.message ||
          "You don't have permission to proceed to checkout page.",
        type: AlertType.Info,
      });

    return history.push("/check-out/extra-info");
  };

  if (isStockListMode) {
    return (
      <Wrapper margin="2.5em 0">
        <h2>Start browsing products with the search bar above, or through the menu in the top left.</h2>
      </Wrapper>
    );
  }

  return (
    <main role="main" className="cart-page">
      {currentOrder ? (
        <>
          <div className="cart-top-bar">
            {currentOrder.order_items.length > 0 && (
              <Container row aic className="action-group">
                <PDFDownloadButton
                  document={
                    <CartPDF
                      title={currentOrder.note ?? localStorage.getItem("email")}
                      order={currentOrder.order_items}
                      appBranch={appBranch}
                    />
                  }
                >
                  <StyledButton className="icon-and-text-button-wrapper">
                    <div>
                      <Icon name="Print" iconWidth="2rem" />
                      <span>Print</span>
                    </div>
                  </StyledButton>
                </PDFDownloadButton>
                <StyledButton
                  className="icon-and-text-button-wrapper"
                  style={{ margin: "0 1em" }}
                  onClick={() => setModalToShow(2)}
                >
                  <div>
                    <Icon name="Save" iconWidth="2rem" />
                    <span>Save</span>
                  </div>
                </StyledButton>
                <StyledButton
                  className="icon-and-text-button-wrapper"
                  style={{ marginRight: "1em" }}
                  onClick={() => setModalToShow(1)}
                >
                  <div>
                    <Icon name="TrashWhite" iconWidth="2rem" />
                    <span>Empty</span>
                  </div>
                </StyledButton>
                <StyledButton
                  onClick={handleCheckOutButton}
                  className="icon-and-text-button-wrapper"
                >
                  <div>
                    <Icon name="CreditCard" iconWidth="2rem" />
                    <span>Checkout</span>
                  </div>
                </StyledButton>
              </Container>
            )}
          </div>
          {isPromotionRunning && (
            <Accordion
              title="Promotions"
              expanded={location.search === "?tab=promotions"}
            >
              <BOGOPromotion
                cartItems={currentOrder.order_items}
                updatePromotionResult={setPromotionResult}
              />
            </Accordion>
          )}

          <Accordion title="Cart" expanded={true}>
            {renderCart()}
          </Accordion>
        </>
      ) : (
        <div style={{ margin: "10px" }}>
          <Wrapper>
            <LoadingSpinner />
          </Wrapper>
        </div>
      )}
      <ModalSwitcher
        modalToShow={modalToShow}
        setModalToShow={setModalToShow}
        saveOrder={saveOrder}
        clearCart={clearCart}
        modalMessage={modalMessage}
        setSaveOrderInput={setSaveOrderInput}
        isLoading={isLoading}
      />
      <Toast content={toast} onClick={() => setToast(null)} />
    </main>
  );
};

export default CartPage;
