import { SVGProps } from "react"
const PlusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 118.8 119.3"
    {...props}
  >
    <path d="M66.8 27v23.9c0 1.2.4 1.4 1.5 1.4h45.6c1.2 0 2.4.2 3.6.3.9.1 1.3.6 1.3 1.5v11.3c0 .9-.6 1.4-1.4 1.5-.9.1-1.8.3-2.7.3H68.3c-1.2 0-1.5.4-1.5 1.5v47.9c-.1 1.9-.9 2.8-2.8 2.8-2.9.1-5.9.1-8.8 0-2.3-.1-2.9-.7-2.9-3.3 0-10.4 0-20.9-.1-31.3V69.4c0-2.4.4-2.1-2.2-2.1H4.7c-1.1 0-2.1-.1-3.2-.3-.9-.1-1.3-.6-1.4-1.5 0-1.2 0-2.3-.1-3.5 0-2.4-.1-4.7-.1-7.1 0-1.7.3-2.1 2-2.4.8-.1 1.6-.2 2.5-.2h46.3c1.2 0 1.5-.4 1.5-1.6V3.5c0-2.7.4-3.2 3.1-3.4C58.2 0 61 0 63.9.1c2.2.1 2.9.9 2.9 3V27z" />
  </svg>
)
export default PlusIcon
