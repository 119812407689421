import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FieldGenerator from "../../../../../../components/Address/AddressFormFieldGenerator";
import { getAddressParams } from "../../../../../../components/Address/util";
import AuthenticationHOC from "../../../../../../components/AuthenticationHOC/AuthenticationHOC";
import { Address as AddressSearcher} from "../../../../../../components/Address/AddressSearcher";
import {
  Container,
  StyledButton,
  StyledFormField,
  StyledFormLayout,
} from "../../../../../../components/styled/styled-components";
import { Address } from "../../../../../../interfaces/interfaces";
import { updateFreightOption } from "../../../../../../redux/actions/orders";
import { RootState } from "../../../../../../redux/store";
import { Context, IContext } from "../../../../../../context/ContextApp";

interface Props {
  updateShippingAddressInRedux: (a: Address | null) => void;
}

interface Form {
  ship_to_name: string;
  address: string;
}

function NewAddress({ updateShippingAddressInRedux }: Props) {
  const [form, setForm] = useState<Form>({ ship_to_name: "", address: "" });
  const [ManualEnterModal, setManualEnterModal] = useState(false);
  const [shipName, setShipName] = useState("");
  const [address, setAddress] = useState("");
  const [suburb, setSuburb] = useState("");
  const [city, setCity] = useState("");
  const [NIorSI, setNIorSI] = useState(true);
  const [postCode, setPostCode] = useState("");
  const dispatch = useDispatch();
  const shippingAddress = useSelector(
    (state: RootState) => state.orders.currentOrderShipAddress
  );
  const { appBranch }: IContext = useContext(Context);

  useEffect(() => {
    if (form.ship_to_name && form.address) {
      updateShippingAddressInRedux(
        getAddressParams(form.address, form.ship_to_name)
      );
    } else if (!form.ship_to_name || !form.address) {
      updateShippingAddressInRedux(null);
      dispatch(updateFreightOption(null));
    }
  }, [form, updateShippingAddressInRedux, dispatch]);

  const isAddressSearcher = (object: any): object is AddressSearcher =>
  "FullAddress" in object;

  const onFieldChange = (
    event: React.ChangeEvent<HTMLInputElement> | AddressSearcher,
    key: string
  ) => {
    // If we're setting the address returned from the address searcher
    if (isAddressSearcher(event)) {
      setForm({
        ...form,
        [key]: event.FullAddress,
      });
      // Else if setting ship_to_name
    } else {
      setForm({
        ...form,
        [key]: event.target.value,
      });
    }
  };

  const onFormChange = () => {
    if (NIorSI === true) {
      if (shipName && address && suburb && city && postCode) {
        const shipToAddress = address + "! " + suburb + "! " + city + "! " + "NI" + "! " + postCode;
        setForm({
          ship_to_name: shipName,
          address: shipToAddress,
        });
      }
    } else {
      if (shipName && address && suburb && city && postCode) {
        const shipToAddress = address + "! " + suburb + "! " + city + "! " + "SI" + "! " + postCode;
        setForm({
          ship_to_name: shipName,
          address: shipToAddress,
        });
      }
    }
  };

  const changeManualForm = (isChange: boolean) => {
    setManualEnterModal(isChange)
    setForm({
      ship_to_name: "",
      address: "",
    });
  };

  const checkoutBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      dispatch(updateFreightOption("pickup"));
    } else {
      dispatch(updateFreightOption(null));
    }
  };

  return (
    <div className="new-address-wrapper">
      {ManualEnterModal ?
        <>
          <StyledButton style={{ marginRight: "12px" }} onClick={() => changeManualForm(false)}>Return</StyledButton>
          <h5 style={{ color: "red", display: "contents" }}>* All fields required</h5>
          <StyledFormLayout>
            <StyledFormField>
              <label>Ship to Name</label>
              <input
                onChange={(event) => {
                  setShipName(event.target.value);
                }}
              />
            </StyledFormField>
            <StyledFormField>
              <label>Address</label>
              <input
                onChange={(event) => {
                  setAddress(event.target.value);
                }}
              />
            </StyledFormField>
            <StyledFormField>
              <label>Suburb</label>
              <input
                onChange={(event) => {
                  setSuburb(event.target.value);
                }}
              />
            </StyledFormField>
            <StyledFormField>
              <label>City</label>
              <input
                onChange={(event) => {
                  setCity(event.target.value);
                }}
              />
            </StyledFormField>
            <StyledFormField>
              <label>Island</label>
              <div style={{ display: "inline" }}>
                <input
                  style={{ width: "auto", marginRight: "12px" }}
                  type="checkbox"
                  checked={NIorSI == true}
                  onChange={() => setNIorSI(true)}
                />
                North Island
              </div>
              <div style={{ display: "inline", marginLeft: "10px" }}>
                <input
                  style={{ width: "auto", marginRight: "12px" }}
                  type="checkbox"
                  checked={NIorSI == false}
                  onChange={() => setNIorSI(false)}
                />
                South Island
              </div>
            </StyledFormField>
            <StyledFormField>
              <label>Postcode</label>
              <input
                onChange={(event) => {
                  setPostCode(event.target.value);
                }}
              />
            </StyledFormField>
            {form.address !== "" && <span style={{ textAlign: "center" }}>Entered Address: {form.address}</span>}
          </StyledFormLayout>
          <StyledButton onClick={onFormChange}>Submit</StyledButton>
        </>
        :
        <StyledFormLayout>
          <FieldGenerator form={form} onChange={onFieldChange} />
        </StyledFormLayout>
      }
      {appBranch === "nz" && shippingAddress && (
        <>
          <span
            style={{
              color: "blue",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            className={ManualEnterModal ? "hide" : ""}
            onClick={() => changeManualForm(true)}
          >
            Address Not Correct?
          </span>
          <Container row>
            <b>Third Party Pick Up</b>
            <div style={{ marginLeft: "1em" }}>
              <input
                type="checkbox"
                style={{ width: "auto", marginRight: "10px" }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  checkoutBox(event)
                }
              />
              <span style={{ color: "red" }}>
                *Using a 3rd party to collect this order? Click here.
              </span>
            </div>
          </Container>
        </>
      )}
    </div>
  );
}

export default AuthenticationHOC(NewAddress);
