import { SVGProps } from "react"
const DollarSign = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 79.1 159.1"
    {...props}
  >
    <path d="M44.2 148.6v8.1c0 1.4-.6 2.2-1.9 2.3-1.8.1-3.6.2-5.4.1-1.2-.1-1.9-.8-1.9-2.1 0-1.5 0-3.1-.1-4.6 0-3.9-.1-7.8-.1-11.7 0-1.2-.4-1.4-1.5-1.5-3.1-.1-6.1-.1-9.2-.5-8.1-.9-15-4.6-20.9-10.2-1.4-1.3-1.4-2.3 0-3.7 1.3-1.2 2.6-2.3 3.9-3.5.8-.7 1.6-.6 2.3 0 .7.6 1.3 1.1 2 1.7 4.9 4.1 10.5 6.1 16.9 6.2 7.9.1 15.9.1 23.8 0 6.5-.1 11-3.5 14.3-8.8 5.1-8.3 3.6-19.5-2.9-26-1.9-1.9-4.2-3.4-6.8-4.2-6.1-1.9-12.1-3.8-18.2-5.8s-12.3-4-18.4-6c-9.6-3-15.4-10-18.6-19.3-1.8-5.4-1.9-11-.7-16.5 1.8-8 6-14.5 13.1-18.9 4.3-2.7 9.3-3.7 14.3-3.9 1.9-.1 3.8 0 5.7 0 .8 0 1-.3 1-1.1-.1-5.3-.1-10.7-.1-16 0-1.8.7-2.6 2.5-2.7 1.7-.1 3.4.1 5.1.2 1.2.1 1.7.8 1.7 2v15.9c0 1.8 0 1.8 1.8 1.8 4.9-.1 9.7.3 14.4 1.5 6.2 1.6 11.4 5 15.9 9.5 1 1 1 1.9-.1 2.9-1.3 1.2-2.6 2.4-4 3.5-1.3 1.1-1.7 1-3-.1-2.8-2.5-5.9-4.6-9.4-5.8-2.9-1.1-5.9-1.6-9-1.6H27.5c-7 0-12 3.6-15.3 9.5-3.2 5.5-3.7 11.4-1.7 17.4 2.1 6.1 6.2 10.5 12.5 12.5 11.8 3.7 23.6 7.4 35.5 11.2 8.9 2.8 14.7 8.8 18.2 17.2 2.8 6.6 3.2 13.6 1.2 20.3-3 9.9-8.9 17.4-19.2 20.3-2.5.7-5.2.8-7.9 1-1.8.1-3.6.1-5.5 0-.9 0-1.1.3-1.1 1.2v8.2z" />
  </svg>
)
export default DollarSign
