import axios from "axios";
import React, { useCallback, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { generateAuthHeader } from "../../../../commonfunctions/authentication";
import { log } from "../../../../commonfunctions/logger";
import AddressBook from "../../../../components/Address/AddressBook/AddressBook";
import { isAddressValid } from "../../../../components/Address/util";
import { StyledButton } from "../../../../components/styled/styled-components";
import { Context, IContext } from "../../../../context/ContextApp";
import { ShippingAddressType } from "../../../../interfaces/types";
import { setCurrentOrderShipAddress } from "../../../../redux/actions/orders";
import { RootState } from "../../../../redux/store";
import AddressOptions from "./AddressOptions/AddressOptions";
import NewAddress from "./AddressOptions/NewAddress/NewAddress";
import PrimaryAddress from "./AddressOptions/PrimaryAddress/PrimaryAddress";
import FreightOptions from "./FreightOptions";

function ShippingOptionsAU() {
  const [shippingAddressType, setShippingAddressType] =
    useState<ShippingAddressType>(null);
  const {appBranch}: IContext = useContext(Context);
  const [showContinueButton, setShowContinueButton] = useState(false);
  const shippingMethodType = useSelector(
    (state: RootState) => state.orders.freightOption
  );
  const currentOrderShipAddress = useSelector(
    (state: RootState) => state.orders.currentOrderShipAddress
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const handleShippingAddressOptionChange = useCallback(
    (option: ShippingAddressType) => {
      setShowContinueButton(false);
      setShippingAddressType(option);
    },
    []
  );

  const updateShippingAddressInRedux = useCallback(
    (address) => {
      if (isAddressValid(address, appBranch)) {
        dispatch(setCurrentOrderShipAddress(address));
        setShowContinueButton(true);
      } else {
        setShowContinueButton(false);
      }
    },
    [dispatch, appBranch]
  );

  function saveNewAddress() {
    /* Halfback consumes "address_state" but returns "state" */
    currentOrderShipAddress["address_state"] = currentOrderShipAddress["state"];
    delete currentOrderShipAddress["state"];

    axios
      .post(
        `${process.env.REACT_APP_HALFBACK_ROOT_API}/ship_to_addresses`,
        currentOrderShipAddress,
        generateAuthHeader()
      )
      .then((response) => {
        if (response.status === 201) {
          dispatch(
            setCurrentOrderShipAddress({
              ...currentOrderShipAddress,
              is_primary_address: false,
            })
          );
          history.push("/check-out/payment-selection");
        } else if (response.status === 401) {
          alert("Your session is expired. Please login again.");
        } else {
          alert("Something went wrong. Please try again later.");
        }
      })
      .catch((error) => {
        log(error, "New address save failed (AU)");
      });
  }

  function _renderShippingAddressForm() {
    switch (shippingAddressType) {
      case "Address Book":
        return (
          <AddressBook
            updateShippingAddressInRedux={updateShippingAddressInRedux}
          />
        );
      case "New Address":
        return (
          <NewAddress
            updateShippingAddressInRedux={updateShippingAddressInRedux}
          />
        );
      case "Primary Address":
        return (
          <PrimaryAddress
            updateShippingAddressInRedux={updateShippingAddressInRedux}
          />
        );
      default:
        return <></>;
    }
  }

  function renderContinueButton() {
    if (showContinueButton) {
      if (shippingAddressType === "New Address") {
        return (
          <React.Fragment>
            <Link to="/check-out/payment-selection">
              <StyledButton primary>Continue</StyledButton>
            </Link>
            <StyledButton
              primary
              style={{ marginLeft: "1rem" }}
              onClick={() => {
                saveNewAddress();
              }}
            >
              Save and Continue
            </StyledButton>
          </React.Fragment>
        );
      } else {
        return (
          <Link to="/check-out/payment-selection">
            <StyledButton primary>Continue</StyledButton>
          </Link>
        );
      }
    }
  }

  return (
    <div className="shipping-page">
      <h4>How would you like your order shipped?</h4>
      <div className="freight-options">
        <FreightOptions />
      </div>
      {shippingMethodType && (
        <AddressOptions
          shippingAddressType={shippingAddressType}
          handleShippingAddressOptionChange={handleShippingAddressOptionChange}
        />
      )}
      <div className="shippingAddressForm">{_renderShippingAddressForm()}</div>
      {renderContinueButton()}
    </div>
  );
}

export default ShippingOptionsAU;
