import { SVGProps } from "react"
const Bars = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 138.9 110.4"
    {...props}
  >
    <path d="M69.6 0h65.8c3.5 0 3.5 0 3.5 3.4 0 1.9-.1 3.8 0 5.7 0 1.3-.5 1.7-1.7 1.7H3C.2 10.8.2 10.8.2 8V2.4C0 .3.3 0 2.4 0h67.2zM69.5 49.7h66.3c3.1 0 3.1 0 3.1 3.1 0 2.1-.1 4.2-.1 6.3 0 1-.4 1.4-1.3 1.4H2.9c-2.8 0-2.8 0-2.8-2.9v-5.7c0-2 .3-2.3 2.3-2.3 22.3.1 44.7.1 67.1.1zM69.5 99.5h66.3c3.1 0 3.1 0 3.1 3.1 0 2.1-.1 4.2-.1 6.3 0 1-.5 1.4-1.3 1.4H2.9c-2.7 0-2.8 0-2.8-2.7v-5.8c0-2 .3-2.3 2.3-2.3h67.1z" />
  </svg>
)
export default Bars
