import { pdf } from "@react-pdf/renderer";
import React from "react";

interface Props {
  document: any;
  children: any;
}
const PDFDownloadButton = (props: Props) => {
  const { document, children } = props;

  const generateDocument = async () => {
    const blob = await pdf(document).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url);
  };

  return React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement<any>(child, {
        onClick: () => generateDocument(),
      });
    }
    return child;
  });
};

export default PDFDownloadButton;
