import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { generateAuthHeader } from "../../../commonfunctions/authentication";
import { log } from "../../../commonfunctions/logger";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { Wrapper } from "../../../components/styled/styled-components";

interface CustomerInfoAPIResponse {
  allow_webstore: boolean;
  brands: string[];
  company: string;
  company_accpac_no: string;
  customer_branch: string;
  customer_id: number;
  email: string;
  first_name: string;
  last_name: string;
}

const CustomerInfo = () => {
  const [customerInfo, setCustomerInfo] = useState<
    CustomerInfoAPIResponse | undefined
  >();
  const history = useHistory();
  const { push } = history;

  useEffect(() => {
    const getCustomerInfo = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_HALFBACK_ROOT_API}/current_customer/info`,
          generateAuthHeader()
        );

        if (res.ok) {
          const data = await res.json();
          setCustomerInfo(data);
        } else {
          const error = await res.text();
          throw error;
        }
      } catch (error) {
        log(error, "Failed to fetch customer info at /preferences");
      }
    };

    getCustomerInfo();
  }, [push]);

  return (
    <div className="preferences-sub-section">
      <div>
        <div className="title">
          <h5>Your information</h5>
        </div>
        {customerInfo ? (
          <div className="main-content customer-info-container">
            <div className="info-table">
              <div className="field">
                <label>Name</label>
                <span>{`${customerInfo.first_name} ${customerInfo.last_name}`}</span>
              </div>
              <div className="field">
                <label>Email</label>
                <span>{customerInfo.email ?? "N/A"}</span>
              </div>
              <div className="field">
                <label>Company</label>
                <span>{customerInfo.company ?? "N/A"}</span>
              </div>
              <div className="field">
                <label>Company ID</label>
                <span>{customerInfo.company_accpac_no ?? "N/A"}</span>
              </div>
              <div className="field">
                <label>Country</label>
                <span>
                  {customerInfo.customer_branch.toUpperCase() ?? "N/A"}
                </span>
              </div>
              <div className="field">
                <label>Available Brands</label>
                <span>
                  {customerInfo.brands ? customerInfo.brands.join(", ") : "N/A"}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <Wrapper margin="1em 0">
            <LoadingSpinner />
          </Wrapper>
        )}
      </div>
    </div>
  );
};

export default CustomerInfo;
