import { SVGProps } from "react"
const CaretCircleDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 153.8 154"
    {...props}
  >
    <style>{'.caretCircleDown{fill:#FFFFFF}'}</style>
    <path
    d="M77 153.9c-7.5.3-14.7-.6-21.7-2.7-9-2.8-17.4-6.9-24.9-12.7-11.3-8.8-19.6-19.8-25.1-33C2.2 98.1.9 90.4.2 82.4c-.5-6.6-.1-13.1 1-19.6C3.3 51.2 7.7 40.5 15 31.1c6.8-8.8 14.8-16.3 24.6-21.8 8.1-4.6 16.9-7.2 26-8.5 7.5-1 15-1.2 22.4-.1 9.9 1.4 19.4 4.3 28.1 9.6 7.1 4.4 13.2 9.7 18.7 15.8 6.5 7.2 11.4 15.4 14.6 24.6 2.2 6.4 3.4 13 4.1 19.8.7 6.3.2 12.6-.7 18.8-1.5 10.5-5.2 20.2-11.1 29.1-5.6 8.4-12.3 15.7-20.5 21.7-7.7 5.6-16.3 9.5-25.6 11.7-6.1 1.7-12.4 2.4-18.6 2.1zm67.3-76.3c1-37.8-31-66.4-64.3-67.9C40.8 8 8.9 40.6 9.6 76c-1.9 36.7 30.6 69.8 70.2 68.5 32.6-1 65.2-29.9 64.5-66.9z"
    className="caretCircleDown"
    />
    <path
    d="M77.1 57.2h28.5c1.3 0 2.7.3 4 .6 5.7 1.3 9.5 10.3 4.9 15.3-3.2 3.5-6.6 6.8-9.9 10.1C98 89.8 91.4 96.4 84.9 103c-4.7 4.8-10.9 4.9-15.7.2C59.5 93.6 50 84 40.4 74.3c-1-1-1.8-2.2-2.4-3.4-2.5-5.1 1.1-12.8 7.7-13.5 1.1-.1 2.2-.2 3.3-.2h28.1zm-29.8 10c-.1.1-.1.2-.2.3 2.3 2.2 4.6 4.4 6.8 6.7L75.7 96c1.2 1.2 1.4 1.2 2.3.3l28.2-28.2c.2-.2.2-.5.4-.8-19.9-.1-39.6-.1-59.3-.1z"
    className="caretCircleDown"
    />
  </svg>
)
export default CaretCircleDown
