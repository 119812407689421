export enum subdomainTypes {
  NZ = "nz",
  AU = "au",
  CA = "ca"
}

interface IAvailableSubdomains {
  [key: string]: subdomainTypes
}

export const getAvailableSubdomain = (subdomain: string): subdomainTypes | "" => {
  const availableSubdomains: IAvailableSubdomains = {
    "nz": subdomainTypes.NZ,
    "au": subdomainTypes.AU,
    "ca": subdomainTypes.CA,
    "nz-store": subdomainTypes.NZ,
    "au-store": subdomainTypes.AU,
    "ca-store": subdomainTypes.CA,
    "nz-develop": subdomainTypes.NZ,
    "au-develop": subdomainTypes.AU,
    "ca-develop": subdomainTypes.CA,
    "nz-staging": subdomainTypes.NZ,
    "au-staging": subdomainTypes.AU,
    "ca-staging": subdomainTypes.CA
  };


  return availableSubdomains[subdomain] || ""
}

