import React from "react";

interface Props {
  state: string | undefined;
  showSyd: boolean;
  showMel: boolean;
  showPer: boolean;
}

const PickupNotice = ({ state, showSyd, showMel, showPer }: Props) => {
  if (
    ((state === "New South Wales" || state === "NSW") && showSyd) ||
    ((state === "Victoria" || state === "VIC") && showMel) ||
    ((state === "Western Australia" || state === "WA") && showPer)
  ) return (
    <div className="alert alert-warning">
      <div
        className="glyphicon glyphicon-info-sign infoIcon"
        style={{ marginRight: "5px", opacity: "1" }}
      />
      <div style={{ opacity: "1", paddingTop: "3px" }}>
        Please allow 4 business hours for order pick up.
      </div>
    </div>
  );
  else return <></>;
};

export default PickupNotice;
