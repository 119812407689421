import { View } from "@react-pdf/renderer";
import FashionBizInfo from "../../components/FashionBizInfo/FashionBizInfo";
import Heading from "../../components/Heading/Heading";
import Logo from "../../components/Logo/Logo";
import PDFWrapper from "../PDFWrapper";
import TransactionCosts from "./TransactionCosts/TransactionCosts";
import TransactionData from "./TransactionData/TransactionData";
import TransactionSummary from "./TransactionSummary/TransactionSummary";
import { subdomainTypes } from "../../../../context/availableSubdomains";

interface Props {
  transactionDetails: any;
  appBranch: subdomainTypes | "";
}

const CreditNotePDF = (props: Props) => {
  const { transactionDetails, appBranch } = props;

  return (
    <PDFWrapper title="Credit Note">
      <View>
        <Logo appBranch={appBranch} />
        <FashionBizInfo appBranch={appBranch} />
        <Heading>Credit Note</Heading>
        <View style={{ marginTop: 30 }}>
          <TransactionData credit transactionDetails={transactionDetails} />
          {transactionDetails.items && transactionDetails.items.length > 0 && (
            <TransactionCosts transactionDetails={transactionDetails} />
          )}
          <TransactionSummary credit transactionDetails={transactionDetails} />
        </View>
      </View>
    </PDFWrapper>
  );
};

export default CreditNotePDF;
